import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type BookingsOverviewFiltersViewsState = {
  value?: string;
  blockApply: boolean;
};

const initialState: BookingsOverviewFiltersViewsState = {
  value: undefined,
  blockApply: false,
};

function setValueFunction(
  state: BookingsOverviewFiltersViewsState,
  action: PayloadAction<BookingsOverviewFiltersViewsState['value']>
) {
  return {
    ...state,
    value: action.payload,
  };
}

function setBlockApplyFunction(
  state: BookingsOverviewFiltersViewsState,
  action: PayloadAction<BookingsOverviewFiltersViewsState['blockApply']>
) {
  return {
    ...state,
    blockApply: action.payload,
  };
}

function resetStateFunction() {
  return initialState;
}

const slice = createSlice({
  name: 'bookingsOverviewFilters.filtersViews',
  initialState,
  reducers: {
    setValue: setValueFunction,
    setBlockApply: setBlockApplyFunction,
    resetState: resetStateFunction,
  },
});

export const { setValue, setBlockApply, resetState } = slice.actions;
export default slice.reducer;
