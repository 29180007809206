import type { Validity } from 'types/common/validationTypes';
import type { BookingPayment } from 'routes/tourOperator/bookings/types/bookingPayments/bookingPayments';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import FetchingStatus from 'constants/statuses/FetchingStatus';

export type BookingPaymentsState = {
  fetching: FetchingStatus | null;
  validity: Validity | null;
  value: BookingPayment[] | null;
  updating: FetchingStatus | null;
  updatingKey: string | number | null;
};

const initialState: BookingPaymentsState = {
  fetching: null,
  validity: null,
  value: null,
  updating: null,
  updatingKey: null,
};

function setFetchingFunction(state: BookingPaymentsState, action: PayloadAction<BookingPaymentsState['fetching']>) {
  return {
    ...state,
    fetching: action.payload,
  };
}

function setValidityFunction(state: BookingPaymentsState, action: PayloadAction<BookingPaymentsState['validity']>) {
  return {
    ...state,
    validity: action.payload,
  };
}

function setBookingPaymentsFunction(state: BookingPaymentsState, action: PayloadAction<BookingPaymentsState['value']>) {
  return {
    ...state,
    value: action.payload,
  };
}

function setUpdatingFunction(state: BookingPaymentsState, action: PayloadAction<BookingPaymentsState['updating']>) {
  return {
    ...state,
    updating: action.payload,
  };
}

function setUpdatingKeyFunction(
  state: BookingPaymentsState,
  action: PayloadAction<BookingPaymentsState['updatingKey']>
) {
  return {
    ...state,
    updatingKey: action.payload,
  };
}

function resetStateFunction() {
  return initialState;
}

const slice = createSlice({
  name: 'bookingPayments',
  initialState,
  reducers: {
    setFetching: setFetchingFunction,
    setValidity: setValidityFunction,
    setBookingPayments: setBookingPaymentsFunction,
    setUpdating: setUpdatingFunction,
    setUpdatingKey: setUpdatingKeyFunction,
    resetState: resetStateFunction,
  },
});

export const { setFetching, setValidity, setBookingPayments, setUpdating, setUpdatingKey, resetState } = slice.actions;
export default slice.reducer;
