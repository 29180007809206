import type { DragSourceMonitor, DropTargetMonitor } from 'react-dnd';
import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import styled from 'styled-components/macro';
import type { DraggableTableRowProps, DragObjectWithTypeAndIndex } from './types/draggableTableRowTypes';

const defaultType = 'DraggableTableRow';

function DraggableTableRow({
  index,
  type = defaultType,
  accept = [defaultType],
  moveRow,
  className,
  style,
  ...props
}: DraggableTableRowProps): JSX.Element {
  const ref = useRef<HTMLTableRowElement>(null);
  const useDropArgs = {
    accept,
    collect: (monitor: DropTargetMonitor) => {
      const dragTarget = (monitor.getItem() || {}) as DragObjectWithTypeAndIndex;

      if (dragTarget.index === index && dragTarget.type === type) {
        return {};
      }

      if (dragTarget.type !== type) {
        return {
          isUpward: monitor.isOver(),
        };
      }

      return {
        isUpward: monitor.isOver() && dragTarget.index > index,
        isDownward: monitor.isOver() && dragTarget.index < index,
      };
    },
    drop: (item: DragObjectWithTypeAndIndex) => {
      moveRow(item, { index, type });
    },
  };
  const useDragArgs = {
    item: { type, index },
    collect: (monitor: DragSourceMonitor) => ({ isDragging: monitor.isDragging() }),
  };
  const [{ isUpward, isDownward }, drop] = useDrop(useDropArgs);
  const [, drag] = useDrag(useDragArgs);

  drop(drag(ref));

  return (
    <TableRow ref={ref} className={className} style={style} isUpward={isUpward} isDownward={isDownward} {...props} />
  );
}

export default DraggableTableRow;

type TableRowProps = {
  isUpward?: boolean;
  isDownward?: boolean;
};

const TableRow = styled.tr<TableRowProps>`
  cursor: move;

  > td {
    ${({ theme, isUpward }) =>
      isUpward &&
      `
      border-top: 2px dashed ${theme.palette.info.default} !important;
    `}

    ${({ theme, isDownward }) =>
      isDownward &&
      `
      border-bottom: 2px dashed ${theme.palette.info.default} !important;
    `}
  }
`;
