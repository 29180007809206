import type { AppThunk } from 'state';
import type { Website } from 'common/dataService/types/websitesTypes';
import FetchingStatus from 'constants/statuses/FetchingStatus';
import fetchWebsites from './fetchWebsites';
import getWebsites from '../selectors/getWebsites';
import { setFetching } from '../slice';

function loadWebsites(): AppThunk<Website[]> {
  return async function loadWebsitesThunk(dispatch, getState) {
    try {
      let websites = getWebsites(getState());

      if (websites !== null) {
        return websites;
      }

      await dispatch(fetchWebsites());

      websites = getWebsites(getState());

      if (websites !== null) {
        return websites;
      }
    } catch (error) {
      dispatch(setFetching(FetchingStatus.Failed));
      window.Rollbar.error(error);
    }

    return [];
  };
}

export default loadWebsites;
