import type { FlightCalendar } from 'common/packageCache';
import React from 'react';
import Table from 'comp/wraps/table';
import columns from './tableConfig/columns';

type DateCellTooltipProps = {
  dateData: FlightCalendar[];
};

function DateCellTooltip({ dateData }: DateCellTooltipProps): JSX.Element {
  return (
    <Table
      columns={columns}
      dataSource={dateData}
      pagination={false}
      configuration={{ editColumns: false, tableName: 'dateCellTooltip' }}
    />
  );
}

export default DateCellTooltip;
