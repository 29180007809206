import type { FormConfig, FormConfigField } from 'comp/screenBuilder/comp/formBuilder/types/formConfigTypes';

function getAllFieldsFromForm(form: FormConfig): FormConfigField[] {
  const { sections } = form;

  return sections
    .map(({ rows }) => rows)
    .reduce((acc, curr) => acc.concat(curr), [])
    .map(({ fields }) => fields)
    .reduce((acc, curr) => acc.concat(curr), []);
}

export default getAllFieldsFromForm;
