import type { RootState } from 'state';
import type { GetFlightRoutesRequestParameters } from 'common/packageCache';
import type { RoutesFiltersState } from '../slice';

type RoutesFiltersStateEntry = [
  keyof RoutesFiltersState,
  string | number | boolean | { key: number; value: string } | null
];

const propertiesWithSelectValues: string[] = [];

function transformStateToRequest(state: RootState): GetFlightRoutesRequestParameters {
  const result = (Object.entries(state.flightRoutes.filtersValues) as RoutesFiltersStateEntry[]).reduce(
    (accumulator: GetFlightRoutesRequestParameters, current) => {
      const [property, value] = current;

      if (value !== null) {
        if (propertiesWithSelectValues.indexOf(property) !== -1 && typeof value === 'object') {
          // Because we can't be sure the property we are accessing is not of type boolean, typescript is throwing an error.
          // The type conversion here is the suggested solution from this thread https://github.com/microsoft/TypeScript/issues/31663.
          (accumulator[property] as any) = value.key;
        } else {
          (accumulator[property] as any) = value;
        }
      }

      return accumulator;
    },
    {} as GetFlightRoutesRequestParameters
  );

  if (result.airportsFrom) {
    const { departureGroupings } = state.dataService.airports.airportGroupings;

    if (departureGroupings) {
      result.airportsFrom = result.airportsFrom
        .map((airport) => {
          const grouping = departureGroupings.airportGroupings.find(({ code }) => code === airport);
          if (grouping) {
            return grouping.airports.map(({ code }) => code);
          }
          return airport;
        })
        .flat();
    }
  }

  if (result.airportsTo) {
    const { destinationGroupings } = state.dataService.airports.airportGroupings;

    if (destinationGroupings) {
      result.airportsTo = result.airportsTo
        .map((airport) => {
          const grouping = destinationGroupings.airportGroupings.find(({ code }) => code === airport);
          if (grouping) {
            return grouping.airports.map(({ code }) => code);
          }
          return airport;
        })
        .flat();
    }
  }

  return result;
}

export default transformStateToRequest;
