import { FlightDefinitionRulesModel } from 'common/dataService';
import type { AppDispatch } from 'state';
import deleteFlightDefinitionRules from '../reducers/DefinitionRules/thunks/deleteFlightDefinitionRules';
import executeFetchDefinitionRules from './executeFetchDefinitionRules';

async function deleteDefinintionRules(dispatch: AppDispatch, model: FlightDefinitionRulesModel): Promise<void> {
  if (model?.id) {
    await dispatch(deleteFlightDefinitionRules(model.id));
    dispatch(executeFetchDefinitionRules);
  }
}

export default deleteDefinintionRules;
