import React from 'react';
import { useDispatch } from 'react-redux';
import { Tooltip } from 'antd';
import { FormattedMessage } from 'react-intl';
import FontAwesomeIcon from 'comp/wraps/faIcon/FAIcon';
import { FlightDefinitionGroupsModel } from 'common/dataService';
import ConfirmationButton from 'comp/wraps/confirmationButton/ConfirmationButton';
import deleteDefinintionGroups from 'routes/tourOperator/flightCache/flightDefinitionGroups/managers/deleteDefinitionGroups';

function renderDeleteCell(record: FlightDefinitionGroupsModel): JSX.Element {
  return <DeleteFlightDefinitionGroup record={record} />;
}

export default renderDeleteCell;

type FlightDefinitionGroupsModelProp = {
  record: FlightDefinitionGroupsModel;
};

function DeleteFlightDefinitionGroup({ record }: FlightDefinitionGroupsModelProp): JSX.Element {
  const dispatch = useDispatch();

  function onSubmit() {
    deleteDefinintionGroups(dispatch, record);
  }

  return (
    <Tooltip title={<FormattedMessage id='delete' />}>
      <ConfirmationButton
        buttonProps={{ ghost: true, disabled: false, danger: true, icon: <FontAwesomeIcon icon='times' /> }}
        alertMessage={<FormattedMessage id='flightDefinitionGroup.delete' />}
        onSubmit={onSubmit}
        visibleForm={false}
      />
    </Tooltip>
  );
}
