import type { FormInstance } from 'antd/lib/form/hooks/useForm';
import type { CustomEventDefinition, EventHandlerFunction } from 'comp/screenBuilder/types/eventsTypes';
import { useState, useEffect } from 'react';
import usePrevious from 'comp/hooks/usePrevious';
import resolvePathValue from 'utils/object/resolvePathValue';
import immutableRemoveAtIndex from 'utils/array/immutableRemoveAtIndex';
import getOptionsFromData from './utils/getOptionsFromData';
import formatOptions from './utils/formatOptions';
import type { ListDropdownType, ListDropdownOption } from '../../types';

function useListDropdown<T>(
  form: FormInstance,
  formConfigKey: string,
  data: Record<string, unknown>,
  pathToValues: (string | number)[],
  timestamp: number | undefined,
  uniqueIdProp?: string[],
  eventHandlers?: EventHandlerFunction
): [ListDropdownType<T>] {
  // If the value is of type array then we need to use the dropdown.
  const listDropdownIsRequired = Array.isArray(resolvePathValue<T>(data, pathToValues));
  // TODO: Use memo callback for this?
  // On each render we calculate the next available options.
  const nextOptions = getOptionsFromData<T>(listDropdownIsRequired, data, pathToValues, uniqueIdProp);
  // Store of all currently available options.
  const [options, setOptions] = useState(nextOptions);
  // These are the options visible to the user. They must come from the options store above.
  const [filteredOptions, setFilteredOptions] = useState<ListDropdownOption<T>[]>(options);
  const [selectedOption, setSelectedOption] = useState<ListDropdownOption<T> | undefined>();
  const [customEventDefinitions, setCustomEventDefinitions] = useState<CustomEventDefinition[]>([]);
  const prevSelectedOption = usePrevious(selectedOption);

  useEffect(() => {
    if (timestamp !== undefined) {
      setOptions(nextOptions);
      setFilteredOptions(nextOptions);

      if (selectedOption !== undefined) {
        setSelectedOption(nextOptions[selectedOption.value]);
      } else {
        setSelectedOption(nextOptions[0]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timestamp]);

  useEffect(() => {
    function onTriggerUpdate() {
      const internalData = form.getFieldsValue(true) as Record<string, unknown>;
      const internalListDropdownIsRequired = Array.isArray(resolvePathValue<T>(internalData, pathToValues));
      const internalNextOptions = getOptionsFromData<T>(
        internalListDropdownIsRequired,
        internalData,
        pathToValues,
        uniqueIdProp
      );
      setOptions(internalNextOptions);
      setFilteredOptions(internalNextOptions);
    }

    document.addEventListener(`trigger-list-dropdown-update-${formConfigKey}`, onTriggerUpdate as EventListener);

    return () => {
      document.removeEventListener(`trigger-list-dropdown-update-${formConfigKey}`, onTriggerUpdate as EventListener);
    };
  }, [form, formConfigKey, pathToValues, uniqueIdProp]);

  // Set the custom event definitions so we can apply event listeners and execute them in a separate side effect.
  useEffect(() => {
    if (eventHandlers && eventHandlers.customEventDefinitions) {
      setCustomEventDefinitions(eventHandlers.customEventDefinitions());
    }
  }, [eventHandlers]);

  // Side effect for executing custom event definitions.
  useEffect(() => {
    function executeCustomEvent(event: CustomEvent<unknown>) {
      const { type, detail } = event;
      const customEventDefinition = customEventDefinitions.find(({ on }) => on === type);

      if (customEventDefinition && eventHandlers && eventHandlers[customEventDefinition.attach]) {
        eventHandlers[customEventDefinition.attach](options, detail, setFilteredOptions);
      }
    }

    customEventDefinitions.forEach(({ on }) => document.addEventListener(on, executeCustomEvent as EventListener));

    return () => {
      customEventDefinitions.forEach(({ on }) => document.removeEventListener(on, executeCustomEvent as EventListener));
    };
  }, [options, customEventDefinitions, eventHandlers]);

  // Side effect for selected option change.
  useEffect(() => {
    if (selectedOption !== undefined) {
      if (prevSelectedOption && prevSelectedOption.value === selectedOption.value) {
        return;
      }

      if (eventHandlers && eventHandlers.onSelectedOptionChange) {
        eventHandlers.onSelectedOptionChange(selectedOption);
      }
    }
  }, [selectedOption, eventHandlers, prevSelectedOption]);

  // Api Methods
  function isListDropdownRequired() {
    return listDropdownIsRequired;
  }

  function getFilteredOptions() {
    return filteredOptions;
  }

  function getSelectedOption() {
    return selectedOption;
  }

  function getSelectedOptionValue() {
    if (selectedOption) {
      return selectedOption.value;
    }

    return undefined;
  }

  function getSelectedOptionUniqueIdPropValue() {
    if (selectedOption) {
      return selectedOption.uniqueIdPropValue;
    }

    return undefined;
  }

  function createAndSelectOption(option: T) {
    const startAtIndex = options.length > 0 ? options[options.length - 1].value + 1 : 0;
    const formattedNewOption = formatOptions([option], uniqueIdProp, startAtIndex);
    setOptions(options.concat(formattedNewOption));
    setFilteredOptions(filteredOptions.concat(formattedNewOption));
    setSelectedOption(formattedNewOption[0]);
  }

  function deleteOptionAtIndex(optionIndex: number) {
    const filteredOptionsValues = filteredOptions.map(({ value }) => value);
    const filteredOptionsIndexesInNewOptions = options
      // Get options values.
      .map(({ value }) => value)
      // Get filtered options indexes in options array.
      .map((value, index) => (filteredOptionsValues.indexOf(value) !== -1 ? index : undefined))
      .filter<number>((value): value is number => value !== undefined)
      // Map old filtered options indexes to indexes in new options array.
      .map((value) => {
        if (value < optionIndex) {
          return value;
        }

        if (value > optionIndex) {
          return value - 1;
        }

        return undefined;
      })
      .filter<number>((value): value is number => value !== undefined);

    // Options must be reformated to update the indexes correctly for the Form.List component.
    const newOptions = formatOptions(
      immutableRemoveAtIndex(
        options.map(({ option }) => option),
        optionIndex
      ),
      uniqueIdProp
    );
    const newFilteredOptions = newOptions.filter(
      (value, index) => filteredOptionsIndexesInNewOptions.indexOf(index) !== -1
    );

    setOptions(newOptions);
    setFilteredOptions(newFilteredOptions);
    setSelectedOption(newFilteredOptions[0]);
  }

  function replaceOptionAtIndex(optionIndex: number, newOption: T) {
    const filteredOptionsValues = filteredOptions.map(({ value }) => value);
    const filteredOptionsIndexesInNewOptions = options
      // Get options values.
      .map(({ value }) => value)
      // Get filtered options indexes in options array.
      .map((value, index) => (filteredOptionsValues.indexOf(value) !== -1 ? index : undefined))
      .filter<number>((value): value is number => value !== undefined)
      // Map old filtered options indexes to indexes in new options array.
      .map((value) => {
        if (value < optionIndex) {
          return value;
        }

        if (value > optionIndex) {
          return value - 1;
        }

        return undefined;
      })
      .filter<number>((value): value is number => value !== undefined);

    // Options must be reformated to update the indexes correctly for the Form.List component.
    const newOptions = formatOptions(
      immutableRemoveAtIndex(
        nextOptions.map(({ option }) => option),
        optionIndex
      ),
      uniqueIdProp
    );
    const newFilteredOptions = newOptions.filter(
      (value, index) => filteredOptionsIndexesInNewOptions.indexOf(index) !== -1
    );

    const startAtIndex = newOptions.length > 0 ? newOptions[newOptions.length - 1].value + 1 : 0;
    const formattedNewOption = formatOptions([newOption], uniqueIdProp, startAtIndex);

    setOptions(newOptions.concat(formattedNewOption));
    setFilteredOptions(newFilteredOptions.concat(formattedNewOption));
    setSelectedOption(formattedNewOption[0]);
  }

  const listDropdown = {
    isListDropdownRequired,
    getFilteredOptions,
    getSelectedOption,
    getSelectedOptionValue,
    getSelectedOptionUniqueIdPropValue,
    setSelectedOption,
    createAndSelectOption,
    deleteOptionAtIndex,
    replaceOptionAtIndex,
  };

  return [listDropdown];
}

export default useListDropdown;
