import AirportsFrom from './airportsFrom';
import AirportsTo from './airportsTo';
import Duration from './duration';
import Month from './month';

export type FilterItem = {
  key: string;
  component: () => JSX.Element;
};

const filters: FilterItem[] = [
  {
    key: 'airportsFrom',
    component: AirportsFrom,
  },
  {
    key: 'airportsTo',
    component: AirportsTo,
  },
  {
    key: 'month',
    component: Month,
  },
  {
    key: 'duration',
    component: Duration,
  },
];

export default filters;
