import type { AppDispatch } from 'state';
import { StaticRouteModel } from 'common/dataService/types/staticRouteTypes';
import deletestaticRoutes from '../reducers/thunks/deleteStaticroute';
import executeFetchStaticRoutes from './execFetchStaticRoutes';

async function removeStaticRoute(dispatch: AppDispatch, model: StaticRouteModel): Promise<void> {
  if (model?.id) {
    await dispatch(deletestaticRoutes(model));
    await dispatch(executeFetchStaticRoutes);
  }
}

export default removeStaticRoute;
