import React, { useEffect, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components/macro';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Tooltip } from 'antd';
import { FormattedMessage } from 'react-intl';
import mediaQuery from 'styles/utils/mediaQuery';
import { loadDepartureAirportsGroupings, loadDestinationAirportsGroupings } from 'common/dataService';
import {
  executeFetchFlightCalendarsAndLoadMaxFlightDuration,
  clearFlightCalendarsDataForKeyFromFilters,
} from 'routes/tourOperator/flightCache/flightCalendars/managers';
import { loadAndSetAirports, isSearchEnabled } from './reducers';
import { resetFilters } from './managers';
import FiltersList from './comp/filtersList';
import FlightCalendarsLegend from './comp/flightCalendarsLegend';

function FlightCalendarsFilters(): JSX.Element {
  const { breakpoints } = useContext(ThemeContext);
  const isLargeDevice = useMediaQuery({ minWidth: breakpoints.medium });
  const searchDisabled = !useSelector(isSearchEnabled);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadDepartureAirportsGroupings());
    dispatch(loadDestinationAirportsGroupings());
    dispatch(loadAndSetAirports());
  }, []);

  useEffect(() => {
    return () => {
      dispatch(resetFilters);
    };
  }, []);

  useEffect(() => {
    function onSignout() {
      dispatch(resetFilters);
    }

    document.addEventListener('signout', onSignout);

    return () => {
      document.removeEventListener('signout', onSignout);
    };
  }, [dispatch]);

  function handleOnClickSearch() {
    dispatch(clearFlightCalendarsDataForKeyFromFilters());
    dispatch(executeFetchFlightCalendarsAndLoadMaxFlightDuration());
  }

  return (
    <Root>
      <FiltersAndLegendWrap>
        <FiltersList isLargeDevice={isLargeDevice} />
        <FlightCalendarsLegend />
      </FiltersAndLegendWrap>
      {isLargeDevice && (
        <ActionsWrap>
          <SearchWrap>
            <Tooltip title={<FormattedMessage id='searchFlightRoutesTooltip' />}>
              <SearchButton type='primary' disabled={searchDisabled} onClick={handleOnClickSearch}>
                <FormattedMessage id='search' />
              </SearchButton>
            </Tooltip>
          </SearchWrap>
        </ActionsWrap>
      )}
    </Root>
  );
}

export default FlightCalendarsFilters;

const Root = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FiltersAndLegendWrap = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const SearchWrap = styled.div`
  display: none;

  ${mediaQuery.fromAndAbove.medium`
    display: block;
  `}
`;

const SearchButton = styled(Button)`
  flex: 0 0 auto;
  width: 144px;
`;

const ActionsWrap = styled.div`
  display: none;

  > *:not(:last-child) {
    margin-right: 16px;
  }

  ${mediaQuery.fromAndAbove.medium`
    display: flex;
    align-items: flex-start;
    margin-top: 22px;
  `}
`;
