import type { AppThunk } from 'state';
import { loadCarriers } from 'common/packageCache';
import { pushToFetching, removeFromFetching, setCarrier } from '../slice';
import { Carrier } from '../constants/filtersKeys';

function loadAndSetCarriers(): AppThunk {
  return async function loadAndSetCarriersThunk(dispatch) {
    try {
      dispatch(pushToFetching(Carrier));
      const carriers = await dispatch(loadCarriers());
      const carriersOptions = carriers
        .map((item) => ({ value: item, label: item }))
        .sort((a, b) => a.label.localeCompare(b.label));

      dispatch(setCarrier(carriersOptions));
      dispatch(removeFromFetching(Carrier));
    } catch (error) {
      dispatch(removeFromFetching(Carrier));
      window.Rollbar.error(error);
    }
  };
}

export default loadAndSetCarriers;
