import type { AppDispatch, RootState } from 'state';
import type { SelectOption } from 'types/common/SelectOption';
import { getDepartureAirportGroupings } from 'common/dataService';
import {
  getAirportsFromOptions,
  getAirportsToOptions,
  getAirportsToValue,
} from 'routes/tourOperator/flightCache/flightRoutes/comp/flightRoutesFilters';

function getOptionsForAirportsFrom(dispatch: AppDispatch, getState: () => RootState): SelectOption<string>[] {
  const departureGroupings = getDepartureAirportGroupings(getState());
  const selectedAirportsTo = getAirportsToValue(getState());

  if (selectedAirportsTo.length === 0) {
    const airportsFromOptionsMap = getAirportsFromOptions(getState());

    if (airportsFromOptionsMap !== null) {
      return Object.keys(airportsFromOptionsMap)
        .sort((a, b) => a.localeCompare(b))
        .map((item) => {
          if (departureGroupings) {
            const grouping = departureGroupings.airportGroupings.find(({ code }) => code === item);
            if (grouping) {
              return {
                value: item,
                label: item,
                data: grouping.airports.map(({ code }) => code),
              };
            }
          }

          return { value: item, label: item };
        });
    }

    return [];
  }

  const airportsToOptionsMap = getAirportsToOptions(getState());

  if (airportsToOptionsMap !== null) {
    return selectedAirportsTo
      .map((item) => airportsToOptionsMap[item])
      .flat()
      .map((item) => {
        if (departureGroupings) {
          const grouping = departureGroupings.airportGroupings.find(({ code }) => code === item);
          if (grouping) {
            return {
              value: item,
              label: item,
              data: grouping.airports.map(({ code }) => code),
            };
          }
        }

        return { value: item, label: item };
      });
  }

  return [];
}

export default getOptionsForAirportsFrom;
