import type { FormConfig, FormConfigField } from '../types/formConfigTypes';
import type { FormFieldContextShape } from '../comp/formSection/comp/formField/context';

function checkFormFieldsPermissions(form: FormConfig, context: FormFieldContextShape): FormConfig {
  const nextForm = form;
  nextForm.sections = form.sections.map((section) => {
    const nextSection = section;
    nextSection.rows = section.rows.map((row) => {
      const nextRow = row;
      nextRow.fields = nextRow.fields
        .map((field) => {
          if (field.formInput.context?.fieldKey === undefined) {
            return field;
          }
          if (
            context[field.formInput.context?.fieldKey]?.hasPermission !== undefined &&
            !context[field.formInput.context?.fieldKey]?.hasPermission
          ) {
            return null;
          }
          return field;
        })
        .filter<FormConfigField>((value): value is FormConfigField => value !== null);
      return nextRow;
    });
    return nextSection;
  });
  return nextForm;
}

export default checkFormFieldsPermissions;
