import React from 'react';
import styled from 'styled-components/macro';
import { useSelector, useDispatch } from 'react-redux';
import { Slider as AntdSlider, InputNumber, Button, Spin } from 'antd';
import { FormattedMessage } from 'react-intl';
import { isFetchingInProgressForRoute } from 'common/dataService';
import {
  setDurationValue,
  getDurationValue,
  getDurationOptions,
  getActiveSearchFiltersKey,
  getRouteFromSelectedFilters,
  updateMaxFlightDurationForActiveRoute,
} from 'routes/tourOperator/flightCache/flightCalendars/comp/flightCalendarsFilters';
import { getMaxFlightDurationForActiveRoute } from 'routes/tourOperator/flightCache/flightCalendars/managers';
import LabeledValue from 'comp/dataPresentation/labeledValue/LabeledValue';
import mediaQuery from 'styles/utils/mediaQuery';

function Duration(): JSX.Element {
  const value = useSelector(getDurationValue) || 0;
  const { min, max } = useSelector(getDurationOptions);
  const maxFlightDuration = useSelector(getMaxFlightDurationForActiveRoute);
  const activeSearchFiltersKey = useSelector(getActiveSearchFiltersKey);
  const route = useSelector(getRouteFromSelectedFilters);
  const isFetchingInProgress = useSelector(isFetchingInProgressForRoute(route));
  const durationDisabled = activeSearchFiltersKey === null;
  const updateDisabled = durationDisabled || value === maxFlightDuration;
  const label = durationDisabled
    ? { id: 'duration' }
    : { id: 'durationWithCurrent', values: { duration: maxFlightDuration || 'Not Set' } };
  const dispatch = useDispatch();

  function handleOnChangeSlider(nextValue: number) {
    dispatch(setDurationValue(nextValue));
  }

  function handleOnChangeInput(nextValue?: string | number) {
    if (nextValue) {
      if (typeof nextValue === 'string') {
        dispatch(setDurationValue(parseInt(nextValue, 10)));
      } else {
        dispatch(setDurationValue(nextValue));
      }
    }
  }

  function handleOnClickUpdateMaxFlightDuration() {
    dispatch(updateMaxFlightDurationForActiveRoute(value));
  }

  return (
    <LabeledValue label={label}>
      <Spin spinning={isFetchingInProgress}>
        <Root>
          <RangeValue>{min}</RangeValue>
          <Slider min={min} max={max} value={value} disabled={durationDisabled} onChange={handleOnChangeSlider} />
          <RangeValue>{max}</RangeValue>
          <InputNumber min={min} max={max} value={value} disabled={durationDisabled} onChange={handleOnChangeInput} />
          <Button disabled={updateDisabled} onClick={handleOnClickUpdateMaxFlightDuration}>
            <FormattedMessage id='maxFlightDuration.update' />
          </Button>
        </Root>
      </Spin>
    </LabeledValue>
  );
}

export default Duration;

const Root = styled.div`
  display: flex;
  align-items: center;

  > *:not(:last-child) {
    margin-right: 16px;
  }
`;

const RangeValue = styled.div``;

const Slider = styled(AntdSlider)`
  width: 100%;

  ${mediaQuery.fromAndAbove.medium`
    width: 160px;
  `}
`;
