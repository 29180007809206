import React, { useState } from 'react';
import { Tooltip, notification, Spin } from 'antd';
import { FormattedMessage } from 'react-intl';
import FontAwesomeIcon from 'comp/wraps/faIcon/FAIcon';
import { DataServiceFlightDefinitionGroups, FlightDefinitionGroupsModel } from 'common/dataService';
import { ResponseError } from 'httpClient';
import ConfirmationButton from 'comp/wraps/confirmationButton/ConfirmationButton';

function renderRegeneratePeriodCell(text: string, record: FlightDefinitionGroupsModel): JSX.Element {
  return <RegeneratePeriodGroup groupId={record?.id ?? 0} />;
}

export default renderRegeneratePeriodCell;

type FlightDefinitionGroupsModelProp = {
  groupId: number;
};

function RegeneratePeriodGroup({ groupId }: FlightDefinitionGroupsModelProp): JSX.Element {
  const [loading, setLoading] = useState(false);

  function onClickRegenerate() {
    setLoading(true);

    void DataServiceFlightDefinitionGroups.postRegeneratePeriods(groupId)
      .then((response) => {
        notification.info({
          message: 'Successfully regenerated periods',
          description: `Regenerated ${response} periods`,
        });
        setLoading(false);
      })
      .catch((error) => {
        const err = error as ResponseError;
        notification.error({
          message: 'Error on regenerating periods!',
          description: err.response?.data?.message,
        });
        setLoading(false);
      });
  }

  return (
    <Tooltip title={<FormattedMessage id='regeneratePeriod' />}>
      <ConfirmationButton
        buttonProps={{
          ghost: true,
          disabled: false,
          type: 'primary',
          icon: (
            <Spin spinning={loading}>
              <FontAwesomeIcon icon='sync' />
            </Spin>
          ),
        }}
        alertMessage={<FormattedMessage id='group.regeneratePeriodMessage' />}
        onSubmit={onClickRegenerate}
        visibleForm={false}
      />
    </Tooltip>
  );
}
