/* eslint no-param-reassign: [2, { "props": false }] */

import type { AxiosInstance, AxiosResponse, AxiosRequestConfig } from 'axios';
import type { ResponseError } from 'httpClient';
import axios from 'axios';
import qs from 'qs';
import { notification } from 'antd';
import getAuthToken from 'localStorage/methods/getAuthToken';
import getErrorDescription from './utils/getErrorDescription';
import extendAuditMessage from './utils/extendAuditMessage';

/**
 * I want to do this globally but the following code throws an error:
 *
 * Subsequent property declarations must have the same type.
 * Property 'headers' must be of type 'any', but here has type '{ Authorization: string; } | undefined'.
 *
 * declare module 'axios' {
 *  export interface AxiosRequestConfig {
 *    headers?: {
 *      Authorization: string;
 *    };
 *  }
 *}
 */

abstract class HttpClient {
  protected readonly instance: AxiosInstance;

  public constructor(baseURL: string) {
    this.instance = axios.create({ baseURL });

    this.instance.defaults.paramsSerializer = function paramsSerializer(params: any) {
      return qs.stringify(params, { arrayFormat: 'comma' });
    };

    this.initializeRequestInterceptor();
    this.initializeResponseInterceptor();
  }

  private initializeRequestInterceptor() {
    this.instance.interceptors.request.use(this.requestInterceptor);
  }

  private initializeResponseInterceptor() {
    this.instance.interceptors.response.use(this.responseInterceptor, this.onRejectedResponse);
  }

  private requestInterceptor = (config: AxiosRequestConfig): AxiosRequestConfig | Promise<AxiosRequestConfig> => {
    const token = getAuthToken();

    if (config.headers) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return extendAuditMessage(config);
  };

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  private responseInterceptor = ({ data }: AxiosResponse): Promise<any> => data;

  private onRejectedResponse = (error: ResponseError): Promise<never> => {
    if (error?.response?.data) {
      if (typeof error?.response?.data === 'string') {
        error.response.data = { simplifiedDescription: error?.response?.data };
      } else {
        error.response.data.simplifiedDescription = getErrorDescription(error);
      }
    }

    if (error?.response?.data?.status === 400) {
      const errorData = error?.response?.data;
      const message = errorData.title;
      const description = errorData.errors?.messages.join('n/');
      notification.error({ message, description });
    }

    return Promise.reject(error);
  };
}

export default HttpClient;
