import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import FetchingStatus from 'constants/statuses/FetchingStatus';
import BookingsExportType from 'constants/bookingsExport/BookingsExportType';

export type BookingsExportState = {
    [BookingsExportType.Overview] : { fetching: FetchingStatus | null },
    [BookingsExportType.RefundClaims] : { fetching: FetchingStatus | null },
    [BookingsExportType.ProductAudit] : { fetching: FetchingStatus | null }
};

const initialState: BookingsExportState = {
    [BookingsExportType.Overview]: { fetching: null },
    [BookingsExportType.RefundClaims]: { fetching: null },
    [BookingsExportType.ProductAudit]: { fetching: null }
};

function setFetchingFunction(state: BookingsExportState, action: PayloadAction<{exportType: BookingsExportType, fetchingStatus: FetchingStatus}>) {
    return {
      ...state,
      [action.payload.exportType]: { fetching: action.payload.fetchingStatus }
    };
}

const slice = createSlice({
    name: 'bookingsExport',
    initialState,
    reducers: {
      setFetching: setFetchingFunction
    },
});
  
export const {
    setFetching
} = slice.actions;

export default slice.reducer;