import type { ButtonProps } from 'antd/lib/button';
import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Modal, Button, Form, Input, Col, Alert, Checkbox, Select } from 'antd';
import styled from 'styled-components/macro';
import { FormattedMessage } from 'react-intl';
import Severity from 'constants/Severity';
import trimExtraSpaces from 'utils/string/trimExtraSpaces';
import getEnums from 'routes/tourOperator/bookings/reducers/configs/enums/selectors/getEnums';
import { EnumValue } from 'routes/tourOperator/bookings/types/configs/enumsTypes';
import { BookingAudit } from 'routes/tourOperator/bookings/types/bookingAudit/bookingAudit';

type ConfirmationButtonProps<T> = {
  alertMessage: React.ReactNode;
  alertDescription?: React.ReactNode;
  buttonProps?: ButtonProps;
  additionalData?: T;
  auditRequired?: boolean;
  onSubmit: (audit: BookingAudit, additionalData?: T) => void;
  onClickCancel?: () => void;
  onShouldModalOpen?: () => Promise<boolean>;
  children?: React.ReactNode;
  visibleForm?: boolean;
  otherAuditRequired?: boolean;
};

function ConfirmationButton<T>({
  alertMessage,
  alertDescription,
  buttonProps,
  additionalData,
  auditRequired,
  onSubmit,
  onClickCancel,
  onShouldModalOpen,
  children,
  visibleForm = true,
  otherAuditRequired = false
}: ConfirmationButtonProps<T>): JSX.Element {
  const [form] = Form.useForm<BookingAudit>();
  const [visible, setVisible] = useState(false);
  const messageInputRef = useRef<any>();
  const messageMinLength = 1;
  const messageMaxLength = 128;
  const configEnums = useSelector(getEnums);

  async function handleOnClickOpenForm() {
    if (onShouldModalOpen) {
      setVisible(await onShouldModalOpen());
    } else {
      setVisible(true);
    }
  }

  function handleOnClickCancel() {
    if (onClickCancel) {
      onClickCancel();
    }

    setVisible(false);
  }

  function handleOnClickOk() {
    form.submit();
  }

  function onFinish(audit: BookingAudit) {
    if (auditRequired) {
      const messageTrimmed = audit.message.trim();
      if (messageTrimmed.length < messageMinLength && messageInputRef.current != null) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        messageInputRef.current.focus();
        form.setFieldsValue({ message: messageTrimmed });
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        form.validateFields();
        return;
      }
    }
    onSubmit(audit, additionalData);
    form.resetFields();
    setVisible(false);
  }

  const reasonOptions: { label: string; value: string }[] | undefined = configEnums?.find((key) => key.name.toLowerCase() === 'bookingauditreason')?.values.map((configEnum: EnumValue) => ({
    label: configEnum.value,
    value: configEnum.value
  })).sort((a, b) => a.label.localeCompare(b.label));

  return (
    <div>
      <Button {...buttonProps} onClick={handleOnClickOpenForm}>
        {children}
      </Button>
      <Modal
        visible={visible}
        title={<FormattedMessage id='confirmationModal.title' />}
        okText={<FormattedMessage id='common.submit' />}
        cancelText={<FormattedMessage id='common.cancel' />}
        onOk={handleOnClickOk}
        onCancel={handleOnClickCancel}
        destroyOnClose
        closable
      >
        <AlertMessage message={alertMessage} description={alertDescription} type={Severity.WARNING} showIcon />
        <Form form={form} name='confirmationForm' onFinish={onFinish} style={visibleForm ? {} : { display: 'none' }}>
          <Col>
            <Form.Item
              name='message'
              labelCol={{ span: 24 }}
              label={<FormattedMessage id='confirmationModal.additionalMessage' />}
              rules={[
                { required: auditRequired, message: 'Please enter the reason for this change.' },
                { min: messageMinLength, message: `The message should have at least ${messageMinLength} characters.` },
                { max: messageMaxLength, message: `The message should have maximum ${messageMaxLength} characters.` },
              ]}
              normalize={(value) => trimExtraSpaces(value)}
            >
              <Input.TextArea ref={messageInputRef} />
            </Form.Item>
            { otherAuditRequired && (
              <React.Fragment>
                <Form.Item
                name='reason'
                labelCol={{ span: 24 }}
                label={<FormattedMessage id='confirmationModal.reason' />}
                >
                  <Select
                  options={reasonOptions}
                  />
                </Form.Item>
                <Form.Item
                name='important'
                valuePropName='checked'
                label={<FormattedMessage id='confirmationModal.important' />}
                >
                  <Checkbox />
                </Form.Item>
              </React.Fragment>
            )}
          </Col>
        </Form>
      </Modal>
    </div>
  );
}

export default ConfirmationButton;

const AlertMessage = styled(Alert)`
  margin-bottom: 16px;
`;
