import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  FlightDefinitionRulesModel,
  FlightDefinitonRulesPagedResult,
} from 'common/dataService/types/flightDefinintionRulesTypes';
import FetchingStatus from 'constants/statuses/FetchingStatus';

export type DefinitionRulesState = {
  page: number;
  takeCount: number;
  sorting: string | null;
  fetching: FetchingStatus | null;
  value: FlightDefinitonRulesPagedResult | null;
  editModel: FlightDefinitionRulesModel | null;
  visibleModel: boolean;
  updating: FetchingStatus | null;
  updatingKey: string | null;
};

const initialState: DefinitionRulesState = {
  page: 1,
  takeCount: 25,
  sorting: null,
  fetching: null,
  value: null,
  editModel: null,
  visibleModel: false,
  updating: null,
  updatingKey: null,
};

function setPageFunction(state: DefinitionRulesState, action: PayloadAction<DefinitionRulesState['page']>) {
  return {
    ...state,
    page: action.payload,
  };
}

function setTakeCountFunction(state: DefinitionRulesState, action: PayloadAction<DefinitionRulesState['takeCount']>) {
  return {
    ...state,
    takeCount: action.payload,
  };
}

function setSortingFunction(state: DefinitionRulesState, action: PayloadAction<DefinitionRulesState['sorting']>) {
  return {
    ...state,
    sorting: action.payload,
  };
}

function setFetchingFunction(state: DefinitionRulesState, action: PayloadAction<DefinitionRulesState['fetching']>) {
  return {
    ...state,
    fetching: action.payload,
  };
}

function setFlightDefinitionRulesResponseFunction(
  state: DefinitionRulesState,
  action: PayloadAction<DefinitionRulesState['value']>
) {
  return {
    ...state,
    value: action.payload,
  };
}

function setUpdatingFunction(state: DefinitionRulesState, action: PayloadAction<DefinitionRulesState['updating']>) {
  return {
    ...state,
    updating: action.payload,
  };
}

function setVisibleModuleFunction(
  state: DefinitionRulesState,
  action: PayloadAction<DefinitionRulesState['visibleModel']>
) {
  return {
    ...state,
    visibleModel: action.payload,
  };
}

function setEditModelFunction(state: DefinitionRulesState, action: PayloadAction<DefinitionRulesState['editModel']>) {
  return {
    ...state,
    editModel: action.payload,
  };
}

function setUpdatingKeyFunction(
  state: DefinitionRulesState,
  action: PayloadAction<DefinitionRulesState['updatingKey']>
) {
  return {
    ...state,
    updatingKey: action.payload,
  };
}

const slice = createSlice({
  name: 'flightDefinitionRules',
  initialState,
  reducers: {
    setPage: setPageFunction,
    setTakeCount: setTakeCountFunction,
    setSorting: setSortingFunction,
    setFetching: setFetchingFunction,
    setFlightDefinitionRulesResponse: setFlightDefinitionRulesResponseFunction,
    setUpdating: setUpdatingFunction,
    setVisibleModule: setVisibleModuleFunction,
    setEditModel: setEditModelFunction,
    setUpdatingKey: setUpdatingKeyFunction,
  },
});

export const {
  setPage,
  setTakeCount,
  setSorting,
  setFetching,
  setFlightDefinitionRulesResponse,
  setUpdating,
  setVisibleModule,
  setEditModel,
  setUpdatingKey,
} = slice.actions;
export default slice.reducer;
