import type { InnerRef } from 'comp/screenBuilder';
import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components/macro';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Tooltip, Button, notification } from 'antd';
import { FormattedMessage } from 'react-intl';
import { FormattedMessageWithI18n } from 'i18n';
import FetchingStatus from 'constants/statuses/FetchingStatus';
import usePrevious from 'comp/hooks/usePrevious';
import FontAwesomeIcon from 'comp/wraps/faIcon/FAIcon';
import Drawer from 'comp/common/drawer/Drawer';
import { setCurrentEdit, getCurrentEdit } from 'routes/tourOperator/bookings/reducers/screensConfig';
import { getUpdatingProps } from '../../reducer';
import FlightRouteScreen from './comp/flightRouteScreen';
import Footer from './comp/footer';

type UpdateFlightRouteProps = {
  flightRouteKey: string;
};

function UpdateFlightRoute({ flightRouteKey }: UpdateFlightRouteProps): JSX.Element {
  const dispatch = useDispatch();
  const currentEditKey = useRef(`update-flight-route-${flightRouteKey}`);
  const [formSubmit] = Form.useForm();
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [updating, updatingKey] = useSelector(getUpdatingProps);
  const spinning = updating === FetchingStatus.InProgress;
  const prevUpdating = usePrevious(updating);
  const currentEdit = useSelector(getCurrentEdit);
  const currentEditActive = currentEdit === currentEditKey.current;
  const actionsDisabled = currentEdit !== null && !currentEditActive;
  const title = currentEditActive ? <FormattedMessage id='continueEdit' /> : <FormattedMessage id='view' />;
  const icon = currentEditActive ? <FontAwesomeIcon icon='pen' /> : <FontAwesomeIcon icon='eye' />;

  useEffect(() => {
    if (
      flightRouteKey === updatingKey &&
      prevUpdating === FetchingStatus.InProgress &&
      updating === FetchingStatus.Succeeded
    ) {
      dispatch(setCurrentEdit(null));
      setSubmitDisabled(true);
      setDrawerVisible(false);
      notification.info({
        message: <FormattedMessageWithI18n id='flightRoute.update.succeeded.title' />,
        description: <FormattedMessageWithI18n id='flightRoute.update.succeeded.description' />,
      });
    }
  }, [flightRouteKey, updating, updatingKey, prevUpdating, dispatch]);

  function onClickOpenDrawer() {
    if (currentEdit === null) {
      dispatch(setCurrentEdit(currentEditKey.current));
    }

    setDrawerVisible(true);
  }

  function onClickCloseDrawer(event: React.SyntheticEvent) {
    if (spinning) {
      return;
    }

    if (currentEditActive) {
      if (!event.currentTarget.classList.contains('ant-drawer-mask') || submitDisabled) {
        dispatch(setCurrentEdit(null));
        setSubmitDisabled(true);
      }
    }

    setDrawerVisible(false);
  }

  function handleOnClickActionButton(innerRef?: InnerRef) {
    if (innerRef) {
      if (innerRef.fragmentsNotInRead.length > 0) {
        setSubmitDisabled(true);
        return;
      }

      if (innerRef.formsTouched) {
        setSubmitDisabled(false);
      }
    }
  }

  const screenWrapProps = {
    flightRouteKey,
    contextValues: {
      actionsDisabled,
      preventFormProviderUpdate: currentEditActive,
      callbacks: {
        onClickActionButton: handleOnClickActionButton,
      },
    },
    formSubmit,
  };

  return (
    <Root>
      <Tooltip title={title}>
        <Button type='primary' icon={icon} danger={currentEditActive} ghost onClick={onClickOpenDrawer} />
      </Tooltip>
      <Drawer
        visible={drawerVisible}
        footer={<Footer formSubmit={formSubmit} onClickCancel={onClickCloseDrawer} submitDisabled={submitDisabled} />}
        title={<FormattedMessage id='flightRoute.update' />}
        destroyOnClose={!currentEditActive}
        onClose={onClickCloseDrawer}
        spinning={spinning}
      >
        <FlightRouteScreen {...screenWrapProps} />
      </Drawer>
    </Root>
  );
}

export default UpdateFlightRoute;

const Root = styled.div``;
