import type { RootState } from 'state';
import FetchingStatus from 'constants/statuses/FetchingStatus';

function isFetchingInProgress(state: RootState): boolean {
  const { fetching } = state.dataService.affiliates.affiliates;

  return fetching === FetchingStatus.InProgress;
}

export default isFetchingInProgress;
