import React from 'react';
import styled from 'styled-components/macro';
import { Avatar, Dropdown, Menu as AntdMenu } from 'antd';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import FontAwesomeIcon from 'comp/wraps/faIcon/FAIcon';
import localStorage from 'localStorage';
import { AUTH_TOKEN, EXPIRATION_TIME } from 'localStorage/constants/keys';
import { setAuthenticated } from 'state/reducers/auth/slice';
import { PATH_CHANGE_PASSWORD, PATH_LOGIN } from 'router/constants/routePaths';

function UserActions(): JSX.Element {
  const history = useHistory();
  const dispatch = useDispatch();

  function handleOnClickSignOut() {
    dispatch(setAuthenticated(false));
    localStorage.remove(AUTH_TOKEN);
    localStorage.remove(EXPIRATION_TIME);
    document.dispatchEvent(new CustomEvent('signout'));
    setTimeout(() => {
      history.push(PATH_LOGIN);
    }, 0);
  }

  function handleOnClickChangePassword() {
    setTimeout(() => {
      history.push(PATH_CHANGE_PASSWORD);
    }, 0);
  }

  function MenuContainer(): JSX.Element {
    return (
      <AntdMenu>
        <AntdMenu.Item onClick={handleOnClickChangePassword}>
          <FormattedMessage id='common.change-password' />
        </AntdMenu.Item>
        <AntdMenu.Item onClick={handleOnClickSignOut} icon={<FontAwesomeIcon icon='sign-out' />}>
          <FormattedMessage id='common.sign-out' />
        </AntdMenu.Item>
      </AntdMenu>
    );
  }

  return (
    <User>
      <Dropdown overlay={MenuContainer} placement='bottomCenter' arrow>
        <Button>
          <Avatar icon={<FontAwesomeIcon icon='user' />} />
        </Button>
      </Dropdown>
    </User>
  );
}

export default UserActions;

const User = styled.div`
  display: flex;
  flex-direction: column;
`;

const Button = styled.button`
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
`;
