import type { FormConfig, FormItem } from 'comp/screenBuilder/comp/formBuilder/types/formConfigTypes';
import getAllFieldsFromForm from './getAllFieldsFromForm';

function getNameListForForm(form: FormConfig, listIndex?: (string | number)[]): FormItem['name'][] {
  const formFields = getAllFieldsFromForm(form);
  return formFields.map(({ formItem }) => {
    if (listIndex) {
      return [...listIndex, ...formItem.name];
    }

    return formItem.name;
  });
}

export default getNameListForForm;
