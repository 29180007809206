/* eslint-disable @typescript-eslint/no-unsafe-return */

function resolvePathValue<T = any>(
  object: Record<string, unknown>,
  path: (string | number)[] | string,
  defaultValue: any = null
): T {
  const pathNormalized = typeof path === 'string' ? path.split('.') : path;
  return pathNormalized.reduce((o, p) => (o ? o[p] : defaultValue), object) as T;
}

export default resolvePathValue;
