import type { Validity } from 'types/common/validationTypes';
import type { FinancialTransaction } from 'routes/tourOperator/bookings/types/bookingFinancialTransaction/bookingFinancialTransaction';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import FetchingStatus from 'constants/statuses/FetchingStatus';

export type BookingFinancialTransactionsState = {
  fetching: FetchingStatus | null;
  validity: Validity | null;
  value: FinancialTransaction[] | null;
};

const initialState: BookingFinancialTransactionsState = {
  fetching: null,
  validity: null,
  value: null,
};

function setFetchingFunction(
  state: BookingFinancialTransactionsState,
  action: PayloadAction<BookingFinancialTransactionsState['fetching']>
) {
  return {
    ...state,
    fetching: action.payload,
  };
}

function setValidityFunction(
  state: BookingFinancialTransactionsState,
  action: PayloadAction<BookingFinancialTransactionsState['validity']>
) {
  return {
    ...state,
    validity: action.payload,
  };
}

function setBookingFinancialTransactionsFunction(
  state: BookingFinancialTransactionsState,
  action: PayloadAction<BookingFinancialTransactionsState['value']>
) {
  return {
    ...state,
    value: action.payload,
  };
}

function resetStateFunction() {
  return initialState;
}

const slice = createSlice({
  name: 'bookingFinancialTransactionsState',
  initialState,
  reducers: {
    setFetching: setFetchingFunction,
    setValidity: setValidityFunction,
    setBookingFinancialTransactions: setBookingFinancialTransactionsFunction,
    resetState: resetStateFunction,
  },
});

export const { setFetching, setValidity, setBookingFinancialTransactions, resetState } = slice.actions;
export default slice.reducer;
