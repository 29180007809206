function trimExtraSpaces(value: string) : string {
    let formattedValue = value.trimStart();

    if (value.length > 2 && value.charAt(value.length-2) === ' ' && value.charAt(value.length-1) === ' ') {
      formattedValue = value.substring(0, value.length-1);
    }

    return formattedValue;
}
  
export default trimExtraSpaces;  