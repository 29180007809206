import { combineReducers } from '@reduxjs/toolkit';
import websitesFullFilters from './websitesFullFilters/slice';
import websitesFull from './websitesFull/slice';
import websitesInitial from './websiteInitial/slice';

export default combineReducers({
  websitesFull,
  websitesFullFilters,
  websitesInitial,
});
