import type { AppThunk } from 'state';
import FetchingStatus from 'constants/statuses/FetchingStatus';
import handleRequestError from 'managers/handleRequestError';
import { setUpdating, setUpdatingKey } from '../slice';
import { DataServiceFlightDefinitionGroups } from 'common/dataService';

function deleteFlightDefinitionGroups(groupId: number): AppThunk {
  return async function deleteFlightDefinitionGroupsThunk(dispatch, getState) {
    try {
      const { updating } = getState().flightDefinitionGroups;

      if (updating === FetchingStatus.InProgress) {
        return;
      }

      dispatch(setUpdatingKey('delete'));
      dispatch(setUpdating(FetchingStatus.InProgress));

      await DataServiceFlightDefinitionGroups.deleteFlightDefinitionGroups(groupId);

      dispatch(setUpdating(FetchingStatus.Succeeded));
    } catch (error) {
      dispatch(handleRequestError(error, 'Error occured on deliting flight definition route!', setUpdating));
    }
  };
}

export default deleteFlightDefinitionGroups;
