import type { Props as FormattedMessageProps } from 'react-intl/lib/src/components/message';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import I18n from '../../I18n';

function FormattedMessageWithI18n(props: FormattedMessageProps): JSX.Element {
  return (
    <I18n>
      <FormattedMessage {...props} />
    </I18n>
  );
}

export default FormattedMessageWithI18n;
