import type { RootState } from 'state';

function areFlightCalendarsAvailableByKey(keys: [string, string]) {
  return function areFlightCalendarsAvailableByKeyCallback(state: RootState): boolean {
    const { outbound, inbound } = state.flightCalendars.data;
    const [outboundKey, inboundKey] = keys;

    if (outbound === null || inbound === null) {
      return false;
    }

    if (outbound !== null && (outbound[outboundKey] === undefined || outbound[outboundKey] === null)) {
      return false;
    }

    if (inbound !== null && (inbound[inboundKey] === undefined || inbound[inboundKey] === null)) {
      return false;
    }

    return true;
  };
}

export default areFlightCalendarsAvailableByKey;
