import { library } from '@fortawesome/fontawesome-svg-core';
import { faAd } from '@fortawesome/pro-light-svg-icons/faAd';
import { faBallotCheck } from '@fortawesome/pro-light-svg-icons/faBallotCheck';
import { faBan } from '@fortawesome/pro-light-svg-icons/faBan';
import { faBars } from '@fortawesome/pro-light-svg-icons/faBars';
import { faBarcodeRead } from '@fortawesome/pro-light-svg-icons/faBarcodeRead';
import { faBedEmpty } from '@fortawesome/pro-light-svg-icons/faBedEmpty';
import { faBook } from '@fortawesome/pro-light-svg-icons/faBook';
import { faBoxOpen } from '@fortawesome/pro-light-svg-icons/faBoxOpen';
import { faCalendarAlt } from '@fortawesome/pro-light-svg-icons/faCalendarAlt';
import { faCalendarEdit } from '@fortawesome/pro-light-svg-icons/faCalendarEdit';
import { faCalendarTimes } from '@fortawesome/pro-light-svg-icons/faCalendarTimes';
import { faCalendarWeek } from '@fortawesome/pro-light-svg-icons/faCalendarWeek';
import { faCarBus } from '@fortawesome/pro-light-svg-icons/faCarBus';
import { faCars } from '@fortawesome/pro-light-svg-icons/faCars';
import { faChartLine } from '@fortawesome/pro-light-svg-icons/faChartLine';
import { faCheck } from '@fortawesome/pro-light-svg-icons/faCheck';
import { faCheckCircle } from '@fortawesome/pro-light-svg-icons/faCheckCircle';
import { faChevronDown } from '@fortawesome/pro-light-svg-icons/faChevronDown';
import { faClock } from '@fortawesome/pro-light-svg-icons/faClock';
import { faCog } from '@fortawesome/pro-light-svg-icons/faCog';
import { faCommentsDollar } from '@fortawesome/pro-light-svg-icons/faCommentsDollar';
import { faCodeBranch } from '@fortawesome/pro-light-svg-icons/faCodeBranch';
import { faEnvelope } from '@fortawesome/pro-light-svg-icons/faEnvelope';
import { faEuroSign } from '@fortawesome/pro-light-svg-icons/faEuroSign';
import { faEye } from '@fortawesome/pro-light-svg-icons/faEye';
import { faFile } from '@fortawesome/pro-light-svg-icons/faFile';
import { faFileExport } from '@fortawesome/pro-light-svg-icons/faFileExport';
import { faFileUpload } from '@fortawesome/pro-light-svg-icons/faFileUpload';
import { faFireAlt } from '@fortawesome/pro-light-svg-icons/faFireAlt';
import { faGlobeEurope } from '@fortawesome/pro-light-svg-icons/faGlobeEurope';
import { faGlobe } from '@fortawesome/pro-light-svg-icons/faGlobe';
import { faHotel } from '@fortawesome/pro-light-svg-icons/faHotel';
import { faIdCard } from '@fortawesome/pro-light-svg-icons/faIdCard';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons/faInfoCircle';
import { faKey } from '@fortawesome/pro-light-svg-icons/faKey';
import { faLink } from '@fortawesome/pro-light-svg-icons/faLink';
import { faMailBulk } from '@fortawesome/pro-light-svg-icons/faMailBulk';
import { faMapMarkedAlt } from '@fortawesome/pro-light-svg-icons/faMapMarkedAlt';
import { faMapMarkerCheck } from '@fortawesome/pro-light-svg-icons/faMapMarkerCheck';
import { faMinus } from '@fortawesome/pro-light-svg-icons/faMinus';
import { faMinusCircle } from '@fortawesome/pro-light-svg-icons/faMinusCircle';
import { faParachuteBox } from '@fortawesome/pro-light-svg-icons/faParachuteBox';
import { faPen } from '@fortawesome/pro-light-svg-icons/faPen';
import { faPhone } from '@fortawesome/pro-light-svg-icons/faPhone';
import { faPlaneAlt } from '@fortawesome/pro-light-svg-icons/faPlaneAlt';
import { faPlus } from '@fortawesome/pro-light-svg-icons/faPlus';
import { faQuestionCircle } from '@fortawesome/pro-light-svg-icons/faQuestionCircle';
import { faRedo } from '@fortawesome/pro-light-svg-icons/faRedo';
import { faRoute } from '@fortawesome/pro-light-svg-icons/faRoute';
import { faSadCry } from '@fortawesome/pro-light-svg-icons/faSadCry';
import { faSearch } from '@fortawesome/pro-light-svg-icons/faSearch';
import { faShieldCheck } from '@fortawesome/pro-light-svg-icons/faShieldCheck';
import { faSignOut } from '@fortawesome/pro-light-svg-icons/faSignOut';
import { faSuitcase } from '@fortawesome/pro-light-svg-icons/faSuitcase';
import { faSwatchbook } from '@fortawesome/pro-light-svg-icons/faSwatchbook';
import { faSync } from '@fortawesome/pro-light-svg-icons/faSync';
import { faTag } from '@fortawesome/pro-light-svg-icons/faTag';
import { faTasks } from '@fortawesome/pro-light-svg-icons/faTasks';
import { faTicketAlt } from '@fortawesome/pro-light-svg-icons/faTicketAlt';
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { faTools } from '@fortawesome/pro-light-svg-icons/faTools';
import { faTrash } from '@fortawesome/pro-light-svg-icons/faTrash';
import { faUser } from '@fortawesome/pro-light-svg-icons/faUser';
import { faUserChart } from '@fortawesome/pro-light-svg-icons/faUserChart';
import { faUserFriends } from '@fortawesome/pro-light-svg-icons/faUserFriends';
import { faUtensils } from '@fortawesome/pro-light-svg-icons/faUtensils';
import { faWindowClose } from '@fortawesome/pro-light-svg-icons/faWindowClose';
import { faStar } from '@fortawesome/pro-light-svg-icons/faStar';
import { faHandshake } from '@fortawesome/pro-light-svg-icons/faHandshake';

library.add(
  faAd,
  faBallotCheck,
  faBan,
  faBars,
  faBarcodeRead,
  faBedEmpty,
  faBook,
  faBoxOpen,
  faCalendarAlt,
  faCalendarEdit,
  faCalendarTimes,
  faCalendarWeek,
  faCarBus,
  faCars,
  faChartLine,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faClock,
  faCog,
  faCommentsDollar,
  faCodeBranch,
  faEnvelope,
  faEuroSign,
  faEye,
  faFile,
  faFileExport,
  faFileUpload,
  faFireAlt,
  faGlobeEurope,
  faGlobe,
  faHotel,
  faHandshake,
  faIdCard,
  faInfoCircle,
  faKey,
  faLink,
  faMailBulk,
  faMapMarkedAlt,
  faMapMarkerCheck,
  faMinus,
  faMinusCircle,
  faParachuteBox,
  faPen,
  faPhone,
  faPlaneAlt,
  faPlus,
  faQuestionCircle,
  faRedo,
  faRoute,
  faSadCry,
  faSearch,
  faShieldCheck,
  faSignOut,
  faSuitcase,
  faSwatchbook,
  faSync,
  faTag,
  faTasks,
  faTicketAlt,
  faTools,
  faTimes,
  faTrash,
  faUser,
  faUserChart,
  faUserFriends,
  faUtensils,
  faWindowClose,
  faStar
);
