import type { AppDispatch } from 'state';
import { setTakeCount } from 'routes/tourOperator/flightCache/flightRoutes/comp/flightRoutesFilters';
import updatePageAndLoadResults from './updatePageAndLoadResults';

function updateTakeCountAndLoadResults(takeCount: number) {
  return async function updateTakeCountAndLoadResultsThunk(dispatch: AppDispatch): Promise<void> {
    dispatch(setTakeCount(takeCount));
    await dispatch(updatePageAndLoadResults(1));
  };
}

export default updateTakeCountAndLoadResults;
