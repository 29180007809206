import type { AppThunk } from 'state';
import FetchingStatus from 'constants/statuses/FetchingStatus';
import handleRequestError from 'managers/handleRequestError';
import { setFetching, setFlightDefinitionPeriodsResponse } from '../slice';
import { DataServiceFlightDefinitionRules, FlightCacheDefinitionPeriodsRequest } from 'common/dataService';

function fetchGeneratedDefinitionPeriods(requestParameters: FlightCacheDefinitionPeriodsRequest): AppThunk {
  return async function fetchGeneratedDefinitionPeriodsThunk(dispatch, getState) {
    try {
      const { fetching } = getState().flightDefinitionRules.generatedDefinitionPeriods;

      if (fetching === FetchingStatus.InProgress) {
        return;
      }

      dispatch(setFetching(FetchingStatus.InProgress));

      const data = await DataServiceFlightDefinitionRules.getFlightDefinitionPeriods(requestParameters);

      if (!data) {
        dispatch(setFetching(FetchingStatus.Failed));
        return;
      }

      dispatch(setFlightDefinitionPeriodsResponse(data));
      dispatch(setFetching(FetchingStatus.Succeeded));
    } catch (error) {
      dispatch(handleRequestError(error, 'Error occured on fetch generated definition periods!', setFetching));
    }
  };
}

export default fetchGeneratedDefinitionPeriods;
