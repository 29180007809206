import { FlightDefinitionGroupsModel } from 'common/dataService';
import type { AppDispatch } from 'state';
import { setVisibleModule } from '../reducers/slice';
import addFlightDefinitionGroups from '../reducers/thunks/addFlightDefinitionGroups';
import editFlightDefinitionGroups from '../reducers/thunks/editFlightDefinitionGroups';
import executeFetchDefinitionGroups from './executeFetchDefinitionGroups';

async function upsertDefinintionGroups(dispatch: AppDispatch, model: FlightDefinitionGroupsModel): Promise<void> {
  if (model?.id) {
    await dispatch(editFlightDefinitionGroups(model));
  } else if (model) {
    await dispatch(addFlightDefinitionGroups(model));
  }
  dispatch(setVisibleModule(false));
  dispatch(executeFetchDefinitionGroups);
}

export default upsertDefinintionGroups;
