import type { Validity } from 'types/common/validationTypes';
import type { BookingComment } from 'routes/tourOperator/bookings/types/bookingComment/bookingCommentTypes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import FetchingStatus from 'constants/statuses/FetchingStatus';

export type BookingCommentState = {
  fetching: FetchingStatus | null;
  validity: Validity | null;
  value: BookingComment[] | null;
  updating: FetchingStatus | null;
};

const initialState: BookingCommentState = {
  fetching: null,
  validity: null,
  value: null,
  updating: null,
};

function setFetchingFunction(state: BookingCommentState, action: PayloadAction<BookingCommentState['fetching']>) {
  return {
    ...state,
    fetching: action.payload,
  };
}

function setValidityFunction(state: BookingCommentState, action: PayloadAction<BookingCommentState['validity']>) {
  return {
    ...state,
    validity: action.payload,
  };
}

function setBookingCommentsFunction(state: BookingCommentState, action: PayloadAction<BookingCommentState['value']>) {
  return {
    ...state,
    value: action.payload,
  };
}

function setUpdatingFunction(state: BookingCommentState, action: PayloadAction<BookingCommentState['updating']>) {
  return {
    ...state,
    updating: action.payload,
  };
}

function resetStateFunction() {
  return initialState;
}

const slice = createSlice({
  name: 'bookingComments',
  initialState,
  reducers: {
    setFetching: setFetchingFunction,
    setValidity: setValidityFunction,
    setBookingComments: setBookingCommentsFunction,
    setUpdating: setUpdatingFunction,
    resetState: resetStateFunction,
  },
});

export const { setFetching, setValidity, setBookingComments, setUpdating, resetState } = slice.actions;
export default slice.reducer;
