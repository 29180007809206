import type { FormFieldProps } from 'comp/screenBuilder/comp/formBuilder/types/formPropsTypes';
import React from 'react';
import styled from 'styled-components/macro';
import { Form } from 'antd';
import { FormattedMessage } from 'react-intl';

function ImagePreview({ field, formFieldName, removeFormItemLabel }: FormFieldProps): JSX.Element {
  const { formItem } = field;
  const { label, ...formItemProps } = formItem;

  return (
    <Root>
      <Form.Item
        required={false}
        label={!removeFormItemLabel && label && <FormattedMessage {...label} />}
        {...formItemProps}
        name={formFieldName}
      >
        <ImagePreviewValue />
      </Form.Item>
    </Root>
  );
}

export default ImagePreview;

const Root = styled.div`
  .ant-form-item-no-colon {
    user-select: none;
    color: ${({ theme }) => theme.palette.text.label};
  }

  .ant-form-item-control-input {
    min-height: initial;
  }
`;

type ImagePreviewValueProps = {
  id?: string;
  value?: string;
  onChange?: () => void;
};

function ImagePreviewValue({ value, ...props }: ImagePreviewValueProps): JSX.Element {
  return <Image src={value} alt='file failed to load' {...props} />;
}

const Image = styled.img`
  width: 100%;
`;
