import type { Enum } from 'comp/screenBuilder/comp/formBuilder/types/formConfigTypes';
import type { FormConfig } from '../types/formConfigTypes';
import getAllFieldsWithIndexesFromForm from './getAllFieldsWithIndexesFromForm';

function updateFormFieldsWithOptionsFromEnum(form: FormConfig, enums?: Enum[]): FormConfig {
  if (!enums) {
    return form;
  }

  const indexedFormFields = getAllFieldsWithIndexesFromForm(form);
  const updatedFormFields = indexedFormFields
    .map((formField) => {
      if (formField.field.formInput.enumName) {
        return {
          ...formField,
          field: {
            ...formField.field,
            formInput: {
              ...formField.field.formInput,
              options: enums.find(({ name }) => name === formField.field.formInput.enumName)?.values,
            },
          },
        };
      }

      return null;
    })
    .filter(Boolean);

  const updatedForm = form;
  updatedFormFields.forEach((formField) => {
    if (!formField) {
      return;
    }

    const { sectionIndex, rowIndex, fieldIndex, field } = formField;
    updatedForm.sections[sectionIndex].rows[rowIndex].fields[fieldIndex] = { ...field };
  });

  return updatedForm;
}

export default updateFormFieldsWithOptionsFromEnum;
