/* eslint-disable class-methods-use-this */

class LocalStorage {
  add(key: string, data: string | Record<string, unknown> | Record<string, unknown>[]): void {
    try {
      const value = typeof data === 'object' ? JSON.stringify(data) : data;
      localStorage.setItem(key, value);
    } catch (error) {
      window.Rollbar.error('Error on LocalStorage.add', key, data);
    }
  }

  get<T>(key: string): T | null {
    const value = localStorage.getItem(key);

    if (value !== null) {
      try {
        return JSON.parse(value) as T;
      } catch (error) {
        // No need to log or handle this error. We know it will fail for string and we handle it below.
      }
    }

    return (value as unknown) as T;
  }

  remove(key: string): void {
    localStorage.removeItem(key);
  }

  clear(): void {
    localStorage.clear();
  }
}

export default new LocalStorage();
