import type { TableProps, ColumnsType } from 'antd/lib/table';
import React, { useEffect, useState } from 'react';
import { Table as AntdTable } from 'antd';
import styled from 'styled-components/macro';
import Severity from 'constants/Severity';
import resolveDifferencesBetweenColumnsAndLocalStorageConfig from './comp/columnsConfig/utils/resolveDifferencesBetweenColumnsAndLocalStorageConfig';
import processColumnsUsingConfig from './comp/columnsConfig/utils/processColumnsUsingConfig';
import getLocalStorageConfig from './comp/columnsConfig/utils/getLocalStorageConfig';
import ColumnsConfig from './comp/columnsConfig/ColumnsConfig';
import Error from './comp/error/Error';
import type { TableWrapConfiguration } from './types/tableTypes';

type TableWrapProps<T> = TableProps<T> & {
  configuration: TableWrapConfiguration;
};

function Table<T extends Record<string, unknown>>({
  configuration,
  columns,
  dataSource,
  ...props
}: TableWrapProps<T>): JSX.Element {
  const { tableName, editColumns, validity } = configuration;
  const locale = validity && validity.severity === Severity.ERROR ? { emptyText: <Error /> } : undefined;
  const [columnsConfigTimestamp, setColumnsConfigTimestamp] = useState(Date.now());
  const [filteredColumns, setFilteredColumns] = useState<ColumnsType<T>>([]);
  const [finalColumns, setFinalColumns] = useState<ColumnsType<T>>([]);
  const columnsKeys = columns?.map((column) => column.key).join();

  useEffect(() => {
    setFilteredColumns(columns ?? []);
    setFinalColumns(columns ?? []);
  }, [columns, columnsKeys]);

  useEffect(() => {
    if (columns) {
      resolveDifferencesBetweenColumnsAndLocalStorageConfig(tableName, columns);
    }

    const tableColumnConfigs = getLocalStorageConfig(tableName);

    if (!tableColumnConfigs) {
      return;
    }

    if (columns && tableColumnConfigs.length > 0) {
      setFilteredColumns(processColumnsUsingConfig(columns, tableColumnConfigs, true));
    }
  }, [columnsConfigTimestamp, columns, tableName]);

  useEffect(() => {
    if (editColumns) {
      const indexOfActions = filteredColumns.findIndex((column) => column.key === 'actions');

      if (indexOfActions !== -1 && columns) {
        filteredColumns[indexOfActions].title = () => (
          <ColumnsConfig
            tableName={tableName}
            columns={columns}
            setColumnsConfigTimestamp={setColumnsConfigTimestamp}
          />
        );
      }
    }

    setFinalColumns([...filteredColumns]);
  }, [columns, editColumns, filteredColumns, tableName]);

  return (
    <TableWrap>
      <AntdTable<T>
        columns={finalColumns}
        dataSource={dataSource}
        size='small'
        scroll={{ x: true }}
        locale={locale}
        {...props}
      />
    </TableWrap>
  );
}

export default Table;

const TableWrap = styled.div`
  border: 1px solid ${({ theme }) => theme.palette.border.default};
  border-radius: 4px;

  .ant-table-title {
    border-bottom: 1px solid ${({ theme }) => theme.palette.border.default};
  }

  .row-red {
    background-color: #ff7875;
  }
`;
