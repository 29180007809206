import React from 'react';

export type I18nContextShape = {
  locale?: string;
  currency?: string;
  updateI18nContext: (nextLocale?: string, nextCurrency?: string) => void;
};

const initialContext: I18nContextShape = {
  locale: undefined,
  currency: undefined,
  updateI18nContext: () => {},
};

const I18nContext = React.createContext(initialContext);

export const I18nProvider = I18nContext.Provider;
export const I18nConsumer = I18nContext.Consumer;

export default I18nContext;
