import type { RootState } from 'state';
import type { Module } from 'types/user/userTypes';
import type { MenuItemShape } from 'comp/layout/comp/sider/comp/menu/types';
import routes from 'router/routes';

import iconsMap from 'comp/layout/comp/sider/comp/menu/config';
import { ProductModule, ProductSubModuleTourOperator } from 'constants/userPermissions/UserPermissions';
import insertAtIndex from 'utils/immutability/insertAtIndex';
import { PATH_TOOLS_SETTINGS } from '../../../../router/constants/routePaths';

function getModulesWithoutParent(moduleId?: number) {
  return function getModulesWithoutParentCallback(item: Module): boolean {
    if (moduleId) {
      return item.parent === null && item.id === moduleId;
    }

    return item.parent === null;
  };
}

function formatNameAsKey(name: string): string {
  return `${name.replace(/\s+/g, '-').replace('&', 'and').toLowerCase()}`;
}

function formatByOrder(key: string): number {
  switch (key) {
    case 'tour-operator': {
      return 1;
    }
    case 'accommodation': {
      return 2;
    }
    case 'transfers': {
      return 3;
    }
    case 'tools': {
      return 4;
    }
    default: {
      return 5;
    }
  }
}

function transformModuleToMenuItem(item: Module): MenuItemShape {
  const key = formatNameAsKey(item.name);
  const orderForMenu = formatByOrder(key);

  return {
    originalId: item.id,
    key,
    label: `module.label.${key}`,
    description: `module.description.${key}`,
    icon: iconsMap[item.id],
    path: routes?.find((route) => route.componentProps?.moduleId === item.id)?.path,
    orderForMenu,
  };
}

function addAdditionalModules(permittedModules: MenuItemShape[]): MenuItemShape[] {
  const nextPermittedModules = permittedModules;

  // WITH THIS WE WERE ADDING VIEW-BOOKINGS-OLD MODULE , SHOULD BE DELETED

  // const indexOfTourOperator = nextPermittedModules.findIndex(
  //   (module) => module.originalId === ProductModule.Tour_Operator
  // );

  // if (indexOfTourOperator !== -1) {
  //   const tourOperatorModule = nextPermittedModules[indexOfTourOperator];
  //   const indexOfViewBookings = tourOperatorModule.items?.findIndex(
  //     (module) => module.originalId === ProductSubModuleTourOperator.ViewBookings
  //   );

  //   if (indexOfViewBookings !== undefined && indexOfViewBookings !== -1) {
  //     const viewBookingsModule = tourOperatorModule.items?.[indexOfViewBookings];

  //     if (viewBookingsModule) {
  //       const key = `${viewBookingsModule.key}-old`;
  //       const nextTourOperatorModuleItems = insertAtIndex(tourOperatorModule.items || [], indexOfViewBookings + 1, {
  //         ...viewBookingsModule,
  //         key,
  //         label: `module.label.${key}`,
  //         description: `module.description.${key}`,
  //         path: PATH_TOUR_OPERATOR_BOOKINGS_OLD,
  //       });
  //       tourOperatorModule.items = nextTourOperatorModuleItems;
  //       nextPermittedModules[indexOfTourOperator] = tourOperatorModule;
  //     }
  //   }
  // }

  const indexOfTools = nextPermittedModules.findIndex((module) => module.originalId === ProductModule.Tools);

  if (indexOfTools !== -1) {
    const toolsModule = nextPermittedModules[indexOfTools];
    const indexOfRG = toolsModule.items?.findIndex(
      (module) => module.originalId === ProductSubModuleTourOperator.ReseguidenLinkGenerator
    );

    if (indexOfRG !== undefined && indexOfRG !== -1) {
      const rgModule = toolsModule.items?.[indexOfRG];

      if (rgModule) {
        const key = `${rgModule.key}-settings`;
        const nextToolsModuleItems = insertAtIndex(toolsModule.items || [], indexOfRG + 1, {
          ...rgModule,
          key,
          label: `module.label.${key}`,
          description: `module.description.${key}`,
          path: PATH_TOOLS_SETTINGS,
        });
        toolsModule.items = nextToolsModuleItems;
        nextPermittedModules[indexOfTools] = toolsModule;
      }
    }
  }

  return nextPermittedModules;
}

function removeModulesForSider(permittedModules: MenuItemShape[]): MenuItemShape[] {
  const modulesToRemove = [
    'documents',
    'packages',
    'email-translator',
    'themes-and-boxes',
    'affiliates',
    'rooms',
    'boards',
    'availability',
    'periods',
    'agegroups',
    'rates',
    'policies',
    'special-offers',
    'rate-plans',
    'packaging-and-extras',
    'cancellation-policies',
    'view-all-hotels-permission',
  ];

  const removedPermittedModules = permittedModules.map((element) => {
    if (element.items) {
      const newElement = { ...element };
      let elementItems: MenuItemShape[] | undefined = [];
      elementItems = element.items.filter((item) => !modulesToRemove.includes(item.key));
      newElement.items = elementItems;
      return newElement;
    }
    return element;
  });
  // Rearanging modules for menu
  removedPermittedModules.sort((a, b) => {
    return a.orderForMenu - b.orderForMenu;
  });

  return removedPermittedModules;
}

function getPermittedModulesForSider(moduleId?: number) {
  return function getPermittedModulesForSiderCallback(state: RootState): MenuItemShape[] {
    if (state.auth.currentUser === null) {
      return [];
    }

    let permittedModules = state.auth.currentUser.modules.filter(getModulesWithoutParent(moduleId)).map(
      (item) =>
        ({
          ...transformModuleToMenuItem(item),
          items: state.auth.currentUser?.modules
            ?.filter(({ parent }) => parent === item.id)
            .map(transformModuleToMenuItem),
        } as MenuItemShape)
    );

    permittedModules = addAdditionalModules(permittedModules);
    permittedModules = removeModulesForSider(permittedModules);
    return permittedModules;
  };
}

export default getPermittedModulesForSider;
