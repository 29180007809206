import type { BookingDetails } from 'routes/tourOperator/bookings/types/bookingDetails/bookingDetailsTypes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type BookingSummaryState = {
  value: BookingDetails['booking'] | null;
};

const initialState: BookingSummaryState = {
  value: null,
};

function setBookingSummaryFunction(state: BookingSummaryState, action: PayloadAction<BookingSummaryState['value']>) {
  return {
    ...state,
    value: action.payload,
  };
}

function resetStateFunction() {
  return initialState;
}

const slice = createSlice({
  name: 'bookingDetails.bookingSummary',
  initialState,
  reducers: {
    setBookingSummary: setBookingSummaryFunction,
    resetState: resetStateFunction,
  },
});

export const { setBookingSummary, resetState } = slice.actions;
export default slice.reducer;
