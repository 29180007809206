import type { FormFieldProps } from 'comp/screenBuilder/comp/formBuilder/types/formPropsTypes';
import React from 'react';
import moment from 'moment';
import styled from 'styled-components/macro';
import { Form } from 'antd';
import { FormattedMessage } from 'react-intl';
import { DEFAULT_FORMAT } from './constants/defaults';

function DateTimePreview({ field, formFieldName, removeFormItemLabel }: FormFieldProps): JSX.Element {
  const { formItem, formInput } = field;
  const { label, ...formItemProps } = formItem;

  return (
    <Root>
      <Form.Item
        required={false}
        label={!removeFormItemLabel && label && <FormattedMessage {...label} />}
        {...formItemProps}
        name={formFieldName}
      >
        <DateTimePreviewValue formInput={formInput} />
      </Form.Item>
    </Root>
  );
}

export default DateTimePreview;

type DateTimePreviewValueProps = {
  formInput: FormFieldProps['field']['formInput'];
  value?: string;
};

function DateTimePreviewValue({ formInput, value }: DateTimePreviewValueProps): JSX.Element {
  return <div>{moment(value, DEFAULT_FORMAT).format(formInput.format || DEFAULT_FORMAT)}</div>;
}

const Root = styled.div`
  .ant-form-item-no-colon {
    user-select: none;
    color: ${({ theme }) => theme.palette.text.label};
  }

  .ant-form-item-control-input {
    min-height: initial;
  }
`;
