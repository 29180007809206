import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';
import BookingsOverviewDateType from 'routes/tourOperator/bookings/comp/bookingsOverview/constants/BookingsOverviewDateType';

export type BookingsOverviewFiltersState = {
  page: number;
  takeCount: number;
  sorting: string | null;
  search: string | null;
  textSearchType: string | null;
  showIncomplete: boolean | null;
  dateType: BookingsOverviewDateType;
  dateFrom: string;
  dateTo: string;
  hotelName: string | null;
  departureAirport: string | null;
  destinationCode: string | null;
  flightProvider: string | null;
  hotelProvider: string | null;
  bookingStatus: string | null;
  productStatus: string | null;
  promocodeId: number | null;
  websiteIds: number[] | null;
  affiliate: string | null;
  flightCarrier: string | null;
  showRatedBookings: boolean | null;
  showRequestedRefund: boolean | null;
  product: string | null;
  refundPromoCodeId: number | null;
};

const initialState: BookingsOverviewFiltersState = {
  page: 1,
  takeCount: 25,
  sorting: null,
  search: null,
  textSearchType: 'BookingReference',
  showIncomplete: null,
  dateType: BookingsOverviewDateType.DateBooked,
  dateFrom: moment().format('YYYY-MM-DD'),
  dateTo: moment().format('YYYY-MM-DD'),
  hotelName: null,
  departureAirport: null,
  destinationCode: null,
  flightProvider: null,
  hotelProvider: null,
  bookingStatus: null,
  productStatus: null,
  promocodeId: null,
  websiteIds: null,
  affiliate: null,
  flightCarrier: null,
  showRatedBookings: null,
  showRequestedRefund: null,
  product: null,
  refundPromoCodeId: null
};

function setPageFunction(
  state: BookingsOverviewFiltersState,
  action: PayloadAction<BookingsOverviewFiltersState['page']>
) {
  return {
    ...state,
    page: action.payload,
  };
}

function setTakeCountFunction(
  state: BookingsOverviewFiltersState,
  action: PayloadAction<BookingsOverviewFiltersState['takeCount']>
) {
  return {
    ...state,
    takeCount: action.payload,
  };
}

function setSortingFunction(
  state: BookingsOverviewFiltersState,
  action: PayloadAction<BookingsOverviewFiltersState['sorting']>
) {
  return {
    ...state,
    sorting: action.payload,
  };
}

function setSearchFunction(
  state: BookingsOverviewFiltersState,
  action: PayloadAction<BookingsOverviewFiltersState['search']>
) {
  return {
    ...state,
    search: action.payload,
  };
}

function setTextSearchTypeFunction(
  state: BookingsOverviewFiltersState,
  action: PayloadAction<BookingsOverviewFiltersState['textSearchType']>
) {
  return {
    ...state,
    textSearchType: action.payload,
  };
}

function setShowIncompleteFunction(
  state: BookingsOverviewFiltersState,
  action: PayloadAction<BookingsOverviewFiltersState['showIncomplete']>
) {
  return {
    ...state,
    showIncomplete: action.payload,
  };
}

function setShowRatedBookingsFunction(
  state: BookingsOverviewFiltersState,
  action: PayloadAction<BookingsOverviewFiltersState['showRatedBookings']>
) {
  return {
    ...state,
    showRatedBookings: action.payload,
  };
}

function setShowRequestedRefundFunction(
  state: BookingsOverviewFiltersState,
  action: PayloadAction<BookingsOverviewFiltersState['showRequestedRefund']>
) {
  return {
    ...state,
    showRequestedRefund: action.payload,
  };
}

function setDateTypeFunction(
  state: BookingsOverviewFiltersState,
  action: PayloadAction<BookingsOverviewFiltersState['dateType']>
) {
  return {
    ...state,
    dateType: action.payload,
  };
}

function setDateFromFunction(
  state: BookingsOverviewFiltersState,
  action: PayloadAction<BookingsOverviewFiltersState['dateFrom']>
) {
  let { dateTo } = state;

  if (moment(action.payload).isAfter(moment(dateTo))) {
    dateTo = action.payload;
  }

  return {
    ...state,
    dateFrom: action.payload,
    dateTo,
  };
}

function setDateToFunction(
  state: BookingsOverviewFiltersState,
  action: PayloadAction<BookingsOverviewFiltersState['dateTo']>
) {
  let { dateFrom } = state;

  if (moment(action.payload).isBefore(moment(dateFrom))) {
    dateFrom = action.payload;
  }

  return {
    ...state,
    dateTo: action.payload,
    dateFrom,
  };
}

function setHotelNameFunction(
  state: BookingsOverviewFiltersState,
  action: PayloadAction<BookingsOverviewFiltersState['hotelName']>
) {
  return {
    ...state,
    hotelName: action.payload,
  };
}

function setDepartureAirportFunction(
  state: BookingsOverviewFiltersState,
  action: PayloadAction<BookingsOverviewFiltersState['departureAirport']>
) {
  return {
    ...state,
    departureAirport: action.payload,
  };
}

function setDestinationCodeFunction(
  state: BookingsOverviewFiltersState,
  action: PayloadAction<BookingsOverviewFiltersState['destinationCode']>
) {
  return {
    ...state,
    destinationCode: action.payload,
  };
}

function setFlightProviderFunction(
  state: BookingsOverviewFiltersState,
  action: PayloadAction<BookingsOverviewFiltersState['flightProvider']>
) {
  return {
    ...state,
    flightProvider: action.payload,
  };
}

function setHotelProviderFunction(
  state: BookingsOverviewFiltersState,
  action: PayloadAction<BookingsOverviewFiltersState['hotelProvider']>
) {
  return {
    ...state,
    hotelProvider: action.payload,
  };
}

function setBookingStatusFunction(
  state: BookingsOverviewFiltersState,
  action: PayloadAction<BookingsOverviewFiltersState['bookingStatus']>
) {
  return {
    ...state,
    bookingStatus: action.payload,
  };
}

function setProductStatusFunction(
  state: BookingsOverviewFiltersState,
  action: PayloadAction<BookingsOverviewFiltersState['productStatus']>
) {
  return {
    ...state,
    productStatus: action.payload,
  };
}

function setPromocodeIdFunction(
  state: BookingsOverviewFiltersState,
  action: PayloadAction<BookingsOverviewFiltersState['promocodeId']>
) {
  return {
    ...state,
    promocodeId: action.payload,
  };
}

function setRefundPromoCodeIdFunction(
  state: BookingsOverviewFiltersState,
  action: PayloadAction<BookingsOverviewFiltersState['refundPromoCodeId']>
) {
  return {
    ...state,
    refundPromoCodeId: action.payload,
  };
}

function setWebsiteIdsFunction(
  state: BookingsOverviewFiltersState,
  action: PayloadAction<BookingsOverviewFiltersState['websiteIds']>
) {
  return {
    ...state,
    websiteIds: action.payload,
  };
}

function setAffiliateIdFunction(
  state: BookingsOverviewFiltersState,
  action: PayloadAction<BookingsOverviewFiltersState['affiliate']>
) {
  return {
    ...state,
    affiliate: action.payload,
  };
}

function setCarrierFunction(
  state: BookingsOverviewFiltersState,
  action: PayloadAction<BookingsOverviewFiltersState['flightCarrier']>
) {
  return {
    ...state,
    flightCarrier: action.payload,
  };
}

function updateFiltersValuesFunction(
  state: BookingsOverviewFiltersState,
  action: PayloadAction<BookingsOverviewFiltersState>
) {
  return {
    ...state,
    ...action.payload,
  };
}

function setProductFunction(
  state: BookingsOverviewFiltersState,
  action: PayloadAction<BookingsOverviewFiltersState['product']>
) {
  return {
    ...state,
    product: action.payload,
  };
}

function resetStateFunction() {
  return initialState;
}

const slice = createSlice({
  name: 'bookingsOverviewFilters.values',
  initialState,
  reducers: {
    setPage: setPageFunction,
    setTakeCount: setTakeCountFunction,
    setSorting: setSortingFunction,
    setSearch: setSearchFunction,
    setTextSearchType: setTextSearchTypeFunction,
    setShowIncomplete: setShowIncompleteFunction,
    setDateType: setDateTypeFunction,
    setDateFrom: setDateFromFunction,
    setDateTo: setDateToFunction,
    setHotelName: setHotelNameFunction,
    setDepartureAirport: setDepartureAirportFunction,
    setDestinationCode: setDestinationCodeFunction,
    setFlightProvider: setFlightProviderFunction,
    setHotelProvider: setHotelProviderFunction,
    setBookingStatus: setBookingStatusFunction,
    setProductStatus: setProductStatusFunction,
    setProduct: setProductFunction,
    setPromocodeId: setPromocodeIdFunction,
    setWebsiteIds: setWebsiteIdsFunction,
    setAffiliateId: setAffiliateIdFunction,
    setCarrier: setCarrierFunction,
    updateFiltersValues: updateFiltersValuesFunction,
    resetState: resetStateFunction,
    setShowRatedBookings: setShowRatedBookingsFunction,
    setShowRequestedRefund: setShowRequestedRefundFunction,
    setRefundPromoCodeId: setRefundPromoCodeIdFunction
  },
});

export const {
  setPage,
  setTakeCount,
  setSorting,
  setSearch,
  setTextSearchType,
  setShowIncomplete,
  setDateType,
  setDateFrom,
  setDateTo,
  setHotelName,
  setDepartureAirport,
  setDestinationCode,
  setFlightProvider,
  setHotelProvider,
  setBookingStatus,
  setProductStatus,
  setPromocodeId,
  setWebsiteIds,
  setAffiliateId,
  setCarrier,
  updateFiltersValues,
  resetState,
  setShowRatedBookings,
  setShowRequestedRefund,
  setProduct,
  setRefundPromoCodeId
} = slice.actions;
export default slice.reducer;
