import type { OriginsDestinationsResponse } from 'types/originsDestinations/originsDestinationsTypes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import FetchingStatus from 'constants/statuses/FetchingStatus';

export type OriginsDestinationsState = {
  fetchingOrigins: FetchingStatus | null;
  origins: OriginsDestinationsResponse | null;
  fetchingDestinations: FetchingStatus | null;
  destinations: OriginsDestinationsResponse | null;
};

const initialState: OriginsDestinationsState = {
  fetchingOrigins: null,
  origins: null,
  fetchingDestinations: null,
  destinations: null,
};

function setFetchingOriginsFunction(state: OriginsDestinationsState, action: PayloadAction<FetchingStatus>) {
  return {
    ...state,
    fetchingOrigins: action.payload,
  };
}

function setOriginsFunction(state: OriginsDestinationsState, action: PayloadAction<OriginsDestinationsResponse>) {
  return {
    ...state,
    origins: action.payload,
  };
}

function setFetchingDestinationsFunction(state: OriginsDestinationsState, action: PayloadAction<FetchingStatus>) {
  return {
    ...state,
    fetchingDestinations: action.payload,
  };
}

function setDestinationsFunction(state: OriginsDestinationsState, action: PayloadAction<OriginsDestinationsResponse>) {
  return {
    ...state,
    destinations: action.payload,
  };
}

const slice = createSlice({
  name: 'originsDestinations',
  initialState,
  reducers: {
    setFetchingOrigins: setFetchingOriginsFunction,
    setOrigins: setOriginsFunction,
    setFetchingDestinations: setFetchingDestinationsFunction,
    setDestinations: setDestinationsFunction,
  },
});

export const { setFetchingOrigins, setOrigins, setFetchingDestinations, setDestinations } = slice.actions;
export default slice.reducer;
