import type { Validity } from 'types/common/validationTypes';
import type { ConfigsEnums } from 'routes/tourOperator/bookings/types/configs/enumsTypes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import FetchingStatus from 'constants/statuses/FetchingStatus';

export type ConfigsEnumsState = {
  fetching: FetchingStatus | null;
  validity: Validity | null;
  value: ConfigsEnums | null;
};

const initialState: ConfigsEnumsState = {
  fetching: null,
  validity: null,
  value: null,
};

function setFetchingFunction(state: ConfigsEnumsState, action: PayloadAction<ConfigsEnumsState['fetching']>) {
  return {
    ...state,
    fetching: action.payload,
  };
}

function setValidityFunction(state: ConfigsEnumsState, action: PayloadAction<ConfigsEnumsState['validity']>) {
  return {
    ...state,
    validity: action.payload,
  };
}

function setConfigsEnumsFunction(state: ConfigsEnumsState, action: PayloadAction<ConfigsEnumsState['value']>) {
  return {
    ...state,
    value: action.payload,
  };
}

const slice = createSlice({
  name: 'configsEnums',
  initialState,
  reducers: {
    setFetching: setFetchingFunction,
    setValidity: setValidityFunction,
    setConfigsEnums: setConfigsEnumsFunction,
  },
});

export const { setFetching, setValidity, setConfigsEnums } = slice.actions;
export default slice.reducer;
