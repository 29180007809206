import { StaticRouteParams } from 'common/dataService/types/staticRouteTypes';
import type { AppDispatch, RootState } from 'state';
import fetchStaticRoutes from '../reducers/thunks/fetchStaticRoutes';

async function executeFetchStaticRoutes(dispatch: AppDispatch, getState: () => RootState): Promise<void> {
  const { sorting } = getState().staticRoutes;
  const request = {} as StaticRouteParams;
  if (sorting) {
    var sortParam = sorting.split(' ');
    request.sortColumn = sortParam[0];
    request.sortDirection = sortParam[1];
    request.sorting = sorting;
  }

  await dispatch(fetchStaticRoutes(request));
}

export default executeFetchStaticRoutes;
