import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type WebsiteFiltersState = {
  takeCount: number;
  affiliate: string | null;
  countryCode: string | null;
  langCode: string | null;
  flightType: string | null;
};

const initialState: WebsiteFiltersState = {
  takeCount: 100,
  affiliate: null,
  countryCode: null,
  langCode: null,
  flightType: null,
};
function setTakeCountFunction(state: WebsiteFiltersState, action: PayloadAction<WebsiteFiltersState['takeCount']>) {
  return {
    ...state,
    takeCount: action.payload,
  };
}
function setAffiliateIdFunction(state: WebsiteFiltersState, action: PayloadAction<WebsiteFiltersState['affiliate']>) {
  return {
    ...state,
    affiliate: action.payload,
  };
}

function setCountryCodeFunction(state: WebsiteFiltersState, action: PayloadAction<WebsiteFiltersState['countryCode']>) {
  return {
    ...state,
    countryCode: action.payload,
  };
}

function setLangCodeFunction(state: WebsiteFiltersState, action: PayloadAction<WebsiteFiltersState['langCode']>) {
  return {
    ...state,
    langCode: action.payload,
  };
}
function setFlightTypeFunction(state: WebsiteFiltersState, action: PayloadAction<WebsiteFiltersState['flightType']>) {
  return {
    ...state,
    flightType: action.payload,
  };
}
function resetStateFunction() {
  return initialState;
}

const slice = createSlice({
  name: 'websiteFilters.values',
  initialState,
  reducers: {
    setTakeCount: setTakeCountFunction,
    setAffiliateId: setAffiliateIdFunction,
    setCountryCode: setCountryCodeFunction,
    setLangCode: setLangCodeFunction,
    setFlightType: setFlightTypeFunction,
    resetState: resetStateFunction,
  },
});

export const { setTakeCount, setAffiliateId, setCountryCode, setLangCode, setFlightType, resetState } = slice.actions;
export default slice.reducer;
