import React from 'react';
import styled from 'styled-components/macro';
import { Menu as AntdMenu } from 'antd';
import MenuItem from './comp/menuItem/MenuItem';
import type { MenuConfig } from './types';

function Menu({ items }: MenuConfig): JSX.Element {
  return <Root mode='inline'>{items.map(MenuItem)}</Root>;
}

export default Menu;

const Root = styled(AntdMenu).attrs(() => ({ theme: 'dark' }))`
  margin-top: 32px;
  .ant-menu-item-only-child {
    margin-bottom: 10px !important;
  }
`;
