import React from 'react';
import styled from 'styled-components/macro';
import { Layout as AntdLayout } from 'antd';

const { Footer: AntdFooter } = AntdLayout;

function Footer(): JSX.Element {
  return (
    <Root>
      © Copyright - TripX Travel AB - Org.nr 556813-0099 (Mva reg nr: SE556813009901)- Stora Trädgårdsgatan 30, 211 28
      Malmö - info@tripx.se
    </Root>
  );
}

export default Footer;

const Root = styled(AntdFooter)`
  display: none;
  user-select: none;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 13px;
  text-align: center;
  color: rgba(0, 0, 0, 0.65);
`;
