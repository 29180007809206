import type { FormAction, FormListCreateCallback, FormListDeleteCallback } from 'comp/screenBuilder/comp/formBuilder';
import React, { useContext } from 'react';
import styled from 'styled-components/macro';
import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import { OuterContext } from 'comp/screenBuilder/context';
import { defaultAction } from 'comp/screenBuilder/comp/formBuilder';
import isActionDisabled from './utils/isActionDisabled';

type ActionsProps = {
  actions?: FormAction[];
  activeAction: FormAction;
  listDropdownIsEmpty?: boolean;
  onClickAction: (
    arg1: React.MouseEvent<HTMLElement, MouseEvent>,
    arg2?: FormListCreateCallback,
    arg3?: FormListDeleteCallback
  ) => void;
  onClickCreateCallback?: FormListCreateCallback;
  onClickDeleteCallback?: FormListDeleteCallback;
};

function Actions({
  actions,
  activeAction,
  listDropdownIsEmpty,
  onClickAction,
  onClickCreateCallback,
  onClickDeleteCallback,
}: ActionsProps): JSX.Element | null {
  const { actionsDisabled } = useContext(OuterContext);
  const actionIsActive = activeAction.type !== defaultAction.type;
  const hideActions =
    activeAction.type !== defaultAction.type && activeAction.buttons !== undefined && activeAction.buttons.length > 0;

  function onClick(event: React.MouseEvent<HTMLElement, MouseEvent>) {
    event.stopPropagation();
    onClickAction(event, onClickCreateCallback, onClickDeleteCallback);
  }

  if (hideActions || !actions) {
    return null;
  }

  return (
    <Root>
      {actions.map((action) => {
        const messageId = action.label ? `formBuilder.action.${action.label}` : `formBuilder.action.${action.type}`;
        const disabled = actionsDisabled || isActionDisabled(action.type, actionIsActive, listDropdownIsEmpty);

        return (
          <Button
            key={action.type}
            size='middle'
            data-action-type={action.type}
            disabled={disabled}
            onClick={onClick}
            {...action.props}
          >
            <FormattedMessage id={messageId} />
          </Button>
        );
      })}
    </Root>
  );
}

export default Actions;

const Root = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;

  > *:not(:last-child) {
    margin-right: 8px;
  }
`;
