import type { AppThunk } from 'state';
import DataServiceAffiliates from 'common/dataService/services/affiliates';
import FetchingStatus from 'constants/statuses/FetchingStatus';
import { setFetching, setAffiliates } from '../slice';

function fetchAffiliates(): AppThunk {
  return async function fetchAffiliatesThunk(dispatch, getState) {
    try {
      const { fetching } = getState().dataService.affiliates.affiliates;

      if (fetching === FetchingStatus.InProgress) {
        return;
      }

      dispatch(setFetching(FetchingStatus.InProgress));

      const data = await DataServiceAffiliates.getAffiliates();

      if (!data) {
        dispatch(setFetching(FetchingStatus.Failed));
        return;
      }

      dispatch(setAffiliates(data));
      dispatch(setFetching(FetchingStatus.Succeeded));
    } catch (error) {
      dispatch(setFetching(FetchingStatus.Failed));
      window.Rollbar.error(error);
    }
  };
}

export default fetchAffiliates;
