import type { Props as FormattedMessageProps } from 'react-intl/lib/src/components/message';
import moment from 'moment';

type MonthOption = {
  value: string;
  label: FormattedMessageProps;
};

function generateMonthOptions(): MonthOption[] {
  const options = [];
  const currentDate = moment();
  const currentYear = currentDate.get('year');
  const currentMonth = currentDate.get('month') + 1;

  for (let i = currentMonth; i <= 12; i++) {
    const monthIndex = `0${i}`.substr(-2);
    options.push({
      value: `${currentYear}-${monthIndex}`,
      label: {
        id: `monthByIndexAndYear.${monthIndex}`,
        values: { year: currentYear },
      },
    });
  }

  for (let i = 1; i < currentMonth; i++) {
    const monthIndex = `0${i}`.substr(-2);
    options.push({
      value: `${currentYear}-${monthIndex}`,
      label: {
        id: `monthByIndexAndYear.${monthIndex}`,
        values: { year: currentYear + 1 },
      },
    });
  }

  return options;
}

export default generateMonthOptions;
