import type { AppThunk } from 'state';
import DataServicePromocodes from 'common/dataService/services/promocodes';
import FetchingStatus from 'constants/statuses/FetchingStatus';
import { setFetching, setRefundPromoCodes } from '../slice';

function fetchRefundPromoCodes(): AppThunk {
  return async function fetchRefundPromoCodesThunk(dispatch, getState) {
    try {
      const { fetching } = getState().dataService.refundPromoCodes.refundPromoCodes;

      if (fetching === FetchingStatus.InProgress) {
        return;
      }

      dispatch(setFetching(FetchingStatus.InProgress));

      const data = await DataServicePromocodes.getRefundPromoCodes();

      if (!data) {
        dispatch(setFetching(FetchingStatus.Failed));
        return;
      }

      dispatch(setRefundPromoCodes(data));
      dispatch(setFetching(FetchingStatus.Succeeded));
    } catch (error) {
      dispatch(setFetching(FetchingStatus.Failed));
      window.Rollbar.error(error);
    }
  };
}

export default fetchRefundPromoCodes;
