import React from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import store from 'state';
import I18n from 'i18n';
import Router from 'router';
import routes from 'router/routes';
import Auth from 'comp/auth/Auth';
import GlobalStyles from './styles/global';
import theme from './styles/themes/default';

function App(): JSX.Element {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <Auth />
        <I18n>
          <Router routes={routes} />
        </I18n>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
