import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type RoutesFiltersState = {
  page: number;
  takeCount: number;
  sorting: string | null;
  airportsFrom: string[];
  airportsTo: string[];
  carrier: string | null;
  stops: number[] | null;
  isActive: boolean | null;
  isOperating: boolean | null;
};

const initialState: RoutesFiltersState = {
  page: 1,
  takeCount: 25,
  sorting: null,
  airportsFrom: [],
  airportsTo: [],
  carrier: null,
  stops: null,
  isActive: null,
  isOperating: null,
};

function setPageFunction(state: RoutesFiltersState, action: PayloadAction<RoutesFiltersState['page']>) {
  return {
    ...state,
    page: action.payload,
  };
}

function setTakeCountFunction(state: RoutesFiltersState, action: PayloadAction<RoutesFiltersState['takeCount']>) {
  return {
    ...state,
    takeCount: action.payload,
  };
}

function setSortingFunction(state: RoutesFiltersState, action: PayloadAction<RoutesFiltersState['sorting']>) {
  return {
    ...state,
    sorting: action.payload,
  };
}

function setAirportsFromFunction(state: RoutesFiltersState, action: PayloadAction<RoutesFiltersState['airportsFrom']>) {
  return {
    ...state,
    airportsFrom: action.payload,
  };
}

function setAirportsToFunction(state: RoutesFiltersState, action: PayloadAction<RoutesFiltersState['airportsTo']>) {
  return {
    ...state,
    airportsTo: action.payload,
  };
}

function setCarrierFunction(state: RoutesFiltersState, action: PayloadAction<RoutesFiltersState['carrier']>) {
  return {
    ...state,
    carrier: action.payload,
  };
}

function setStopsFunction(state: RoutesFiltersState, action: PayloadAction<RoutesFiltersState['stops']>) {
  return {
    ...state,
    stops: action.payload,
  };
}

function setIsActiveFunction(state: RoutesFiltersState, action: PayloadAction<RoutesFiltersState['isActive']>) {
  return {
    ...state,
    isActive: action.payload,
  };
}

function setIsOperatingFunction(state: RoutesFiltersState, action: PayloadAction<RoutesFiltersState['isOperating']>) {
  return {
    ...state,
    isOperating: action.payload,
  };
}

function resetStateFunction() {
  return initialState;
}

const slice = createSlice({
  name: 'flightRoutes.filters.values',
  initialState,
  reducers: {
    setPage: setPageFunction,
    setTakeCount: setTakeCountFunction,
    setSorting: setSortingFunction,
    setAirportsFrom: setAirportsFromFunction,
    setAirportsTo: setAirportsToFunction,
    setCarrier: setCarrierFunction,
    setStops: setStopsFunction,
    setIsActive: setIsActiveFunction,
    setIsOperating: setIsOperatingFunction,
    resetState: resetStateFunction,
  },
});

export const {
  setPage,
  setTakeCount,
  setSorting,
  setAirportsFrom,
  setAirportsTo,
  setCarrier,
  setStops,
  setIsActive,
  setIsOperating,
  resetState,
} = slice.actions;
export default slice.reducer;
