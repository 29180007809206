import type { AppThunkSync } from 'state';
import { updateFlightCalendarsOutboundData, updateFlightCalendarsInboundData } from '../slice';

function clearFlightCalendarsDataForKey(keys: [string, string]): AppThunkSync {
  return function clearFlightCalendarsDataForKeyThunk(dispatch, getState) {
    const { outbound, inbound } = getState().flightCalendars.data;
    const [outboundKey, inboundKey] = keys;

    if (outbound !== null && outbound[outboundKey] !== undefined && outbound[outboundKey] !== null) {
      dispatch(updateFlightCalendarsOutboundData({ key: outboundKey, value: null }));
    }

    if (inbound !== null && inbound[inboundKey] !== undefined && inbound[inboundKey] !== null) {
      dispatch(updateFlightCalendarsInboundData({ key: inboundKey, value: null }));
    }
  };
}

export default clearFlightCalendarsDataForKey;
