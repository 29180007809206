import type { AppThunkSync } from 'state';
import { getFiltersValues } from '../comp/flightCalendarsFilters';
import { areFlightCalendarsAvailableByKey, clearFlightCalendarsDataForKey } from '../comp/flightCalendarsData';
import { transformFiltersValuesToKeys } from '../comp/flightCalendarsFilters';

function clearFlightCalendarsDataForKeyFromFilters(): AppThunkSync {
  return function clearFlightCalendarsDataForKeyFromFiltersThunk(dispatch, getState) {
    const filtersValues = getFiltersValues(getState());
    const keys = transformFiltersValuesToKeys(filtersValues);
    const flightCalendarsAreAvailable = areFlightCalendarsAvailableByKey(keys)(getState());

    if (flightCalendarsAreAvailable) {
      dispatch(clearFlightCalendarsDataForKey(keys));
    }
  };
}

export default clearFlightCalendarsDataForKeyFromFilters;
