import HttpClient from 'httpClient/index';
import type {
  FlightDefinitonGroupsPagedResult,
  FlightDefinitionGroupsRequest,
  FlightDefinitionGroupsModel,
} from '../types/flightDefinintionGroupsTypes';

const BASE_URL = `${process.env.REACT_APP_DATA_API || ''}/api/flightDefinitionGroups`;

class DataServiceFlightDefinitionGroups extends HttpClient {
  public constructor() {
    super(BASE_URL);
  }

  public getFlightDefinitionGroups = (
    params: FlightDefinitionGroupsRequest
  ): Promise<FlightDefinitonGroupsPagedResult> => this.instance.get('', { params });

  public postFlightDefinitionGroups = (body: FlightDefinitionGroupsModel): Promise<void> =>
    this.instance.post('/', body);

  public putFlightDefinitionGroups = (body: FlightDefinitionGroupsModel): Promise<void> => this.instance.put('/', body);

  public deleteFlightDefinitionGroups = (groupId: number): Promise<void> => this.instance.delete(`?groupId=${groupId}`);

  public postRegeneratePeriods = (groupId: number): Promise<number> =>
    this.instance.post(`/regeneratePeriods?groupId=${groupId}`);
}

export default new DataServiceFlightDefinitionGroups();
