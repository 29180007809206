import type { SelectValue } from 'antd/lib/select/index';
import type { FlightCalendarsFiltersState } from 'routes/tourOperator/flightCache/flightCalendars/comp/flightCalendarsFilters';
import React from 'react';
import styled from 'styled-components/macro';
import { useSelector, useDispatch } from 'react-redux';
import { Select as AntdSelect } from 'antd';
import { FormattedMessage } from 'react-intl';
import {
  setMonthValue,
  getMonthValue,
} from 'routes/tourOperator/flightCache/flightCalendars/comp/flightCalendarsFilters';
import LabeledValue from 'comp/dataPresentation/labeledValue/LabeledValue';
import mediaQuery from 'styles/utils/mediaQuery';
import generateMonthOptions from './utils/generateMonthOptions';

const options = generateMonthOptions();

function Month(): JSX.Element {
  const value = useSelector(getMonthValue);
  const dispatch = useDispatch();

  function handleOnChange(option: SelectValue) {
    if (option === undefined) {
      dispatch(setMonthValue(null));
    } else {
      dispatch(setMonthValue(option as FlightCalendarsFiltersState['month']));
    }
  }

  return (
    <LabeledValue label={{ id: 'month' }}>
      <Select
        listHeight={640}
        placeholder={<FormattedMessage id='month.placeholder' />}
        value={value || undefined}
        onChange={handleOnChange}
      >
        {options.map(({ value, label }) => (
          <Select.Option key={value} value={value}>
            <FormattedMessage {...label} />
          </Select.Option>
        ))}
      </Select>
    </LabeledValue>
  );
}

export default Month;

const Select = styled(AntdSelect)`
  width: 100%;

  ${mediaQuery.fromAndAbove.medium`
    width: 160px;
  `}
`;
