import type { BookingDetails } from 'routes/tourOperator/bookings/types/bookingDetails/bookingDetailsTypes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type BookingFlightsState = {
  value: BookingDetails['flights'] | null;
};

const initialState: BookingFlightsState = {
  value: null,
};

function setBookingFlightsFunction(state: BookingFlightsState, action: PayloadAction<BookingFlightsState['value']>) {
  return {
    ...state,
    value: action.payload,
  };
}

function resetStateFunction() {
  return initialState;
}

const slice = createSlice({
  name: 'bookingDetails.bookingFlights',
  initialState,
  reducers: {
    setBookingFlights: setBookingFlightsFunction,
    resetState: resetStateFunction,
  },
});

export const { setBookingFlights, resetState } = slice.actions;
export default slice.reducer;
