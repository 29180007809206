import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import FetchingStatus from 'constants/statuses/FetchingStatus';
import type { PaymentTransactionStatus } from '../../types/bookingPaymentStatuses/paymentTransactionStatus';

export type BookingPaymentStatusesState = {
  fetching: FetchingStatus | null;
  value: PaymentTransactionStatus[] | null;
};

const initialState: BookingPaymentStatusesState = {
  fetching: null,
  value: null
};

function setFetchingFunction(state: BookingPaymentStatusesState, action: PayloadAction<BookingPaymentStatusesState['fetching']>) {
  return {
    ...state,
    fetching: action.payload,
  };
}

function setPaymentStatusesFunction(state: BookingPaymentStatusesState, action: PayloadAction<BookingPaymentStatusesState['value']>) {
  return {
    ...state,
    value: action.payload,
  };
}

function resetStateFunction() {
  return initialState;
}

const slice = createSlice({
  name: 'bookingPaymentStatuses',
  initialState,
  reducers: {
    setFetching: setFetchingFunction,
    setPaymentStatuses: setPaymentStatusesFunction,
    resetState: resetStateFunction,
  },
});

export const { setFetching, setPaymentStatuses, resetState } = slice.actions;
export default slice.reducer;
