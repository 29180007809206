import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  FlightDefinitionGroupsModel,
  FlightDefinitonGroupsPagedResult,
} from 'common/dataService/types/flightDefinintionGroupsTypes';
import FetchingStatus from 'constants/statuses/FetchingStatus';

export type DefinitionGroupsState = {
  page: number;
  takeCount: number;
  sorting: string | null;
  fetching: FetchingStatus | null;
  value: FlightDefinitonGroupsPagedResult | null;
  editModel: FlightDefinitionGroupsModel | null;
  visibleModel: boolean;
  updating: FetchingStatus | null;
  updatingKey: string | null;
};

const initialState: DefinitionGroupsState = {
  page: 1,
  takeCount: 25,
  sorting: null,
  fetching: null,
  value: null,
  editModel: null,
  visibleModel: false,
  updating: null,
  updatingKey: null,
};

function setPageFunction(state: DefinitionGroupsState, action: PayloadAction<DefinitionGroupsState['page']>) {
  return {
    ...state,
    page: action.payload,
  };
}

function setTakeCountFunction(state: DefinitionGroupsState, action: PayloadAction<DefinitionGroupsState['takeCount']>) {
  return {
    ...state,
    takeCount: action.payload,
  };
}

function setSortingFunction(state: DefinitionGroupsState, action: PayloadAction<DefinitionGroupsState['sorting']>) {
  return {
    ...state,
    sorting: action.payload,
  };
}

function setFetchingFunction(state: DefinitionGroupsState, action: PayloadAction<DefinitionGroupsState['fetching']>) {
  return {
    ...state,
    fetching: action.payload,
  };
}

function setFlightDefinitionGroupsResponseFunction(
  state: DefinitionGroupsState,
  action: PayloadAction<DefinitionGroupsState['value']>
) {
  return {
    ...state,
    value: action.payload,
  };
}

function setUpdatingFunction(state: DefinitionGroupsState, action: PayloadAction<DefinitionGroupsState['updating']>) {
  return {
    ...state,
    updating: action.payload,
  };
}

function setVisibleModuleFunction(
  state: DefinitionGroupsState,
  action: PayloadAction<DefinitionGroupsState['visibleModel']>
) {
  return {
    ...state,
    visibleModel: action.payload,
  };
}

function setEditModelFunction(state: DefinitionGroupsState, action: PayloadAction<DefinitionGroupsState['editModel']>) {
  return {
    ...state,
    editModel: action.payload,
  };
}

function setUpdatingKeyFunction(
  state: DefinitionGroupsState,
  action: PayloadAction<DefinitionGroupsState['updatingKey']>
) {
  return {
    ...state,
    updatingKey: action.payload,
  };
}

const slice = createSlice({
  name: 'flightDefinitionGroups',
  initialState,
  reducers: {
    setPage: setPageFunction,
    setTakeCount: setTakeCountFunction,
    setSorting: setSortingFunction,
    setFetching: setFetchingFunction,
    setFlightDefinitionGroupsResponse: setFlightDefinitionGroupsResponseFunction,
    setUpdating: setUpdatingFunction,
    setVisibleModule: setVisibleModuleFunction,
    setEditModel: setEditModelFunction,
    setUpdatingKey: setUpdatingKeyFunction,
  },
});

export const {
  setPage,
  setTakeCount,
  setSorting,
  setFetching,
  setFlightDefinitionGroupsResponse,
  setUpdating,
  setVisibleModule,
  setEditModel,
  setUpdatingKey,
} = slice.actions;
export default slice.reducer;
