import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import FetchingStatus from 'constants/statuses/FetchingStatus';

export type WebsitesFullFiltersState = {
  search: FetchingStatus | null;
};

const initialState: WebsitesFullFiltersState = {
  search: null,
};

function setSearchFunction(state: WebsitesFullFiltersState, action: PayloadAction<WebsitesFullFiltersState['search']>) {
  return {
    ...state,
    search: action.payload,
  };
}

const slice = createSlice({
  name: 'websitesFullFiltersState',
  initialState,
  reducers: {
    setSearch: setSearchFunction,
  },
});

export const { setSearch } = slice.actions;

export default slice.reducer;
