import type { RootState } from 'state';
import type { FlightCalendar } from 'common/packageCache';

function getFlightCalendarsByKey(keys: [string, string]) {
  return function getFlightCalendarsByKeyCallback(state: RootState): FlightCalendar[] {
    const [outboundKey, inboundKey] = keys;
    const { outbound, inbound } = state.flightCalendars.data;
    const outboundCalendarsDataMap = outbound && outbound[outboundKey];
    const inboundCalendarsDataMap = inbound && inbound[inboundKey];
    let calendars: FlightCalendar[] = [];

    if (outboundCalendarsDataMap !== null) {
      const outboundCalendars = outbound && outbound[outboundKey] && Object.values(outboundCalendarsDataMap).flat();

      if (outboundCalendars) {
        calendars = calendars.concat(outboundCalendars);
      }
    }

    if (inboundCalendarsDataMap !== null) {
      const inboundCalendars = inbound && inbound[inboundKey] && Object.values(inboundCalendarsDataMap).flat();

      if (inboundCalendars) {
        calendars = calendars.concat(inboundCalendars);
      }
    }

    return calendars;
  };
}

export default getFlightCalendarsByKey;
