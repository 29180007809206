import type { FormListFieldData } from 'antd/lib/form/FormList';
import type { FormConfigRow, FormAction } from 'comp/screenBuilder/comp/formBuilder';
import React from 'react';
import { Row, Col } from 'antd';
import FormField from '../formField';

type FormRowProps = {
  row: FormConfigRow;
  formListField?: FormListFieldData;
  activeAction: FormAction;
};

function FormRow({ row, formListField, activeAction }: FormRowProps): JSX.Element {
  const { fields } = row;

  return (
    <Row
      gutter={16}
      style={{
        alignItems: 'flex-end',
      }}
    >
      {fields.map((field) => (
        <Col key={field.formItem.name.toString()} {...field.colspan}>
          <FormField field={field} formListField={formListField} activeAction={activeAction} />
        </Col>
      ))}
    </Row>
  );
}

export default FormRow;
