import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FlightCacheDefinitionPeriodsPagedResult } from 'common/dataService';
import FetchingStatus from 'constants/statuses/FetchingStatus';

export type GeneratedDefinitionPeriodsState = {
  page: number;
  takeCount: number;
  sorting: string | null;
  fetching: FetchingStatus | null;
  value: FlightCacheDefinitionPeriodsPagedResult | null;
  ruleId: string;
};

const initialState: GeneratedDefinitionPeriodsState = {
  page: 1,
  takeCount: 25,
  sorting: null,
  fetching: null,
  value: null,
  ruleId: '0',
};

function setPageFunction(
  state: GeneratedDefinitionPeriodsState,
  action: PayloadAction<GeneratedDefinitionPeriodsState['page']>
) {
  return {
    ...state,
    page: action.payload,
  };
}

function setTakeCountFunction(
  state: GeneratedDefinitionPeriodsState,
  action: PayloadAction<GeneratedDefinitionPeriodsState['takeCount']>
) {
  return {
    ...state,
    takeCount: action.payload,
  };
}

function setSortingFunction(
  state: GeneratedDefinitionPeriodsState,
  action: PayloadAction<GeneratedDefinitionPeriodsState['sorting']>
) {
  return {
    ...state,
    sorting: action.payload,
  };
}

function setFetchingFunction(
  state: GeneratedDefinitionPeriodsState,
  action: PayloadAction<GeneratedDefinitionPeriodsState['fetching']>
) {
  return {
    ...state,
    fetching: action.payload,
  };
}

function setFlightDefinitionPeriodsResponseFunction(
  state: GeneratedDefinitionPeriodsState,
  action: PayloadAction<GeneratedDefinitionPeriodsState['value']>
) {
  return {
    ...state,
    value: action.payload,
  };
}

function setRuleIdFunction(
  state: GeneratedDefinitionPeriodsState,
  action: PayloadAction<GeneratedDefinitionPeriodsState['ruleId']>
) {
  return {
    ...state,
    ruleId: action.payload,
  };
}

const slice = createSlice({
  name: 'generatedDefinitionPeriods',
  initialState,
  reducers: {
    setPage: setPageFunction,
    setTakeCount: setTakeCountFunction,
    setSorting: setSortingFunction,
    setFetching: setFetchingFunction,
    setFlightDefinitionPeriodsResponse: setFlightDefinitionPeriodsResponseFunction,
    setRuleId: setRuleIdFunction,
  },
});

export const {
  setPage,
  setTakeCount,
  setSorting,
  setFetching,
  setFlightDefinitionPeriodsResponse,
  setRuleId,
} = slice.actions;
export default slice.reducer;
