import React, { useContext } from 'react';
import styled from 'styled-components/macro';
import { Layout as AntdLayout, Button } from 'antd';
import { useSelector } from 'react-redux';
import FontAwesomeIcon from 'comp/wraps/faIcon/FAIcon';
import ScrollbarSimple from 'styles/comp/scrollbar/ScrollbarSimple';
import mediaQuery from 'styles/utils/mediaQuery';
import getPermittedModulesForSider from 'state/reducers/auth/selectors/getPermittedModulesForSider';
import Logo from './comp/logo/Logo';
import Menu from './comp/menu/Menu';
import SiderContext from './context/index';
import UserActions from './comp/userActions/UserActions';

const { Sider: AntdSider } = AntdLayout;

function Sider(): JSX.Element {
  const { collapsed, setCollapsed } = useContext(SiderContext);
  const modules = useSelector(getPermittedModulesForSider());

  function onCollapse(_collapsed: boolean): void {
    setCollapsed(_collapsed);
  }

  function handleOnClick() {
    if (collapsed) {
      setCollapsed(false);
    }
  }

  function handleOnCollapse() {
    setCollapsed(!collapsed);
  }

  return (
    <>
      <Root width='288px' collapsible collapsed={collapsed} onCollapse={onCollapse} onClick={handleOnClick}>
        <LogoWrap>
          <Logo collapsed={collapsed} />
        </LogoWrap>
        <Menu items={modules} />
        <UserActions />
      </Root>
      <SiderTrigger onClick={handleOnCollapse} type='primary' shape='circle' icon={<FontAwesomeIcon icon='bars' />} />
    </>
  );
}

export default Sider;

const Root = styled(AntdSider)`
  z-index: 999;
  user-select: none;
  height: 100%;
  position: absolute;
  left: ${({ collapsed }) => (collapsed ? '-100%' : '0')};
  overflow: auto;

  .ant-layout-sider-children {
    display: flex;
    flex-flow: column nowrap;

    div:last-of-type {
      margin-top: auto;
      margin-bottom: 18px;
    }
  }

  ${ScrollbarSimple};

  ${mediaQuery.fromAndAbove.medium`
    height: initial;
    position: initial;
  `}
`;

const LogoWrap = styled.div`
  height: 64px;
  background: rgba(0, 0, 0, 0.3);
`;

const SiderTrigger = styled(Button)`
  position: fixed;
  z-index: 999;
  top: 16px;
  right: 16px;

  ${mediaQuery.fromAndAbove.medium`
    display: none;
  `}
`;
