import HttpClient from 'httpClient/index';
import { GetStaticRoutesResponse, StaticRouteModel, StaticRouteParams } from '../types/staticRouteTypes';

const BASE_URL = `${process.env.REACT_APP_PACKAGE_CACHE_API || ''}/api/staticRoutes/`;

class DataServiceStaticRoutes extends HttpClient {
  public constructor() {
    super(BASE_URL);
  }

  public getStaticRoutes = (params: StaticRouteParams): Promise<GetStaticRoutesResponse> =>
    this.instance.get('get', { params });

  public createStaticRoute = (body: StaticRouteModel): Promise<void> => this.instance.post('post', body);

  public updateStaticRoute = (body: StaticRouteModel): Promise<void> => this.instance.put('update', body);

  public deleteStaticRoute = (params: StaticRouteModel): Promise<void> => this.instance.delete('delete', { params });
}

export default new DataServiceStaticRoutes();
