import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { PATH_LOGIN } from 'router/constants/routePaths';
import isAuthenticated from 'state/reducers/auth/selectors/isAuthenticated';

type PropTypes = {
  path: string;
  children: JSX.Element;
};

function AuthWrapper({ path, children }: PropTypes): JSX.Element {
  const authenticated = useSelector(isAuthenticated);

  if (authenticated) {
    return children;
  }

  return <Redirect to={{ pathname: PATH_LOGIN, state: { from: path } }} />;
}

export default AuthWrapper;
