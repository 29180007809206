import type { AppDispatch } from 'state';
import type { SelectValue } from 'antd/lib/select/index';
import type { SelectOption } from 'types/common/SelectOption';
import type { RoutesFiltersState } from 'routes/tourOperator/flightCache/flightRoutes/comp/flightRoutesFilters';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { useSelector, useDispatch } from 'react-redux';
import { Select as AntdSelect } from 'antd';
import { FormattedMessage } from 'react-intl';
import {
  setAirportsTo,
  loadAndSetAirports,
  getAirportsToValue,
  getAirportsToOptions,
  getAirportsFromValue,
  isFetchingAirportsToInProgress,
  getOptionsForAirportsTo,
} from 'routes/tourOperator/flightCache/flightRoutes/comp/flightRoutesFilters';
import LabeledValue from 'comp/dataPresentation/labeledValue/LabeledValue';
import mediaQuery from 'styles/utils/mediaQuery';

function AirportsTo(): JSX.Element {
  const [options, setOptions] = useState<SelectOption<string>[]>();
  const availableOptions = useSelector(getAirportsToOptions);
  const value = useSelector(getAirportsToValue);
  const loading = useSelector(isFetchingAirportsToInProgress);
  const selectedAirportsFrom = useSelector(getAirportsFromValue);
  const dispatch = useDispatch() as AppDispatch;

  useEffect(() => {
    setOptions(dispatch(getOptionsForAirportsTo));
  }, [availableOptions, selectedAirportsFrom]);

  function handleOnChange(option: SelectValue) {
    if (option === undefined) {
      dispatch(setAirportsTo([]));
    } else {
      dispatch(setAirportsTo([option] as RoutesFiltersState['airportsTo']));
    }
  }

  function handleOnFocus() {
    if (availableOptions === null) {
      dispatch(loadAndSetAirports());
    }
  }

  return (
    <LabeledValue label={{ id: 'airportsTo' }}>
      <Select
        allowClear
        showSearch
        listHeight={640}
        loading={loading}
        placeholder={<FormattedMessage id='airportsTo.placeholder' />}
        value={value || undefined}
        onFocus={handleOnFocus}
        onChange={handleOnChange}
      >
        {options?.map(({ value, label, data }) => (
          <Select.Option key={value} value={value}>
            {label}
            {data?.map((item) => (
              <div key={item} style={{ margin: '10px', fontSize: '12px' }}>
                {item}
              </div>
            ))}
          </Select.Option>
        ))}
      </Select>
    </LabeledValue>
  );
}

export default AirportsTo;

const Select = styled(AntdSelect)`
  width: 100%;

  ${mediaQuery.fromAndAbove.medium`
    width: 200px;
  `}
`;
