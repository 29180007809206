import type { Validity } from 'types/common/validationTypes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import FetchingStatus from 'constants/statuses/FetchingStatus';

export type BookingCancellationState = {
  fetching: FetchingStatus | null;
  validity: Validity | null;
  value: string | null;
  updating: FetchingStatus | null;
  updatingKey: string | number | null;
};

const initialState: BookingCancellationState = {
  fetching: null,
  validity: null,
  value: null,
  updating: null,
  updatingKey: null,
};

function setFetchingFunction(
  state: BookingCancellationState,
  action: PayloadAction<BookingCancellationState['fetching']>
) {
  return {
    ...state,
    fetching: action.payload,
  };
}

function setBookingCancellationFunction(
  state: BookingCancellationState,
  action: PayloadAction<BookingCancellationState['value']>
) {
  return {
    ...state,
    value: action.payload,
  };
}

function setUpdatingFunction(
  state: BookingCancellationState,
  action: PayloadAction<BookingCancellationState['updating']>
) {
  return {
    ...state,
    updating: action.payload,
  };
}

function setUpdatingKeyFunction(
  state: BookingCancellationState,
  action: PayloadAction<BookingCancellationState['updatingKey']>
) {
  return {
    ...state,
    updatingKey: action.payload,
  };
}

function resetStateFunction() {
  return initialState;
}

const slice = createSlice({
  name: 'bookingCancellation',
  initialState,
  reducers: {
    setFetching: setFetchingFunction,
    setBookingCancellation: setBookingCancellationFunction,
    setUpdating: setUpdatingFunction,
    setUpdatingKey: setUpdatingKeyFunction,
    resetState: resetStateFunction,
  },
});

export const { setFetching, setBookingCancellation, setUpdating, setUpdatingKey, resetState } = slice.actions;
export default slice.reducer;
