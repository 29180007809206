import type { CollapseProps, CollapsePanelProps } from 'antd/lib/collapse';
import React from 'react';
import styled from 'styled-components/macro';
import { Collapse } from 'antd';
import CollapsibleCardHeader from './comp/collapsibleCardHeader/CollapsibleCardHeader';

type CollapsibleCardProps = CollapseProps & {
  panel: CollapsePanelProps;
  compact?: boolean;
  defaultClosed?: boolean;
  styles?: { header?: { root?: React.CSSProperties; title?: React.CSSProperties } };
  children: React.ReactNode;
};

function CollapsibleCard({
  panel,
  compact,
  defaultClosed,
  styles = {},
  children,
  ...props
}: CollapsibleCardProps): JSX.Element {
  const defaultActiveKey = defaultClosed ? [] : [panel.key];

  return (
    <CSSReset compact={compact}>
      <Collapse expandIconPosition='right' defaultActiveKey={defaultActiveKey} {...props}>
        <Collapse.Panel
          {...panel}
          header={<CollapsibleCardHeader styles={styles.header}>{panel.header}</CollapsibleCardHeader>}
        >
          {children}
        </Collapse.Panel>
      </Collapse>
    </CSSReset>
  );
}

export default CollapsibleCard;

type CSSResetProps = {
  compact?: boolean;
};

const CSSReset = styled.div<CSSResetProps>`
  .ant-collapse {
    .ant-collapse-item {
      .ant-collapse-header {
        display: flex;
        align-items: center;
        padding: 0;
        color: unset;

        .ant-collapse-extra {
          position: absolute;
          right: 64px;
        }
      }
    }
  }

  .ant-collapse-icon-position-right {
    .ant-collapse-item {
      .ant-collapse-header {
        .ant-collapse-arrow {
          right: 24px;
        }
      }
    }
  }

  .ant-collapse-ghost {
    .ant-collapse-item {
      .ant-collapse-content {
        border-top: 1px solid ${({ theme }) => theme.palette.border.default};
      }
    }
  }

  ${({ compact }) =>
    compact &&
    `
    .ant-collapse-header {
      padding-bottom: 8px !important;
    }

    .ant-collapse-content-box {
      padding: 0 !important;
    }
  `}
`;
