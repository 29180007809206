import type { FormFieldProps } from 'comp/screenBuilder/comp/formBuilder/types/formPropsTypes';
import React, { useContext } from 'react';
import styled from 'styled-components/macro';
import { Form, InputNumber as AntdInputNumber } from 'antd';
import { FormattedMessage } from 'react-intl';
import FormFieldContext from '../../context';

function Number({ field, formFieldName, removeFormItemLabel }: FormFieldProps): JSX.Element {
  const { formItem, formInput } = field;
  const { label, ...formItemProps } = formItem;

  const { context } = formInput;
  const fieldKey = context?.fieldKey ?? '';
  const formFieldContext = useContext(FormFieldContext);
  const { onChange } = formFieldContext[fieldKey] || {};

  async function handleOnChange(option: any) {
    if (typeof onChange === 'function') {
      await onChange(option as string, formFieldName);
    }
  }

  return (
    <Form.Item
      label={!removeFormItemLabel && label && <FormattedMessage {...label} />}
      {...formItemProps}
      name={formFieldName}
    >
      <InputNumber {...formInput} onChange={handleOnChange} />
    </Form.Item>
  );
}

export default Number;

const InputNumber = styled(AntdInputNumber)`
  width: 100%;
  min-width: 100px;
`;
