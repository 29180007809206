import type { AppThunk } from 'state';
import originsDestinationsService from 'httpClient/services/originsDestinations/index';
import FetchingStatus from 'constants/statuses/FetchingStatus';
import { setFetchingOrigins, setOrigins } from '../slice';

function fetchOrigins(): AppThunk {
  return async function fetchOriginsThunk(dispatch, getState) {
    try {
      const { fetchingOrigins } = getState().originsDestinations;

      if (fetchingOrigins === FetchingStatus.InProgress) {
        return;
      }

      dispatch(setFetchingOrigins(FetchingStatus.InProgress));

      const data = await originsDestinationsService.getOrigins();

      if (!data) {
        dispatch(setFetchingOrigins(FetchingStatus.Failed));
        return;
      }

      dispatch(setOrigins(data));
      dispatch(setFetchingOrigins(FetchingStatus.Succeeded));
    } catch (error) {
      dispatch(setFetchingOrigins(FetchingStatus.Failed));
      window.Rollbar.error(error);
    }
  };
}

export default fetchOrigins;
