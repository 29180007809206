import { combineReducers } from '@reduxjs/toolkit';
import airports, { loadAirports } from './airports';
import carriers, { loadCarriers } from './carriers';

export default combineReducers({
  flightRoutes: combineReducers({
    airports,
    carriers,
  }),
});
export { loadAirports };
export { loadCarriers };
