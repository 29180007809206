import type { FormFieldProps } from 'comp/screenBuilder/comp/formBuilder/types/formPropsTypes';
import React from 'react';
import styled from 'styled-components/macro';
import { Form, Checkbox as AntdCheckbox } from 'antd';
import { FormattedMessage } from 'react-intl';

function CheckboxPreview({ field, formFieldName, removeFormItemLabel }: FormFieldProps): JSX.Element {
  const { formItem } = field;
  const { label, ...formItemProps } = formItem;

  return (
    <Root>
      <Form.Item {...formItemProps} name={formFieldName}>
        <AntdCheckbox disabled>{!removeFormItemLabel && label && <FormattedMessage {...label} />}</AntdCheckbox>
      </Form.Item>
    </Root>
  );
}

export default CheckboxPreview;

const Root = styled.div`
  .ant-checkbox-disabled + span {
    color: initial;
  }
`;
