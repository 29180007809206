import type { FormConfig, FormFieldValidatorMap } from '..';

function updateFormFieldsValidators(form: FormConfig, validators?: FormFieldValidatorMap): FormConfig {
  if (!validators) {
    return form;
  }

  const nextForm = form;
  nextForm.sections = form.sections.map((section) => {
    const nextSection = section;
    nextSection.rows = section.rows.map((row) => {
      const nextRow = row;
      nextRow.fields = row.fields.map((field) => {
        const { formItem } = field;

        if (formItem.validatorKey) {
          if (formItem.rules === undefined) {
            formItem.rules = [];
          }

          formItem.rules.push(validators[formItem.validatorKey]);
          delete formItem.validatorKey;
        }

        return field;
      });
      return nextRow;
    });
    return nextSection;
  });

  return nextForm;
}

export default updateFormFieldsValidators;
