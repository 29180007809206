import type { RootState } from 'state';
import type { Enum } from 'routes/tourOperator/bookings/types/configs/enumsTypes';

function getAirportsToAsConfigEnum(state: RootState): Enum | undefined {
  const { airportsTo } = state.flightRoutes.filtersOptions;

  if (airportsTo) {
    const values = Object.keys(airportsTo)
      .sort((a, b) => a.localeCompare(b))
      .map((item) => ({ key: item, value: item }));

    return {
      name: 'AirportsTo',
      values,
    };
  }
}

export default getAirportsToAsConfigEnum;
