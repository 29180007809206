import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import FetchingStatus from 'constants/statuses/FetchingStatus';
import removeAtIndex from 'utils/immutability/removeAtIndex';
import type { BookingsOverviewResponse, BookingsOverviewResult } from '../types/bookingOverviewTypes';

export type BookingsOverviewState = {
  fetching: FetchingStatus | null;
  value: BookingsOverviewResponse | null;
  filteredValues: BookingsOverviewResult[] | null;
  filteredValuesTimeStamp: number | null;
  showAdditionalColumns: string[];
};

const initialState: BookingsOverviewState = {
  fetching: null,
  value: null,
  filteredValues: null,
  filteredValuesTimeStamp: null,
  showAdditionalColumns: [],
};

function setFetchingFunction(state: BookingsOverviewState, action: PayloadAction<BookingsOverviewState['fetching']>) {
  return {
    ...state,
    fetching: action.payload,
  };
}

function setBookingsOverviewFunction(
  state: BookingsOverviewState,
  action: PayloadAction<BookingsOverviewState['value']>
) {
  return {
    ...state,
    value: action.payload,
  };
}

function pushToShowAdditionalColumnsFunction(state: BookingsOverviewState, action: PayloadAction<string>) {
  return {
    ...state,
    showAdditionalColumns: [...state.showAdditionalColumns, action.payload],
  };
}

function removeFromShowAdditionalColumnsFunction(state: BookingsOverviewState, action: PayloadAction<string>) {
  const index = state.showAdditionalColumns.indexOf(action.payload);

  if (index === -1) {
    return state;
  }

  return {
    ...state,
    showAdditionalColumns: removeAtIndex(state.showAdditionalColumns, index),
  };
}

function resetStateFunction() {
  return initialState;
}

const slice = createSlice({
  name: 'bookingsOverview',
  initialState,
  reducers: {
    setFetching: setFetchingFunction,
    setBookingsOverview: setBookingsOverviewFunction,
    pushToShowAdditionalColumns: pushToShowAdditionalColumnsFunction,
    removeFromShowAdditionalColumns: removeFromShowAdditionalColumnsFunction,
    resetState: resetStateFunction,
  },
});

export const {
  setFetching,
  setBookingsOverview,
  pushToShowAdditionalColumns,
  removeFromShowAdditionalColumns,
  resetState,
} = slice.actions;
export default slice.reducer;
