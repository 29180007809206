import type { GetAirportGroupingsResponse } from 'common/dataService';
import type { GetFlightCalendarsRequestParameters } from 'common/packageCache';
import type { FlightCalendarsFiltersState } from '../slice';

type FlightCalendarsFiltersStateEntry = [
  keyof Omit<FlightCalendarsFiltersState, 'duration' | 'activeSearchFiltersKey'>,
  string | number | boolean | { key: number; value: string } | null
];

const propertiesWithSelectValues: string[] = [];

function transformStateToRequest(
  filtersValues: FlightCalendarsFiltersState,
  airportGroupings: [GetAirportGroupingsResponse | null, GetAirportGroupingsResponse | null]
): [GetFlightCalendarsRequestParameters, GetFlightCalendarsRequestParameters] {
  const { duration, activeSearchFiltersKey, ...restFiltersValues } = filtersValues;
  const outboundResult = (Object.entries(restFiltersValues) as FlightCalendarsFiltersStateEntry[]).reduce(
    (accumulator: GetFlightCalendarsRequestParameters, current) => {
      const [property, value] = current;

      if (value !== null) {
        if (propertiesWithSelectValues.indexOf(property) !== -1 && typeof value === 'object') {
          // Because we can't be sure the property we are accessing is not of type boolean, typescript is throwing an error.
          // The type conversion here is the suggested solution from this thread https://github.com/microsoft/TypeScript/issues/31663.
          (accumulator[property] as any) = value.key;
        } else {
          (accumulator[property] as any) = value;
        }
      }

      return accumulator;
    },
    {} as GetFlightCalendarsRequestParameters
  );
  const inboundResult = {
    ...outboundResult,
    airportsFrom: outboundResult.airportsTo,
    airportsTo: outboundResult.airportsFrom,
  };
  const [departureGroupings, destinationGroupings] = airportGroupings;

  const combinedAirportGroupings = [
    ...(departureGroupings?.airportGroupings ?? []),
    ...(destinationGroupings?.airportGroupings ?? []),
  ];

  if (outboundResult.airportsFrom && combinedAirportGroupings) {
    outboundResult.airportsFrom = outboundResult.airportsFrom
      .map((airport) => {
        const grouping = combinedAirportGroupings.find(({ code }) => code === airport);
        if (grouping) {
          return grouping.airports.map(({ code }) => code);
        }
        return airport;
      })
      .flat();
  }

  if (outboundResult.airportsTo && combinedAirportGroupings) {
    outboundResult.airportsTo = outboundResult.airportsTo
      .map((airport) => {
        const grouping = combinedAirportGroupings.find(({ code }) => code === airport);
        if (grouping) {
          return grouping.airports.map(({ code }) => code);
        }
        return airport;
      })
      .flat();
  }

  if (inboundResult.airportsFrom && combinedAirportGroupings) {
    inboundResult.airportsFrom = inboundResult.airportsFrom
      .map((airport) => {
        const grouping = combinedAirportGroupings.find(({ code }) => code === airport);
        if (grouping) {
          return grouping.airports.map(({ code }) => code);
        }
        return airport;
      })
      .flat();
  }

  if (inboundResult.airportsTo && combinedAirportGroupings) {
    inboundResult.airportsTo = inboundResult.airportsTo
      .map((airport) => {
        const grouping = combinedAirportGroupings.find(({ code }) => code === airport);
        if (grouping) {
          return grouping.airports.map(({ code }) => code);
        }
        return airport;
      })
      .flat();
  }

  return [outboundResult, inboundResult];
}

export default transformStateToRequest;
