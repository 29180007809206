import type { AppThunk } from 'state';
import DataServiceAirports from 'common/dataService/services/airports';
import { pushToFetching, removeFromFetching, setDepartureGroupings } from '../slice';
import { DepartureAirportGroupings } from '../constants/fetchingKeys';

function fetchDepartureAirportGroupings(): AppThunk {
  return async function fetchDepartureAirportGroupingsThunk(dispatch, getState) {
    try {
      const { fetching } = getState().dataService.airports.airportGroupings;

      if (fetching.indexOf(DepartureAirportGroupings) !== -1) {
        return;
      }

      dispatch(pushToFetching(DepartureAirportGroupings));

      const data = await DataServiceAirports.getDepartureAirportGroupings();

      dispatch(setDepartureGroupings(data));
      dispatch(removeFromFetching(DepartureAirportGroupings));
    } catch (error) {
      dispatch(removeFromFetching(DepartureAirportGroupings));
      window.Rollbar.error(error);
    }
  };
}

export default fetchDepartureAirportGroupings;
