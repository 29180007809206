import type { RootState } from 'state';
import { FlightDefinitionGroupsModel } from 'common/dataService';

function getFlightDefinitionGroupsItems(state: RootState): FlightDefinitionGroupsModel[] {
  const { value } = state.flightDefinitionGroups;
  if (!value) {
    return [];
  }

  return value.items.map((item) => ({ key: item.id, ...item }));
}

export default getFlightDefinitionGroupsItems;
