import type { AppThunk } from 'state';
import DataServiceAirports from 'common/dataService/services/airports';
import FetchingStatus from 'constants/statuses/FetchingStatus';
import { setFetching, setDataAirports } from '../slice';

function fetchDataAirports(): AppThunk {
  return async function fetchDataAirportsThunk(dispatch, getState) {
    try {
      const { fetching } = getState().dataService.airports.dataAirports;

      if (fetching === FetchingStatus.InProgress) {
        return;
      }

      dispatch(setFetching(FetchingStatus.InProgress));

      const data = await DataServiceAirports.getDataAirports();

      if (!data) {
        dispatch(setFetching(FetchingStatus.Failed));
        return;
      }

      dispatch(setDataAirports(data));
      dispatch(setFetching(FetchingStatus.Succeeded));
    } catch (error) {
      dispatch(setFetching(FetchingStatus.Failed));
      window.Rollbar.error(error);
    }
  };
}

export default fetchDataAirports;
