import HttpClient from 'httpClient';
import type { OriginsDestinationsResponse } from 'types/originsDestinations/originsDestinationsTypes';

const BASE_URL = `${process.env.REACT_APP_DATA_API || ''}/api/airports/airportGroupings`;

class OriginsDestinationsService extends HttpClient {
  public constructor() {
    super(BASE_URL);
  }

  public getOrigins = (): Promise<OriginsDestinationsResponse> => this.instance.get('/departure');

  public getDestinations = (): Promise<OriginsDestinationsResponse> => this.instance.get('/destination');
}

export default new OriginsDestinationsService();
