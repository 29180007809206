import { useRef, useEffect } from 'react';

function usePrevious<P>(value: P): P | undefined {
  const ref = useRef<P>();

  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
}

export default usePrevious;
