import React, { useState } from 'react';
import moment from 'moment';
import enGB from 'antd/es/locale/en_GB';
import 'moment/locale/en-gb';
import { ConfigProvider } from 'antd';
import { IntlProvider } from 'react-intl';
import { I18nProvider } from './context/index';
import enGBMessages from './messages/en_GB.json';

moment.locale('en');

const localeMap: Record<string, { moment: string; antd: string; message: string }> = {
  en: { moment: 'en-gb', antd: 'en_GB', message: 'en_GB' },
  sv: { moment: 'sv', antd: 'sv_SE', message: 'sv_SE' },
};

function I18n({ children }: { children: JSX.Element }): JSX.Element {
  const [locale, setLocale] = useState<string>('en');
  const [currency, setCurrency] = useState<string>('EUR');
  const [messages, setMessages] = useState(enGBMessages);
  const [antdLocaleData, setAntdLocaleData] = useState(enGB);

  function updateI18nContext(nextLocale?: string, nextCurrency?: string): void {
    if (nextLocale && locale !== nextLocale) {
      import(`moment/locale/${localeMap[nextLocale].moment}`)
        .then(() => {
          moment.locale(localeMap[nextLocale].moment);
        })
        .catch(() => {
          /** Add Rollbar notification */
        });

      import(`./messages/${localeMap[nextLocale].message}.json`)
        .then((module: { default: any }) => {
          setMessages(module.default);
        })
        .catch(() => {
          /** Add Rollbar notification */
        });

      import(`antd/es/locale/${localeMap[nextLocale].antd}`)
        .then((module: { default: any }) => {
          setAntdLocaleData(module.default);
        })
        .catch(() => {
          /** Add Rollbar notification */
        });

      setLocale(nextLocale);
    }

    if (nextCurrency && currency !== nextCurrency) {
      setCurrency(nextCurrency);
    }
  }

  return (
    <I18nProvider value={{ locale, currency, updateI18nContext }}>
      <IntlProvider locale={locale} messages={messages}>
        <ConfigProvider locale={antdLocaleData}>{children}</ConfigProvider>
      </IntlProvider>
    </I18nProvider>
  );
}

export default I18n;
