import resolvePathValue from 'utils/object/resolvePathValue';
import type { ListDropdownOption } from '../../../types';

function formatOptions<T>(options: T[], uniqueIdProp = ['id'], startAtIndex = 0): ListDropdownOption<T>[] {
  return options.map((option, index) => ({
    value: startAtIndex + index,
    label: `Option ${startAtIndex + index + 1}`,
    uniqueIdPropValue: resolvePathValue(option as Record<string, unknown>, uniqueIdProp),
    option,
  }));
}

export default formatOptions;
