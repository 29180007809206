import type { FlightCacheDefinitionsResponse } from 'types/flightCache/flightCacheDefinitionsTypes';
import type { BrowseFlightCacheResponse } from 'types/flightCache/browseFlightCacheTypes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import FetchingStatus from 'constants/statuses/FetchingStatus';

type Pagination = {
  pageSize: number;
  page: number;
  paging: boolean;
  total: number | null;
};

export type FlightCacheDefinitionsState = {
  fetching: FetchingStatus | null;
  definitions: FlightCacheDefinitionsResponse | null;
  browseFlightCacheDefinitions: BrowseFlightCacheResponse | null;
  browseFlightCacheDefinitionsPagination: Pagination;
};

const initialState: FlightCacheDefinitionsState = {
  fetching: null,
  definitions: null,
  browseFlightCacheDefinitions: null,
  browseFlightCacheDefinitionsPagination: {
    pageSize: 50,
    page: 1,
    paging: true,
    total: null,
  },
};

function setFetchingFlightCacheDefinitionsFunction(
  state: FlightCacheDefinitionsState,
  action: PayloadAction<FetchingStatus>
) {
  return {
    ...state,
    fetching: action.payload,
  };
}

function setFlightCacheDefinitionsFunction(
  state: FlightCacheDefinitionsState,
  action: PayloadAction<FlightCacheDefinitionsResponse>
) {
  return {
    ...state,
    definitions: action.payload,
  };
}

function setBrowseFlightCacheDefinitionsFunction(
  state: FlightCacheDefinitionsState,
  action: PayloadAction<BrowseFlightCacheResponse>
) {
  return {
    ...state,
    browseFlightCacheDefinitions: action.payload,
  };
}

function setBrowseFlightCacheDefinitionsPaginationFunction(
  state: FlightCacheDefinitionsState,
  action: PayloadAction<Pagination>
) {
  return {
    ...state,
    browseFlightCacheDefinitionsPagination: action.payload,
  };
}

const slice = createSlice({
  name: 'flightCache',
  initialState,
  reducers: {
    setFetchingFlightCacheDefinitions: setFetchingFlightCacheDefinitionsFunction,
    setFlightCacheDefinitions: setFlightCacheDefinitionsFunction,
    setBrowseFlightCacheDefinitions: setBrowseFlightCacheDefinitionsFunction,
    setBrowseFlightCacheDefinitionsPagination: setBrowseFlightCacheDefinitionsPaginationFunction,
  },
});

export const {
  setFetchingFlightCacheDefinitions,
  setFlightCacheDefinitions,
  setBrowseFlightCacheDefinitions,
  setBrowseFlightCacheDefinitionsPagination,
} = slice.actions;
export default slice.reducer;
