import React from 'react';
import InnerContext from './context';
import type { InnerContextShape } from './context';

type InnerContextProviderProps = {
  children: JSX.Element;
} & InnerContextShape;

function InnerContextProvider({ children, ...props }: InnerContextProviderProps): JSX.Element {
  return <InnerContext.Provider value={props}>{children}</InnerContext.Provider>;
}

export default InnerContextProvider;
