import { combineReducers } from '@reduxjs/toolkit';
import airportGroupings from './airportGroupings';
import departureAirports from './departureAirports';
import maxFlightDuration from './maxFlightDuration';
import dataAirports from './dataAirports';

export default combineReducers({
  airportGroupings,
  departureAirports,
  maxFlightDuration,
  dataAirports
});
export {
  loadDepartureAirportsGroupings,
  loadDestinationAirportsGroupings,
  getAirportGroupings,
  getDepartureAirportGroupings,
  getDestinationAirportGroupings,
} from './airportGroupings';
export {
  loadDepartureAirports,
  isFetchingInProgress as isFetchingDepartureAirportsInProgress,
} from './departureAirports';
export {
  loadMaxFlightDuration,
  getDurationForRoute,
  setDurationForRoute,
  isFetchingInProgressForRoute,
} from './maxFlightDuration';
export {
  loadDataAirports
} from './dataAirports'
