import type { WebsiteInitial } from 'common/dataService/types/websitesFullTypes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import FetchingStatus from 'constants/statuses/FetchingStatus';

export type WebsiteInitialState = {
  fetching: FetchingStatus | null;
  value: WebsiteInitial[] | null;
  filteredValue: WebsiteInitial[] | null;
};

const initialState: WebsiteInitialState = {
  fetching: null,
  value: null,
  filteredValue: null,
};

function setFetchingFunction(state: WebsiteInitialState, action: PayloadAction<WebsiteInitialState['fetching']>) {
  return {
    ...state,
    fetching: action.payload,
  };
}
function setWebsitesInitialFunction(state: WebsiteInitialState, action: PayloadAction<WebsiteInitialState['value']>) {
  return {
    ...state,
    value: action.payload,
  };
}

function setFilteredWebsitesInitialFunction(
  state: WebsiteInitialState,
  action: PayloadAction<WebsiteInitialState['filteredValue']>
) {
  return {
    ...state,
    filteredValue: action.payload,
  };
}
const slice = createSlice({
  name: 'dataService.websitesFull.websitesInitial',
  initialState,
  reducers: {
    setFetching: setFetchingFunction,
    setWebsitesInitial: setWebsitesInitialFunction,
    setFilteredWebsitesInitial: setFilteredWebsitesInitialFunction,
  },
});

export const { setFetching, setWebsitesInitial, setFilteredWebsitesInitial } = slice.actions;
export default slice.reducer;
