import type { AppThunk } from 'state';
import { getAirportGroupings } from 'common/dataService';
import { areFlightCalendarsAvailableByKey, fetchFlightCalendars, Direction } from '../comp/flightCalendarsData';
import {
  transformFiltersValuesToKeys,
  transformStateToRequest,
  setActiveSearchFiltersKeyValue,
} from '../comp/flightCalendarsFilters';
import type { FlightCalendarsFiltersState } from '../comp/flightCalendarsFilters';

function executeFetchFlightCalendars(filtersValues: FlightCalendarsFiltersState, direction?: Direction): AppThunk {
  return async function executeFetchFlightCalendarsThunk(dispatch, getState): Promise<void> {
    const airportGroupings = getAirportGroupings(getState());
    const keys = transformFiltersValuesToKeys(filtersValues);
    const flightCalendarsAreAvailable = areFlightCalendarsAvailableByKey(keys)(getState());

    if (!flightCalendarsAreAvailable) {
      const flightCalendarsRequestsData = transformStateToRequest(filtersValues, airportGroupings);
      await dispatch(fetchFlightCalendars(flightCalendarsRequestsData, keys));
    }

    dispatch(setActiveSearchFiltersKeyValue(keys[0]));
    const detail = { direction, keys };
    document.dispatchEvent(new CustomEvent('fech-flight-calendars-finished', { detail }));
  };
}

export default executeFetchFlightCalendars;
