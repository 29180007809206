import type { AppThunk } from 'state';
import type { GetMaxFlightDurationRequestParameters } from 'common/dataService';
import DataServiceAirports from 'common/dataService/services/airports';
import { pushToFetching, removeFromFetching, setDurationForRoute } from '../slice';

function fetchMaxFlightDuration(requestParameters: GetMaxFlightDurationRequestParameters): AppThunk {
  return async function fetchMaxFlightDurationThunk(dispatch, getState) {
    const { airportFrom, airportTo } = requestParameters;
    const key = `${airportFrom}${airportTo}`;

    try {
      const { fetching } = getState().dataService.airports.maxFlightDuration;

      if (fetching.indexOf(key) !== -1) {
        return;
      }

      dispatch(pushToFetching(key));

      const data = await DataServiceAirports.getMaxFlightDuration(requestParameters);

      if (data && data.length > 0) {
        const value = data[0].durationInMinutes;
        dispatch(setDurationForRoute({ key, value }));
      }

      dispatch(removeFromFetching(key));
    } catch (error) {
      dispatch(removeFromFetching(key));
      window.Rollbar.error(error);
    }
  };
}

export default fetchMaxFlightDuration;
