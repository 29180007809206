import type { RootState } from 'state';
import type { Enum } from 'routes/tourOperator/bookings/types/configs/enumsTypes';

function getCarriersAsConfigEnum(state: RootState): Enum | undefined {
  const { carrier } = state.flightRoutes.filtersOptions;

  if (carrier) {
    const values = carrier.map(({ value, label }) => ({ key: value, value: label }));

    return {
      name: 'Carriers',
      values,
    };
  }
}

export default getCarriersAsConfigEnum;
