import type { RootState } from 'state';
import type { FlightRoute } from 'common/packageCache';

function getFlightRouteByKey(flightRouteKey: string) {
  return function getFlightRouteByKeyCallback(state: RootState): FlightRoute | null {
    const { value } = state.flightRoutes.routes;

    if (!value) {
      return null;
    }

    return value.items.find(({ key }) => key === flightRouteKey) ?? null;
  };
}

export default getFlightRouteByKey;
