import type { FormAction } from '../types/formPropsTypes';
import defaultAction from '../constants/defaultAction';

function getInitialActiveAction(actions?: FormAction[], initialActiveActionType?: string): FormAction {
  if (actions && initialActiveActionType) {
    return actions.find((action) => action.type === initialActiveActionType) || defaultAction;
  }

  return defaultAction;
}

export default getInitialActiveAction;
