import type { FlightRouteAirport } from 'common/packageCache/types/flightRoutesTypes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import FetchingStatus from 'constants/statuses/FetchingStatus';

export type AirportsState = {
  fetching: FetchingStatus | null;
  value: FlightRouteAirport[] | null;
};

const initialState: AirportsState = {
  fetching: null,
  value: null,
};

function setFetchingFunction(state: AirportsState, action: PayloadAction<AirportsState['fetching']>) {
  return {
    ...state,
    fetching: action.payload,
  };
}

function setAirportsFunction(state: AirportsState, action: PayloadAction<AirportsState['value']>) {
  return {
    ...state,
    value: action.payload,
  };
}

function resetStateFunction() {
  return initialState;
}

const slice = createSlice({
  name: 'packageCache.flightRoutes.airports',
  initialState,
  reducers: {
    setFetching: setFetchingFunction,
    setAirports: setAirportsFunction,
    resetState: resetStateFunction,
  },
});

export const { setFetching, setAirports, resetState } = slice.actions;
export default slice.reducer;
