function getUpdatedPathToValues(pathToValues: (string | number)[], placeholdersValues: number[]): (string | number)[] {
  return pathToValues.map((path) => {
    if (typeof path === 'string' && path.indexOf('{') !== -1) {
      return placeholdersValues[parseInt(path.replace('{', '').replace('}', ''), 10)] || 0;
    }

    return path;
  });
}

export default getUpdatedPathToValues;
