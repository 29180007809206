import type { AppThunk } from 'state';
import { Direction } from '../comp/flightCalendarsData';
import { getFiltersValues } from '../comp/flightCalendarsFilters';
import type { FlightCalendarsFiltersState } from '../comp/flightCalendarsFilters';
import executeFetchFlightCalendars from './executeFetchFlightCalendars';
import executeLoadMaxFlightDuration from './executeLoadMaxFlightDuration';
import updateDurationOptions from './updateDurationOptions';

function executeFetchFlightCalendarsAndLoadMaxFlightDuration(
  direction?: Direction,
  forcedFiltersValues?: FlightCalendarsFiltersState
): AppThunk {
  return async function executeFetchFlightCalendarsAndLoadMaxFlightDurationThunk(dispatch, getState): Promise<void> {
    const filtersValues = forcedFiltersValues ?? getFiltersValues(getState());

    await dispatch(executeLoadMaxFlightDuration(filtersValues, direction));
    await dispatch(executeFetchFlightCalendars(filtersValues, direction));
    await dispatch(updateDurationOptions(filtersValues));
  };
}

export default executeFetchFlightCalendarsAndLoadMaxFlightDuration;
