import type { VanCreditCardDetails } from 'routes/tourOperator/bookings/types/getVanCreditCardDetails/getVanCreditCardTypes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import FetchingStatus from 'constants/statuses/FetchingStatus';

export type VanCreditCardDetailsState = {
  fetching: FetchingStatus | null;
  value: VanCreditCardDetails | null;
};

const initialState: VanCreditCardDetailsState = {
  fetching: null,
  value: null
};

function setFetchingFunction(
  state: VanCreditCardDetailsState,
  action: PayloadAction<VanCreditCardDetailsState['fetching']>
) {
  return {
    ...state,
    fetching: action.payload,
  };
}

function setVanCreditCardDetailsFunction(
  state: VanCreditCardDetailsState,
  action: PayloadAction<VanCreditCardDetailsState['value']>
) {
  return {
    ...state,
    value: action.payload,
  };
}

function resetStateFunction() {
  return initialState;
}

const slice = createSlice({
  name: 'vanCreditCardDetails',
  initialState,
  reducers: {
    setFetching: setFetchingFunction,
    setVanCreditCardDetails: setVanCreditCardDetailsFunction,
    resetState: resetStateFunction,
  },
});

export const { setFetching, setVanCreditCardDetails, resetState } = slice.actions;
export default slice.reducer;
