import type { RootState } from 'state';

function getFlightDefinitionGroupsTotalCount(state: RootState): number {
  const { value } = state.flightDefinitionGroups;

  if (!value) {
    return 0;
  }

  return value.totalCount;
}

export default getFlightDefinitionGroupsTotalCount;
