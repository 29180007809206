import type { AppThunk } from 'state';
import _uniq from 'lodash/uniq';
import { loadAirports } from 'common/packageCache';
import { pushToFetching, removeFromFetching, setAirportsFrom, setAirportsTo } from '../slice';
import { AirportsFrom, AirportsTo } from '../constants/filtersKeys';

function loadAndSetAirports(): AppThunk {
  return async function loadAndSetAirportsThunk(dispatch) {
    try {
      dispatch(pushToFetching(AirportsFrom));
      dispatch(pushToFetching(AirportsTo));
      const airports = await dispatch(loadAirports());

      const initialValueFrom: Record<string, string[]> = {};
      const airportsFrom = airports.reduce((prev, curr) => {
        const { airportFrom, airportTo } = curr;

        if (prev[airportFrom] === undefined) {
          prev[airportFrom] = [airportTo];
        } else {
          prev[airportFrom].push(airportTo);
        }

        return prev;
      }, initialValueFrom);

      for (const airport in airportsFrom) {
        if (Object.prototype.hasOwnProperty.call(airportsFrom, airport)) {
          airportsFrom[airport] = _uniq(airportsFrom[airport]).sort((a, b) => a.localeCompare(b));
        }
      }

      const initialValueTo: Record<string, string[]> = {};
      const airportsTo = airports.reduce((prev, curr) => {
        const { airportFrom, airportTo } = curr;

        if (prev[airportTo] === undefined) {
          prev[airportTo] = [airportFrom];
        } else {
          prev[airportTo].push(airportFrom);
        }

        return prev;
      }, initialValueTo);

      for (const airport in airportsTo) {
        if (Object.prototype.hasOwnProperty.call(airportsTo, airport)) {
          airportsTo[airport] = _uniq(airportsTo[airport]).sort((a, b) => a.localeCompare(b));
        }
      }

      dispatch(setAirportsFrom(airportsFrom));
      dispatch(removeFromFetching(AirportsFrom));
      dispatch(setAirportsTo(airportsTo));
      dispatch(removeFromFetching(AirportsTo));
    } catch (error) {
      dispatch(removeFromFetching(AirportsFrom));
      dispatch(removeFromFetching(AirportsTo));
      window.Rollbar.error(error);
    }
  };
}

export default loadAndSetAirports;
