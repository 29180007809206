import type { User } from 'types/user/userTypes';
import type { Validity } from 'types/common/validationTypes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import FetchingStatus from 'constants/statuses/FetchingStatus';

export type AuthState = {
  fetching: FetchingStatus | null;
  authenticated: boolean;
  currentUser: User | null;
  validity: Validity | null;
};

const initialState: AuthState = {
  fetching: null,
  authenticated: false,
  currentUser: null,
  validity: null,
};

function setFetchingFunction(state: AuthState, action: PayloadAction<FetchingStatus>) {
  return {
    ...state,
    fetching: action.payload,
  };
}

function setAuthenticatedFunction(state: AuthState, action: PayloadAction<boolean>) {
  return {
    ...state,
    authenticated: action.payload,
  };
}

function setCurrentUserFunction(state: AuthState, action: PayloadAction<User>) {
  return {
    ...state,
    currentUser: action.payload,
  };
}

function setValidityFunction(state: AuthState, action: PayloadAction<Validity>) {
  return {
    ...state,
    validity: action.payload,
  };
}

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setFetching: setFetchingFunction,
    setAuthenticated: setAuthenticatedFunction,
    setCurrentUser: setCurrentUserFunction,
    setValidity: setValidityFunction,
  },
});

export const { setFetching, setAuthenticated, setCurrentUser, setValidity } = slice.actions;
export default slice.reducer;
