import React from 'react';

export type SiderContextShape = {
  collapsed: boolean;
  setCollapsed: (nextCollapsed: boolean) => void;
};

const initialContext: SiderContextShape = {
  collapsed: false,
  setCollapsed: () => {},
};

const SiderContext = React.createContext(initialContext);

export const SiderProvider = SiderContext.Provider;
export const SiderConsumer = SiderContext.Consumer;

export default SiderContext;
