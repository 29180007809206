import type { RootState } from 'state';
import FetchingStatus from 'constants/statuses/FetchingStatus';

function getGeneratedDefinitionPeriodFetching(state: RootState): boolean {
  const { fetching } = state.flightDefinitionRules.generatedDefinitionPeriods;

  return fetching === FetchingStatus.InProgress;
}

export default getGeneratedDefinitionPeriodFetching;
