import type { FormFieldProps } from 'comp/screenBuilder/comp/formBuilder/types/formPropsTypes';
import React from 'react';
import styled from 'styled-components/macro';
import { Form } from 'antd';
import { FormattedMessage } from 'react-intl';

function UrlPreview({ field, formFieldName, removeFormItemLabel }: FormFieldProps): JSX.Element {
  const { formItem, formInput } = field;
  const { label, ...formItemProps } = formItem;

  return (
    <Root>
      <Form.Item
        label={!removeFormItemLabel && label && <FormattedMessage {...label} />}
        {...formItemProps}
        name={formFieldName}
      >
        <UrlPreviewValue formInput={formInput} />
      </Form.Item>
    </Root>
  );
}

export default UrlPreview;

const Root = styled.div`
  .ant-form-item-no-colon {
    user-select: none;
    color: ${({ theme }) => theme.palette.text.label};
  }

  .ant-form-item-control-input {
    min-height: initial;
  }
`;

type UrlPreviewValueProps = {
  formInput: FormFieldProps['field']['formInput'];
  id?: string;
  value?: string;
  onChange?: () => void;
};

function UrlPreviewValue({ value, formInput, ...props }: UrlPreviewValueProps): JSX.Element {
  const label = formInput.context?.label ?? value;
  return (
    <Anchor href={value} target='_blank' {...props}>
      {label}
    </Anchor>
  );
}

const Anchor = styled.a`
  width: 100%;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
