/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-var-requires */

const palette = require('./palette.js');
const fonts = require('./fonts.js');
const breakpoints = require('./breakpoints.js');

module.exports = {
  palette,
  fonts,
  breakpoints,
};
