import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import FetchingStatus from 'constants/statuses/FetchingStatus';
import type { ScheduleChangesHistoryResponse } from 'routes/tourOperator/bookings/types/schedules/scheduleChangesTypes';

export type FlightStopScheduleChangesHistoryState = {
  fetching: FetchingStatus | null;
  value: ScheduleChangesHistoryResponse | null;
};

const initialState: FlightStopScheduleChangesHistoryState = {
  fetching: null,
  value: null,
};

function setFetchingFunction(
  state: FlightStopScheduleChangesHistoryState,
  action: PayloadAction<FlightStopScheduleChangesHistoryState['fetching']>
) {
  return {
    ...state,
    fetching: action.payload,
  };
}

function setFlightStopScheduleChangesHistoryFunction(
  state: FlightStopScheduleChangesHistoryState,
  action: PayloadAction<FlightStopScheduleChangesHistoryState['value']>
) {
  return {
    ...state,
    value: action.payload,
  };
}

const slice = createSlice({
  name: 'flightStopScheduleChangesHistory',
  initialState,
  reducers: {
    setFetching: setFetchingFunction,
    setFlightStopScheduleChangesHistory: setFlightStopScheduleChangesHistoryFunction,
  },
});

export const { setFetching, setFlightStopScheduleChangesHistory } = slice.actions;
export default slice.reducer;
