import type { FlightRoute } from 'common/packageCache';
import React from 'react';
import styled from 'styled-components/macro';
import OpenRouteInCalendars from '../../comp/openRouteInCalendars';
import UpdateFlightRoute from '../../comp/updateFlightRoute';

function renderActionsCell(text: string, record: FlightRoute): JSX.Element {
  return (
    <Root>
      <OpenRouteInCalendars flightRoute={record} />
      <UpdateFlightRoute flightRouteKey={record.key} />
    </Root>
  );
}

export default renderActionsCell;

const Root = styled.div`
  display: flex;
  justify-content: flex-end;

  > *:not(:last-child) {
    margin-right: 16px;
  }
`;
