import _groupBy from 'lodash/groupBy';
import type { ScreenConfig, ScreenFormsConfigsGroupedByData } from '../types/screenConfigTypes';

function groupScreenFormsConfigsByData(screenConfig: ScreenConfig): ScreenFormsConfigsGroupedByData[] {
  const group = _groupBy(screenConfig.forms, 'dataFrom');
  const keys = Object.keys(group);

  return keys.map((key) => {
    return {
      key,
      forms: group[key],
    };
  });
}

export default groupScreenFormsConfigsByData;
