import type { RootState } from 'state';
import FetchingStatus from 'constants/statuses/FetchingStatus';

function getFlightDefinitionRuleFetching(state: RootState): boolean {
  const { fetching } = state.flightDefinitionRules.definitionRules;

  return fetching === FetchingStatus.InProgress;
}

export default getFlightDefinitionRuleFetching;
