import { combineReducers } from '@reduxjs/toolkit';
import affiliates from './affiliates/slice';
import loadAffiliates from './affiliates/thunks/loadAffiliates';
import isFetchingAffiliatesInProgress from './affiliates/selectors/isFetchingInProgress';

export { loadAffiliates };
export { isFetchingAffiliatesInProgress };

export default combineReducers({
  affiliates,
});
