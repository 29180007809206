import type { AppDispatch } from 'state';
import { setPage } from '../reducers/slice';
import executeFetchDefinitionGroups from './executeFetchDefinitionGroups';

function updatePageAndLoadResults(page: number) {
  return async function updatePageAndLoadResultsThunk(dispatch: AppDispatch): Promise<void> {
    dispatch(setPage(page));
    await dispatch(executeFetchDefinitionGroups);
  };
}

export default updatePageAndLoadResults;
