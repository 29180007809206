import type { Forms } from '../types/formTypes';

type ResultType = {
  [x: string]: Record<string, unknown>;
};

async function handleOnFormFinish(forms: Forms): Promise<ResultType | boolean> {
  // Validate forms fields.
  const formsNames = Object.keys(forms).filter((formName) => formName !== 'footer');
  const validations = await Promise.all(
    formsNames.map(async (formName) => {
      try {
        await forms[formName].validateFields();
        return true;
      } catch (error) {
        return false;
      }
    })
  );
  const valid = validations.reduce((acc, curr) => acc && curr, true);

  if (!valid) {
    return valid;
  }

  // Gather the values from all forms and reduce them to a single object.
  const result = formsNames
    .map((formName) => ({ [formName]: forms[formName].getFieldsValue(true) as Record<string, unknown> }))
    .reduce((acc, curr) => ({ ...acc, ...curr }), {});

  return result;
}

export default handleOnFormFinish;
