import React from 'react';
import styled from 'styled-components/macro';
import { Badge } from 'antd';
import { FormattedMessage } from 'react-intl';

function FlightCalendarsLegend(): JSX.Element {
  return (
    <Root>
      <Badge status={'success'} text={<FormattedMessage id='direct' />} />
      <Badge status={'warning'} text={<FormattedMessage id='oneStop' />} />
      <Badge status={'error'} text={<FormattedMessage id='staleFlightLegendDescription' />} />
    </Root>
  );
}

export default FlightCalendarsLegend;

const Root = styled.div`
  align-self: flex-end;
  margin-right: 16px;

  > *:not(:last-child) {
    margin-right: 16px;
  }
`;
