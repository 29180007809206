import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import FetchingStatus from 'constants/statuses/FetchingStatus';
import type { WebsiteDestination } from '../../types';

export type WebsiteDestinationsState = {
  fetching: FetchingStatus | null;
  updating: FetchingStatus | null;
  allWebDestinations: WebsiteDestination[] | null;
  allowedWebDestinationIds: number[] | null;
};

const initialState: WebsiteDestinationsState = {
  fetching: null,
  updating: null,
  allWebDestinations: null,
  allowedWebDestinationIds: null,
};

function setFetchingFunction(
  state: WebsiteDestinationsState,
  action: PayloadAction<WebsiteDestinationsState['fetching']>
) {
  return {
    ...state,
    fetching: action.payload,
  };
}

function setUpdatingFunction(
  state: WebsiteDestinationsState,
  action: PayloadAction<WebsiteDestinationsState['updating']>
) {
  return {
    ...state,
    updating: action.payload,
  };
}

function setAllWebDestinationsFunction(
  state: WebsiteDestinationsState,
  action: PayloadAction<WebsiteDestinationsState['allWebDestinations']>
) {
  return {
    ...state,
    allWebDestinations: action.payload,
  };
}

function setAllowedWebDestinationIdsFunction(
  state: WebsiteDestinationsState,
  action: PayloadAction<WebsiteDestinationsState['allowedWebDestinationIds']>
) {
  return {
    ...state,
    allowedWebDestinationIds: action.payload,
  };
}

function resetStateFunction() {
  return initialState;
}

const slice = createSlice({
  name: 'website.websiteDestinations',
  initialState,
  reducers: {
    setFetching: setFetchingFunction,
    setUpdating: setUpdatingFunction,
    setAllWebDestinations: setAllWebDestinationsFunction,
    setAllowedWebDestinationIds: setAllowedWebDestinationIdsFunction,
    resetState: resetStateFunction,
  },
});

export const {
  setFetching,
  setUpdating,
  setAllWebDestinations,
  setAllowedWebDestinationIds,
  resetState,
} = slice.actions;
export default slice.reducer;
