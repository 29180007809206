import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Breadcrumb as AntdBreadcrumb } from 'antd';
import capitalizeEachWord from 'utils/string/capitalizeEachWord';
import styled from 'styled-components';

function generateBreadcrumbItems(pathname: string): JSX.Element[] {
  const pathnameParts = pathname.split('/').filter((value) => value);

  return pathnameParts.map((pathnamePart, index, array) => {
    const pathnamePartUrl = `/${array.slice(0, index + 1).join('/')}`;

    return (
      <AntdBreadcrumb.Item key={pathnamePartUrl}>
        <Link to={pathnamePartUrl}>{capitalizeEachWord(pathnamePart.replaceAll('-', ' '))}</Link>
      </AntdBreadcrumb.Item>
    );
  });
}

function Breadcrumb(): JSX.Element {
  const { pathname } = useLocation();
  const breadcrumbItems = generateBreadcrumbItems(pathname);

  return <StyledBreadcrumb>{breadcrumbItems.map((breadcrumbItem) => breadcrumbItem)}</StyledBreadcrumb>;
}

export default Breadcrumb;

const StyledBreadcrumb = styled(AntdBreadcrumb)`
  margin-bottom: 8px;
`;
