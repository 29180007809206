import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import FetchingStatus from 'constants/statuses/FetchingStatus';

export type HotelProvidersState = {
  fetching: FetchingStatus | null;
  value: string[] | null;
};

const initialState: HotelProvidersState = {
  fetching: null,
  value: null,
};

function setFetchingFunction(state: HotelProvidersState, action: PayloadAction<HotelProvidersState['fetching']>) {
  return {
    ...state,
    fetching: action.payload,
  };
}

function setHotelProvidersFunction(state: HotelProvidersState, action: PayloadAction<HotelProvidersState['value']>) {
  return {
    ...state,
    value: action.payload,
  };
}

const slice = createSlice({
  name: 'dataService.productProviders.hotel',
  initialState,
  reducers: {
    setFetching: setFetchingFunction,
    setHotelProviders: setHotelProvidersFunction,
  },
});

export const { setFetching, setHotelProviders } = slice.actions;
export default slice.reducer;
