import type { RouteProps } from 'react-router-dom';
import React from 'react';
import styled from 'styled-components/macro';
import { Layout as AntdLayout } from 'antd';
import Sider from './comp/sider/Sider';
import Footer from './comp/footer/Footer';
import SiderContextProvider from './comp/sider/context/SiderContextProvider';

const { Content: AntdContent } = AntdLayout;

type LayoutProps = {
  children: RouteProps['children'];
};

function Layout({ children }: LayoutProps): JSX.Element {
  return (
    <SiderContextProvider>
      <Root>
        <Sider />
        <AntdLayout>
          <Content>{children}</Content>
          <Footer />
        </AntdLayout>
      </Root>
    </SiderContextProvider>
  );
}

export default Layout;

const Root = styled(AntdLayout)`
  height: 100vh;
`;

const Content = styled(AntdContent)`
  overflow-y: auto;
  padding: 32px;
`;
