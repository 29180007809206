import type { AppThunk } from 'state';
import type { GetPromocodesRequestParameters, Promocode } from 'common/dataService/types/promocodesTypes';
import FetchingStatus from 'constants/statuses/FetchingStatus';
import fetchPromocodes from './fetchPromocodes';
import getPromocodes from '../selectors/getPromocodes';
import { setFetching } from '../slice';

function loadPromocodes(params?: GetPromocodesRequestParameters): AppThunk<Promocode[]> {
  return async function loadPromocodesThunk(dispatch, getState) {
    try {
      let promocodes = getPromocodes(getState());

      if (promocodes !== null) {
        return promocodes;
      }

      await dispatch(fetchPromocodes(params));

      promocodes = getPromocodes(getState());

      if (promocodes !== null) {
        return promocodes;
      }
    } catch (error) {
      dispatch(setFetching(FetchingStatus.Failed));
      window.Rollbar.error(error);
    }

    return [];
  };
}

export default loadPromocodes;
