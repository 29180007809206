import type { FormFieldProps } from 'comp/screenBuilder/comp/formBuilder/types/formPropsTypes';
import React, { useCallback, useContext } from 'react';
import styled from 'styled-components/macro';
import { Form, InputNumber as AntdInputNumber } from 'antd';
import { FormattedMessage } from 'react-intl';
import FormFieldContext from '../../context';

type CurrencyProps = FormFieldProps & {
  currency?: string;
};

function Currency({ field, formFieldName, removeFormItemLabel, currency }: CurrencyProps): JSX.Element {
  const { formItem, formInput } = field;
  const { label, ...formItemProps } = formItem;
  const { context } = formInput;
  const fieldKey = context?.fieldKey ?? '';
  const formFieldContext = useContext(FormFieldContext);
  const { defaultCurrency } = formFieldContext[fieldKey] || {};
  const { onChange } = formFieldContext[fieldKey] || {};

  const currencyValue = currency ?? defaultCurrency;

  const memoizedFormatter = useCallback(
    (value?: string | number) => {
      let nextValue = value;

      if (value && typeof value === 'string') {
        const pattern = context?.allowNegative ? /[^0-9.-]|(?<!^)-|(?<=\..*)\./g : /[^0-9,.]+/g;
        nextValue = value.replace(pattern, '');
      }

      return nextValue ? `${currencyValue as string} ${nextValue}` : '';
    },
    [currencyValue, context]
  );
  const memoizedParser = useCallback(
    (value?: string) => (value ? value.replace(`${currencyValue as string} `, '') : ''),
    [currencyValue]
  );

  async function handleOnChange(option: any) {
    if (typeof onChange === 'function') {
      await onChange(option as string, formFieldName);
    }
  }

  return (
    <Form.Item
      label={!removeFormItemLabel && label && <FormattedMessage {...label} />}
      {...formItemProps}
      name={formFieldName}
    >
      <InputNumber formatter={memoizedFormatter} parser={memoizedParser} {...formInput} onChange={handleOnChange} />
    </Form.Item>
  );
}

export default Currency;

const InputNumber = styled(AntdInputNumber)`
  width: 100%;
`;
