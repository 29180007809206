import type { AppThunk } from 'state';
import type { Promocode } from 'common/dataService/types/promocodesTypes';
import FetchingStatus from 'constants/statuses/FetchingStatus';
import fetchRefundPromoCodes from './fetchRefundPromoCodes';
import getRefundPromoCodes from '../selectors/getRefundPromoCodes';
import { setFetching } from '../slice';

function loadRefundPromoCodes(): AppThunk<Promocode[]> {
  return async function loadRefundPromoCodesThunk(dispatch, getState) {
    try {
      let promocodes = getRefundPromoCodes(getState());

      if (promocodes !== null) {
        return promocodes;
      }

      await dispatch(fetchRefundPromoCodes());

      promocodes = getRefundPromoCodes(getState());

      if (promocodes !== null) {
        return promocodes;
      }
    } catch (error) {
      dispatch(setFetching(FetchingStatus.Failed));
      window.Rollbar.error(error);
    }

    return [];
  };
}

export default loadRefundPromoCodes;
