import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import removeAtIndex from 'utils/immutability/removeAtIndex';

export type RGLinkGeneratorOptionsState = {
  fetching: string[];
  departureAirport: { value: string; label: string }[] | null;
  primaryHotelIds: { value: number; label: string }[] | null;
};

const initialState: RGLinkGeneratorOptionsState = {
  fetching: [],
  departureAirport: null,
  primaryHotelIds: null,
};

function pushToFetchingFunction(state: RGLinkGeneratorOptionsState, action: PayloadAction<string>) {
  return {
    ...state,
    fetching: [...state.fetching, action.payload],
  };
}

function removeFromFetchingFunction(state: RGLinkGeneratorOptionsState, action: PayloadAction<string>) {
  const index = state.fetching.indexOf(action.payload);

  if (index === -1) {
    return state;
  }

  return {
    ...state,
    fetching: removeAtIndex(state.fetching, index),
  };
}

function setDepartureAirportFunction(
  state: RGLinkGeneratorOptionsState,
  action: PayloadAction<RGLinkGeneratorOptionsState['departureAirport']>
) {
  return {
    ...state,
    departureAirport: action.payload,
  };
}

function setPrimaryHotelIdsFunction(
  state: RGLinkGeneratorOptionsState,
  action: PayloadAction<RGLinkGeneratorOptionsState['primaryHotelIds']>
) {
  return {
    ...state,
    primaryHotelIds: action.payload,
  };
}

const slice = createSlice({
  name: 'rgLinkGenerator.options',
  initialState,
  reducers: {
    pushToFetching: pushToFetchingFunction,
    removeFromFetching: removeFromFetchingFunction,
    setDepartureAirport: setDepartureAirportFunction,
    setPrimaryHotelIds: setPrimaryHotelIdsFunction,
  },
});

export const { pushToFetching, removeFromFetching, setDepartureAirport, setPrimaryHotelIds } = slice.actions;
export default slice.reducer;
