import { combineReducers } from '@reduxjs/toolkit';
import refundPromoCodes from './refundPromoCodes/slice';
import loadRefundPromoCodes from './refundPromoCodes/thunks/loadRefundPromoCodes';
import isFetchingInProgress from './refundPromoCodes/selectors/isFetchingInProgress';

export { loadRefundPromoCodes };
export { isFetchingInProgress };

export default combineReducers({
  refundPromoCodes
});
