import type { FlightCalendarsFiltersState } from '../slice';

function transformFiltersValuesToKeys(filtersValues: FlightCalendarsFiltersState): [string, string] {
  const { airportsFrom, airportsTo, month } = filtersValues;
  const outboundKey = `${airportsFrom.join('')}|${airportsTo.join('')}|${month}`;
  const inboundKey = `${airportsTo.join('')}|${airportsFrom.join('')}|${month}`;
  return [outboundKey, inboundKey];
}

export default transformFiltersValuesToKeys;
