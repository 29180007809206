import type { FormListFieldData } from 'antd/lib/form/FormList';
import type { FormConfigSection, FormAction } from 'comp/screenBuilder/comp/formBuilder';
import React from 'react';
import styled from 'styled-components/macro';
import FormRow from './comp/formRow';

type FormSectionProps = {
  section: FormConfigSection;
  formListField?: FormListFieldData;
  activeAction: FormAction;
};

function FormSection({ section, formListField, activeAction }: FormSectionProps): JSX.Element {
  const { rows } = section;

  return (
    <Root>
      <Content>
        {rows.map((row) => {
          const key = row.fields
            .map((field) => field.formItem.name)
            .reduce((acc, cur) => `${acc}${cur.toString()}`, '');
          return <FormRow key={key} row={row} formListField={formListField} activeAction={activeAction} />;
        })}
      </Content>
    </Root>
  );
}

export default FormSection;

const Root = styled.div``;

const Content = styled.div``;
