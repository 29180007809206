import type { DatePickerProps } from 'antd/lib/date-picker';
import type { FormFieldProps } from 'comp/screenBuilder/comp/formBuilder/types/formPropsTypes';
import React from 'react';
import styled from 'styled-components/macro';
import moment, { Moment } from 'moment';
import { Form, DatePicker as AntdDatePicker } from 'antd';
import { FormattedMessage } from 'react-intl';
import { NORMALIZE_FORMAT } from './constants/defaults';

function DateTime({ field, formFieldName, removeFormItemLabel }: FormFieldProps): JSX.Element {
  const { formItem, formInput } = field;
  const { label, ...formItemProps } = formItem;

  function normalizeValue(value: Moment) {
    return value.format(NORMALIZE_FORMAT);
  }

  return (
    <Form.Item
      label={!removeFormItemLabel && label && <FormattedMessage {...label} />}
      normalize={normalizeValue}
      {...formItemProps}
      name={formFieldName}
    >
      <DateTimePicker formInput={formInput} />
    </Form.Item>
  );
}

export default DateTime;

type DateTimePickerProps = {
  formInput: FormFieldProps['field']['formInput'];
  id?: string;
  value?: string;
  onChange?: () => void;
};

function DateTimePicker({ formInput, value, ...props }: DateTimePickerProps): JSX.Element {
  const momentValue = value ? moment(value) : null;
  const showTime = formInput.format ? formInput.format.indexOf('HH') !== -1 : true;

  return (
    <DatePickerStyled
      value={momentValue}
      showTime={showTime}
      allowClear={false}
      {...formInput}
      {...props}
      disabledDate={(current: Moment) => {
        return formInput.disableDatesInThePast ? current && moment().add(-1, 'days') >= current : false;
      }}
    />
  );
}

const DatePickerStyled = styled(AntdDatePicker)<DatePickerProps>`
  width: 100%;
`;
