import type { Validity } from 'types/common/validationTypes';
import type { BookingAudit } from 'routes/tourOperator/bookings/types/bookingAudit/bookingAudit';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import FetchingStatus from 'constants/statuses/FetchingStatus';

export type BookingAuditState = {
  fetching: FetchingStatus | null;
  validity: Validity | null;
  value: BookingAudit[] | null;
};

const initialState: BookingAuditState = {
  fetching: null,
  validity: null,
  value: null,
};

function setFetchingFunction(state: BookingAuditState, action: PayloadAction<BookingAuditState['fetching']>) {
  return {
    ...state,
    fetching: action.payload,
  };
}

function setValidityFunction(state: BookingAuditState, action: PayloadAction<BookingAuditState['validity']>) {
  return {
    ...state,
    validity: action.payload,
  };
}

function setBookingAuditsFunction(state: BookingAuditState, action: PayloadAction<BookingAuditState['value']>) {
  return {
    ...state,
    value: action.payload,
  };
}

function resetStateFunction() {
  return initialState;
}

const slice = createSlice({
  name: 'bookingAudits',
  initialState,
  reducers: {
    setFetching: setFetchingFunction,
    setValidity: setValidityFunction,
    setBookingAudits: setBookingAuditsFunction,
    resetState: resetStateFunction,
  },
});

export const { setFetching, setValidity, setBookingAudits, resetState } = slice.actions;
export default slice.reducer;
