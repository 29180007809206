import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';

export type RGLinkGeneratorInputsState = {
  departureAirport: string | null;
  departureDate: string;
  primaryHotelIds: number | null;
  returnDate: string;
  withFlight: boolean;
};

const initialState: RGLinkGeneratorInputsState = {
  departureAirport: null,
  departureDate: moment().format('YYYY-MM-DD'),
  primaryHotelIds: null,
  returnDate: moment().format('YYYY-MM-DD'),
  withFlight: true,
};

function setDepartureAirportFunction(
  state: RGLinkGeneratorInputsState,
  action: PayloadAction<RGLinkGeneratorInputsState['departureAirport']>
) {
  return {
    ...state,
    departureAirport: action.payload,
  };
}

function setDepartureDateFunction(
  state: RGLinkGeneratorInputsState,
  action: PayloadAction<RGLinkGeneratorInputsState['departureDate']>
) {
  return {
    ...state,
    departureDate: action.payload,
  };
}

function setPrimaryHotelIdsFunction(
  state: RGLinkGeneratorInputsState,
  action: PayloadAction<RGLinkGeneratorInputsState['primaryHotelIds']>
) {
  return {
    ...state,
    primaryHotelIds: action.payload,
  };
}

function setReturnDateFunction(
  state: RGLinkGeneratorInputsState,
  action: PayloadAction<RGLinkGeneratorInputsState['returnDate']>
) {
  return {
    ...state,
    returnDate: action.payload,
  };
}

function setWithFlightFunction(
  state: RGLinkGeneratorInputsState,
  action: PayloadAction<RGLinkGeneratorInputsState['withFlight']>
) {
  return {
    ...state,
    withFlight: action.payload,
  };
}

const slice = createSlice({
  name: 'rgLinkGenerator.values',
  initialState,
  reducers: {
    setDepartureAirport: setDepartureAirportFunction,
    setDepartureDate: setDepartureDateFunction,
    setPrimaryHotelIds: setPrimaryHotelIdsFunction,
    setReturnDate: setReturnDateFunction,
    setWithFlight: setWithFlightFunction,
  },
});

export const {
  setDepartureAirport,
  setDepartureDate,
  setPrimaryHotelIds,
  setReturnDate,
  setWithFlight,
} = slice.actions;
export default slice.reducer;
