import type { RootState } from 'state';

function getGeneratedDefinitionPeriodTotalCount(state: RootState): number {
  const { value } = state.flightDefinitionRules.generatedDefinitionPeriods;

  if (!value) {
    return 0;
  }

  return value.totalCount;
}

export default getGeneratedDefinitionPeriodTotalCount;
