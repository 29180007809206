import type { AppThunk } from 'state';
import FetchingStatus from 'constants/statuses/FetchingStatus';
import fetchHotelProviders from './fetchHotelProviders';
import getHotelProviders from '../selectors/getHotelProviders';
import { setFetching } from '../slice';

function loadHotelProviders(): AppThunk<string[]> {
  return async function loadHotelProvidersThunk(dispatch, getState) {
    try {
      let hotelProviders = getHotelProviders(getState());

      if (hotelProviders !== null) {
        return hotelProviders;
      }

      await dispatch(fetchHotelProviders());

      hotelProviders = getHotelProviders(getState());

      if (hotelProviders !== null) {
        return hotelProviders;
      }
    } catch (error) {
      dispatch(setFetching(FetchingStatus.Failed));
      window.Rollbar.error(error);
    }

    return [];
  };
}

export default loadHotelProviders;
