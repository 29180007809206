import type { FlightRoute } from 'common/packageCache';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Tooltip, Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { PATH_TOUR_OPERATOR_FLIGHT_CALENDARS } from 'router';
import FontAwesomeIcon from 'comp/wraps/faIcon/FAIcon';
import { openRouteInFlightCalendars } from 'routes/tourOperator/flightCache/managers';

type OpenRouteInCalendarsProps = {
  flightRoute: FlightRoute;
};

function OpenRouteInCalendars({ flightRoute }: OpenRouteInCalendarsProps) {
  const history = useHistory();
  const dispatch = useDispatch();

  function onClickOpenInFlightCalendars() {
    dispatch(
      openRouteInFlightCalendars(
        flightRoute.airportFrom,
        flightRoute.airportTo,
        flightRoute.operatingFrom,
        flightRoute.operatingTo
      )
    );
    history.push(PATH_TOUR_OPERATOR_FLIGHT_CALENDARS);
  }

  return (
    <Tooltip title={<FormattedMessage id='openInFlightCalendars' />}>
      <Button
        type='primary'
        icon={<FontAwesomeIcon icon='calendar-alt' />}
        ghost
        onClick={onClickOpenInFlightCalendars}
      />
    </Tooltip>
  );
}

export default OpenRouteInCalendars;
