import type { Breakpoints, BreakpointsEntry } from 'styles/themes/types/Breakpoints';
import type { MQConfigurationEntry } from './configuration';

// TODO: This is the correct type definition for MQBreakpoints but i was not able to make it work...
// export type MQBreakpoints = Record<MQConfigurationEntryKey, Breakpoints>;
// export type MQBreakpoints = {
//   [P in MQConfigurationEntryKey]?: Breakpoints;
// };

export type MQBreakpoints = {
  [prop: string]: Breakpoints;
};

function getBreakpointEntriesWithModifiedValueCallback(modifier: number): (arg0: BreakpointsEntry) => BreakpointsEntry {
  return function getBreakpointEntriesWithModifiedValue(entry: BreakpointsEntry) {
    const [key, value] = entry;
    return [key, value + modifier];
  };
}

function getBreakpointsForConfigurationKey(accumulator: Breakpoints, currentValue: BreakpointsEntry): Breakpoints {
  const [key, value] = currentValue;
  accumulator[key] = value;
  return accumulator;
}

function getMediaQueryConfigurationEntryBreakpointsCallback(
  breakpointEntries: BreakpointsEntry[],
  callbackForMap: (arg0: number) => (arg0: BreakpointsEntry) => BreakpointsEntry,
  callbackForReduce: (arg0: Breakpoints, arg1: BreakpointsEntry) => Breakpoints
): (arg0: MQConfigurationEntry) => MQBreakpoints {
  return function getMediaQueryConfigurationEntryBreakpoints(entry: MQConfigurationEntry) {
    const { key, modifier } = entry;
    const getBreakpointEntriesWithModifiedValue = callbackForMap(modifier);

    return {
      [key]: {
        ...breakpointEntries.map(getBreakpointEntriesWithModifiedValue).reduce(callbackForReduce, {}),
      },
    };
  };
}

function mergeMediaQueryBreakpoints(accumulator: MQBreakpoints, currentValue: MQBreakpoints): MQBreakpoints {
  return {
    ...accumulator,
    ...currentValue,
  };
}

function generateMediaQueryBreakpoints(configuration: MQConfigurationEntry[], breakpoints: Breakpoints): MQBreakpoints {
  const breakpointEntries = Object.entries(breakpoints) as BreakpointsEntry[];
  const getMediaQueryConfigurationEntryBreakpoints = getMediaQueryConfigurationEntryBreakpointsCallback(
    breakpointEntries,
    getBreakpointEntriesWithModifiedValueCallback,
    getBreakpointsForConfigurationKey
  );

  return configuration.map(getMediaQueryConfigurationEntryBreakpoints).reduce(mergeMediaQueryBreakpoints, {});
}

export default generateMediaQueryBreakpoints;
