import HttpClient from 'httpClient/index';
import type {
  GetFlightRoutesRequestParameters,
  GetFlightRoutesResponse,
  FlightRouteAirport,
  FlightRoute,
} from '../types/flightRoutesTypes';
import { AuditData } from 'routes/tourOperator/bookings/types/bookingAudit/bookingAudit';

const BASE_URL = `${process.env.REACT_APP_PACKAGE_CACHE_API || ''}/api/flightroutes`;

class PackageCacheFlightRoutes extends HttpClient {
  public constructor() {
    super(BASE_URL);
  }

  public getFlightRoutes = (params: GetFlightRoutesRequestParameters): Promise<GetFlightRoutesResponse> =>
    this.instance.get('/', { params });

  public getFlightRoutesAirports = (): Promise<FlightRouteAirport[]> => this.instance.get('/airports');

  public getFlightRoutesCarriers = (): Promise<string[]> => this.instance.get('/carriers');

  public postFlightRoutesUpdate = (body: FlightRoute, auditData: AuditData) =>
    this.instance.post('/update', body, { headers: { auditmessage: auditData.message, otherAuditData: auditData.otherAuditData ? JSON.stringify(auditData.otherAuditData) : '' } });
}

export default new PackageCacheFlightRoutes();
