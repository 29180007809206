import type { Validity } from 'types/common/validationTypes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import FetchingStatus from 'constants/statuses/FetchingStatus';

export type BookingDetailsState = {
  fetching: FetchingStatus | null;
  validity: Validity | null;
  updating: FetchingStatus | null;
  updatingKey: string | number | null;
  updateValidity: Validity | null;
};

const initialState: BookingDetailsState = {
  fetching: null,
  validity: null,
  updating: null,
  updatingKey: null,
  updateValidity: null,
};

function setFetchingFunction(state: BookingDetailsState, action: PayloadAction<BookingDetailsState['fetching']>) {
  return {
    ...state,
    fetching: action.payload,
  };
}

function setValidityFunction(state: BookingDetailsState, action: PayloadAction<BookingDetailsState['validity']>) {
  return {
    ...state,
    validity: action.payload,
  };
}

function setUpdatingFunction(state: BookingDetailsState, action: PayloadAction<BookingDetailsState['updating']>) {
  return {
    ...state,
    updating: action.payload,
  };
}

function setUpdatingKeyFunction(state: BookingDetailsState, action: PayloadAction<BookingDetailsState['updatingKey']>) {
  return {
    ...state,
    updatingKey: action.payload,
  };
}

function setUpdateValidityFunction(
  state: BookingDetailsState,
  action: PayloadAction<BookingDetailsState['updateValidity']>
) {
  return {
    ...state,
    updateValidity: action.payload,
  };
}

function resetStateFunction() {
  return initialState;
}

const slice = createSlice({
  name: 'bookingDetails',
  initialState,
  reducers: {
    setFetching: setFetchingFunction,
    setValidity: setValidityFunction,
    setUpdating: setUpdatingFunction,
    setUpdatingKey: setUpdatingKeyFunction,
    setUpdateValidity: setUpdateValidityFunction,
    resetState: resetStateFunction,
  },
});

export const { setFetching, setValidity, setUpdating, setUpdatingKey, setUpdateValidity, resetState } = slice.actions;
export default slice.reducer;
