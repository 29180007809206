import HttpClient from 'httpClient/index';
import type {
  GetHotelNameSearchRequestParameters,
  HotelNameSearchResponseItem,
  GetHotelsRequestParameters,
  HotelsResponse,
} from '../types/hotelsTypes';

const BASE_URL = `${process.env.REACT_APP_DATA_API || ''}/api/hotels`;

class DataServiceHotels extends HttpClient {
  public constructor() {
    super(BASE_URL);
  }

  public getHotels = (params?: GetHotelsRequestParameters): Promise<HotelsResponse> =>
    this.instance.get('', { params });

  public getHotelNameSearch = (params: GetHotelNameSearchRequestParameters): Promise<HotelNameSearchResponseItem[]> =>
    this.instance.get('/hotelNameSearch', { params });
}

export default new DataServiceHotels();
