import type { AppDispatch } from 'state';
import updatePageAndLoadResults from './updatePageandLoadResults';
import { setSorting } from '../reducers/slice';

function updateSortingAndLoadResults(sorting: string | null) {
  return async function updateSortingAndLoadResultsThunk(dispatch: AppDispatch): Promise<void> {
    dispatch(setSorting(sorting));
    await dispatch(updatePageAndLoadResults(1));
  };
}

export default updateSortingAndLoadResults;
