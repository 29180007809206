import { FlightDefinitionGroupsRequest } from 'common/dataService/types/flightDefinintionGroupsTypes';
import type { AppDispatch, RootState } from 'state';
import fetchFlightDefinitionGroups from '../reducers/thunks/fetchFlightDefinitionGroups';

async function executeFetchDefinitionGroups(dispatch: AppDispatch, getState: () => RootState): Promise<void> {
  const { page, takeCount, sorting } = getState().flightDefinitionGroups;
  const request = {} as FlightDefinitionGroupsRequest;
  request.page = page;
  request.takeCount = takeCount;
  if (sorting) {
    var sortParam = sorting.split(' ');
    request.sortColumn = sortParam[0];
    request.sortDirection = sortParam[1];
  }
  await dispatch(fetchFlightDefinitionGroups(request));
}

export default executeFetchDefinitionGroups;
