import type { RootState } from 'state';
import type { FlightCalendarsDataMap } from '../slice';
import type Direction from '../constants/Direction';

function getFlightCalendarsByKeyDirectionAndDuration(direction: Direction, duration: number | null, key?: string) {
  return function getFlightCalendarsByKeyDirectionAndDurationCallback(state: RootState): FlightCalendarsDataMap | null {
    const data = state.flightCalendars.data[direction];

    if (!data || !key) {
      return null;
    }

    const flightCalendarsDataMap = data[key];
    if (flightCalendarsDataMap && duration) {
      return Object.entries(flightCalendarsDataMap).reduce((prev, curr) => {
        const [entryKey, entryValue] = curr;
        prev[entryKey] = entryValue.filter((calendar) => calendar.flightDuration <= duration);

        return prev;
      }, {} as FlightCalendarsDataMap);
    }

    return data[key] ?? null;
  };
}

export default getFlightCalendarsByKeyDirectionAndDuration;
