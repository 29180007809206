import nestedProperty from 'nested-property';
import type { FormTableDataItem } from '../../../types/formPropsTypes';

function getTableDataItem(uniqueIdProp: (string | number)[]) {
  return function getTableDataItemCallback<T>(item: T, index: number): FormTableDataItem<T> {
    let key = nestedProperty.get(item, uniqueIdProp.join('.')) as string;

    if (key === undefined) {
      key = `custom-${index}`;
    }

    return {
      key,
      index,
      item,
    };
  };
}

export default getTableDataItem;
