import HttpClient from 'httpClient/index';
import type { GetHotelRoomNameSearchRequestParameters, HotelRoomNameSearchResponseItem } from '../types/hotelsTypes';

const BASE_URL = `${process.env.REACT_APP_DATA_API || ''}/api/rooms`;

class DataServiceRooms extends HttpClient {
  public constructor() {
    super(BASE_URL);
  }

  public getHotelRoomNameSearch = (
    params: GetHotelRoomNameSearchRequestParameters
  ): Promise<HotelRoomNameSearchResponseItem[]> => this.instance.get('/roomNameSearch', { params });
}

export default new DataServiceRooms();
