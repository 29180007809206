import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Tooltip, Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import FontAwesomeIcon from 'comp/wraps/faIcon/FAIcon';
import { FlightDefinitionRulesModel } from 'common/dataService';
import { useHistory } from 'react-router';
import { PATH_TOUR_OPERATOR_FLIGHT_DEFINITION_RULES_PERIOD_OVERVIEW } from 'router/constants/routePaths';
import executeFetchDefinitionPeriods from 'routes/tourOperator/flightCache/flightDefinitionRules/manager/GeneratedDefinitionPeriods/executeFetchDefinitionPeriods';
import { setRuleId } from 'routes/tourOperator/flightCache/flightDefinitionRules/reducers/GeneratedDefinitionPeriods/slice';

function renderViewCell(text: string, record: FlightDefinitionRulesModel): JSX.Element {
  return <RedirectToGeneratedPeriodsOverview ruleId={record.id?.toString() ?? ''} />;
}

export default renderViewCell;

type RedirectToGeneratedPeriodsOverviewProps = {
  ruleId: string;
};

function RedirectToGeneratedPeriodsOverview({ ruleId }: RedirectToGeneratedPeriodsOverviewProps): JSX.Element {
  const [anchorHref, setAnchorHref] = useState('');
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const href = `${window.location.origin}${PATH_TOUR_OPERATOR_FLIGHT_DEFINITION_RULES_PERIOD_OVERVIEW.replace(
      ':ruleId',
      ruleId
    )}`;
    setAnchorHref(href);
  }, [ruleId]);

  function onClickViewRule(event: React.MouseEvent<HTMLAnchorElement>) {
    // Prevent anchor default behavior on click (redirecting and reloading the application)
    event.preventDefault();

    dispatch(setRuleId(ruleId));
    dispatch(executeFetchDefinitionPeriods);
    history.push(PATH_TOUR_OPERATOR_FLIGHT_DEFINITION_RULES_PERIOD_OVERVIEW.replace(':ruleId', ruleId));
  }

  return (
    <Tooltip title={<FormattedMessage id='view' />}>
      <a href={anchorHref} onClick={onClickViewRule}>
        <Button type='primary' icon={<FontAwesomeIcon icon='eye' />} ghost />
      </a>
    </Tooltip>
  );
}
