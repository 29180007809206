import type { BookingDetails } from 'routes/tourOperator/bookings/types/bookingDetails/bookingDetailsTypes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type BookingFeesState = {
  value: BookingDetails['fees'] | null;
};

const initialState: BookingFeesState = {
  value: null,
};

function setBookingFeesFunction(state: BookingFeesState, action: PayloadAction<BookingFeesState['value']>) {
  return {
    ...state,
    value: action.payload,
  };
}

function resetStateFunction() {
  return initialState;
}

const slice = createSlice({
  name: 'bookingDetails.bookingFees',
  initialState,
  reducers: {
    setBookingFees: setBookingFeesFunction,
    resetState: resetStateFunction,
  },
});

export const { setBookingFees, resetState } = slice.actions;
export default slice.reducer;
