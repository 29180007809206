import type { Validity } from 'types/common/validationTypes';
import type { GetRebookDetailsResponse } from 'routes/tourOperator/bookings/types/rebook/rebookResponseTypes';
import removeAtIndex from 'utils/immutability/removeAtIndex';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type RebookDetailsState = {
  fetching: number[];
  validity: Validity | null;
  value: Record<number, GetRebookDetailsResponse | undefined> | null;
};

const initialState: RebookDetailsState = {
  fetching: [],
  validity: null,
  value: null,
};

function pushToFetchingFunction(state: RebookDetailsState, action: PayloadAction<number>) {
  return {
    ...state,
    fetching: [...state.fetching, action.payload],
  };
}

function removeFromFetchingFunction(state: RebookDetailsState, action: PayloadAction<number>) {
  const index = state.fetching.indexOf(action.payload);

  if (index === -1) {
    return state;
  }

  return {
    ...state,
    fetching: removeAtIndex(state.fetching, index),
  };
}

function setValidityFunction(state: RebookDetailsState, action: PayloadAction<RebookDetailsState['validity']>) {
  return {
    ...state,
    validity: action.payload,
  };
}

function setRebookDetailsForTransactionFunction(
  state: RebookDetailsState,
  action: PayloadAction<{ transactionId: number; response: GetRebookDetailsResponse }>
) {
  const {
    payload: { transactionId, response },
  } = action;
  return {
    ...state,
    value: {
      ...state.value,
      [transactionId]: response,
    },
  };
}

function removeRebookDetailsForTransactionFunction(state: RebookDetailsState, action: PayloadAction<number>) {
  if (state.value === null) {
    return {
      ...state,
    };
  }

  return {
    ...state,
    value: {
      ...state.value,
      [action.payload]: undefined,
    },
  };
}

function resetStateFunction() {
  return initialState;
}

const slice = createSlice({
  name: 'rebook',
  initialState,
  reducers: {
    pushToFetching: pushToFetchingFunction,
    removeFromFetching: removeFromFetchingFunction,
    setValidity: setValidityFunction,
    setRebookDetailsForTransaction: setRebookDetailsForTransactionFunction,
    removeRebookDetailsForTransaction: removeRebookDetailsForTransactionFunction,
    resetState: resetStateFunction,
  },
});

export const {
  pushToFetching,
  removeFromFetching,
  setValidity,
  setRebookDetailsForTransaction,
  removeRebookDetailsForTransaction,
  resetState,
} = slice.actions;
export default slice.reducer;
