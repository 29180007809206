import React, { useEffect, useState } from 'react';
import { Button, Modal, notification } from 'antd';
import { FormattedMessage } from 'react-intl';
import { DataServiceFlightDefinitionRules, FlightDefinitionRulesModel } from 'common/dataService';
import { ResponseError } from 'httpClient';
import Spinner from 'comp/feedback/spinner/Spinner';
import { useSelector } from 'react-redux';
import { getFlightDefinitionRulesItems } from '../../../reducers/DefinitionRules';

type PreviewGeneratedPeriodsCountProps = {
  onClickOk: Function;
  request: FlightDefinitionRulesModel;
};

function PreviewGeneratedPeriodsCount({ onClickOk, request }: PreviewGeneratedPeriodsCountProps): JSX.Element {
  const dataSource = useSelector(getFlightDefinitionRulesItems);
  const [newPeriodCount, setNewPeriodCount] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  const currentPeriodCount = dataSource?.find((x) => x.id == request.id)?.countGeneratedPeriods ?? 0;

  useEffect(() => {
    setIsFetching(true);
    DataServiceFlightDefinitionRules.postCountPreviewPeriods(request)
      .then((result) => {
        setNewPeriodCount(result);
        setIsFetching(false);
      })
      .catch((error) => {
        setIsFetching(false);
        const err = error as ResponseError;
        notification.error({
          message: 'Error on getting period count!',
          description: err.response?.data?.message,
        });
      });
  }, []);

  return (
    <Modal
      visible={true}
      title={<FormattedMessage id='addRule.PreviewGeneratedPeriodsCount' />}
      width={720}
      destroyOnClose={true}
      onCancel={(e) => onClickOk(false)}
      footer={[
        <Button key='ok' onClick={(e) => onClickOk(false)}>
          Ok
        </Button>,
      ]}
    >
      <div>
        {isFetching && <Spinner useDefaultMessage />}
        {!isFetching && (
          <FormattedMessage
            id='addRule.MessagePeriodPreviewCount'
            values={{ currentPeriodCount, newPeriodCount, difference: newPeriodCount - currentPeriodCount }}
          />
        )}
      </div>
    </Modal>
  );
}

export default PreviewGeneratedPeriodsCount;
