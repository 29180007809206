import React from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import FontAwesomeIcon from 'comp/wraps/faIcon/FAIcon';
import { PATH_DASHBOARD } from 'router/constants/routePaths';

type LogoProps = {
  collapsed?: boolean;
};

function Logo({ collapsed }: LogoProps): JSX.Element {
  return (
    <Root>
      <Link to={PATH_DASHBOARD}>{collapsed ? <Icon icon='globe-europe' /> : <Name>Tripx Partner</Name>}</Link>
    </Root>
  );
}

export default Logo;

const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 32px;
  color: #ffffff;
`;

const Name = styled.span`
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 3px;
  text-transform: uppercase;
  text-decoration: none;
  white-space: nowrap;
  color: rgb(249, 249, 249);
`;
