import type { AppThunk } from 'state';
import DataServiceDestinations from 'common/dataService/services/destinations';
import FetchingStatus from 'constants/statuses/FetchingStatus';
import { setFetching, setWebDestinations } from '../slice';

function fetchWebDestinations(): AppThunk {
  return async function fetchWebDestinationsThunk(dispatch, getState) {
    try {
      const { fetching } = getState().dataService.destinations.webDestinations;

      if (fetching === FetchingStatus.InProgress) {
        return;
      }

      dispatch(setFetching(FetchingStatus.InProgress));

      const data = await DataServiceDestinations.getWebDestinations();

      if (!data) {
        dispatch(setFetching(FetchingStatus.Failed));
        return;
      }

      dispatch(setWebDestinations(data));
      dispatch(setFetching(FetchingStatus.Succeeded));
    } catch (error) {
      dispatch(setFetching(FetchingStatus.Failed));
      window.Rollbar.error(error);
    }
  };
}

export default fetchWebDestinations;
