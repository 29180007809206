import type { ColumnsType, ColumnType } from 'antd/lib/table';
import type { TableColumnConfig } from '../types/columnConfigTypes';

function processColumnsUsingConfig<T>(
  columns: ColumnsType<T>,
  tableColumnConfigs?: TableColumnConfig[] | null,
  applyFilter = false
): ColumnsType<T> {
  if (!tableColumnConfigs) {
    return columns.filter((item) => item.key !== 'actions');
  }

  const sortedColumnKeys = tableColumnConfigs
    .filter((item) => {
      if (applyFilter) {
        return item.visible;
      }

      return item.key !== 'actions';
    })
    .sort((a, b) => {
      if (a.order === undefined && b.order) {
        return 1;
      }

      if (b.order === undefined && a.order) {
        return -1;
      }

      if (a.order && b.order) {
        return a.order - b.order;
      }

      return 0;
    })
    .map((item) => item.key);

  return sortedColumnKeys
    .map((key) => columns.find((item) => item.key === key))
    .filter<ColumnType<T>>((value): value is ColumnType<T> => value !== undefined);
}

export default processColumnsUsingConfig;
