import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import FetchingStatus from 'constants/statuses/FetchingStatus';

export type AffiliatesFullFiltersState = {
  search: FetchingStatus | null;
};

const initialState: AffiliatesFullFiltersState = {
  search: null,
};

function setSearchFunction(
  state: AffiliatesFullFiltersState,
  action: PayloadAction<AffiliatesFullFiltersState['search']>
) {
  return {
    ...state,
    search: action.payload,
  };
}

const slice = createSlice({
  name: 'affiliatesFullFilters',
  initialState,
  reducers: {
    setSearch: setSearchFunction,
  },
});

export const { setSearch } = slice.actions;

export default slice.reducer;
