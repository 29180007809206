import type { CustomEventDefinition, EventHandlerFunction } from 'comp/screenBuilder/types/eventsTypes';
import type { FormListCreateCallback, FormListDeleteCallback } from 'comp/screenBuilder/comp/formBuilder';
import { useState, useEffect } from 'react';

type ListOperationsEventsProps = {
  screenFormConfigKey: string;
  eventHandlers: EventHandlerFunction;
  add: FormListCreateCallback;
  remove: FormListDeleteCallback;
};

function ListOperationsEvents({ screenFormConfigKey, eventHandlers, add, remove }: ListOperationsEventsProps): null {
  const [customEventDefinitions, setCustomEventDefinitions] = useState<CustomEventDefinition[]>([]);

  // Set the custom event definitions so we can apply event listeners and execute them in a separate side effect.
  useEffect(() => {
    if (eventHandlers && eventHandlers.customEventDefinitions) {
      setCustomEventDefinitions(eventHandlers.customEventDefinitions());
    }
  }, [eventHandlers]);

  // Side effect for executing custom event definitions.
  useEffect(() => {
    function executeCustomEvent(event: CustomEvent<unknown>) {
      const { type, detail } = event;
      const customEventDefinition = customEventDefinitions.find(({ on }) => on === type);

      if (customEventDefinition && eventHandlers && eventHandlers[customEventDefinition.attach]) {
        eventHandlers[customEventDefinition.attach](add, remove, detail);
        document.dispatchEvent(new CustomEvent(`update-table-data-${screenFormConfigKey}`, { detail }));
      }
    }

    customEventDefinitions.forEach(({ on }) => document.addEventListener(on, executeCustomEvent as EventListener));

    return () => {
      customEventDefinitions.forEach(({ on }) => document.removeEventListener(on, executeCustomEvent as EventListener));
    };
  }, [customEventDefinitions, eventHandlers, screenFormConfigKey, add, remove]);

  return null;
}

export default ListOperationsEvents;
