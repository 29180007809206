import React, { useEffect, useState } from 'react';
import { Button, Modal, notification } from 'antd';
import { FormattedMessage } from 'react-intl';
import { DataServiceFlightDefinitionRules, FlightDefinitionRulesDates, RuleDatesRequest } from 'common/dataService';
import { ResponseError } from 'httpClient';
import Spinner from 'comp/feedback/spinner/Spinner';

type PreviewDateRangeProps = {
  onClickOk: Function;
  request: RuleDatesRequest;
};

function PreviewDateRange({ onClickOk, request }: PreviewDateRangeProps): JSX.Element {
  const [ruleDates, setRuleDates] = useState<FlightDefinitionRulesDates[]>([]);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    setIsFetching(true);
    DataServiceFlightDefinitionRules.getFlightDefinitionRuleDatesPreview(request)
      .then((response) => {
        setRuleDates(response);
        setIsFetching(false);
      })
      .catch((error) => {
        setIsFetching(false);
        const err = error as ResponseError;
        notification.error({
          message: 'Error on getting rule dates!',
          description: err.response?.data?.message,
        });
      });
  }, []);

  return (
    <Modal
      visible={true}
      title={<FormattedMessage id='addRule.PreviewPeriod' />}
      width={720}
      destroyOnClose={true}
      onCancel={(e) => onClickOk(false)}
      footer={[
        <Button key='ok' onClick={(e) => onClickOk(false)}>
          Ok
        </Button>,
      ]}
    >
      <h4>
        {<FormattedMessage id='addRule.periodFrom' />} - {<FormattedMessage id='addRule.periodTo' />}
      </h4>
      <div>
        {isFetching && <Spinner useDefaultMessage />}
        {ruleDates.map((item, index) => (
          <div key={index}>
            {item.periodFrom} - {item.periodTo}
          </div>
        ))}
        {ruleDates?.length === 0 && <FormattedMessage id='addRule.noResult' />}
      </div>
    </Modal>
  );
}

export default PreviewDateRange;
