import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import removeAtIndex from 'utils/immutability/removeAtIndex';

export type MaxFlightDurationState = {
  fetching: string[];
  value: Record<string, number>;
};

const initialState: MaxFlightDurationState = {
  fetching: [],
  value: {},
};

function pushToFetchingFunction(state: MaxFlightDurationState, action: PayloadAction<string>) {
  return {
    ...state,
    fetching: [...state.fetching, action.payload],
  };
}

function removeFromFetchingFunction(state: MaxFlightDurationState, action: PayloadAction<string>) {
  const index = state.fetching.indexOf(action.payload);

  if (index === -1) {
    return state;
  }

  return {
    ...state,
    fetching: removeAtIndex(state.fetching, index),
  };
}

function setDurationForRouteFunction(
  state: MaxFlightDurationState,
  action: PayloadAction<{ key: string; value: number }>
) {
  const { key, value } = action.payload;

  return {
    ...state,
    value: {
      ...state.value,
      [key]: value,
    },
  };
}

function resetStateFunction() {
  return initialState;
}

const slice = createSlice({
  name: 'dataService.airports.maxFlightDuration',
  initialState,
  reducers: {
    pushToFetching: pushToFetchingFunction,
    removeFromFetching: removeFromFetchingFunction,
    setDurationForRoute: setDurationForRouteFunction,
    resetState: resetStateFunction,
  },
});

export const { pushToFetching, removeFromFetching, setDurationForRoute } = slice.actions;
export default slice.reducer;
