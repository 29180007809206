import type { FormInstance } from 'antd/lib/form/hooks/useForm';
import type { FlightRoute } from 'common/packageCache';
import React from 'react';
import styled from 'styled-components/macro';
import { useDispatch } from 'react-redux';
import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import { updateFlightRoutes } from 'routes/tourOperator/flightCache/flightRoutes/comp/flightRoutesTable/reducer';
import ConfirmationButton from 'comp/wraps/confirmationButton/ConfirmationButton';
import { AuditData, BookingAudit } from 'routes/tourOperator/bookings/types/bookingAudit/bookingAudit';

type FooterProps = {
  formSubmit: FormInstance;
  onClickCancel: (event: React.SyntheticEvent) => void;
  submitDisabled?: boolean;
};

type FormsValues = {
  flightRoute?: FlightRoute;
};

function Footer({ formSubmit, onClickCancel, submitDisabled }: FooterProps): JSX.Element {
  const dispatch = useDispatch();
  const otherAuditRequired = false;

  function onShouldModalOpen(): Promise<boolean> {
    formSubmit.submit();

    return new Promise((resolve) => {
      setTimeout(() => {
        const fieldsValue = formSubmit.getFieldsValue(true) as Record<string, unknown>;

        if (fieldsValue.valid === false) {
          resolve(false);
          formSubmit.resetFields();
        } else {
          resolve(true);
        }
      }, 0);
    });
  }

  function onSubmit(audit: BookingAudit) {
    const { flightRoute } = formSubmit.getFieldsValue(true) as FormsValues;

    if (flightRoute) {
      const auditData: AuditData = {
        message: audit.message,
        otherAuditData: otherAuditRequired ? {
          reason: audit.reason,
          important: audit.important
        } : null
      }

      dispatch(updateFlightRoutes(flightRoute, auditData));
    }
  }

  return (
    <Root>
      <Button onClick={onClickCancel}>
        <FormattedMessage id='common.cancel' />
      </Button>
      <ConfirmationButton
        buttonProps={{ type: 'primary', disabled: submitDisabled }}
        alertMessage={<FormattedMessage id='flightRoute.update.confirmationMessage.submit' />}
        onSubmit={onSubmit}
        onShouldModalOpen={onShouldModalOpen}
        otherAuditRequired={otherAuditRequired}
      >
        <FormattedMessage id='common.submit' />
      </ConfirmationButton>
    </Root>
  );
}

export default Footer;

export const Root = styled.div`
  display: flex;
  justify-content: flex-end;

  > *:not(:last-child) {
    margin-right: 16px;
  }
`;
