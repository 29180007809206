import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Menu } from 'antd';
import FontAwesomeIcon from 'comp/wraps/faIcon/FAIcon';
import styled from 'styled-components/macro';

export default class SwitchTabMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openTab: '/tour-operator/flight-cache',
    };
  }

  render() {
    return (
      <Menu mode='horizontal' style={{ marginBottom: '16px' }}>
        <Menu.Item key='/tour-operator/flight-cache'>
          <Link to='/tour-operator/flight-cache'>
            <Icon icon={'book'} />
            Flight Cache Definitions
          </Link>
        </Menu.Item>
        <Menu.Item key='/tour-operator/browse-flight-cache'>
          <Link to='/tour-operator/browse-flight-cache'>
            <Icon icon={'search'} />
            Browse Flight Cache
          </Link>
        </Menu.Item>
        <Menu.Item key='/tour-operator/flight-routes'>
          <Link to='/tour-operator/flight-routes'>
            <Icon icon={'plane-alt'} />
            Flight Routes
          </Link>
        </Menu.Item>
        <Menu.Item key='/tour-operator/flight-calendars'>
          <Link to='/tour-operator/flight-calendars'>
            <Icon icon={'calendar-alt'} />
            Flight Calendars
          </Link>
        </Menu.Item>
        <Menu.Item key='/tour-operator/flight-definition-groups'>
          <Link to='/tour-operator/flight-definition-groups'>
            <Icon icon={'book'} />
            Flight Definintion Groups
          </Link>
        </Menu.Item>
        <Menu.Item key='/tour-operator/flight-definition-rules'>
          <Link to='/tour-operator/flight-definition-rules'>
            <Icon icon={'book'} />
            Flight Definintion Rules
          </Link>
        </Menu.Item>
        <Menu.Item key='/tour-operator/static-routes'>
          <Link to='/tour-operator/static-routes'>
            <Icon icon={'plane-alt'} />
            Static Routes
          </Link>
        </Menu.Item>
      </Menu>
    );
  }
}

const Icon = styled(FontAwesomeIcon)`
  margin-right: 12px;
  font-size: 1em;
`;
