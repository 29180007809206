import type { AppThunk } from 'state';
import FetchingStatus from 'constants/statuses/FetchingStatus';
import handleRequestError from 'managers/handleRequestError';
import { setFetching, setFlightDefinitionGroupsResponse } from '../slice';
import { DataServiceFlightDefinitionGroups, FlightDefinitionGroupsRequest } from 'common/dataService';

function fetchFlightDefinitionGroups(requestParameters: FlightDefinitionGroupsRequest): AppThunk {
  return async function fetchFlightDefinitionGroupsThunk(dispatch, getState) {
    try {
      const { fetching } = getState().flightDefinitionGroups;

      if (fetching === FetchingStatus.InProgress) {
        return;
      }

      dispatch(setFetching(FetchingStatus.InProgress));

      const data = await DataServiceFlightDefinitionGroups.getFlightDefinitionGroups(requestParameters);

      if (!data) {
        dispatch(setFetching(FetchingStatus.Failed));
        return;
      }

      dispatch(setFlightDefinitionGroupsResponse(data));
      dispatch(setFetching(FetchingStatus.Succeeded));
    } catch (error) {
      dispatch(handleRequestError(error, 'Error occured on fetch flight definition routes!', setFetching));
    }
  };
}

export default fetchFlightDefinitionGroups;
