import type { RootState } from 'state';
import { getDurationForRoute } from 'common/dataService';
import { getActiveSearchFiltersKey } from '../comp/flightCalendarsFilters';

function getMaxFlightDurationForActiveRoute(state: RootState): number | null {
  const activeSearchFiltersKey = getActiveSearchFiltersKey(state);

  if (activeSearchFiltersKey) {
    const [airportsFrom, airportsTo] = activeSearchFiltersKey.split('|');
    const route = `${airportsFrom}${airportsTo}`;
    return getDurationForRoute(route)(state);
  }

  return null;
}
export default getMaxFlightDurationForActiveRoute;
