import TemplateStringsArray, { css, FlattenSimpleInterpolation } from 'styled-components';
import type { Breakpoints } from 'styles/themes/types/Breakpoints';
import type { MQBreakpoints } from './generateMediaQueryBreakpoints';
import { MQ_CONFIG_KEY_BELOW, MQ_CONFIG_KEY_FROM_AND_BELOW } from './configuration';

const MEDIA_FEATURE_MAX_WIDTH = 'max-width';
const MEDIA_FEATURE_MIN_WIDTH = 'min-width';

type MediaFeature = typeof MEDIA_FEATURE_MAX_WIDTH | typeof MEDIA_FEATURE_MIN_WIDTH;

type PartialInterpolation = { [x: string]: (args: TemplateStringsArray) => FlattenSimpleInterpolation };

function getMediaFeature(key: string): MediaFeature {
  if (key === MQ_CONFIG_KEY_BELOW || key === MQ_CONFIG_KEY_FROM_AND_BELOW) {
    return MEDIA_FEATURE_MAX_WIDTH;
  }

  return MEDIA_FEATURE_MIN_WIDTH;
}

function getDeviceBreakpointsToSimpleInterpolationCallback(mediaFeature: string) {
  return function deviceBreakpointsToSimpleInterpolation(
    deviceBreakpointEntry: [string, number | undefined]
  ): PartialInterpolation {
    const [key, value] = deviceBreakpointEntry;
    return {
      [key]: (args: TemplateStringsArray) => css`
        @media (${mediaFeature}: ${value}px) {
          ${css(args)}
        }
      `,
    };
  };
}

function mergeInterpolations(
  accumulator: PartialInterpolation,
  currentValue: PartialInterpolation
): PartialInterpolation {
  return {
    ...accumulator,
    ...currentValue,
  };
}

function mqBreakpointsToFlattenSimpleInterpolation(mqBreakpointEntry: [string, Breakpoints]) {
  const [key, value] = mqBreakpointEntry;
  const mediaFeature = getMediaFeature(key);
  const deviceBreakpointsToSimpleInterpolation = getDeviceBreakpointsToSimpleInterpolationCallback(mediaFeature);

  return {
    [key]: Object.entries(value).map(deviceBreakpointsToSimpleInterpolation).reduce(mergeInterpolations, {}),
  };
}

function generateMediaRules(breakpoints: MQBreakpoints): { [x: string]: PartialInterpolation } {
  return Object.entries(breakpoints)
    .map(mqBreakpointsToFlattenSimpleInterpolation)
    .reduce((acc, curr) => ({ ...acc, ...curr }), {});
}

export default generateMediaRules;
