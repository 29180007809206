import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip, Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import FontAwesomeIcon from 'comp/wraps/faIcon/FAIcon';
import { FlightDefinitionRulesModel } from 'common/dataService';
import { RootState } from 'state';
import {
  setEditModel,
  setVisibleModule,
} from 'routes/tourOperator/flightCache/flightDefinitionRules/reducers/DefinitionRules/slice';

function renderEditCell(text: string, record: FlightDefinitionRulesModel): JSX.Element {
  return <UpdateFlightDefinitionRule record={record} />;
}

export default renderEditCell;

type FlightDefinitionRulesModelProp = {
  record: FlightDefinitionRulesModel;
};

function UpdateFlightDefinitionRule({ record }: FlightDefinitionRulesModelProp): JSX.Element {
  const visibleModule = useSelector((state: RootState) => state.flightDefinitionRules.definitionRules.visibleModel);

  const dispatch = useDispatch();

  function onClickEdit(event: React.MouseEvent<HTMLAnchorElement>) {
    event.preventDefault();
    dispatch(setVisibleModule(!visibleModule));
    dispatch(setEditModel(record));
  }

  return (
    <Tooltip title={<FormattedMessage id='edit' />}>
      <a onClick={onClickEdit}>
        <Button type='primary' icon={<FontAwesomeIcon icon='pen' />} ghost />
      </a>
    </Tooltip>
  );
}
