import type { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import type { ResponseError } from 'httpClient';
import type { AppDispatch } from 'state';
import type { Validity } from 'types/common/validationTypes';
import { notification } from 'antd';
import Severity from 'constants/Severity';
import FetchingStatus from 'constants/statuses/FetchingStatus';
import axios from 'axios';

function handleRequestError(
  error: any,
  message: string | React.ReactNode,
  setFetching?: ActionCreatorWithPayload<FetchingStatus | null, string>,
  setValidity?: ActionCreatorWithPayload<Validity | null, string>
) {
  return function handleRequestErrorCallback(dispatch: AppDispatch): void {
    const err = error as ResponseError;

    if (setFetching) {
      dispatch(setFetching(FetchingStatus.Failed));
    }

    if (setValidity) {
      dispatch(
        setValidity({ valid: false, severity: Severity.ERROR, message: err.response?.data?.simplifiedDescription })
      );
    }

    window.Rollbar.error(error);
    if (!axios.isCancel(error)) {
      notification.error({ message, description: err.response?.data?.simplifiedDescription });
    }
  };
}

export default handleRequestError;
