import { DataServiceStaticRoutes } from 'common/dataService/services';
import { setUpdating, setUpdatingKey } from '../slice';
import { AppThunk } from 'state';
import FetchingStatus from 'constants/statuses/FetchingStatus';
import { StaticRouteModel } from 'common/dataService/types/staticRouteTypes';
import handleRequestError from 'managers/handleRequestError';

function deletestaticRoutes(params: StaticRouteModel): AppThunk {
  return async function deletestaticRoute(dispatch, getState) {
    try {
      const { fetching } = getState().flightDefinitionGroups;

      if (fetching === FetchingStatus.InProgress) {
        return;
      }

      dispatch(setUpdatingKey('delete'));

      dispatch(setUpdating(FetchingStatus.InProgress));

      await DataServiceStaticRoutes.deleteStaticRoute(params);

      dispatch(setUpdating(FetchingStatus.Succeeded));
    } catch (error) {
      dispatch(handleRequestError(error, 'Error occured on deleting static routes!', setUpdating));
    }
  };
}

export default deletestaticRoutes;
