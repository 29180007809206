import { FlightDefinitionRulesModel } from 'common/dataService';
import type { AppDispatch } from 'state';
import { setVisibleModule } from '../reducers/DefinitionRules/slice';
import addFlightDefinitionRules from '../reducers/DefinitionRules/thunks/addFlightDefinitionRules';
import editFlightDefinitionRules from '../reducers/DefinitionRules/thunks/editFlightDefinitionRules';
import executeFetchDefinitionRules from './executeFetchDefinitionRules';

async function upsertDefinintionRules(dispatch: AppDispatch, model: FlightDefinitionRulesModel): Promise<void> {
  if (model?.id) {
    await dispatch(editFlightDefinitionRules(model));
  } else if (model) {
    await dispatch(addFlightDefinitionRules(model));
  }
  dispatch(setVisibleModule(false));
  dispatch(executeFetchDefinitionRules);
}

export default upsertDefinintionRules;
