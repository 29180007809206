import type { FlightCalendar } from 'common/packageCache';
import React from 'react';
import styled from 'styled-components/macro';
import moment from 'moment';
import { Popover, Badge } from 'antd';
import { FormattedCurrency } from 'i18n';
import DateCellTooltip from '../dateCellTooltip';

type DateCellProps = {
  dateData: FlightCalendar[];
};

function DateCell({ dateData }: DateCellProps): JSX.Element {
  return (
    <Popover trigger='click' content={<DateCellTooltip dateData={dateData} />} overlayStyle={{ width: '576px' }}>
      <Root>
        <List>
          {dateData.map(({ key, stops, carrier, price, currency, updateTime }) => {
            const isStale = moment(updateTime).isBefore(moment().subtract(10, 'days'));

            return (
              <ListItem key={key} direct={stops === 0}>
                <Badge dot={isStale} offset={[3, 3]}>
                  {`${carrier}: `}
                  <FormattedCurrency value={price} currency={currency} />
                </Badge>
              </ListItem>
            );
          })}
        </List>
      </Root>
    </Popover>
  );
}

export default DateCell;

type ListItemProps = {
  direct: boolean;
};

const Root = styled.div`
  height: 100%;
`;

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

const ListItem = styled.li<ListItemProps>`
  color: ${({ direct, theme }) => (direct ? theme.palette.success.default : theme.palette.warning.default)};
  white-space: nowrap;

  span {
    font-size: 12px;
  }
`;
