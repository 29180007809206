import type { GetFlightCalendarsResponse } from 'common/packageCache';
import _sortBy from 'lodash/sortBy';
import type { FlightCalendarsDataMap } from '../slice';

function transformFlightCalendarResponseToStateMap(response: GetFlightCalendarsResponse): FlightCalendarsDataMap {
  const { flights } = response;

  const result = _sortBy(flights, ['stops', 'price']).reduce((prev, curr) => {
    const key = curr.date.slice(2, 10).replaceAll('-', '');

    if (prev[key] !== undefined) {
      prev[key].push(curr);
    } else {
      prev[key] = [curr];
    }

    return prev;
  }, {} as FlightCalendarsDataMap);

  return result;
}

export default transformFlightCalendarResponseToStateMap;
