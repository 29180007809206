/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable no-cond-assign */
import nestedProperty from 'nested-property';

function updateWithValues(text: string, values: Record<string, any>, paths?: Record<string, string[]>): string {
  const iterator = text.matchAll(/\{(.*?)\}/g);
  let currItem: IteratorResult<RegExpMatchArray, any>;
  let updated = text;

  while (!(currItem = iterator.next()).done) {
    const [withBrackets, property] = currItem.value;
    let value;

    if (paths) {
      const pathToValue = paths[property]?.join('.');

      if (pathToValue) {
        value = nestedProperty.get(values, pathToValue);
      }
    } else {
      value = values[property];
    }

    if (value !== undefined) {
      updated = updated.replace(withBrackets, value);
    } else {
      updated = updated.replace(withBrackets, '');
    }
  }

  return updated;
}

export default updateWithValues;
