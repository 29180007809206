import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import FetchingStatus from 'constants/statuses/FetchingStatus';

export type RGLinkGeneratorResultState = {
  fetching: FetchingStatus | null;
  links: string[];
};

const initialState: RGLinkGeneratorResultState = {
  fetching: null,
  links: [],
};

function setFetchingFunction(
  state: RGLinkGeneratorResultState,
  action: PayloadAction<RGLinkGeneratorResultState['fetching']>
) {
  return {
    ...state,
    fetching: action.payload,
  };
}

function setLinksFunction(
  state: RGLinkGeneratorResultState,
  action: PayloadAction<RGLinkGeneratorResultState['links']>
) {
  return {
    ...state,
    links: action.payload,
  };
}

const slice = createSlice({
  name: 'rgLinkGenerator.result',
  initialState,
  reducers: {
    setFetching: setFetchingFunction,
    setLinks: setLinksFunction,
  },
});

export const { setFetching, setLinks } = slice.actions;
export default slice.reducer;
