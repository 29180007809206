import HttpClient from 'httpClient/index';
import type {
  GetDepartureAirportsRequestParameters,
  DepartureAirport,
  GetAirportGroupingsResponse,
  GetMaxFlightDurationResponse,
  GetMaxFlightDurationRequestParameters,
  UpsertMaxFlightDurationRequestParameters,
  DataAirport
} from '../types/airportsTypes';

const BASE_URL = `${process.env.REACT_APP_DATA_API || ''}/api/airports`;

class DataServiceAirports extends HttpClient {
  public constructor() {
    super(BASE_URL);
  }

  public getDepartureAirports = (params?: GetDepartureAirportsRequestParameters): Promise<DepartureAirport[]> =>
    this.instance.get('/departureAirports', { params });

  public getDepartureAirportGroupings = (): Promise<GetAirportGroupingsResponse> =>
    this.instance.get('/airportGroupings/departure');

  public getDestinationAirportGroupings = (): Promise<GetAirportGroupingsResponse> =>
    this.instance.get('/airportGroupings/destination');

  public getMaxFlightDuration = (
    params: GetMaxFlightDurationRequestParameters
  ): Promise<GetMaxFlightDurationResponse[]> => this.instance.get('/maxFlightDuration', { params });

  public upsertMaxFlightDuration = (params: UpsertMaxFlightDurationRequestParameters): Promise<void> =>
    this.instance.get('/upsertMaxFlightDuration', { params });

  public getDataAirports = (): Promise<DataAirport[]> =>
    this.instance.get('/dataAirports');
}

export default new DataServiceAirports();
