import type { TablePaginationConfig } from 'antd/lib/table';
import type { SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface';
import type { FlightRoute } from 'common/packageCache';
import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import { useSelector, useDispatch } from 'react-redux';
import Table, { showTotal } from 'comp/wraps/table';
import columns from './tableConfig/columns';
import { resetState, getFlightRoutesItems, getFlightRoutesTotalCount, isFetchingInProgress } from './reducer';
import {
  getPage,
  getTakeCount,
  updatePageAndLoadResults,
  updateSortingAndLoadResults,
  updateTakeCountAndLoadResults,
} from '../flightRoutesFilters';

function updateOrderForBackedn(order: string): string {
  if (order === 'ascend') {
    return 'asc';
  }

  return 'desc';
}

function FlightRoutesTable() {
  const current = useSelector(getPage);
  const pageSize = useSelector(getTakeCount);
  const total = useSelector(getFlightRoutesTotalCount);
  const dataSource = useSelector(getFlightRoutesItems);
  const loading = useSelector(isFetchingInProgress);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetState);
    };
  }, []);

  useEffect(() => {
    function onSignout() {
      dispatch(resetState);
    }

    document.addEventListener('signout', onSignout);

    return () => {
      document.removeEventListener('signout', onSignout);
    };
  }, [dispatch]);

  function getRowKey(record: FlightRoute) {
    return record.key;
  }

  function handleOnChangePage(page: number) {
    dispatch(updatePageAndLoadResults(page));
  }

  function handleOnShowSizeChange(page: number, size: number) {
    dispatch(updateTakeCountAndLoadResults(size));
  }

  function handleOnChange(
    paggination: TablePaginationConfig,
    filters: Record<string, React.ReactText[] | null>,
    sorter: SorterResult<FlightRoute> | SorterResult<FlightRoute>[],
    extra: TableCurrentDataSource<FlightRoute>
  ) {
    if (extra.action === 'sort') {
      const { columnKey, order } = sorter as SorterResult<FlightRoute>;
      const sorting = order === undefined ? null : `${columnKey as string} ${updateOrderForBackedn(order as string)}`;
      dispatch(updateSortingAndLoadResults(sorting));
    }
  }

  return (
    <Root>
      <Table
        columns={columns}
        pagination={{
          current,
          pageSize,
          total,
          showSizeChanger: true,
          showTotal,
          onChange: handleOnChangePage,
          onShowSizeChange: handleOnShowSizeChange,
        }}
        rowKey={getRowKey}
        dataSource={dataSource}
        loading={loading}
        onChange={handleOnChange}
        configuration={{ editColumns: true, tableName: 'flightRoutesTable' }}
      />
    </Root>
  );
}

export default FlightRoutesTable;

const Root = styled.div``;
