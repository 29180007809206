import HttpClient from 'httpClient';
import { FlightProviderType, AllowedFlightProvidersType } from '../types';

const BASE_URL = `${process.env.REACT_APP_PACKAGE_CACHE_API || ''}/api/flightcache`;

class FlightProvidersService extends HttpClient {
  constructor() {
    super(BASE_URL);
  }

  public getAllFlightProvidersSettings = (): Promise<FlightProviderType[]> =>
    this.instance.get('/get/allflightproviders');

  public setAllowedFlightProviders = (params: AllowedFlightProvidersType): Promise<void> =>
    this.instance.put('update/allowedflightproviders', params);
}

export default new FlightProvidersService();
