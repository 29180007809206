import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import FetchingStatus from 'constants/statuses/FetchingStatus';

export type CarriersState = {
  fetching: FetchingStatus | null;
  value: string[] | null;
};

const initialState: CarriersState = {
  fetching: null,
  value: null,
};

function setFetchingFunction(state: CarriersState, action: PayloadAction<CarriersState['fetching']>) {
  return {
    ...state,
    fetching: action.payload,
  };
}

function setCarriersFunction(state: CarriersState, action: PayloadAction<CarriersState['value']>) {
  return {
    ...state,
    value: action.payload,
  };
}

function resetStateFunction() {
  return initialState;
}

const slice = createSlice({
  name: 'flightRoutes.carriers',
  initialState,
  reducers: {
    setFetching: setFetchingFunction,
    setCarriers: setCarriersFunction,
    resetState: resetStateFunction,
  },
});

export const { setFetching, setCarriers, resetState } = slice.actions;
export default slice.reducer;
