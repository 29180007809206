import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type NewTransactionData = {
  dateFrom?: string;
  dateTo?: string;
};

const initialState: NewTransactionData = {
  dateFrom: undefined,
  dateTo: undefined,
};

function setTransactionDataStateFunction(state: NewTransactionData, action: PayloadAction<NewTransactionData>) {
  return {
    dateFrom: action.payload.dateFrom,
    dateTo: action.payload.dateTo,
  };
}

function resetStateFunction() {
  return initialState;
}

const slice = createSlice({
  name: 'newTransactionFormData',
  initialState,
  reducers: {
    setTransactionDataState: setTransactionDataStateFunction,
    resetState: resetStateFunction,
  },
});

export const { setTransactionDataState, resetState } = slice.actions;
export default slice.reducer;
