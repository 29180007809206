import type { BookingDetails } from 'routes/tourOperator/bookings/types/bookingDetails/bookingDetailsTypes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type BookingCostsState = {
  value: BookingDetails['costs'] | null;
};

const initialState: BookingCostsState = {
  value: null,
};

function setBookingCostsFunction(state: BookingCostsState, action: PayloadAction<BookingCostsState['value']>) {
  return {
    ...state,
    value: action.payload,
  };
}

function resetStateFunction() {
  return initialState;
}

const slice = createSlice({
  name: 'bookingDetails.bookingCosts',
  initialState,
  reducers: {
    setBookingCosts: setBookingCostsFunction,
    resetState: resetStateFunction,
  },
});

export const { setBookingCosts, resetState } = slice.actions;
export default slice.reducer;
