import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Input, Select, Spin } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { setEditModel, setVisibleModule } from '../../reducers/slice';
import { FlightDefinitionGroupsModel } from 'common/dataService';
import { RootState } from 'state';
import { useForm } from 'antd/lib/form/Form';
import {
  getFlightDefinitionGroupUpdating,
  getFlightDefinitionGroupVisibleModel,
  getFlightOrigins,
} from '../../reducers';
import { FormattedMessage } from 'react-intl';
import fetchOrigins from 'state/reducers/originsDestinations/thunks/fetchOrigins';
import { SelectValue } from 'antd/lib/select';
import upsertDefinintionGroups from '../../managers/upsertDefinintionGroups';
import getWebDestinations from 'common/dataService/reducers/destinations/webDestinations/selectors/getWebDestinations';
import fetchWebDestinations from 'common/dataService/reducers/destinations/webDestinations/thunks/fetchWebDestinations';
import { OptionsList } from '../../types/OptionsList';

const typeOptions = [
  { value: 'Origin', label: 'Origin' },
  { value: 'Destination', label: 'Destination' },
];

const { Option } = Select;

function AddGroup(): JSX.Element {
  const loading = useSelector(getFlightDefinitionGroupUpdating);
  const visible = useSelector(getFlightDefinitionGroupVisibleModel);
  const editModel = useSelector((state: RootState) => state.flightDefinitionGroups.editModel);
  const webDestinations = useSelector(getWebDestinations);
  const [webDestinationsSorted, setWebDestinationsSorted] = useState<OptionsList[]>([]);
  const origins = useSelector(getFlightOrigins);
  const [groupDataOptions, setGroupDataOptions] = useState(origins);
  const [form] = useForm<FlightDefinitionGroupsModel>();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!origins || origins.length === 0) {
      dispatch(fetchOrigins());
    }
    if (!webDestinations || webDestinations.length === 0) {
      dispatch(fetchWebDestinations());
    }
  }, []);

  useEffect(() => {
    if (webDestinations)
      setWebDestinationsSorted(
        webDestinations
          .map((item) => ({ code: item.code, ungrouped: false, airports: [] }))
          .sort((a, b) => (a.code < b.code ? -1 : 1))
      );
  }, [webDestinations]);

  useEffect(() => {
    if (editModel) {
      form.setFieldsValue(editModel);
      if (editModel.type === typeOptions[0].value) {
        setGroupDataOptions(origins);
      } else {
        setGroupDataOptions(webDestinationsSorted);
      }
    } else if (origins && origins.length !== 0) {
      setGroupDataOptions(origins);
    }
  }, [editModel, origins, webDestinationsSorted]);

  const onOk = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    form.submit();
  };

  const onClose = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    dispatch(setVisibleModule(!visible));
    dispatch(setEditModel(null));
    form.resetFields();
  };

  const onFinish = (values: FlightDefinitionGroupsModel) => {
    const newModel = { ...editModel, ...values };
    upsertDefinintionGroups(dispatch, newModel);
    dispatch(setEditModel(null));
    form.resetFields();
  };

  function handleOnChange(option: SelectValue) {
    if (option === typeOptions[0].value) {
      setGroupDataOptions(origins);
    } else {
      setGroupDataOptions(webDestinationsSorted);
    }
    form.setFieldsValue({ ...form.getFieldsValue, groupData: [] });
  }

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      title={<FormattedMessage id={(editModel?.id ? 'edit' : 'save') + 'Group'} />}
      destroyOnClose={true}
      width={720}
      onOk={onOk}
      confirmLoading={loading}
      maskClosable={false}
    >
      <Spin spinning={loading}>
        <Form
          name='addGroup'
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          autoComplete='off'
          form={form}
          onFinish={onFinish}
        >
          <Form.Item
            label={<FormattedMessage id='name' />}
            name='name'
            rules={[{ required: true, message: 'Please input group name!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item label={<FormattedMessage id='type' />} name='type' initialValue={typeOptions[0].value}>
            <Select options={typeOptions} onChange={handleOnChange} />
          </Form.Item>
          <Form.Item
            label={<FormattedMessage id='content' />}
            name='groupData'
            rules={[{ required: true, message: 'Please input content!' }]}
          >
            <Select mode='multiple' style={{ width: '100%' }}>
              {groupDataOptions?.map((group) => (
                <Option value={group.code} key={group.code}>
                  {group.code}
                  {group.airports &&
                    group.airports?.map((airport) => (
                      <div key={airport} style={{ margin: '10px', fontSize: '12px' }}>
                        {airport}
                      </div>
                    ))}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
}

export default AddGroup;

const Root = styled.div``;
