import type { RootState } from 'state';

function getIsOperatingAsNumber(state: RootState): number | undefined {
  const { isOperating } = state.flightRoutes.filtersValues;

  if (isOperating === null) {
    return undefined;
  }

  return isOperating ? 1 : 0;
}

export default getIsOperatingAsNumber;
