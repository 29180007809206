import type { ScreenFormConfig } from '../types/screenConfigTypes';

function mapScreenFormConfigKeyToActiveActionType(screenFormConfigs: ScreenFormConfig[]): Record<string, string> {
  return screenFormConfigs.reduce((prev, curr) => {
    return {
      ...prev,
      [curr.key]: curr.activeActionType,
    };
  }, {});
}

export default mapScreenFormConfigKeyToActiveActionType;
