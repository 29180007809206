import { UPDATE as ACTION_TYPE_UPDATE, DELETE as ACTION_TYPE_DELETE } from '../../../constants/actionTypes';

function isActionDisabled(actionType: string, actionIsActive: boolean, listDropdownIsEmpty?: boolean): boolean {
  if (actionIsActive) {
    return true;
  }

  if (listDropdownIsEmpty && (actionType === ACTION_TYPE_UPDATE || actionType === ACTION_TYPE_DELETE)) {
    return true;
  }

  return false;
}

export default isActionDisabled;
