/* eslint react/style-prop-object: "off" */

import React, { useContext } from 'react';
import { FormattedNumber } from 'react-intl';
import I18nContext from 'i18n/context';

type FormattedCurrencyProps = {
  value: number | bigint;
  currency?: string;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
};

function FormattedCurrency({
  value,
  currency,
  minimumFractionDigits,
  maximumFractionDigits,
}: FormattedCurrencyProps): JSX.Element {
  const i18nContext = useContext(I18nContext);

  return (
    <FormattedNumber
      value={value}
      currency={currency || i18nContext.currency}
      style='currency'
      minimumFractionDigits={minimumFractionDigits || 0}
      maximumFractionDigits={maximumFractionDigits || 0}
    />
  );
}

export default FormattedCurrency;
