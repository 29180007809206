import type { Validity } from 'types/common/validationTypes';
import type { BookingRefunds } from 'routes/tourOperator/bookings/types/bookingRefund/bookingRefundTypes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import FetchingStatus from 'constants/statuses/FetchingStatus';

export type BookingRefundDetailsState = {
  fetching: FetchingStatus | null;
  validity: Validity | null;
  value: BookingRefunds[] | null;
  updating: FetchingStatus | null;
  updatingKey: string | number | null;
};

const initialState: BookingRefundDetailsState = {
  fetching: null,
  validity: null,
  value: null,
  updating: null,
  updatingKey: null,
};

function setFetchingFunction(
  state: BookingRefundDetailsState,
  action: PayloadAction<BookingRefundDetailsState['fetching']>
) {
  return {
    ...state,
    fetching: action.payload,
  };
}

function setValidityFunction(
  state: BookingRefundDetailsState,
  action: PayloadAction<BookingRefundDetailsState['validity']>
) {
  return {
    ...state,
    validity: action.payload,
  };
}

function setUpdatingFunction(
  state: BookingRefundDetailsState,
  action: PayloadAction<BookingRefundDetailsState['updating']>
) {
  return {
    ...state,
    updating: action.payload,
  };
}

function setUpdatingKeyFunction(
  state: BookingRefundDetailsState,
  action: PayloadAction<BookingRefundDetailsState['updatingKey']>
) {
  return {
    ...state,
    updatingKey: action.payload,
  };
}

function setBookingRefundDetailsFunction(
  state: BookingRefundDetailsState,
  action: PayloadAction<BookingRefundDetailsState['value']>
) {
  return {
    ...state,
    value: action.payload,
  };
}

function resetStateFunction() {
  return initialState;
}

const slice = createSlice({
  name: 'bookingRefundDetails',
  initialState,
  reducers: {
    setFetching: setFetchingFunction,
    setValidity: setValidityFunction,
    setBookingRefundDetails: setBookingRefundDetailsFunction,
    setUpdating: setUpdatingFunction,
    setUpdatingKey: setUpdatingKeyFunction,
    resetState: resetStateFunction,
  },
});

export const {
  setFetching,
  setValidity,
  setBookingRefundDetails,
  setUpdating,
  setUpdatingKey,
  resetState,
} = slice.actions;
export default slice.reducer;
