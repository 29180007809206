import type { FormFieldProps } from 'comp/screenBuilder/comp/formBuilder/types/formPropsTypes';
import React from 'react';
import styled from 'styled-components/macro';
import { Form } from 'antd';
import { FormattedMessage } from 'react-intl';
import updateWithValues from 'utils/string/updateWithValues';
import type { AffixesFieldProps } from '../affixProvider/types';

type DefaultPreviewProps = FormFieldProps & Partial<AffixesFieldProps>;

function DefaultPreview({ field, formFieldName, removeFormItemLabel, item1, item2 }: DefaultPreviewProps): JSX.Element {
  const { formItem, formInput } = field;
  const { label, ...formItemProps } = formItem;
  const format = formInput.context?.format ?? '{item1} {value} {item2}';

  return (
    <Root>
      <Form.Item
        required={false}
        label={!removeFormItemLabel && label && <FormattedMessage {...label} />}
        {...formItemProps}
        name={formFieldName}
      >
        <Value item1={item1} item2={item2} format={format} />
      </Form.Item>
    </Root>
  );
}

export default DefaultPreview;

const Root = styled.div`
  .ant-form-item-no-colon {
    user-select: none;
    color: ${({ theme }) => theme.palette.text.label};
  }

  .ant-form-item-control-input {
    min-height: initial;
  }
`;

type ValueProps = {
  value?: string;
  item1?: string | number;
  item2?: string | number;
  format: string;
};

function Value({ value, item1, item2, format }: ValueProps): JSX.Element | null {
  if (value === undefined) {
    return null;
  }

  if (item1 || item2) {
    const formattedValue = updateWithValues(format, { item1, item2, value });
    return <div>{formattedValue}</div>;
  }

  return <div>{value}</div>;
}
