import type { FormInstance } from 'antd/lib/form/hooks/useForm';
import type {
  CustomEventDefinition,
  ActionOrEventTypeHandlers,
  FormConnections,
} from 'comp/screenBuilder/types/eventsTypes';
import type { TitleOrLabel, FormBuilderProps } from 'comp/screenBuilder/types/screenConfigTypes';
import type { FormConfig, FormAction, FormTableDataItem } from 'comp/screenBuilder/comp/formBuilder';
import React, { useState, useEffect } from 'react';
import nestedProperty from 'nested-property';
import getTableDataItem from './utils/getTableDataItem';
import FormTable from './FormTable';

type FormTableDataProviderProps<T> = {
  form: FormInstance;
  formConfig: FormConfig;
  formLocalValues: React.MutableRefObject<Record<string, unknown>>;
  formListName: (string | number)[];
  screenFormConfigKey: string;
  uniqueIdProp: (string | number)[];
  formBuilderProps?: FormBuilderProps;
  handlers: ActionOrEventTypeHandlers;
  formConnections?: FormConnections;
  title?: TitleOrLabel;
  actions?: FormAction[];
  initialActiveActionType?: string;
  updateFormLocalValues: () => void;
};

function FormTableDataProvider<T>({
  form,
  formConfig,
  formLocalValues,
  formListName,
  screenFormConfigKey,
  uniqueIdProp,
  formBuilderProps,
  handlers,
  formConnections,
  title,
  actions,
  initialActiveActionType,
  updateFormLocalValues,
}: FormTableDataProviderProps<T>): JSX.Element {
  // tableData is created from the form values, but the values displayed in the table cells come directly from the form
  // because in each cell we render FormField which is binded to the form.
  // The tableData is required only to make it easier to perfrom the various operations of data manipulation
  // and to display the correct number of table rows (the number of table rows comes from the tableData directly).

  // TODO: formDataAsArray and initialTableData will be calculated on every render
  // but they will be used only on the first render. How can we avoid this?
  const formDataAsArray = (nestedProperty.get(form.getFieldsValue(true), formListName.join('.')) as T[]) || [];
  const initialTableData: FormTableDataItem<T>[] = formDataAsArray.map(getTableDataItem(uniqueIdProp));
  const [tableData, setTableData] = useState(initialTableData);
  const [filteredTableData, setFilteredTableData] = useState(tableData);
  const [filteredIndexes, setFilteredIndexes] = useState<number[]>([]);
  const [customEventDefinitions, setCustomEventDefinitions] = useState<CustomEventDefinition[]>([]);
  const shouldUpdate = initialTableData.map((item) => item.key).join('') !== tableData.map((item) => item.key).join('');

  // Set the custom event definitions so we can apply event listeners and execute them in a separate side effect.
  useEffect(() => {
    if (handlers && handlers.tableDataProviderEvents && handlers.tableDataProviderEvents.customEventDefinitions) {
      setCustomEventDefinitions(handlers.tableDataProviderEvents.customEventDefinitions());
    }
  }, [handlers]);

  // Side effect for executing custom event definitions.
  useEffect(() => {
    function executeCustomEvent(event: CustomEvent<unknown>) {
      const { type, detail } = event;
      const customEventDefinition = customEventDefinitions.find(({ on }) => on === type);
      if (
        customEventDefinition &&
        handlers &&
        handlers.tableDataProviderEvents &&
        handlers.tableDataProviderEvents[customEventDefinition.attach]
      ) {
        handlers.tableDataProviderEvents[customEventDefinition.attach](detail, tableData, setFilteredTableData);
      }
    }

    customEventDefinitions.forEach(({ on }) => document.addEventListener(on, executeCustomEvent as EventListener));

    return () => {
      customEventDefinitions.forEach(({ on }) => document.removeEventListener(on, executeCustomEvent as EventListener));
    };
  }, [customEventDefinitions, handlers, tableData]);

  // The indexes are required to bind correctly the FormItem with the FormList field.
  useEffect(() => {
    setFilteredIndexes(filteredTableData.map(({ index }) => index));
  }, [filteredTableData]);

  useEffect(() => {
    if (shouldUpdate) {
      setTableData(initialTableData);
      setFilteredTableData(initialTableData);
    }
  }, [initialTableData, shouldUpdate]);

  // Side effect for updating the tableData as a result from a FormList operation (add, remove).
  // Events are dispatched from ListOperationsEvents component.
  useEffect(() => {
    function onUpdateTableData(event: CustomEvent<unknown>) {
      const { detail } = event;
      const nextFormDataAsArray = (nestedProperty.get(form.getFieldsValue(true), formListName.join('.')) as T[]) || [];
      const nextTableData: FormTableDataItem<T>[] = nextFormDataAsArray.map(getTableDataItem(uniqueIdProp));

      setTableData(nextTableData);

      if (detail === null) {
        setFilteredTableData([]);
      } else if (typeof detail === 'number') {
        // Case where we need to remove item from the table.
        setFilteredTableData(
          filteredTableData
            .filter((tableDataItem) => tableDataItem.index !== detail)
            .map(({ key, item, index }) => ({
              key,
              item,
              index: index < detail ? index : index - 1,
            }))
        );
      } else {
        // Case where we need to add an item to the table.
        setFilteredTableData(
          filteredTableData.concat([getTableDataItem(uniqueIdProp)(detail as T, nextTableData.length - 1)])
        );
      }
    }

    document.addEventListener(`update-table-data-${screenFormConfigKey}`, onUpdateTableData as EventListener);

    return () => {
      document.removeEventListener(`update-table-data-${screenFormConfigKey}`, onUpdateTableData as EventListener);
    };
  }, [form, formListName, screenFormConfigKey, uniqueIdProp, filteredTableData]);

  function addTableRow(detail: T) {
    const nextFormDataAsArray = nestedProperty.get(form.getFieldsValue(true), formListName.join('.')) as T[];
    const nextTableData: FormTableDataItem<T>[] = nextFormDataAsArray.map(getTableDataItem(uniqueIdProp));
    setTableData(nextTableData);
    setFilteredTableData(filteredTableData.concat([getTableDataItem(uniqueIdProp)(detail, nextTableData.length - 1)]));
  }

  function removeLastTableRow() {
    const nextFormDataAsArray = nestedProperty.get(form.getFieldsValue(true), formListName.join('.')) as T[];
    const nextTableData: FormTableDataItem<T>[] = nextFormDataAsArray.map(getTableDataItem(uniqueIdProp));
    setTableData(nextTableData);
    filteredTableData.pop();
    setFilteredTableData([...filteredTableData]);
  }

  return (
    <FormTable
      form={form}
      formConfig={formConfig}
      formLocalValues={formLocalValues}
      formListName={formListName}
      filteredTableData={filteredTableData}
      filteredIndexes={filteredIndexes}
      screenFormConfigKey={screenFormConfigKey}
      uniqueIdProp={uniqueIdProp}
      formBuilderProps={formBuilderProps}
      handlers={handlers}
      formConnections={formConnections}
      title={title}
      actions={actions?.filter((action) => action.target === undefined)}
      itemActions={actions?.filter((action) => action.target === 'item')}
      initialActiveActionType={initialActiveActionType}
      updateFormLocalValues={updateFormLocalValues}
      addTableRow={addTableRow}
      removeLastTableRow={removeLastTableRow}
    />
  );
}

export default FormTableDataProvider;
