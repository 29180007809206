import type { AlertProps as AntdAlertProps } from 'antd/lib/alert';
import type { Props as FormattedMessageProps } from 'react-intl/lib/src/components/message';
import type { Override } from 'utils/types/Override';
import React from 'react';
import { useIntl } from 'react-intl';
import { Alert as AntdAlert } from 'antd';
import Severity from 'constants/Severity';

type AlertProps = Override<
  AntdAlertProps,
  {
    severity: Severity;
    message: FormattedMessageProps | string;
    description?: FormattedMessageProps | string;
  }
>;

function Alert({ severity, message, description, ...props }: AlertProps): JSX.Element {
  const intl = useIntl();
  const messageAsString = typeof message === 'object' ? intl.formatMessage(message) : message;
  const descriptionAsString = typeof description === 'object' ? intl.formatMessage(description) : description;

  return <AntdAlert type={severity} message={messageAsString} description={descriptionAsString} {...props} />;
}

export default Alert;
