import type { RootState } from 'state';
import FetchingStatus from 'constants/statuses/FetchingStatus';

function getStaticRouteUpdating(state: RootState): boolean {
  const { updating } = state.staticRoutes;

  return updating === FetchingStatus.InProgress;
}

export default getStaticRouteUpdating;
