import React from 'react';
import Severity from 'constants/Severity';
import Alert from 'comp/common/Alert';

const message = { id: 'tableErrorMessage' };
const description = { id: 'tableErrorDescription' };

function Error(): JSX.Element {
  return (
    <div>
      <Alert severity={Severity.ERROR} message={message} description={description} />
    </div>
  );
}

export default Error;
