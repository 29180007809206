import type { Validity } from 'types/common/validationTypes';
import type { BookingPaymentDetails } from 'routes/tourOperator/bookings/types/bookingDetails/bookingPaymentDetailsTypes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import FetchingStatus from 'constants/statuses/FetchingStatus';

export type BookingPaymentDetailsState = {
  fetching: FetchingStatus | null;
  validity: Validity | null;
  value: BookingPaymentDetails[] | null;
};

const initialState: BookingPaymentDetailsState = {
  fetching: null,
  validity: null,
  value: null,
};

function setFetchingFunction(
  state: BookingPaymentDetailsState,
  action: PayloadAction<BookingPaymentDetailsState['fetching']>
) {
  return {
    ...state,
    fetching: action.payload,
  };
}

function setValidityFunction(
  state: BookingPaymentDetailsState,
  action: PayloadAction<BookingPaymentDetailsState['validity']>
) {
  return {
    ...state,
    validity: action.payload,
  };
}

function setBookingPaymentDetailsFunction(
  state: BookingPaymentDetailsState,
  action: PayloadAction<BookingPaymentDetailsState['value']>
) {
  return {
    ...state,
    value: action.payload,
  };
}

function resetStateFunction() {
  return initialState;
}

const slice = createSlice({
  name: 'bookingPaymentDetails',
  initialState,
  reducers: {
    setFetching: setFetchingFunction,
    setValidity: setValidityFunction,
    setBookingPaymentDetails: setBookingPaymentDetailsFunction,
    resetState: resetStateFunction,
  },
});

export const { setFetching, setValidity, setBookingPaymentDetails, resetState } = slice.actions;
export default slice.reducer;
