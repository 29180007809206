import type { RootState } from 'state';
import { OptionsList } from '../../types/OptionsList';

function getFlightOrigins(state: RootState): OptionsList[] {
  const { origins } = state.originsDestinations;

  const airportGroupings = origins?.airportGroupings;
  const airports = origins?.airports.map((item) => ({ code: item.code })) as OptionsList[];

  if (!airportGroupings) return [];

  const originCodes = airportGroupings.map((x) => ({
    code: x.code,
    ungrouped: x.ungrouped,
    airports: x.airports.map((item) => item.code),
  })) as OptionsList[];

  return originCodes.concat(airports).sort((a, b) => (a.code < b.code ? -1 : 1));
}

export default getFlightOrigins;
