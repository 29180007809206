import type { FormInstance } from 'antd/lib/form/hooks/useForm';
import type { ActionOrEventTypeHandlers } from 'comp/screenBuilder/types/eventsTypes';
import type { TitleOrLabel } from 'comp/screenBuilder/types/screenConfigTypes';
import type { FormConfig, FormAction } from 'comp/screenBuilder/comp/formBuilder';
import React, { useState, useContext } from 'react';
import styled from 'styled-components/macro';
import { FormattedMessage } from 'react-intl';
import { InnerContext } from 'comp/screenBuilder/context';
import { defaultAction, actionTypes } from 'comp/screenBuilder/comp/formBuilder';
import { useDispatch } from 'react-redux';
import type { BookingHotel } from 'routes/tourOperator/bookings/types/bookingDetails/bookingHotelTypes';
import { setTransactionDataState } from 'routes/tourOperator/bookings/reducers/newTransactionFormData/slice';
import ActionButtons from '../../comp/actionButtons';
import Actions from '../../comp/actions';
import FormSection from '../../comp/formSection';
import getInitialActiveAction from '../../utils/getInitialActiveAction';
import getNameListForForm from '../../utils/getNameListForForm';

type FormSingleProps<T> = {
  form: FormInstance;
  formConfig: FormConfig;
  formLocalValues: React.MutableRefObject<Record<string, unknown>>;
  handlers: ActionOrEventTypeHandlers;
  title?: TitleOrLabel;
  actions?: FormAction[];
  initialActiveActionType?: string;
  updateFormLocalValues: () => void;
};

function FormSingle<T>({
  form,
  formConfig,
  formLocalValues,
  handlers,
  title,
  actions,
  initialActiveActionType,
  updateFormLocalValues,
}: FormSingleProps<T>): JSX.Element {
  const dispatch = useDispatch();
  const { updateInnerRefProperty, updateFragmentProperty } = useContext(InnerContext);
  const [activeAction, setActiveAction] = useState<FormAction>(
    getInitialActiveAction(actions, initialActiveActionType)
  );

  const dispatchSetFormData = (data: BookingHotel) => {
    const { dateFrom, dateTo } = data;
    dispatch(setTransactionDataState({ dateFrom, dateTo }));
  };

  function onClickAction(event: React.MouseEvent<HTMLElement, MouseEvent>) {
    if (!actions) {
      return;
    }

    const { actionType } = event.currentTarget.dataset;

    if (actionType) {
      const action = actions.find(({ type }) => type === actionType);

      if (action) {
        // Execute handlers defined in the configuration file.
        if (handlers && handlers[actionType]) {
          const { self } = handlers[actionType];
          if (self !== undefined) {
            self(form.getFieldsValue(true));
          }
        }

        // Update the current active action.
        setActiveAction(action);
        updateFragmentProperty(formConfig.key, 'activeActionType', action.type);
      }
    }
  }

  async function onClickActionButton(event: React.MouseEvent<HTMLElement, MouseEvent>) {
    const { actionType, buttonType } = event.currentTarget.dataset;

    if (actionType && buttonType) {
      // Execute handlers defined in the configuration file.
      if (handlers && handlers[actionType] && handlers[actionType][buttonType]) {
        handlers[actionType][buttonType](form.getFieldsValue(true));
      }

      if (actionType === actionTypes.UPDATE) {
        if (buttonType === 'dismiss') {
          // On DISMISS changes from UPDATE action.
          // Reset the fields values to what we have in local state.
          form.setFieldsValue(formLocalValues.current);
        }

        if (buttonType === 'confirm') {
          // On CONFIRM changes from UPDATE action.
          // If fields validation fails stop the execution of this function.
          // We don't want to set the default action as active action.
          try {
            const nameList = getNameListForForm(formConfig);
            await form.validateFields(nameList);
            updateFormLocalValues();
            updateInnerRefProperty('formsTouched', true);
          } catch (error) {
            return;
          }
          if (handlers && handlers.hotelInfoFormEvents) {
            dispatchSetFormData(form.getFieldsValue(true));
          }
        }
      }
    }

    setActiveAction(defaultAction);
    updateFragmentProperty(formConfig.key, 'activeActionType', defaultAction.type);
  }

  return (
    <Root>
      <Header>
        {title && (
          <Title>
            <FormattedMessage id={`formBuilder.${title.id}.${activeAction.type}`} />
          </Title>
        )}
        <Actions actions={actions} activeAction={activeAction} onClickAction={onClickAction} />
        <ActionButtons action={activeAction} onClickActionButton={onClickActionButton} />
      </Header>
      <div>
        {formConfig.sections.map((section) => (
          <FormSection key={section.key} section={section} activeAction={activeAction} />
        ))}
      </div>
    </Root>
  );
}

export default FormSingle;

const Root = styled.div`
  > *:not(:last-child) {
    margin-bottom: 16px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 8px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.border.darker[0]};
`;

const Title = styled.h2`
  width: 100%;
  margin-bottom: 0;
`;
