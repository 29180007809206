import type { FormFieldProps } from 'comp/screenBuilder/comp/formBuilder/types/formPropsTypes';
import React from 'react';
import styled from 'styled-components/macro';
import { Form, Input } from 'antd';
import { FormattedMessage } from 'react-intl';

function Textarea({ field, formFieldName, removeFormItemLabel }: FormFieldProps): JSX.Element {
  const { formItem, formInput } = field;
  const { label, ...formItemProps } = formItem;

  return (
    <Form.Item
      label={!removeFormItemLabel && label && <FormattedMessage {...label} />}
      {...formItemProps}
      name={formFieldName}
    >
      <InputTextarea {...formInput} />
    </Form.Item>
  );
}

export default Textarea;

const InputTextarea = styled(Input.TextArea)`
  width: 100%;
  min-width: 100px;
`;
