import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import type { IconName } from '@fortawesome/fontawesome-svg-core';
import type { Override } from 'utils/types/Override';

type FAIconProps = Override<FontAwesomeIconProps, { icon: IconName }>;

function FAIcon({ icon, ...props }: FAIconProps): JSX.Element {
  return <FontAwesomeIcon icon={['fal', icon]} {...props} />;
}

export default FAIcon;
