import type { ColumnsType } from 'antd/lib/table';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { StaticRouteModel } from 'common/dataService/types/staticRouteTypes';
import renderEditCell from './tableCells/renderEditCell';
import renderDeleteCell from './tableCells/renderDeteleCell';

const columns: ColumnsType<StaticRouteModel> = [
  {
    key: 'origin',
    title: <FormattedMessage id='Origin' />,
    dataIndex: 'origin',
    sorter: true,
  },
  {
    key: 'destination',
    title: <FormattedMessage id='Destination' />,
    dataIndex: 'destination',
    sorter: true,
  },
  {
    key: 'createdOn',
    title: <FormattedMessage id='Created on' />,
    dataIndex: 'createdOn',
    sorter: true,
  },
  {
    key: 'providers',
    title: <FormattedMessage id='Providers' />,
    dataIndex: 'providers',
    sorter: false,
    render: (providers) => providers.join(', ')
  },
  {
    key: 'comment',
    title: <FormattedMessage id='Comment' />,
    dataIndex: 'comment',
    sorter: false,
  },
  {
    key: 'enable',
    title: <FormattedMessage id='Enabled' />,
    dataIndex: 'enable',
    sorter: false,
    render: (e, record) => <input type='checkbox' checked={record.enable} readOnly />
  },
  {
    key: 'editActions',
    align: 'right' as const,
    fixed: 'right' as const,
    render: renderEditCell,
  },
  {
    key: 'deleteActions',
    align: 'right' as const,
    fixed: 'right' as const,
    render: renderDeleteCell,
  },
];

export default columns;
