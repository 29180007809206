import type { AppThunk } from 'state';
import FetchingStatus from 'constants/statuses/FetchingStatus';
import fetchCarriers from './fetchCarriers';
import getCarriers from '../selectors/getCarriers';
import { setFetching } from '../slice';

function loadCarriers(): AppThunk<string[]> {
  return async function loadCarriersThunk(dispatch, getState) {
    try {
      let carriers = getCarriers(getState());

      if (carriers !== null) {
        return carriers;
      }

      await dispatch(fetchCarriers());

      carriers = getCarriers(getState());

      if (carriers !== null) {
        return carriers;
      }
    } catch (error) {
      dispatch(setFetching(FetchingStatus.Failed));
      window.Rollbar.error(error);
    }

    return [];
  };
}

export default loadCarriers;
