import type { AppDispatch, RootState } from 'state';
import { fetchFlightRoutes } from 'routes/tourOperator/flightCache/flightRoutes/comp/flightRoutesTable';
import { transformStateToRequest } from '../reducers/values';

async function executeFetchFlightRoutes(dispatch: AppDispatch, getState: () => RootState): Promise<void> {
  const flightRoutesRequestData = transformStateToRequest(getState());
  await dispatch(fetchFlightRoutes(flightRoutesRequestData));
}

export default executeFetchFlightRoutes;
