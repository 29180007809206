import type { ResponseError } from '../types/responseErrorTypes';

function getErrorDescription(error?: ResponseError): string | undefined {
  if (error?.response?.data) {
    if (error?.response?.data.errors?.messages) {
      return error?.response?.data.errors?.messages.join('n/');
    }

    if (error?.response?.data.title) {
      return error?.response?.data.title;
    }

    if (error?.response?.data.message) {
      return error?.response?.data.message;
    }

    if (error?.response?.data.error) {
      return error?.response?.data.error;
    }
  }

  return undefined;
}

export default getErrorDescription;
