import type { GetFlightRoutesResponse } from 'common/packageCache';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import FetchingStatus from 'constants/statuses/FetchingStatus';

export type RoutesState = {
  fetching: FetchingStatus | null;
  value: GetFlightRoutesResponse | null;
  updating: FetchingStatus | null;
  updatingKey: string | number | null;
};

const initialState: RoutesState = {
  fetching: null,
  value: null,
  updating: null,
  updatingKey: null,
};

function setFetchingFunction(state: RoutesState, action: PayloadAction<RoutesState['fetching']>) {
  return {
    ...state,
    fetching: action.payload,
  };
}

function setFlightRoutesResponseFunction(state: RoutesState, action: PayloadAction<RoutesState['value']>) {
  return {
    ...state,
    value: action.payload,
  };
}

function setUpdatingFunction(state: RoutesState, action: PayloadAction<RoutesState['updating']>) {
  return {
    ...state,
    updating: action.payload,
  };
}

function setUpdatingKeyFunction(state: RoutesState, action: PayloadAction<RoutesState['updatingKey']>) {
  return {
    ...state,
    updatingKey: action.payload,
  };
}

function resetStateFunction() {
  return initialState;
}

const slice = createSlice({
  name: 'flightRoutes.routes',
  initialState,
  reducers: {
    setFetching: setFetchingFunction,
    setFlightRoutesResponse: setFlightRoutesResponseFunction,
    setUpdating: setUpdatingFunction,
    setUpdatingKey: setUpdatingKeyFunction,
    resetState: resetStateFunction,
  },
});

export const { setFetching, setFlightRoutesResponse, setUpdating, setUpdatingKey, resetState } = slice.actions;
export default slice.reducer;
