import type { AppThunk } from 'state';
import type { ResponseError } from 'httpClient';
import Severity from 'constants/Severity';
import FetchingStatus from 'constants/statuses/FetchingStatus';
import authService from 'httpClient/services/auth/index';
import { setFetching, setAuthenticated, setCurrentUser, setValidity } from '../slice';

function authenticateUserWithJWT(): AppThunk {
  return async function authenticateUserWithJWTThunk(dispatch, getState) {
    // The JWT token is attached using the request interceptor.
    try {
      const { fetching } = getState().auth;

      if (fetching === FetchingStatus.InProgress) {
        return;
      }

      dispatch(setFetching(FetchingStatus.InProgress));

      const user = await authService.loginWithJWT();

      if (!user) {
        dispatch(setFetching(FetchingStatus.Failed));
        return;
      }

      dispatch(setCurrentUser(user));
      dispatch(setValidity({ valid: true, severity: Severity.INFO }));
      dispatch(setFetching(FetchingStatus.Succeeded));
      dispatch(setAuthenticated(true));
    } catch (error) {
      const err = error as ResponseError;
      dispatch(setFetching(FetchingStatus.Failed));
      dispatch(setValidity({ valid: false, severity: Severity.ERROR, message: err.response?.data?.error }));
      window.Rollbar.error(error);
    }
  };
}

export default authenticateUserWithJWT;
