module.exports = {
  primary: {
    lighter: ['#cddeff', '#92b6ff'],
    default: '#4482ff',
    darker: ['#1d68ff', '#095bff'],
  },
  secondary: {
    lighter: ['#4c5776', '#5b698e'],
    default: '#2d3446',
    darker: ['#252b3a', '#1e222e'],
  },
  background: {
    white: '#ffffff',
    black: '#000000',
  },
  border: {
    lighter: ['#ebebeb'],
    default: '#e9e9e9',
    darker: ['#d8d8d8'],
  },
  text: {
    heading: '#323332',
    paragraph: '#979797',
    label: '#b1b1b1',
    white: '#ffffff',
    black: '#000000',
  },
  success: {
    default: '#00b16a',
  },
  info: {
    default: '#449af6',
  },
  warning: {
    default: '#ffbf00',
  },
  error: {
    default: '#f64744',
  },
  passive: {
    default: '#7b7b7b',
  },
};
