import type { FormFieldProps } from 'comp/screenBuilder/comp/formBuilder/types/formPropsTypes';
import React from 'react';
import styled from 'styled-components/macro';
import { Form } from 'antd';
import { FormattedMessage } from 'react-intl';

type HtmlPreviewProps = FormFieldProps;

function HtmlPreview({ field, formFieldName, removeFormItemLabel }: HtmlPreviewProps): JSX.Element {
  const { formItem } = field;
  const { label, ...formItemProps } = formItem;

  return (
    <Root>
      <Form.Item
        required={false}
        label={!removeFormItemLabel && label && <FormattedMessage {...label} />}
        {...formItemProps}
        name={formFieldName}
      >
        <Value />
      </Form.Item>
    </Root>
  );
}

export default HtmlPreview;

const Root = styled.div`
  .ant-form-item-no-colon {
    user-select: none;
    color: ${({ theme }) => theme.palette.text.label};
  }

  .ant-form-item-control-input {
    min-height: initial;
  }
`;

type ValueProps = {
  value?: string;
};

function Value({ value }: ValueProps): JSX.Element | null {
  if (value === undefined) {
    return null;
  }
  return <div dangerouslySetInnerHTML={{ __html: value }} />;
}
