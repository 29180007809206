import type { FormInstance } from 'antd/lib/form/hooks/useForm';
import type { ScreenFormConfig } from 'comp/screenBuilder/types/screenConfigTypes';
import type { Enum } from 'comp/screenBuilder/comp/formBuilder/types/formConfigTypes';
import type {
  CustomEventDefinition,
  ActionOrEventTypeHandlers,
  FormConnections,
} from 'comp/screenBuilder/types/eventsTypes';
import React, { useState, useEffect, useContext } from 'react';
import checkFormFieldsPermissions from './utils/checkFormFieldsPermissions';
import getUpdatedPathToValues from './utils/getUpdatedPathToValues';
import updateFormFieldsPathToValue from './utils/updateFormFieldsPathToValue';
import updateFormFieldsWithOptionsFromEnum from './utils/updateFormFieldsWithOptionsFromEnum';
import updateFormFieldsValidators from './utils/updateFormFieldsValidators';
import updateFormFieldsVisibility from './utils/updateFormFieldsVisibility';
import FormList, { useListDropdown } from './builders/formList';
import FormSingle from './builders/formSingle';
import FormTableDataProvider from './builders/formTable';
import type { FormConfig, FormFieldValidatorMap } from '.';
import { FormFieldContext } from './comp/formSection/comp/formField';

type FormBuilderProviderProps<T> = {
  screenType?: string;
  screenFormConfig: ScreenFormConfig;
  form: FormInstance;
  formConfig: FormConfig;
  formLocalValues: React.MutableRefObject<Record<string, unknown>>;
  timestamp?: number;
  placeholdersValues: number[];
  handlers: ActionOrEventTypeHandlers;
  validators?: FormFieldValidatorMap;
  enums?: Enum[];
  formConnections?: FormConnections;
  updateFormLocalValues: () => void;
};

function FormBuilderProvider<T>({
  screenType,
  screenFormConfig,
  form,
  formConfig,
  formLocalValues,
  timestamp,
  placeholdersValues,
  handlers,
  validators,
  enums,
  formConnections,
  updateFormLocalValues,
}: FormBuilderProviderProps<T>): JSX.Element | null {
  const formFieldContext = useContext(FormFieldContext);
  const { formBuilderType, pathToValues, uniqueIdProp, title, actions, activeActionType } = screenFormConfig;
  const { builderProviderEvents, listDropdownHookEvents, ...restHandlers } = handlers;
  const [customEventDefinitions, setCustomEventDefinitions] = useState<CustomEventDefinition[]>([]);
  const [updatedPathToValues, setUpdatedPathToValues] = useState(
    getUpdatedPathToValues(pathToValues, placeholdersValues)
  );
  const [listDropdown] = useListDropdown(
    form,
    formConfig.key,
    form.getFieldsValue(true),
    updatedPathToValues,
    timestamp,
    uniqueIdProp,
    handlers.listDropdownHookEvents
  );
  const listDropdownIsRequired = listDropdown.isListDropdownRequired();
  const selectedOption = listDropdown.getSelectedOption();
  let updatedFormConfig = updateFormFieldsPathToValue(
    formConfig,
    updatedPathToValues,
    listDropdownIsRequired || formBuilderType === 'table'
  );
  updatedFormConfig = updateFormFieldsWithOptionsFromEnum(updatedFormConfig, enums);
  updatedFormConfig = updateFormFieldsVisibility(updatedFormConfig, screenType);
  updatedFormConfig = updateFormFieldsValidators(updatedFormConfig, validators);
  updatedFormConfig = checkFormFieldsPermissions(updatedFormConfig, formFieldContext);

  useEffect(() => {
    function onFormConnection(event: CustomEvent<number>) {
      const { detail } = event;
      const placeHolderValue = [detail];
      const newUpdatedPathToValues = getUpdatedPathToValues(pathToValues, placeHolderValue);
      setUpdatedPathToValues(newUpdatedPathToValues);
    }
    if (formConnections && formConnections.listen) {
      formConnections.listen.forEach((on) => document.addEventListener(on, onFormConnection as EventListener));
    }

    return () => {
      if (formConnections && formConnections.listen) {
        formConnections.listen.forEach((on) => document.removeEventListener(on, onFormConnection as EventListener));
      }
    };
  }, [formConnections, pathToValues]);

  // Set the custom event definitions so we can apply event listeners and execute them in a separate side effect.
  useEffect(() => {
    if (handlers && handlers.builderProviderEvents && handlers.builderProviderEvents.customEventDefinitions) {
      const definitions = handlers.builderProviderEvents.customEventDefinitions();
      setCustomEventDefinitions(definitions);
    }
  }, [handlers]);

  // Side effect for executing custom event definitions.
  useEffect(() => {
    function executeCustomEvent(event: CustomEvent<unknown>) {
      const { type, detail } = event;
      const customEventDefinition = customEventDefinitions.find(({ on }) => on === type);

      if (customEventDefinition && builderProviderEvents && builderProviderEvents[customEventDefinition.attach]) {
        builderProviderEvents[customEventDefinition.attach](form, selectedOption, detail);
      }
    }

    customEventDefinitions.forEach(({ on }) => document.addEventListener(on, executeCustomEvent as EventListener));

    return () => {
      customEventDefinitions.forEach(({ on }) => document.removeEventListener(on, executeCustomEvent as EventListener));
    };
  }, [customEventDefinitions, builderProviderEvents, form, selectedOption]);

  if (formBuilderType === 'table') {
    return (
      <FormTableDataProvider
        form={form}
        formConfig={updatedFormConfig}
        formLocalValues={formLocalValues}
        formListName={updatedPathToValues}
        screenFormConfigKey={screenFormConfig.key}
        uniqueIdProp={screenFormConfig.uniqueIdProp || []}
        formBuilderProps={screenFormConfig.formBuilderProps}
        handlers={restHandlers}
        formConnections={formConnections}
        title={title}
        actions={actions}
        initialActiveActionType={activeActionType}
        updateFormLocalValues={updateFormLocalValues}
      />
    );
  }

  if (listDropdownIsRequired) {
    return (
      <FormList
        form={form}
        formConfig={updatedFormConfig}
        formLocalValues={formLocalValues}
        formListName={updatedPathToValues}
        listDropdown={listDropdown}
        handlers={restHandlers}
        formConnections={formConnections}
        title={title}
        actions={actions}
        initialActiveActionType={activeActionType}
        updateFormLocalValues={updateFormLocalValues}
      />
    );
  }

  return (
    <FormSingle
      form={form}
      formConfig={updatedFormConfig}
      formLocalValues={formLocalValues}
      handlers={restHandlers}
      title={title}
      actions={actions}
      initialActiveActionType={activeActionType}
      updateFormLocalValues={updateFormLocalValues}
    />
  );
}

export default FormBuilderProvider;

// This code is not used currently, but i do see it usefull in the future and don't want to remove it.
// useEffect(() => {
//   function onFormConnection() {
//     // event: CustomEvent<number[]>
//     // const { detail } = event;
//     // Execute something on connected form update.
//   }

//   if (formConnections && formConnections.listen) {
//     formConnections.listen.forEach((on) => document.addEventListener(on, onFormConnection as EventListener));
//   }

//   return () => {
//     if (formConnections && formConnections.listen) {
//       formConnections.listen.forEach((on) => document.removeEventListener(on, onFormConnection as EventListener));
//     }
//   };
// }, [formConnections]);
