import type { RootState } from 'state';
import type { SelectOption } from 'types/common/SelectOption';

function getCarrier(state: RootState): SelectOption<string>[] | undefined {
  const { carrier } = state.flightRoutes.filtersOptions;

  if (carrier === null) {
    return undefined;
  }

  return carrier;
}

export default getCarrier;
