import type { TablePaginationConfig } from 'antd/lib/table';
import type { SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface';
import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import { useSelector, useDispatch } from 'react-redux';
import Table, { showTotal } from 'comp/wraps/table';
import columns from './tableConfig/columns';
import { Button, notification } from 'antd';
import { FlightDefinitionRulesModel } from 'common/dataService';
import { RootState } from 'state';
import FetchingStatus from 'constants/statuses/FetchingStatus';
import { setVisibleModule } from '../../reducers/DefinitionRules/slice';
import usePrevious from 'comp/hooks/usePrevious';
import { FormattedMessageWithI18n } from 'i18n';
import AddRule from '../addRule/comp/AddRule';
import {
  getFlightDefinitionRuleFetching,
  getFlightDefinitionRulesItems,
  getFlightDefinitionRulesTotalCount,
} from '../../reducers/DefinitionRules';
import executeFetchDefinitionRules from '../../manager/executeFetchDefinitionRules';
import updateSortingAndLoadResults from '../../manager/updateSortingAndLoadResults';
import updateTakeCountAndLoadResults from '../../manager/updateTakeCountAndLoadResults';
import updatePageAndLoadResults from '../../manager/updatePageAndLoadResults';
import { FormattedMessage } from 'react-intl';

function updateOrderForBackedn(order: string): string {
  if (order === 'ascend') {
    return 'asc';
  }
  return 'desc';
}

function FlightDefinitionRuleTable() {
  const { updating, updatingKey } = useSelector((state: RootState) => state.flightDefinitionRules.definitionRules);
  const prevUpdating = usePrevious(updating);
  const visibleModule = useSelector((state: RootState) => state.flightDefinitionRules.definitionRules.visibleModel);
  const dataSource = useSelector(getFlightDefinitionRulesItems);
  const pageSize = useSelector((state: RootState) => state.flightDefinitionRules.definitionRules.takeCount);
  const current = useSelector((state: RootState) => state.flightDefinitionRules.definitionRules.page);
  const total = useSelector(getFlightDefinitionRulesTotalCount);
  const loading = useSelector(getFlightDefinitionRuleFetching);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(executeFetchDefinitionRules);
  }, []);

  useEffect(() => {
    if (prevUpdating === FetchingStatus.InProgress && updating === FetchingStatus.Succeeded) {
      notification.info({
        message: <FormattedMessageWithI18n id={`flightDefinition.${updatingKey}.succeeded.title`} />,
      });
    }
  }, [updating, prevUpdating]);

  function handleOnChange(
    paggination: TablePaginationConfig,
    filters: Record<string, React.ReactText[] | null>,
    sorter: SorterResult<FlightDefinitionRulesModel> | SorterResult<FlightDefinitionRulesModel>[],
    extra: TableCurrentDataSource<FlightDefinitionRulesModel>
  ) {
    if (extra.action === 'sort') {
      const { columnKey, order } = sorter as SorterResult<FlightDefinitionRulesModel>;
      const sorting = order === undefined ? null : `${columnKey as string} ${updateOrderForBackedn(order as string)}`;
      dispatch(updateSortingAndLoadResults(sorting));
    }
  }

  function handleOnShowSizeChange(page: number, size: number) {
    dispatch(updateTakeCountAndLoadResults(size));
  }

  function handleOnChangePage(page: number) {
    dispatch(updatePageAndLoadResults(page));
  }

  return (
    <Root>
      <Button onClick={() => dispatch(setVisibleModule(!visibleModule))}>Add Rule</Button>
      <AddRule />
      <br />
      <br />
      <div>
        <FormattedMessage id='addRule.totalCountGeneratedPeriods' />
        {dataSource.map((item) => item.countGeneratedPeriods).reduce((a, b) => a + b, 0)}
      </div>
      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={{
          current,
          pageSize,
          total,
          showSizeChanger: true,
          showTotal,
          onChange: handleOnChangePage,
          onShowSizeChange: handleOnShowSizeChange,
        }}
        loading={loading}
        onChange={handleOnChange}
        configuration={{ tableName: 'flightDefinitionRuleTable' }}
      />
    </Root>
  );
}

export default FlightDefinitionRuleTable;

const Root = styled.div``;
