/* eslint-disable @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return */
type Item<T> = {
  [k: string]: T;
};

export default function splice<T>(
  array: Item<T>[] | any,
  item: Item<T> | any,
  fromIndex: number,
  toIndex: number
): Item<T> | any {
  const indexForMove = fromIndex > toIndex ? toIndex : toIndex + 1;
  const indexForRemove = fromIndex > toIndex ? fromIndex + 1 : fromIndex;
  const part = [...array?.slice(0, indexForMove), item, ...array?.slice(indexForMove)];

  return [...part?.slice(0, indexForRemove), ...part?.slice(indexForRemove + 1)];
}
