import type { RootState } from 'state';

function isFetchingInProgressForRoute(route: string) {
  return function isFetchingInProgressForRouteCallback(state: RootState): boolean {
    const { fetching } = state.dataService.airports.maxFlightDuration;
    return fetching.indexOf(route) !== -1;
  };
}

export default isFetchingInProgressForRoute;
