import type { FormFieldProps } from 'comp/screenBuilder/comp/formBuilder/types/formPropsTypes';
import React from 'react';
import { Form, Select as AntdSelect } from 'antd';
import { FormattedMessage } from 'react-intl';

function Select({ field, formFieldName, removeFormItemLabel }: FormFieldProps): JSX.Element {
  const { formItem, formInput, componentProps } = field;
  const { label, ...formItemProps } = formItem;

  return (
    <Form.Item
      label={!removeFormItemLabel && label && <FormattedMessage {...label} />}
      {...formItemProps}
      name={formFieldName}
    >
      <AntdSelect {...componentProps}>
        {formInput.options?.map(({ key, value }) => (
          <AntdSelect.Option key={key} value={key}>
            {value}
          </AntdSelect.Option>
        ))}
      </AntdSelect>
    </Form.Item>
  );
}

export default Select;
