import type { FlightRoute } from 'common/packageCache';
import React from 'react';
import FontAwesomeIcon from 'comp/wraps/faIcon/FAIcon';

function renderIsOperatingCell(text: string, record: FlightRoute): JSX.Element {
  if (record.isOperating) {
    return <FontAwesomeIcon icon='check' color='green' />;
  }

  return <FontAwesomeIcon icon='times' color='red' />;
}

export default renderIsOperatingCell;
