import type { GetAirportGroupingsResponse } from 'common/dataService/types/airportsTypes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import removeAtIndex from 'utils/immutability/removeAtIndex';

export type AirportGroupingsState = {
  fetching: string[];
  departureGroupings: GetAirportGroupingsResponse | null;
  destinationGroupings: GetAirportGroupingsResponse | null;
};

const initialState: AirportGroupingsState = {
  fetching: [],
  departureGroupings: null,
  destinationGroupings: null,
};

function pushToFetchingFunction(state: AirportGroupingsState, action: PayloadAction<string>) {
  return {
    ...state,
    fetching: [...state.fetching, action.payload],
  };
}

function removeFromFetchingFunction(state: AirportGroupingsState, action: PayloadAction<string>) {
  const index = state.fetching.indexOf(action.payload);

  if (index === -1) {
    return state;
  }

  return {
    ...state,
    fetching: removeAtIndex(state.fetching, index),
  };
}

function setDepartureGroupingsFunction(
  state: AirportGroupingsState,
  action: PayloadAction<AirportGroupingsState['departureGroupings']>
) {
  return {
    ...state,
    departureGroupings: action.payload,
  };
}

function setDestinationGroupingsFunction(
  state: AirportGroupingsState,
  action: PayloadAction<AirportGroupingsState['destinationGroupings']>
) {
  return {
    ...state,
    destinationGroupings: action.payload,
  };
}

function resetStateFunction() {
  return initialState;
}

const slice = createSlice({
  name: 'dataService.airports.airportGroupings',
  initialState,
  reducers: {
    pushToFetching: pushToFetchingFunction,
    removeFromFetching: removeFromFetchingFunction,
    setDepartureGroupings: setDepartureGroupingsFunction,
    setDestinationGroupings: setDestinationGroupingsFunction,
    resetState: resetStateFunction,
  },
});

export const {
  pushToFetching,
  removeFromFetching,
  setDepartureGroupings,
  setDestinationGroupings,
  resetState,
} = slice.actions;
export default slice.reducer;
