import React from 'react';
import styled from 'styled-components/macro';
import type { FilterItem } from '../filters';

type LargeDevicesProps = {
  filters: FilterItem[];
};

function LargeDevices({ filters }: LargeDevicesProps): JSX.Element | null {
  return (
    <Root>
      {filters.map(({ key, component: Component }) => (
        <Component key={key} />
      ))}
    </Root>
  );
}

export default LargeDevices;

const Root = styled.div`
  display: flex;
  flex-wrap: wrap;

  > *:not(:last-child) {
    margin-right: 16px;
  }
`;
