import React from 'react';
import styled from 'styled-components/macro';
import LayoutWrapper from 'routes/tourOperator/flightCache/comp/utility/layoutWrapper';
import LayoutContent from 'routes/tourOperator/flightCache/comp/utility/layoutContent';
import SwitchMenu from 'routes/tourOperator/flightCache/comp/flightCache/SwitchMenu';
import FlightRoutesFilters from './comp/flightRoutesFilters';
import FlightRoutesTable from './comp/flightRoutesTable';

function FlightRoutes() {
  return (
    <LayoutWrapper>
      <LayoutContent>
        <SwitchMenu />
        <Root>
          <FlightRoutesFilters />
          <FlightRoutesTable />
        </Root>
      </LayoutContent>
    </LayoutWrapper>
  );
}

export default FlightRoutes;

const Root = styled.div`
  display: flex;
  flex-direction: column;

  > *:not(:last-child) {
    margin-bottom: 16px;
  }
`;
