import _cloneDeep from 'lodash/cloneDeep';
import getAllFieldsWithIndexesFromForm from './getAllFieldsWithIndexesFromForm';
import type { FormConfig } from '../types/formConfigTypes';

function updateFormFieldsPathToValue(
  formConfig: FormConfig,
  pathToValues: (string | number)[],
  listDropdownIsRequired: boolean
): FormConfig {
  const updatedForm = _cloneDeep(formConfig);
  const indexedFormFields = getAllFieldsWithIndexesFromForm(updatedForm);
  const updatedFormFields = indexedFormFields.map((formField) => {
    const result = {
      ...formField,
      field: {
        ...formField.field,
        formItem: {
          ...formField.field.formItem,
          name: listDropdownIsRequired
            ? formField.field.formItem.name
            : pathToValues.concat(formField.field.formItem.name),
        },
      },
    };

    if (result.field.formInput.context?.currency) {
      result.field.formInput.context.currency = listDropdownIsRequired
        ? result.field.formInput.context.currency
        : pathToValues.concat(result.field.formInput.context.currency);
    }

    return result;
  });

  updatedFormFields.forEach((formField) => {
    if (!formField) {
      return;
    }

    const { sectionIndex, rowIndex, fieldIndex, field } = formField;
    updatedForm.sections[sectionIndex].rows[rowIndex].fields[fieldIndex] = { ...field };
  });

  return updatedForm;
}

export default updateFormFieldsPathToValue;
