import { createContext } from 'react';
import type { InnerRef } from '../inner';

export type OuterContextShape = {
  actionsDisabled: boolean;
  preventFormProviderUpdate: boolean;
  callbacks?: {
    onClickActionButton?: (innerRef?: InnerRef) => void;
  };
};

const initialContext: OuterContextShape = {
  actionsDisabled: false,
  preventFormProviderUpdate: false,
};

const OuterContext = createContext(initialContext);

export default OuterContext;
