import type { ColumnsType } from 'antd/lib/table';
import _difference from 'lodash/difference';
import localStorage from 'localStorage';
import getLocalStorageConfig from './getLocalStorageConfig';

function resolveDifferencesBetweenColumnsAndLocalStorageConfig<T>(tableName: string, columns: ColumnsType<T>): void {
  const tableColumnConfigs = getLocalStorageConfig(tableName);

  if (!tableColumnConfigs) {
    return;
  }

  const columnsKeys = columns
    .filter((item) => item.key !== 'actions')
    .map((item) => item.key)
    .filter<string>((item): item is string => item !== undefined);
  const tableColumnConfigsKeys = tableColumnConfigs.filter((item) => item.key !== 'actions').map((item) => item.key);
  const missingKeys = _difference(columnsKeys, tableColumnConfigsKeys);

  if (missingKeys && missingKeys.length > 0) {
    const nextConfig = [...tableColumnConfigs];
    const hasActions = nextConfig[nextConfig.length - 1].key === 'actions';
    const startingOrder = hasActions ? nextConfig.length - 1 : nextConfig.length;

    missingKeys.forEach((key, index) => {
      const order = startingOrder + index;
      nextConfig[order] = {
        key,
        order,
        visible: true,
      };
    });

    nextConfig[nextConfig.length] = { key: 'actions', order: nextConfig.length, visible: true };
    localStorage.add(tableName, nextConfig);
  }
}

export default resolveDifferencesBetweenColumnsAndLocalStorageConfig;
