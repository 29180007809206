import type { FormFieldProps } from 'comp/screenBuilder/comp/formBuilder/types/formPropsTypes';
import React from 'react';
import moment, { Moment } from 'moment';
import { Form, TimePicker as AntdTimePicker } from 'antd';
import { FormattedMessage } from 'react-intl';
import { DEFAULT_FORMAT } from './constants/defaults';

function Time({ field, formFieldName, removeFormItemLabel }: FormFieldProps): JSX.Element {
  const { formItem, formInput } = field;
  const { label, ...formItemProps } = formItem;

  function normalizeValue(value: Moment) {
    return value.format(DEFAULT_FORMAT);
  }

  return (
    <Form.Item
      label={!removeFormItemLabel && label && <FormattedMessage {...label} />}
      normalize={normalizeValue}
      {...formItemProps}
      name={formFieldName}
    >
      <TimePicker formInput={formInput} />
    </Form.Item>
  );
}

export default Time;

type TimePickerProps = {
  formInput: FormFieldProps['field']['formInput'];
  id?: string;
  value?: string;
  onChange?: () => void;
};

function TimePicker({ formInput, value, ...props }: TimePickerProps): JSX.Element {
  const momentValue = value ? moment(value, DEFAULT_FORMAT) : null;

  return <AntdTimePicker value={momentValue} allowClear={false} format={DEFAULT_FORMAT} {...formInput} {...props} />;
}
