import type { ScreenFormConfig } from 'comp/screenBuilder/types/screenConfigTypes';
import type { Enum } from 'comp/screenBuilder/comp/formBuilder/types/formConfigTypes';
import type { EventHandlers, FormsConnections } from 'comp/screenBuilder/types/eventsTypes';
import React, { useState, useEffect, useRef, useContext } from 'react';
import styled from 'styled-components/macro';
import { Form } from 'antd';
import { OuterContext } from 'comp/screenBuilder/context';
import validateMessages from './validation/validateMessages';
import FormBuilderProvider from './FormBuilderProvider';
import type { FormConfig, FormFieldsValidators } from '.';

type FormProviderProps<T> = {
  screenType?: string;
  screenFormsConfigs: ScreenFormConfig[];
  formName: string;
  formsConfigurations: Record<string, FormConfig>;
  initialValues: Record<string, unknown>;
  handlers: EventHandlers;
  validators?: FormFieldsValidators;
  enums: Record<string, Enum[]>;
  formsConnections?: FormsConnections;
  initialPlaceholdersValues: number[];
};

function FormProvider<T>({
  screenType,
  screenFormsConfigs,
  formName,
  formsConfigurations,
  initialValues,
  handlers,
  validators,
  enums,
  formsConnections,
  initialPlaceholdersValues,
}: FormProviderProps<T>): JSX.Element {
  const [form] = Form.useForm();
  // The timestamp is a flag that indicates a change has occured.
  const [timestamp, setTimestamp] = useState<number | undefined>();
  const [placeholdersValues, setPlaceholdersValues] = useState(initialPlaceholdersValues);
  const formLocalValues = useRef(initialValues);
  const { preventFormProviderUpdate } = useContext(OuterContext);

  // When the initial values change (such as when we want to copy values from already existing record)
  // we need to update the form fields and stored values. But because the form fields are encapsulated
  // by the Form component this will not trigger all required updates throughout the form builder.
  // That's why we need to change the timestamp.
  useEffect(() => {
    if (preventFormProviderUpdate) {
      return;
    }

    form.setFieldsValue(initialValues);
    formLocalValues.current = initialValues;
    setTimestamp(Date.now());
  }, [initialValues, form, preventFormProviderUpdate]);

  useEffect(() => {
    function onUpdatePlaceholderValues(event: CustomEvent<number[]>) {
      const { detail } = event;
      setPlaceholdersValues((p) => p.map((value, index) => (detail[index] !== undefined ? detail[index] : value)));
      // setTimestamp(Date.now());
    }

    document.addEventListener('update-placeholder-values', onUpdatePlaceholderValues as EventListener);

    return () => {
      document.removeEventListener('update-placeholder-values', onUpdatePlaceholderValues as EventListener);
    };
  }, []);

  useEffect(() => {
    function onUpdateFormFieldsValues(event: CustomEvent<any>) {
      if (typeof event.detail === 'function') {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        event.detail(form.getFieldsValue());
        return;
      }

      form.setFieldsValue({
        ...form.getFieldsValue(),
        ...event.detail,
      });
      formLocalValues.current = form.getFieldsValue() as Record<string, unknown>;
      // setTimestamp(Date.now());
    }

    document.addEventListener(`update-form-fields-values-${formName}`, onUpdateFormFieldsValues as EventListener);

    return () => {
      document.removeEventListener(`update-form-fields-values-${formName}`, onUpdateFormFieldsValues as EventListener);
    };
  }, [form, formName]);

  function updateFormLocalValues() {
    formLocalValues.current = form.getFieldsValue(true) as Record<string, unknown>;
  }

  return (
    <Form
      name={formName}
      form={form}
      colon={false}
      initialValues={formLocalValues.current}
      layout='vertical'
      validateMessages={validateMessages}
      size='small'
    >
      {screenFormsConfigs.map((screenFormConfig) => {
        const formConfig =
          formsConfigurations[
            screenFormConfig.pathToForm.slice(screenFormConfig.pathToForm.lastIndexOf('/') + 1).replace('.json', '')
          ];
        const formHandlers = handlers[screenFormConfig.key];
        const formValidators = validators ? validators[screenFormConfig.key] : undefined;
        const formEnums = screenFormConfig.enumsFrom ? enums[screenFormConfig.enumsFrom] : undefined;
        const formConnections = formsConnections ? formsConnections[screenFormConfig.key] : undefined;

        return (
          <CSSReset key={screenFormConfig.key}>
            <FormBuilderProvider
              screenType={screenType}
              screenFormConfig={screenFormConfig}
              form={form}
              formConfig={formConfig}
              formLocalValues={formLocalValues}
              timestamp={timestamp}
              placeholdersValues={placeholdersValues}
              handlers={formHandlers}
              validators={formValidators}
              enums={formEnums}
              formConnections={formConnections}
              updateFormLocalValues={updateFormLocalValues}
            />
          </CSSReset>
        );
      })}
    </Form>
  );
}

export default FormProvider;

const CSSReset = styled.div`
  .ant-form-item {
    margin-bottom: 8px;

    .ant-form-item-label {
      padding: 0;
    }
  }
`;
