import type { RootState } from 'state';

function getDurationForRoute(route: string) {
  return function getDurationForRouteCallback(state: RootState): number | null {
    const { value } = state.dataService.airports.maxFlightDuration;
    return value[route] ?? null;
  };
}

export default getDurationForRoute;
