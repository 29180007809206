import HttpClient from 'httpClient/index';

const BASE_URL = `${process.env.REACT_APP_DATA_API || ''}/api/productProviders`;

class DataServiceProductProviders extends HttpClient {
  public constructor() {
    super(BASE_URL);
  }

  public getFlightProviders = (): Promise<string[]> => this.instance.get('/flight');

  public getHotelProviders = (): Promise<string[]> => this.instance.get('/hotel');
}

export default new DataServiceProductProviders();
