import type { AppThunk } from 'state';
import type { FlightRouteAirport } from 'common/packageCache/types/flightRoutesTypes';
import FetchingStatus from 'constants/statuses/FetchingStatus';
import fetchAirports from './fetchAirports';
import getAirports from '../selectors/getAirports';
import { setFetching } from '../slice';

function loadAirports(): AppThunk<FlightRouteAirport[]> {
  return async function loadAirportsThunk(dispatch, getState) {
    try {
      let airports = getAirports(getState());

      if (airports !== null) {
        return airports;
      }

      await dispatch(fetchAirports());

      airports = getAirports(getState());

      if (airports !== null) {
        return airports;
      }
    } catch (error) {
      dispatch(setFetching(FetchingStatus.Failed));
      window.Rollbar.error(error);
    }

    return [];
  };
}

export default loadAirports;
