function clearObjectValues<T>(obj: T): T {
  if (!obj) {
    return {} as T;
  }

  return (Object.entries(obj)
    .map(([key, value]) => {
      if (typeof value === 'object' && value !== null) {
        if (Array.isArray(value)) {
          return { [key]: [] };
        }

        return { [key]: clearObjectValues<unknown>(value) };
      }

      return { [key]: undefined };
    })
    .reduce((acc, cur) => ({ ...acc, ...cur }), {}) as unknown) as T;
}

export default clearObjectValues;
