import React from 'react';
import styled from 'styled-components/macro';
import Calendar from './comp/calendar';
import { Direction } from './reducer';

function FlightCalendarsData(): JSX.Element {
  return (
    <Root>
      <Calendar direction={Direction.Outbound} />
      <Calendar direction={Direction.Inbound} />
    </Root>
  );
}

export default FlightCalendarsData;

const Root = styled.div`
  display: flex;
  justify-content: space-between;

  > * {
    width: 48%;
  }
`;
