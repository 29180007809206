import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';

type HeaderProps = {
  dataKey?: string;
};

function Header({ dataKey }: HeaderProps): JSX.Element {
  const [title, setTitle] = useState('');

  useEffect(() => {
    if (dataKey === undefined) {
      setTitle('');
      return;
    }

    const [airportsFrom, airportsTo] = dataKey.split('|');
    setTitle(`${airportsFrom} - ${airportsTo}`);
  }, [dataKey]);

  return (
    <Root>
      <Title>{title}</Title>
    </Root>
  );
}

export default Header;

const Root = styled.div`
  width: 100%;
  position: absolute;
  top: 16px;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
  text-align: center;
`;
