import HttpClient from 'httpClient/index';
import type { Affiliate } from '../types/affiliatesTypes';

const BASE_URL = `${process.env.REACT_APP_DATA_API || ''}/api/affiliates`;

class DataServiceAffiliates extends HttpClient {
  public constructor() {
    super(BASE_URL);
  }

  public getAffiliates = (): Promise<Affiliate[]> => this.instance.get('');
}

export default new DataServiceAffiliates();
