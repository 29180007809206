import type { FormAction, FormTableDataItem } from 'comp/screenBuilder/comp/formBuilder';
import React from 'react';
import nestedProperty from 'nested-property';
import { Tooltip, Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import FontAwesomeIcon from 'comp/wraps/faIcon/FAIcon';

type EntryActionProps<T> = {
  entry: FormTableDataItem<T>;
  action: FormAction;
  actionsDisabled: boolean;
  uniqueIdProp: (string | number)[];
  onClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
};

function EntryAction<T>({ entry, action, actionsDisabled, uniqueIdProp, onClick }: EntryActionProps<T>): JSX.Element {
  const label = action.label ? <FormattedMessage id={action.label} /> : null;
  const icon = action.icon ? <FontAwesomeIcon icon={action.icon} /> : null;
  const itemId = nestedProperty.get(entry.item, uniqueIdProp.join('.')) as string | number;
  const title = action.tooltip ? <FormattedMessage id={action.tooltip} /> : undefined;

  return (
    <Tooltip title={title}>
      <Button
        type='primary'
        disabled={actionsDisabled}
        icon={icon}
        ghost
        onClick={onClick}
        {...action.props}
        data-item-id={itemId}
        data-entry-index={entry.index}
        data-action-type={action.type}
      >
        {label}
      </Button>
    </Tooltip>
  );
}

export default EntryAction;
