import type { AppThunk } from 'state';
import FetchingStatus from 'constants/statuses/FetchingStatus';
import handleRequestError from 'managers/handleRequestError';
import { setUpdating, setUpdatingKey } from '../slice';
import { DataServiceFlightDefinitionRules } from 'common/dataService';

function deleteFlightDefinitionRules(ruleId: number): AppThunk {
  return async function deleteFlightDefinitionRulesThunk(dispatch, getState) {
    try {
      const { updating } = getState().flightDefinitionRules.definitionRules;

      if (updating === FetchingStatus.InProgress) {
        return;
      }

      dispatch(setUpdatingKey('delete'));
      dispatch(setUpdating(FetchingStatus.InProgress));

      await DataServiceFlightDefinitionRules.deleteFlightDefinitionRules(ruleId);

      dispatch(setUpdating(FetchingStatus.Succeeded));
    } catch (error) {
      dispatch(handleRequestError(error, 'Error occured on deliting flight definition rules!', setUpdating));
    }
  };
}

export default deleteFlightDefinitionRules;
