import { ComponentType, lazy, LazyExoticComponent, ReactNode } from 'react';
import {
  ProductModule,
  ProductSubModuleAccommodation,
  ProductSubModuleTourOperator,
  ProductSubModuleTransfers,
  ProductSubModuleAffiliates,
} from 'constants/userPermissions/UserPermissions';
import * as routePaths from './constants/routePaths';
import type { RoutePathsValues } from './types';

type RouteComponent = ReactNode & RouteComponentPropsType;

export type RouteComponentPropsType = {
  moduleId?: number;
};

export type RouteConfig = {
  path: RoutePathsValues;
  exact: boolean;
  secure: boolean; // `private` and `protected` are reserved words, use `secure` instead.
  useLayout?: boolean;
  routeComponent: LazyExoticComponent<ComponentType<RouteComponent>>; // `component` is an existing prop on <Route />, use `routeComponent` instead.
  componentProps?: RouteComponentPropsType;
};

const urlParams = new URLSearchParams(window.location.search);
const disableLayoutQueryParam = urlParams.get('disableLayout');

const routes: RouteConfig[] = [
  {
    path: routePaths.PATH_DASHBOARD,
    exact: true,
    secure: true,
    useLayout: true,
    routeComponent: lazy(() => import('routes/dashboard/Dashboard')),
  },
  {
    path: routePaths.PATH_LOGIN,
    exact: true,
    secure: false,
    routeComponent: lazy(() => import('routes/login/Login')),
  },
  {
    path: routePaths.PATH_CHANGE_PASSWORD,
    exact: true,
    secure: false,
    useLayout: true,
    routeComponent: lazy(() => import('routes/userManagement/ChangePassword')),
  },
  {
    path: routePaths.PATH_ACCOMMODATION,
    exact: true,
    secure: true,
    useLayout: true,
    routeComponent: lazy(() => import('routes/accommodation/Accommodation')),
    componentProps: { moduleId: ProductModule.Accommodation },
  },
  {
    path: routePaths.PATH_ACCOMMODATION_AVAILABILITY,
    exact: true,
    secure: true,
    useLayout: true,
    routeComponent: lazy(() => import('routes/accommodation/availability/Availability')),
    componentProps: { moduleId: ProductSubModuleAccommodation.Availability },
  },
  {
    path: routePaths.PATH_ACCOMMODATION_BOARDS,
    exact: true,
    secure: true,
    useLayout: true,
    routeComponent: lazy(() => import('routes/accommodation/boards/Boards')),
    componentProps: { moduleId: ProductSubModuleAccommodation.Boards },
  },
  {
    path: routePaths.PATH_ACCOMMODATION_ESTABLISHMENT,
    exact: true,
    secure: true,
    useLayout: true,
    routeComponent: lazy(() => import('routes/accommodation/establishment/Establishment')),
    componentProps: { moduleId: ProductSubModuleAccommodation.Establishment },
  },
  {
    path: routePaths.PATH_ACCOMMODATION_PACKAGING_AND_EXTRAS,
    exact: true,
    secure: true,
    useLayout: true,
    routeComponent: lazy(() => import('routes/accommodation/packagingAndExtras/PackagingAndExtras')),
    componentProps: { moduleId: ProductSubModuleAccommodation.PackagingAndExtras },
  },
  {
    path: routePaths.PATH_ACCOMMODATION_PERIODS,
    exact: true,
    secure: true,
    useLayout: true,
    routeComponent: lazy(() => import('routes/accommodation/periods/Periods')),
    componentProps: { moduleId: ProductSubModuleAccommodation.Periods },
  },
  {
    path: routePaths.PATH_ACCOMMODATION_POLICIES,
    exact: true,
    secure: true,
    useLayout: true,
    routeComponent: lazy(() => import('routes/accommodation/policies/Policies')),
    componentProps: { moduleId: ProductSubModuleAccommodation.Policies },
  },
  {
    path: routePaths.PATH_ACCOMMODATION_RATE_PLANS,
    exact: true,
    secure: true,
    useLayout: true,
    routeComponent: lazy(() => import('routes/accommodation/ratePlans/RatePlans')),
    componentProps: { moduleId: ProductSubModuleAccommodation.RatePlans },
  },
  {
    path: routePaths.PATH_ACCOMMODATION_RATES,
    exact: true,
    secure: true,
    useLayout: true,
    routeComponent: lazy(() => import('routes/accommodation/rates/Rates')),
    componentProps: { moduleId: ProductSubModuleAccommodation.Rates },
  },
  {
    path: routePaths.PATH_ACCOMMODATION_ROOMS,
    exact: true,
    secure: true,
    useLayout: true,
    routeComponent: lazy(() => import('routes/accommodation/rooms/Rooms')),
    componentProps: { moduleId: ProductSubModuleAccommodation.Rooms },
  },
  {
    path: routePaths.PATH_ACCOMMODATION_SPECIAL_OFFERS,
    exact: true,
    secure: true,
    useLayout: true,
    routeComponent: lazy(() => import('routes/accommodation/specialOffers/SpecialOffers')),
    componentProps: { moduleId: ProductSubModuleAccommodation.SpecialOffers },
  },
  {
    path: routePaths.PATH_TOOLS,
    exact: true,
    secure: true,
    useLayout: true,
    routeComponent: lazy(() => import('routes/dashboard/Dashboard')),
    componentProps: { moduleId: ProductModule.Tools },
  },
  {
    path: routePaths.PATH_TOOLS_RG_LINK_GENERATOR,
    exact: true,
    secure: false,
    useLayout: true,
    routeComponent: lazy(() => import('routes/tools/rgLinkGenerator/RgLinkGenerator')),
    componentProps: { moduleId: ProductSubModuleTourOperator.ReseguidenLinkGenerator },
  },
  {
    path: routePaths.PATH_TOOLS_SETTINGS,
    exact: true,
    secure: false,
    useLayout: true,
    routeComponent: lazy(() => import('routes/tools/settings/SettingsWrapper')),
    componentProps: { moduleId: ProductSubModuleTourOperator.ReseguidenLinkGenerator },
  },
  {
    path: routePaths.PATH_TOOLS_GDS_SCHEDULE_CHANGES_CONVERTER,
    exact: true,
    secure: false,
    useLayout: true,
    routeComponent: lazy(() => import('routes/tools/GDSScheduleChangesConverter/GDSScheduleChangesConverter')),
    componentProps: { moduleId: ProductSubModuleTourOperator.GDSScheduleChangesConverter },
  },
  {
    path: routePaths.PATH_TOUR_OPERATOR,
    exact: true,
    secure: true,
    useLayout: true,
    routeComponent: lazy(() => import('routes/dashboard/Dashboard')),
    componentProps: { moduleId: ProductModule.Tour_Operator },
  },
  {
    path: routePaths.PATH_TOUR_OPERATOR_ADVERTS,
    exact: true,
    secure: true,
    useLayout: true,
    routeComponent: lazy(() => import('routes/tourOperator/adverts/Adverts')),
    componentProps: { moduleId: ProductSubModuleTourOperator.Adverts },
  },
  {
    path: routePaths.PATH_TOUR_OPERATOR_BEDBANKS,
    exact: true,
    secure: true,
    useLayout: true,
    routeComponent: lazy(() => import('routes/tourOperator/bedbanks/Bedbanks')),
    componentProps: { moduleId: ProductSubModuleTourOperator.Bedbanks },
  },
  {
    path: routePaths.PATH_TOUR_OPERATOR_BOOKINGS,
    exact: true,
    secure: true,
    useLayout: true,
    routeComponent: lazy(() => import('routes/tourOperator/bookings/Bookings')),
    componentProps: { moduleId: ProductSubModuleTourOperator.ViewBookings },
  },
  {
    path: routePaths.PATH_TOUR_OPERATOR_BOOKINGS_OLD,
    exact: true,
    secure: true,
    useLayout: true,
    routeComponent: lazy(() => import('routes/tourOperator/bookingsOld')),
    componentProps: { moduleId: ProductSubModuleTourOperator.ViewBookings },
  },
  {
    path: routePaths.PATH_TOUR_OPERATOR_BOOKING_OVERVIEW,
    exact: true,
    secure: true,
    useLayout: true,
    routeComponent: lazy(
      () => import('routes/tourOperator/bookings/comp/bookingDetailsOverview/BookingDetailsOverview')
    ),
    componentProps: { moduleId: ProductSubModuleTourOperator.ViewBookings },
  },
  {
    path: routePaths.PATH_TOUR_OPERATOR_CONFIGURATION,
    exact: true,
    secure: true,
    useLayout: true,
    routeComponent: lazy(() => import('routes/tourOperator/configuration/Configuration')),
    componentProps: { moduleId: ProductSubModuleTourOperator.Configuration },
  },
  {
    path: routePaths.PATH_TOUR_OPERATOR_CALENDAR_SETTINGS,
    exact: true,
    secure: true,
    useLayout: true,
    routeComponent: lazy(() => import('routes/tourOperator/calendarSettings/CalendarSettings')),
    componentProps: { moduleId: ProductSubModuleTourOperator.CalendarSettings },
  },
  {
    path: routePaths.PATH_TOUR_OPERATOR_CANCELLATION_PROTECTION,
    exact: true,
    secure: true,
    useLayout: true,
    routeComponent: lazy(() => import('routes/tourOperator/cancellationProtection/CancellationProtection')),
    componentProps: { moduleId: ProductSubModuleTourOperator.CancellationProtection },
  },
  {
    path: routePaths.PATH_TOUR_OPERATOR_CONVERT_SUPPLIER_INVOICE,
    exact: true,
    secure: true,
    useLayout: !disableLayoutQueryParam,
    routeComponent: lazy(() => import('routes/tourOperator/convertSupplierInvoice/ConvertSupplierInvoice')),
    componentProps: { moduleId: ProductSubModuleTourOperator.ConvertSupplierInvoice },
  },
  {
    path: routePaths.PATH_TOUR_OPERATOR_DEPOSIT_RULES,
    exact: true,
    secure: true,
    useLayout: true,
    routeComponent: lazy(() => import('routes/tourOperator/depositRules/DepositRules')),
    componentProps: { moduleId: ProductSubModuleTourOperator.PaymentSchedules },
  },
  {
    path: routePaths.PATH_TOUR_OPERATOR_DOCUMENTS,
    exact: true,
    secure: true,
    useLayout: true,
    routeComponent: lazy(() => import('routes/tourOperator/documents/Documents')),
    componentProps: { moduleId: ProductSubModuleTourOperator.Documents },
  },
  {
    path: routePaths.PATH_TOUR_DESTINATION_SETTINGS,
    exact: true,
    secure: true,
    useLayout: true,
    routeComponent: lazy(() => import('routes/tourOperator/destinationSettings/DestinationSettings')),
    componentProps: { moduleId: ProductSubModuleTourOperator.DestinationSettings },
  },
  {
    path: routePaths.PATH_TOUR_OPERATOR_EMAIL_TRANSLATOR,
    exact: true,
    secure: true,
    useLayout: true,
    routeComponent: lazy(() => import('routes/tourOperator/emailTranslator/EmailTranslator')),
    componentProps: { moduleId: ProductSubModuleTourOperator.EmailTranslator },
  },
  {
    path: routePaths.PATH_TOUR_OPERATOR_FLIGHT_ALLOTMENT,
    exact: true,
    secure: true,
    useLayout: true,
    routeComponent: lazy(() => import('routes/tourOperator/flightAllotment/FlightAllotment')),
    componentProps: { moduleId: ProductSubModuleTourOperator.FlightAllotment },
  },
  {
    path: routePaths.PATH_TOUR_OPERATOR_FLIGHT_CACHE,
    exact: true,
    secure: true,
    useLayout: !disableLayoutQueryParam,
    routeComponent: lazy(() => import('routes/tourOperator/flightCache/FlightCache')),
    componentProps: { moduleId: ProductSubModuleTourOperator.FlightCache },
  },
  {
    path: routePaths.PATH_TOUR_OPERATOR_BROWSE_FLIGHT_CACHE,
    exact: true,
    secure: true,
    useLayout: !disableLayoutQueryParam,
    routeComponent: lazy(() => import('routes/tourOperator/flightCache/BrowseFlightCache')),
    componentProps: { moduleId: ProductSubModuleTourOperator.FlightCache },
  },
  {
    path: routePaths.PATH_TOUR_OPERATOR_FLIGHT_ROUTES,
    exact: true,
    secure: true,
    useLayout: !disableLayoutQueryParam,
    routeComponent: lazy(() => import('routes/tourOperator/flightCache/flightRoutes')),
    componentProps: { moduleId: ProductSubModuleTourOperator.FlightCache },
  },
  {
    path: routePaths.PATH_TOUR_OPERATOR_FLIGHT_CALENDARS,
    exact: true,
    secure: true,
    useLayout: !disableLayoutQueryParam,
    routeComponent: lazy(() => import('routes/tourOperator/flightCache/flightCalendars')),
    componentProps: { moduleId: ProductSubModuleTourOperator.FlightCache },
  },
  {
    path: routePaths.PATH_TOUR_OPERATOR_FLIGHT_DEFINITION_GROUPS,
    exact: true,
    secure: true,
    useLayout: true,
    routeComponent: lazy(() => import('routes/tourOperator/flightCache/flightDefinitionGroups')),
    componentProps: { moduleId: ProductSubModuleTourOperator.FlightCache },
  },
  {
    path: routePaths.PATH_TOUR_OPERATOR_STATIC_ROUTES,
    exact: true,
    secure: true,
    useLayout: true,
    routeComponent: lazy(() => import('routes/tourOperator/flightCache/staticRoutes/StaticRoutes')),
    componentProps: { moduleId: ProductSubModuleTourOperator.FlightCache },
  },
  {
    path: routePaths.PATH_TOUR_OPERATOR_FLIGHT_DEFINITION_RULES,
    exact: true,
    secure: true,
    useLayout: true,
    routeComponent: lazy(() => import('routes/tourOperator/flightCache/flightDefinitionRules')),
    componentProps: { moduleId: ProductSubModuleTourOperator.FlightCache },
  },
  {
    path: routePaths.PATH_TOUR_OPERATOR_FLIGHT_DEFINITION_RULES_PERIOD_OVERVIEW,
    exact: true,
    secure: true,
    useLayout: true,
    routeComponent: lazy(
      () =>
        import(
          'routes/tourOperator/flightCache/flightDefinitionRules/comp/viewGeneratedDefinitionPeriods/ViewGeneratedDefinitionPeriods'
        )
    ),
    componentProps: { moduleId: ProductSubModuleTourOperator.FlightCache },
  },
  {
    path: routePaths.PATH_TOUR_OPERATOR_FLIGHTS_MAX_DURATION,
    exact: true,
    secure: true,
    useLayout: true,
    routeComponent: lazy(() => import('routes/tourOperator/flightsMaxDuration/FlightsMaxDuration')),
    componentProps: { moduleId: ProductSubModuleTourOperator.FlightsMaxDuration },
  },
  {
    path: routePaths.PATH_TOUR_OPERATOR_HOT_DEALS,
    exact: true,
    secure: true,
    useLayout: true,
    routeComponent: lazy(() => import('routes/tourOperator/hotDeals/HotDeals')),
    componentProps: { moduleId: ProductSubModuleTourOperator.HotDeals },
  },
  {
    path: routePaths.PATH_TOUR_OPERATOR_MARKUPS,
    exact: true,
    secure: true,
    useLayout: true,
    routeComponent: lazy(() => import('routes/tourOperator/markups/Markups')),
    componentProps: { moduleId: ProductSubModuleTourOperator.Markups },
  },
  {
    path: routePaths.PATH_TOUR_OPERATOR_PACKAGES,
    exact: true,
    secure: true,
    useLayout: true,
    routeComponent: lazy(() => import('routes/tourOperator/packages/Packages')),
    componentProps: { moduleId: ProductSubModuleTourOperator.Packages },
  },
  {
    path: routePaths.PATH_TOUR_OPERATOR_PROMO_CODES,
    exact: true,
    secure: true,
    useLayout: true,
    routeComponent: lazy(() => import('routes/tourOperator/promoCodes/PromoCodes')),
    componentProps: { moduleId: ProductSubModuleTourOperator.PromoCodesManager },
  },
  {
    path: routePaths.PATH_TOUR_OPERATOR_SCHEDULE_CHANGES,
    exact: true,
    secure: true,
    useLayout: !disableLayoutQueryParam,
    routeComponent: lazy(() => import('routes/tourOperator/scheduleChangesPortal/ScheduleChangesPortal')),
    componentProps: { moduleId: ProductSubModuleTourOperator.ScheduleChanges },
  },
  {
    path: routePaths.PATH_TOUR_OPERATOR_SUPPLIERS,
    exact: true,
    secure: true,
    useLayout: true,
    routeComponent: lazy(() => import('routes/tourOperator/suppliers/Suppliers')),
    componentProps: { moduleId: ProductSubModuleTourOperator.Suppliers },
  },
  {
    path: routePaths.PATH_TOUR_OPERATOR_THEMES_AND_BOXES,
    exact: true,
    secure: true,
    useLayout: true,
    routeComponent: lazy(() => import('routes/tourOperator/themesAndBoxes/ThemesAndBoxes')),
    componentProps: { moduleId: ProductSubModuleTourOperator.ThemesAndBoxes },
  },
  {
    path: routePaths.PATH_TRANSFERS,
    exact: true,
    secure: true,
    useLayout: true,
    routeComponent: lazy(() => import('routes/dashboard/Dashboard')),
    componentProps: { moduleId: ProductModule.Transfers },
  },
  {
    path: routePaths.PATH_TRANSFERS_AGE_GROUPS,
    exact: true,
    secure: true,
    useLayout: true,
    routeComponent: lazy(() => import('routes/transfers/ageGroups/AgeGroups')),
    componentProps: { moduleId: ProductSubModuleTransfers.AgeGroups },
  },
  {
    path: routePaths.PATH_TRANSFERS_BOOKINGS,
    exact: true,
    secure: true,
    useLayout: true,
    routeComponent: lazy(() => import('routes/transfers/bookings/Bookings')),
    componentProps: { moduleId: ProductSubModuleTransfers.ViewBookings },
  },
  {
    path: routePaths.PATH_TRANSFERS_POINTS,
    exact: true,
    secure: true,
    useLayout: true,
    routeComponent: lazy(() => import('routes/transfers/points/Points')),
    componentProps: { moduleId: ProductSubModuleTransfers.Points },
  },
  {
    path: routePaths.PATH_TRANSFERS_ROUTES,
    exact: true,
    secure: true,
    useLayout: true,
    routeComponent: lazy(() => import('routes/transfers/routes/Routes')),
    componentProps: { moduleId: ProductSubModuleTransfers.Routes },
  },
  {
    path: routePaths.PATH_TRANSFERS_SPECIAL_OFFERS,
    exact: true,
    secure: true,
    useLayout: true,
    routeComponent: lazy(() => import('routes/transfers/specialOffers/SpecialOffers')),
    componentProps: { moduleId: ProductSubModuleTransfers.SpecialOffers },
  },
  {
    path: routePaths.PATH_TRANSFERS_VEHICLES,
    exact: true,
    secure: true,
    useLayout: true,
    routeComponent: lazy(() => import('routes/transfers/vehicles/Vehicles')),
    componentProps: { moduleId: ProductSubModuleTransfers.Vehicles },
  },
  {
    path: routePaths.PATH_TRANSFERS_ZONES,
    exact: true,
    secure: true,
    useLayout: true,
    routeComponent: lazy(() => import('routes/transfers/zones/Zones')),
    componentProps: { moduleId: ProductSubModuleTransfers.Zones },
  },
  {
    path: routePaths.PATH_SUPPLIER_SETTINGS,
    exact: true,
    secure: true,
    useLayout: true,
    routeComponent: lazy(() => import('routes/supplierSettings/SupplierSettings')),
    componentProps: { moduleId: ProductModule.SupplierSettings },
  },
  {
    path: routePaths.PATH_AFFILIATES,
    exact: true,
    secure: true,
    useLayout: true,
    routeComponent: lazy(() => import('routes/dashboard/Dashboard')),
    componentProps: { moduleId: ProductModule.Affiliates },
  },
  {
    path: routePaths.PATH_AFFILIATES_AFFILIATE,
    exact: true,
    secure: true,
    useLayout: true,
    routeComponent: lazy(() => import('routes/affiliates/affiliate')),
    componentProps: { moduleId: ProductSubModuleAffiliates.Affiliate },
  },
  {
    path: routePaths.PATH_AFFILIATES_WEBSITE,
    exact: true,
    secure: true,
    useLayout: true,
    routeComponent: lazy(() => import('routes/affiliates/website')),
    componentProps: { moduleId: ProductSubModuleAffiliates.Website },
  },
  {
    path: routePaths.PATH_ERROR,
    exact: false,
    secure: false,
    useLayout: true,
    routeComponent: lazy(() => import('routes/error/Error')),
  },
];

export default routes;
