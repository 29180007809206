import type { AppThunk } from 'state';
import FetchingStatus from 'constants/statuses/FetchingStatus';
import fetchFlightProviders from './fetchFlightProviders';
import getFlightProviders from '../selectors/getFlightProviders';
import { setFetching } from '../slice';

function loadFlightProviders(): AppThunk<string[]> {
  return async function loadFlightProvidersThunk(dispatch, getState) {
    try {
      let flightProviders = getFlightProviders(getState());

      if (flightProviders !== null) {
        return flightProviders;
      }

      await dispatch(fetchFlightProviders());

      flightProviders = getFlightProviders(getState());

      if (flightProviders !== null) {
        return flightProviders;
      }
    } catch (error) {
      dispatch(setFetching(FetchingStatus.Failed));
      window.Rollbar.error(error);
    }

    return [];
  };
}

export default loadFlightProviders;
