import type { AppThunk } from 'state';
import type { GetAirportGroupingsResponse } from 'common/dataService/types/airportsTypes';
import fetchDepartureAirportGroupings from './fetchDepartureAirportGroupings';
import getDepartureAirportGroupings from '../selectors/getDepartureAirportGroupings';

function loadDepartureAirportsGroupings(): AppThunk<GetAirportGroupingsResponse | null> {
  return async function loadDepartureAirportsGroupingsThunk(dispatch, getState) {
    try {
      let departureAirportsGroupings = getDepartureAirportGroupings(getState());

      if (departureAirportsGroupings !== null) {
        return departureAirportsGroupings;
      }

      await dispatch(fetchDepartureAirportGroupings());

      departureAirportsGroupings = getDepartureAirportGroupings(getState());

      if (departureAirportsGroupings !== null) {
        return departureAirportsGroupings;
      }
    } catch (error) {
      window.Rollbar.error(error);
    }

    return null;
  };
}

export default loadDepartureAirportsGroupings;
