import type { FormFieldProps } from 'comp/screenBuilder/comp/formBuilder/types/formPropsTypes';
import React, { useContext } from 'react';
import styled from 'styled-components/macro';
import { Form } from 'antd';
import { FormattedMessage } from 'react-intl';
import { FormattedCurrency } from 'i18n';
import updateWithValues from 'utils/string/updateWithValues';
import type { AffixesFieldProps } from '../affixProvider/types';
import FormFieldContext from '../../context';

type CurrencyPreviewProps = FormFieldProps &
  Partial<AffixesFieldProps> & {
    currency?: string;
  };

function CurrencyPreview({
  field,
  formFieldName,
  removeFormItemLabel,
  currency,
  item1,
  item2,
}: CurrencyPreviewProps): JSX.Element {
  const { formItem, formInput } = field;
  const { label, ...formItemProps } = formItem;
  const { context } = formInput;
  const fieldKey = context?.fieldKey ?? '';
  const formFieldContext = useContext(FormFieldContext);
  const { defaultCurrency } = formFieldContext[fieldKey] || {};
  let currencyValue;
  if (defaultCurrency) {
    currencyValue = defaultCurrency;
  } else {
    currencyValue = currency;
  }

  const format = formInput.context?.format ?? '{item1} {value} {currencyValue} {item2}';

  return (
    <Root>
      <Form.Item
        required={false}
        label={!removeFormItemLabel && label && <FormattedMessage {...label} />}
        {...formItemProps}
        name={formFieldName}
      >
        <Value currency={currencyValue} item1={item1} item2={item2} format={format} />
      </Form.Item>
    </Root>
  );
}

export default CurrencyPreview;

type ValueProps = {
  value?: number;
  currency?: string;
  item1?: string | number;
  item2?: string | number;
  format: string;
};

function Value({ value, currency, item1, item2, format }: ValueProps): JSX.Element | null {
  if (value === undefined) {
    return null;
  }

  if (item1 || item2) {
    const formattedValue = updateWithValues(format, { item1, item2, value, currency });
    return <div>{formattedValue}</div>;
  }

  return <FormattedCurrency value={value} currency={currency} maximumFractionDigits={2} />;
}

const Root = styled.div`
  .ant-form-item-no-colon {
    user-select: none;
    color: ${({ theme }) => theme.palette.text.label};
  }

  .ant-form-item-control-input {
    min-height: initial;
  }
`;
