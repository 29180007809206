import type { FormFieldProps } from 'comp/screenBuilder/comp/formBuilder/types/formPropsTypes';
import React from 'react';
import { Form } from 'antd';
import DefaultPreview from '../default/DefaultPreview';
import Number from '../number/Number';

function withCurrencyProvider(currencyName: (string | number)[], preview?: boolean) {
  return function CurrencyProvider(WrappedComponent: React.ComponentType<FormFieldProps & { currency: string }>) {
    return function WithProps(props: FormFieldProps): JSX.Element {
      return (
        <Form.Item required={false} name={currencyName}>
          <CurrencyValue preview={preview} wrappedComponentProps={props} WrappedComponent={WrappedComponent} />
        </Form.Item>
      );
    };
  };
}

export default withCurrencyProvider;

type CurrencyValueProps = {
  id?: string;
  value?: string;
  preview?: boolean;
  wrappedComponentProps: FormFieldProps;
  WrappedComponent: React.ComponentType<FormFieldProps & { currency: string }>;
};

function CurrencyValue({
  id,
  value,
  preview,
  wrappedComponentProps,
  WrappedComponent,
}: CurrencyValueProps): JSX.Element | null {
  if (!value) {
    if (id) {
      // console.error(`CurrencyProvider was not able to find currency in ${id}!`);
    } else {
      // console.error('CurrencyProvider was not able to find currency in data!');
    }

    if (preview) {
      return <DefaultPreview {...wrappedComponentProps} />;
    }

    return <Number {...wrappedComponentProps} />;
  }

  return <WrappedComponent currency={value} {...wrappedComponentProps} />;
}
