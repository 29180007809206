import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import FetchingStatus from 'constants/statuses/FetchingStatus';

export type LanguagesState = {
  fetching: FetchingStatus | null;
  value: string[] | null;
};

const initialState: LanguagesState = {
  fetching: null,
  value: null,
};

function setFetchingFunction(state: LanguagesState, action: PayloadAction<LanguagesState['fetching']>) {
  return {
    ...state,
    fetching: action.payload,
  };
}

function setLanguagesFunction(state: LanguagesState, action: PayloadAction<LanguagesState['value']>) {
  return {
    ...state,
    value: action.payload,
  };
}

const slice = createSlice({
  name: 'dataService.staticData.languages',
  initialState,
  reducers: {
    setFetching: setFetchingFunction,
    setLanguages: setLanguagesFunction,
  },
});

export const { setFetching, setLanguages } = slice.actions;
export default slice.reducer;
