import { combineReducers } from '@reduxjs/toolkit';
import bookingAudits from './bookingAudits/slice';
import bookingBalance from './bookingBalance/slice';
import bookingCancellation from './bookingCancellation/slice';
import bookingRatings from './bookingRatings/slice';
import bookingCancellationInfo from './bookingDetails/bookingCancellationInfo/slice';
import bookingCancellationReasons from './bookingCancellationReasons/slice';
import bookingComments from './bookingComments/slice';
import bookingDetails from './bookingDetails/slice';
import bookingDocuments from './bookingDocuments/slice';
import bookingDetailsBookingCancellationProtections from './bookingDetails/bookingCancellationProtections/slice';
import bookingDetailsBookingCarRentals from './bookingDetails/bookingCarRentals/slice';
import bookingDetailsBookingFlights from './bookingDetails/bookingFlights/slice';
import bookingDetailsBookingHotels from './bookingDetails/bookingHotels/slice';
import bookingDetailsBookingInsurances from './bookingDetails/bookingInsurances/slice';
import bookingDetailsBookingRewardCashPoints from './bookingDetails/bookingRewardCashPoints/slice';
import bookingDetailsBookingSummary from './bookingDetails/bookingSummary/slice';
import bookingDetailsBookingTransfers from './bookingDetails/bookingTransfers/slice';
import bookingDetailsBookingTravellers from './bookingDetails/bookingTravellers/slice';
import bookingDetailsCo2 from './bookingDetails/bookingCo2/slice';
import bookingFeesDetails from './bookingDetails/bookingFees/slice';
import bookingCostsDetails from './bookingDetails/bookingCosts/slice';
import bookingEarthTodayDetails from './bookingDetails/bookingEarthToday/slice';
import bookingFinancialTransactions from './bookingFinancialTransactions/slice';
import bookingPaymentDetails from './bookingPaymentDetails/slice';
import bookingPayments from './bookingPayments/slice';
import bookingPaymentStatuses from './bookingPaymentStatuses/slice';
import bookingsOverview from '../comp/bookingsOverview/reducer/slice';
import bookingsExport from '../comp/bookingsExport/reducer/slice';
import bookingsOverviewFiltersConfig from '../comp/bookingsOverviewFilters/reducers/config/slice';
import bookingsOverviewFiltersViews from '../comp/bookingsOverviewFilters/reducers/filtersViews/slice';
import bookingsOverviewFiltersValues from '../comp/bookingsOverviewFilters/reducers/values/slice';
import bookingsOverviewFiltersOptions from '../comp/bookingsOverviewFilters/reducers/options/slice';
import bookingRefundDetails from './bookingRefundDetails/slice';
import bookingValidations from './bookingValidations/slice';
import configsEnums from './configs/enums/slice';
import enumsFromValues from './enumsFromValues/slice';
import flightStopScheduleChangesHistory from './flightStopScheduleChangesHistory/slice';
import newTransactionData from './newTransactionFormData/slice';
import rebookDetails from './rebookDetails/slice';
import screensConfig from './screensConfig/slice';
import vanCreditCardDetails from './vanCreditCardDetails/slice';

export default combineReducers({
  bookingAudits,
  bookingBalance,
  bookingCancellation,
  bookingCancellationInfo,
  bookingCancellationReasons,
  bookingComments,
  bookingDetails,
  bookingDocuments,
  bookingDetailsBookingCancellationProtections,
  bookingDetailsBookingCarRentals,
  bookingDetailsBookingFlights,
  bookingDetailsBookingHotels,
  bookingDetailsBookingInsurances,
  bookingDetailsBookingRewardCashPoints,
  bookingDetailsBookingSummary,
  bookingDetailsBookingTransfers,
  bookingDetailsBookingTravellers,
  bookingDetailsCo2,
  bookingFeesDetails,
  bookingCostsDetails,
  bookingEarthTodayDetails,
  bookingFinancialTransactions,
  bookingPaymentDetails,
  bookingPayments,
  bookingPaymentStatuses,
  bookingsOverview,
  bookingsExport,
  bookingsOverviewFiltersConfig,
  bookingsOverviewFiltersViews,
  bookingsOverviewFiltersValues,
  bookingsOverviewFiltersOptions,
  bookingRefundDetails,
  bookingRatings,
  bookingValidations,
  configsEnums,
  enumsFromValues,
  flightStopScheduleChangesHistory,
  newTransactionData,
  rebookDetails,
  screensConfig,
  vanCreditCardDetails,
});
