import React from 'react';
import { FormattedMessage } from 'react-intl';

function showTotal(total: number, range: any): JSX.Element {
  return (
    <div>
      <FormattedMessage id='total' />: {total}
    </div>
  );
}

export default showTotal;
