export const PATH_DASHBOARD = '/';
export const PATH_LOGIN = '/login';
export const PATH_ERROR = '*';

// Accommodation
export const PATH_ACCOMMODATION = '/accommodation';
export const PATH_ACCOMMODATION_AVAILABILITY = '/accommodation/availability';
export const PATH_ACCOMMODATION_BOARDS = '/accommodation/boards';
export const PATH_ACCOMMODATION_ESTABLISHMENT = '/accommodation/establishment';
export const PATH_ACCOMMODATION_PACKAGING_AND_EXTRAS = '/accommodation/packaging-and-extras';
export const PATH_ACCOMMODATION_PERIODS = '/accommodation/periods';
export const PATH_ACCOMMODATION_POLICIES = '/accommodation/policies';
export const PATH_ACCOMMODATION_RATE_PLANS = '/accommodation/rate-plans';
export const PATH_ACCOMMODATION_RATES = '/accommodation/rates';
export const PATH_ACCOMMODATION_ROOMS = '/accommodation/rooms';
export const PATH_ACCOMMODATION_SPECIAL_OFFERS = '/accommodation/special-offers';

// Tools
export const PATH_TOOLS = '/tools';
export const PATH_TOOLS_RG_LINK_GENERATOR = '/tools/rg-link-generator';
export const PATH_TOOLS_SETTINGS = '/tools/settings';
export const PATH_TOOLS_GDS_SCHEDULE_CHANGES_CONVERTER = '/tools/gds-schedule-changes-converter'

// Tour Operator
export const PATH_TOUR_OPERATOR = '/tour-operator';
export const PATH_TOUR_OPERATOR_ADVERTS = '/tour-operator/adverts';
export const PATH_TOUR_OPERATOR_BEDBANKS = '/tour-operator/bedbanks';
export const PATH_TOUR_OPERATOR_BOOKINGS = '/tour-operator/bookings';
export const PATH_TOUR_OPERATOR_BOOKINGS_OLD = '/tour-operator/bookings-old';
export const PATH_TOUR_OPERATOR_BOOKING_OVERVIEW = '/tour-operator/bookings/:bookingCode';
export const PATH_TOUR_OPERATOR_CALENDAR_SETTINGS = '/tour-operator/calendar-settings';
export const PATH_TOUR_OPERATOR_CANCELLATION_PROTECTION = '/tour-operator/cancellation-protection';
export const PATH_TOUR_OPERATOR_CONVERT_SUPPLIER_INVOICE = '/tour-operator/convert-supplier-invoice';
export const PATH_TOUR_OPERATOR_DEPOSIT_RULES = '/tour-operator/deposit-rules';
export const PATH_TOUR_OPERATOR_DOCUMENTS = '/tour-operator/documents';
export const PATH_TOUR_DESTINATION_SETTINGS = '/tour-operator/destination-settings';
export const PATH_TOUR_OPERATOR_EMAIL_TRANSLATOR = '/tour-operator/email-translator';
export const PATH_TOUR_OPERATOR_FLIGHT_ALLOTMENT = '/tour-operator/flight-allotment';
export const PATH_TOUR_OPERATOR_FLIGHT_CACHE = '/tour-operator/flight-cache';
export const PATH_TOUR_OPERATOR_BROWSE_FLIGHT_CACHE =
  '/tour-operator/browse-flight-cache/:definitionPeriodId?/:from?/:to?/:originCode?/:destinationCode?';
export const PATH_TOUR_OPERATOR_FLIGHT_ROUTES = '/tour-operator/flight-routes';
export const PATH_TOUR_OPERATOR_FLIGHT_CALENDARS = '/tour-operator/flight-calendars';
export const PATH_TOUR_OPERATOR_FLIGHT_DEFINITION_GROUPS = '/tour-operator/flight-definition-groups';
export const PATH_TOUR_OPERATOR_STATIC_ROUTES = '/tour-operator/static-routes';
export const PATH_TOUR_OPERATOR_FLIGHT_DEFINITION_RULES = '/tour-operator/flight-definition-rules';
export const PATH_TOUR_OPERATOR_FLIGHT_DEFINITION_RULES_PERIOD_OVERVIEW =
  '/tour-operator/flight-definition-rules/:ruleId';
export const PATH_TOUR_OPERATOR_FLIGHTS_MAX_DURATION = '/tour-operator/flights-max-duration';
export const PATH_TOUR_OPERATOR_HOT_DEALS = '/tour-operator/hot-deals';
export const PATH_TOUR_OPERATOR_MARKUPS = '/tour-operator/markups';
export const PATH_TOUR_OPERATOR_PACKAGES = '/tour-operator/packages';
export const PATH_TOUR_OPERATOR_PROMO_CODES = '/tour-operator/promo-codes';
export const PATH_TOUR_OPERATOR_SCHEDULE_CHANGES = '/tour-operator/schedule-changes';
export const PATH_TOUR_OPERATOR_SUPPLIERS = '/tour-operator/suppliers';
export const PATH_TOUR_OPERATOR_THEMES_AND_BOXES = '/tour-operator/themes-and-boxes';
export const PATH_TOUR_OPERATOR_CONFIGURATION = '/tour-operator/configuration';

// Transfers
export const PATH_TRANSFERS = '/transfers';
export const PATH_TRANSFERS_AGE_GROUPS = '/transfers/age-groups';
export const PATH_TRANSFERS_BOOKINGS = '/transfers/bookings';
export const PATH_TRANSFERS_POINTS = '/transfers/points';
export const PATH_TRANSFERS_ROUTES = '/transfers/routes';
export const PATH_TRANSFERS_SPECIAL_OFFERS = '/transfers/special-offers';
export const PATH_TRANSFERS_VEHICLES = '/transfers/vehicles';
export const PATH_TRANSFERS_ZONES = '/transfers/zones';

// User Management
export const PATH_CHANGE_PASSWORD = '/change-password';

// Supplier Settings
export const PATH_SUPPLIER_SETTINGS = '/supplier-settings';

// Affiliates
export const PATH_AFFILIATES = '/affiliates';
export const PATH_AFFILIATES_AFFILIATE = '/affiliates/affiliate';
export const PATH_AFFILIATES_WEBSITE = '/affiliates/website';
