import type { Validity } from 'types/common/validationTypes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import FetchingStatus from 'constants/statuses/FetchingStatus';
import { BookingRating } from '../../types/bookingRating/bookingRatingTypes';

export type BookingRatingsState = {
  fetching: FetchingStatus | null;
  validity: Validity | null;
  value: BookingRating[] | null;
  updating: FetchingStatus | null;
  updatingKey: string | null;
  isApproved: boolean | null;
};

const initialState: BookingRatingsState = {
  fetching: null,
  validity: null,
  value: null,
  updating: null,
  updatingKey: null,
  isApproved: null,
};

function setFetchingFunction(state: BookingRatingsState, action: PayloadAction<BookingRatingsState['fetching']>) {
  return {
    ...state,
    fetching: action.payload,
  };
}

function setValidityFunction(state: BookingRatingsState, action: PayloadAction<BookingRatingsState['validity']>) {
  return {
    ...state,
    validity: action.payload,
  };
}

function setBookingRatingsFunction(state: BookingRatingsState, action: PayloadAction<BookingRatingsState['value']>) {
  return {
    ...state,
    value: action.payload,
  };
}

function setIsApprovedFunction(state: BookingRatingsState, action: PayloadAction<BookingRatingsState['isApproved']>) {
  return {
    ...state,
    isApproved: action.payload,
  };
}

const slice = createSlice({
  name: 'bookingRatings',
  initialState,
  reducers: {
    setFetching: setFetchingFunction,
    setValidity: setValidityFunction,
    setBookingRatings: setBookingRatingsFunction,
    setIsApproved: setIsApprovedFunction,
  },
});

export const { setFetching, setValidity, setBookingRatings, setIsApproved } = slice.actions;
export default slice.reducer;
