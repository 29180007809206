import React from 'react';

export type FormFieldContextShape = Record<
  string,
  {
    onChange?: (arg: string, fieldName?: (string | number)[]) => Promise<void> | void;
    onSearch?: (arg: string) => Promise<{ value: string | number; label: string }[]> | undefined;
    selectOptions?: { value: string | number; label: string }[];
    defaultCurrency?: string | undefined | null;
    hasPermission?: boolean;
  }
>;

const initialContext: FormFieldContextShape = {};

const FormFieldContext = React.createContext(initialContext);

export default FormFieldContext;
