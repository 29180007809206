import type { AppThunk } from 'state';
import type { GetPromocodesRequestParameters } from 'common/dataService/types/promocodesTypes';
import DataServicePromocodes from 'common/dataService/services/promocodes';
import FetchingStatus from 'constants/statuses/FetchingStatus';
import { setFetching, setPromocodes } from '../slice';

function fetchPromocodes(params?: GetPromocodesRequestParameters): AppThunk {
  return async function fetchPromocodesThunk(dispatch, getState) {
    try {
      const { fetching } = getState().dataService.promocodes.promocodes;

      if (fetching === FetchingStatus.InProgress) {
        return;
      }

      dispatch(setFetching(FetchingStatus.InProgress));

      const data = await DataServicePromocodes.getPromocodes(params);

      if (!data) {
        dispatch(setFetching(FetchingStatus.Failed));
        return;
      }

      dispatch(setPromocodes(data));
      dispatch(setFetching(FetchingStatus.Succeeded));
    } catch (error) {
      dispatch(setFetching(FetchingStatus.Failed));
      window.Rollbar.error(error);
    }
  };
}

export default fetchPromocodes;
