import type { RootState } from 'state';
import { FlightDefinitionRulesModel } from 'common/dataService';

function getFlightDefinitionRulesItems(state: RootState): FlightDefinitionRulesModel[] {
  const { value } = state.flightDefinitionRules.definitionRules;
  if (!value) {
    return [];
  }

  return value.items.map((item) => ({
    key: item.id,
    ...item,
  }));
}

export default getFlightDefinitionRulesItems;
