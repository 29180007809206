import resolvePathValue from 'utils/object/resolvePathValue';
import formatOptions from './formatOptions';
import type { ListDropdownOption } from '../../../types';

function getOptionsFromData<T>(
  listDropdownIsRequired: boolean,
  data: Record<string, unknown>,
  pathToValues: (string | number)[],
  uniqueIdProp?: string[]
): ListDropdownOption<T>[] {
  if (!listDropdownIsRequired) {
    return [];
  }

  return formatOptions(resolvePathValue<T[]>(data, pathToValues), uniqueIdProp);
}

export default getOptionsFromData;
