import HttpClient from 'httpClient/index';
import type { WebDestination } from '../types/destinationsTypes';

const BASE_URL = `${process.env.REACT_APP_DATA_API || ''}/api/destinations`;

class DataServiceDestinations extends HttpClient {
  public constructor() {
    super(BASE_URL);
  }

  public getWebDestinations = (): Promise<WebDestination[]> => this.instance.get('/webDestinations');
}

export default new DataServiceDestinations();
