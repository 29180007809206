import type { RootState } from 'state';
import type { Enum } from 'routes/tourOperator/bookings/types/configs/enumsTypes';

function getLanguagesAsEnum(state: RootState): Enum {
  const { value } = state.dataService.staticData.languages;

  if (!value) {
    return {
      name: 'Languages',
      values: [],
    };
  }

  return {
    name: 'Languages',
    values: value.map((item) => ({ key: item.toUpperCase(), value: item.toUpperCase() })),
  };
}

export default getLanguagesAsEnum;
