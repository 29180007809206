import React, { useState } from 'react';
import { SiderProvider } from './index';

type SiderContextProviderProps = {
  children: JSX.Element;
};

function SiderContextProvider({ children }: SiderContextProviderProps): JSX.Element {
  const [collapsed, setCollapsed] = useState<boolean>(true);

  return <SiderProvider value={{ collapsed, setCollapsed }}>{children}</SiderProvider>;
}

export default SiderContextProvider;
