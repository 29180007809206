import type { Moment } from 'moment';
import moment from 'moment';
import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import { Calendar as AntdCalendar, Spin } from 'antd';
import { ScrollbarSimpleLight } from 'styles/comp/scrollbar';
import { getDurationValue } from 'routes/tourOperator/flightCache/flightCalendars/comp/flightCalendarsFilters';
import { executeFetchFlightCalendars } from 'routes/tourOperator/flightCache/flightCalendars/managers';
import DateCell from './comp/dateCell';
import Header from './comp/header';
import { Direction, getFlightCalendarsByKeyDirectionAndDuration, isFetchingInProgress } from '../../reducer';

type CalendarProps = {
  direction: Direction;
};

const validRange: [Moment, Moment] = [moment(), moment().add(1, 'years')];

function Calendar({ direction }: CalendarProps): JSX.Element {
  const [calendarValue, setCalendarValue] = useState<Moment>(moment());
  const [dataKey, setDataKey] = useState<string>();
  const duration = useSelector(getDurationValue);
  const memoizedSelectorForCalendarData = useMemo(
    () => getFlightCalendarsByKeyDirectionAndDuration(direction, duration, dataKey),
    [direction, duration, dataKey]
  );
  const calendarData = useSelector(memoizedSelectorForCalendarData);
  const loading = useSelector(isFetchingInProgress);
  const dispatch = useDispatch();

  useEffect(() => {
    function onFetchFlightCalendarsFinished(event: CustomEvent<{ direction?: Direction; keys: [string, string] }>) {
      const { direction: eventDirection, keys } = event.detail;
      const key = direction === Direction.Outbound ? keys[0] : keys[1];

      if (eventDirection && eventDirection !== direction) {
        return;
      }

      setDataKey(key);
    }

    document.addEventListener('fech-flight-calendars-finished', onFetchFlightCalendarsFinished as EventListener);

    return () => {
      document.removeEventListener('fech-flight-calendars-finished', onFetchFlightCalendarsFinished as EventListener);
    };
  }, []);

  useEffect(() => {
    if (dataKey === undefined) {
      return;
    }

    const [airportsFrom, airportsTo, date, duration] = dataKey.split('|');
    const [year, month] = date.split('-');
    const currentDate = moment();

    currentDate.set('month', parseInt(month, 10) - 1);
    currentDate.set('year', parseInt(year));

    setCalendarValue(currentDate);
  }, [dataKey]);

  function dateCellRender(date: Moment): React.ReactNode {
    const dateKey = date.format('YYMMDD');

    if (calendarData && calendarData[dateKey]) {
      return <DateCell dateData={calendarData[dateKey]} />;
    }
  }

  function handleOnChange(date: Moment) {
    setCalendarValue(date);

    if (dataKey === undefined) {
      return;
    }

    const [airports1, airports2, month, duration] = dataKey.split('|');
    const airportsFrom = direction === Direction.Outbound ? [airports1] : [airports2];
    const airportsTo = direction === Direction.Outbound ? [airports2] : [airports1];
    const filtersValues = {
      airportsFrom,
      airportsTo,
      month: date.format('YYYY-MM'),
      duration: null,
      activeSearchFiltersKey: null,
    };
    dispatch(executeFetchFlightCalendars(filtersValues, direction));
  }

  return (
    <Root>
      <Spin spinning={loading}>
        <Header dataKey={dataKey} />
        <StyledCalendar
          mode='month'
          validRange={validRange}
          value={calendarValue}
          dateCellRender={dateCellRender}
          onChange={handleOnChange}
        />
      </Spin>
    </Root>
  );
}

export default Calendar;

const Root = styled.div`
  position: relative;
`;

const StyledCalendar = styled(AntdCalendar)`
  .ant-picker-cell {
    .ant-picker-cell-inner {
      margin: 0 2px;
      padding: 4px 4px 0;

      .ant-picker-calendar-date-content {
        ${ScrollbarSimpleLight};
      }
    }
  }
`;
