import type { AffiliateFull } from 'common/dataService/types/affiliatesFullTypes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import FetchingStatus from 'constants/statuses/FetchingStatus';

export type AffiliatesFullState = {
  fetching: FetchingStatus | null;
  value: AffiliateFull | null;
  updating: FetchingStatus | null;
};

const initialState: AffiliatesFullState = {
  fetching: null,
  value: null,
  updating: null,
};

function setFetchingFunction(state: AffiliatesFullState, action: PayloadAction<AffiliatesFullState['fetching']>) {
  return {
    ...state,
    fetching: action.payload,
  };
}

function setAffiliatesFullFunction(state: AffiliatesFullState, action: PayloadAction<AffiliatesFullState['value']>) {
  return {
    ...state,
    value: action.payload,
  };
}
function setUpdatingFunction(state: AffiliatesFullState, action: PayloadAction<AffiliatesFullState['updating']>) {
  return {
    ...state,
    updating: action.payload,
  };
}

const slice = createSlice({
  name: 'dataService.affiliatesFull.affiliatesFull',
  initialState,
  reducers: {
    setFetching: setFetchingFunction,
    setAffiliatesFull: setAffiliatesFullFunction,
    setUpdating: setUpdatingFunction,
  },
});

export const { setFetching, setAffiliatesFull, setUpdating } = slice.actions;
export default slice.reducer;
