import { useContext } from 'react';
import { useMediaQuery } from 'react-responsive';
import { ThemeContext } from 'styled-components';

function useDrawerWidth(width?: string | number): string | number {
  const { breakpoints } = useContext(ThemeContext);
  const isSmallDevice = useMediaQuery({ maxWidth: breakpoints.small });

  if (width) {
    return width;
  }

  if (isSmallDevice) {
    return '100%';
  }

  return '50%';
}

export default useDrawerWidth;
