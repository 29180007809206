import type { AppThunk } from 'state';
import DataServiceWebsites from 'common/dataService/services/websites';
import FetchingStatus from 'constants/statuses/FetchingStatus';
import { setFetching, setWebsites } from '../slice';

function fetchWebsites(): AppThunk {
  return async function fetchWebsitesThunk(dispatch, getState) {
    try {
      const { fetching } = getState().dataService.websites.websites;

      if (fetching === FetchingStatus.InProgress) {
        return;
      }

      dispatch(setFetching(FetchingStatus.InProgress));

      const data = await DataServiceWebsites.getWebsites();

      if (!data) {
        dispatch(setFetching(FetchingStatus.Failed));
        return;
      }

      dispatch(setWebsites(data));
      dispatch(setFetching(FetchingStatus.Succeeded));
    } catch (error) {
      dispatch(setFetching(FetchingStatus.Failed));
      window.Rollbar.error(error);
    }
  };
}

export default fetchWebsites;
