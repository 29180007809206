import type { BookingDetails } from 'routes/tourOperator/bookings/types/bookingDetails/bookingDetailsTypes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type BookingRewardCashPointsState = {
  value: BookingDetails['rewardCashPoints'] | null;
};

const initialState: BookingRewardCashPointsState = {
  value: null,
};

function setBookingRewardCashPointsFunction(
  state: BookingRewardCashPointsState,
  action: PayloadAction<BookingRewardCashPointsState['value']>
) {
  return {
    ...state,
    value: action.payload,
  };
}

function resetStateFunction() {
  return initialState;
}

const slice = createSlice({
  name: 'bookingDetails.bookingRewardCashPoints',
  initialState,
  reducers: {
    setBookingRewardCashPoints: setBookingRewardCashPointsFunction,
    resetState: resetStateFunction,
  },
});

export const { setBookingRewardCashPoints, resetState } = slice.actions;
export default slice.reducer;
