import type { AppThunk } from 'state';
import { loadMaxFlightDuration } from 'common/dataService';
import { Direction } from '../comp/flightCalendarsData';
import { setDurationValue } from '../comp/flightCalendarsFilters';
import type { FlightCalendarsFiltersState } from '../comp/flightCalendarsFilters';

function executeLoadMaxFlightDuration(filtersValues: FlightCalendarsFiltersState, direction?: Direction): AppThunk {
  return async function executeLoadMaxFlightDurationThunk(dispatch, getState): Promise<void> {
    if (!direction) {
      const { airportsFrom, airportsTo } = filtersValues;
      const duration = await dispatch(
        loadMaxFlightDuration({ airportFrom: airportsFrom[0], airportTo: airportsTo[0] })
      );
      dispatch(setDurationValue(duration));
    }
  };
}

export default executeLoadMaxFlightDuration;
