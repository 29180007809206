import type { ColumnsType, ColumnType } from 'antd/lib/table';
import type { TableRowSelection } from 'antd/lib/table/interface';
import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components/macro';
import { Tooltip, Button, Drawer, Table } from 'antd';
import { FormattedMessage } from 'react-intl';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import FontAwesomeIcon from 'comp/wraps/faIcon/FAIcon';
import localStorage from 'localStorage';
import splice from 'utils/immutability/splice';
import processColumnsUsingConfig from './utils/processColumnsUsingConfig';
import getLocalStorageConfig from './utils/getLocalStorageConfig';
import getSelectedRowsKeys from './utils/getSelectedRowsKeys';
import DraggableTableRow from '../draggableTableRow/DraggableTableRow';
import type { DraggableTableRowProps, MoveRow } from '../draggableTableRow/types/draggableTableRowTypes';

type ColumnsConfigProps<T> = {
  tableName: string;
  columns: ColumnsType<T>;
  setColumnsConfigTimestamp: React.Dispatch<React.SetStateAction<number>>;
};

const columnsT = [{ key: 'column', title: <FormattedMessage id='table.columns' />, dataIndex: 'title' }];

function ColumnsConfig<T = unknown>({
  tableName,
  columns,
  setColumnsConfigTimestamp,
}: ColumnsConfigProps<T>): JSX.Element {
  const tableColumnsConfig = useMemo(() => getLocalStorageConfig(tableName), [tableName]);
  const [visible, setVisible] = useState(false);
  const [dataSource, setDataSource] = useState<ColumnsType<T>>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  useEffect(() => {
    setDataSource(processColumnsUsingConfig<T>(columns, tableColumnsConfig));
    setSelectedRowKeys(getSelectedRowsKeys<T>(columns, tableColumnsConfig));
  }, [columns, tableColumnsConfig]);

  const comp = {
    body: {
      row: DraggableTableRow,
    },
  };

  const rowSelection: TableRowSelection<ColumnType<T>> = {
    selectedRowKeys,
    onChange: setSelectedRowKeys,
  };

  const moveRow: MoveRow = function moveRow(dragObject, dropObject) {
    const dragRow = dataSource[dragObject.index];
    setDataSource(splice(dataSource, dragRow, dragObject.index, dropObject.index));
  };

  function setPropsOnRow(record: ColumnType<T>, index?: number): DraggableTableRowProps {
    return {
      index: index || 0,
      moveRow,
    };
  }

  function onClickEditColumns() {
    setVisible(true);
  }

  function onClickCloseDrawer() {
    setVisible(false);
  }

  function onClickApplyChanges() {
    const nextConfig = dataSource.map(({ key }, order) => ({
      key,
      order,
      visible: selectedRowKeys.includes(key as string),
    }));

    const indexOfActions = columns.findIndex((column) => column.key === 'actions');
    if (indexOfActions !== -1) {
      nextConfig.push({ key: 'actions', order: nextConfig.length, visible: true });
    }

    localStorage.add(tableName, nextConfig);
    setColumnsConfigTimestamp(Date.now());
    onClickCloseDrawer();
  }

  return (
    <>
      <Tooltip title={<FormattedMessage id='table.edit.columns' />}>
        <Button type='primary' icon={<FontAwesomeIcon icon='pen' />} ghost onClick={onClickEditColumns} />
      </Tooltip>
      <Drawer
        visible={visible}
        placement='right'
        closable
        destroyOnClose
        title={<FormattedMessage id='table.choose.columns' />}
        onClose={onClickCloseDrawer}
        width={520}
        footer={<Footer onClickApplyChanges={onClickApplyChanges} />}
      >
        <DndProvider backend={HTML5Backend}>
          <Table
            bordered
            columns={columnsT}
            rowSelection={rowSelection}
            dataSource={dataSource}
            pagination={false}
            components={comp}
            onRow={setPropsOnRow}
          />
        </DndProvider>
      </Drawer>
    </>
  );
}

export default ColumnsConfig;

type FooterProps = {
  onClickApplyChanges: () => void;
};

function Footer({ onClickApplyChanges }: FooterProps): JSX.Element {
  return (
    <Root>
      <Button onClick={onClickApplyChanges} type='primary'>
        <FormattedMessage id='save' />
      </Button>
    </Root>
  );
}

const Root = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;
