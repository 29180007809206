import type { AppThunk } from 'state';
import DataServiceAirports from 'common/dataService/services/airports';
import { pushToFetching, removeFromFetching, setDestinationGroupings } from '../slice';
import { DestinationAirportGroupings } from '../constants/fetchingKeys';

function fetchDestinationAirportGroupings(): AppThunk {
  return async function fetchDestinationAirportGroupingsThunk(dispatch, getState) {
    try {
      const { fetching } = getState().dataService.airports.airportGroupings;

      if (fetching.indexOf(DestinationAirportGroupings) !== -1) {
        return;
      }

      dispatch(pushToFetching(DestinationAirportGroupings));

      const data = await DataServiceAirports.getDestinationAirportGroupings();

      dispatch(setDestinationGroupings(data));
      dispatch(removeFromFetching(DestinationAirportGroupings));
    } catch (error) {
      dispatch(removeFromFetching(DestinationAirportGroupings));
      window.Rollbar.error(error);
    }
  };
}

export default fetchDestinationAirportGroupings;
