import type { RootState } from 'state';
import type { Enum } from 'routes/tourOperator/bookings/types/configs/enumsTypes';
import getAirportsFromAsConfigEnum from './getAirportsFromAsConfigEnum';
import getAirportsToAsConfigEnum from './getAirportsToAsConfigEnum';
import getCarriersAsConfigEnum from './getCarriersAsConfigEnum';

function getAllConfigEnums(state: RootState): Enum[] {
  const airportsFrom = getAirportsFromAsConfigEnum(state);
  const airportsTo = getAirportsToAsConfigEnum(state);
  const carriers = getCarriersAsConfigEnum(state);
  const enums = [];

  if (airportsFrom) {
    enums.push(airportsFrom);
  }

  if (airportsTo) {
    enums.push(airportsTo);
  }

  if (carriers) {
    enums.push(carriers);
  }

  return enums;
}

export default getAllConfigEnums;
