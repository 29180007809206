import type { RootState } from 'state';
import { FlightCacheDefinitionPeriodsModel } from 'common/dataService';

function getGeneratedDefinitionPeriodItems(state: RootState): FlightCacheDefinitionPeriodsModel[] {
  const { value } = state.flightDefinitionRules.generatedDefinitionPeriods;
  if (!value) {
    return [];
  }

  return value.items.map((item, index) => ({
    key: index,
    ...item,
  }));
}

export default getGeneratedDefinitionPeriodItems;
