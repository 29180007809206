import type { FormConfig, FormConfigField } from '../types/formConfigTypes';

type FormFieldWithIndexes = {
  field: FormConfigField;
  sectionIndex: number;
  rowIndex: number;
  fieldIndex: number;
};

function getAllFieldsWithIndexesFromForm(form: FormConfig): FormFieldWithIndexes[] {
  const { sections } = form;

  return sections
    .map(({ rows }, sectionIndex) =>
      rows
        .map(({ fields }, rowIndex) =>
          fields.map((field, fieldIndex) => ({ field, sectionIndex, rowIndex, fieldIndex }))
        )
        .flat()
    )
    .flat();
}

export default getAllFieldsWithIndexesFromForm;
