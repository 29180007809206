import { combineReducers } from '@reduxjs/toolkit';
import affiliatesFull from './affiliatesFull/slice';
import affiliatesFullFilters from './affiliatesFullFilters/slice';
import affiliatesInitial from './affiliateInitial/slice';

export default combineReducers({
  affiliatesFull,
  affiliatesFullFilters,
  affiliatesInitial,
});
