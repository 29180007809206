import type { Validity } from 'types/common/validationTypes';
import type { DocumentsResponse } from 'routes/tourOperator/documents';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import FetchingStatus from 'constants/statuses/FetchingStatus';

export type DocumentsState = {
  fetching: FetchingStatus | null;
  validity: Validity | null;
  value: DocumentsResponse | null;
};

const initialState: DocumentsState = {
  fetching: null,
  validity: null,
  value: null,
};

function setFetchingFunction(state: DocumentsState, action: PayloadAction<DocumentsState['fetching']>) {
  return {
    ...state,
    fetching: action.payload,
  };
}

function setValidityFunction(state: DocumentsState, action: PayloadAction<DocumentsState['validity']>) {
  return {
    ...state,
    validity: action.payload,
  };
}

function setBookingDocumentsFunction(state: DocumentsState, action: PayloadAction<DocumentsState['value']>) {
  return {
    ...state,
    value: action.payload,
  };
}

function resetStateFunction() {
  return initialState;
}

const slice = createSlice({
  name: 'bookingDocuments',
  initialState,
  reducers: {
    setFetching: setFetchingFunction,
    setValidity: setValidityFunction,
    setBookingDocuments: setBookingDocumentsFunction,
    resetState: resetStateFunction,
  },
});

export const { setFetching, setValidity, setBookingDocuments, resetState } = slice.actions;
export default slice.reducer;
