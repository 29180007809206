import HttpClient from 'httpClient/index';
import type { Website } from '../types/websitesTypes';

const BASE_URL = `${process.env.REACT_APP_DATA_API || ''}/api/websites`;

class DataServiceWebsites extends HttpClient {
  public constructor() {
    super(BASE_URL);
  }

  public getWebsites = (): Promise<Website[]> => this.instance.get('');
}

export default new DataServiceWebsites();
