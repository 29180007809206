import { combineReducers } from '@reduxjs/toolkit';
import rgLinkGenerator from 'routes/tools/rgLinkGenerator/reducers';
import bookings from 'routes/tourOperator/bookings/reducers';
import { reducer as flightCalendars } from 'routes/tourOperator/flightCache/flightCalendars';
import { reducer as flightRoutes } from 'routes/tourOperator/flightCache/flightRoutes';
import { reducer as flightDefinitionGroups } from 'routes/tourOperator/flightCache/flightDefinitionGroups';
import { reducer as staticRoutes } from 'routes/tourOperator/flightCache/staticRoutes';
import { reducer as flightDefinitionRules } from 'routes/tourOperator/flightCache/flightDefinitionRules';
import dataService from 'common/dataService/reducers';
import websiteFilters from 'routes/affiliates/website/comp/websiteFilters/reducers';
import websiteHotels from 'routes/affiliates/website/comp/WebsiteHotels/reducers';
import websiteApiKeys from 'routes/affiliates/website/comp/websiteApiKeys/reducers';
import { reducer as packageCache } from 'common/packageCache';
import auth from './auth/slice';
import flightCache from './flightCache/slice';
import originsDestinations from './originsDestinations/slice';

export default combineReducers({
  auth,
  bookings,
  dataService,
  flightCache,
  flightCalendars,
  flightRoutes,
  flightDefinitionGroups,
  flightDefinitionRules,
  originsDestinations,
  packageCache,
  rgLinkGenerator,
  websiteFilters,
  websiteHotels,
  websiteApiKeys,
  staticRoutes,
});
