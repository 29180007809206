import type { AppThunk } from 'state';
import type { Affiliate } from 'common/dataService/types/affiliatesTypes';
import FetchingStatus from 'constants/statuses/FetchingStatus';
import fetchAffiliates from './fetchAffiliates';
import getAffiliates from '../selectors/getAffiliates';
import { setFetching } from '../slice';

function loadAffiliates(): AppThunk<Affiliate[]> {
  return async function loadAffiliatesThunk(dispatch, getState) {
    try {
      let affiliates = getAffiliates(getState());

      if (affiliates !== null) {
        return affiliates;
      }

      await dispatch(fetchAffiliates());

      affiliates = getAffiliates(getState());

      if (affiliates !== null) {
        return affiliates;
      }
    } catch (error) {
      dispatch(setFetching(FetchingStatus.Failed));
      window.Rollbar.error(error);
    }

    return [];
  };
}

export default loadAffiliates;
