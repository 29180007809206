import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import removeAtIndex from 'utils/immutability/removeAtIndex';

export type FlightsCalendarsFiltersOptionsState = {
  fetching: string[];
  airportsFrom: Record<string, string[]> | null;
  airportsTo: Record<string, string[]> | null;
  duration: {
    min: number;
    max: number;
  };
};

const initialState: FlightsCalendarsFiltersOptionsState = {
  fetching: [],
  airportsFrom: null,
  airportsTo: null,
  duration: {
    min: 0,
    max: 999,
  },
};

function pushToFetchingFunction(state: FlightsCalendarsFiltersOptionsState, action: PayloadAction<string>) {
  return {
    ...state,
    fetching: [...state.fetching, action.payload],
  };
}

function removeFromFetchingFunction(state: FlightsCalendarsFiltersOptionsState, action: PayloadAction<string>) {
  const index = state.fetching.indexOf(action.payload);

  if (index === -1) {
    return state;
  }

  return {
    ...state,
    fetching: removeAtIndex(state.fetching, index),
  };
}

function setAirportsFromFunction(
  state: FlightsCalendarsFiltersOptionsState,
  action: PayloadAction<FlightsCalendarsFiltersOptionsState['airportsFrom']>
) {
  return {
    ...state,
    airportsFrom: action.payload,
  };
}

function setAirportsToFunction(
  state: FlightsCalendarsFiltersOptionsState,
  action: PayloadAction<FlightsCalendarsFiltersOptionsState['airportsTo']>
) {
  return {
    ...state,
    airportsTo: action.payload,
  };
}

function setDurationFunction(
  state: FlightsCalendarsFiltersOptionsState,
  action: PayloadAction<FlightsCalendarsFiltersOptionsState['duration']>
) {
  return {
    ...state,
    duration: action.payload,
  };
}

function resetStateFunction() {
  return initialState;
}

const slice = createSlice({
  name: 'flightCalendars.filters.options',
  initialState,
  reducers: {
    pushToFetching: pushToFetchingFunction,
    removeFromFetching: removeFromFetchingFunction,
    setAirportsFrom: setAirportsFromFunction,
    setAirportsTo: setAirportsToFunction,
    setDuration: setDurationFunction,
    resetState: resetStateFunction,
  },
});

export const {
  pushToFetching,
  removeFromFetching,
  setAirportsFrom,
  setAirportsTo,
  setDuration,
  resetState,
} = slice.actions;
export default slice.reducer;
