import type { FormConfig, FormConfigField } from '../types/formConfigTypes';

function updateFormFieldsVisibility(form: FormConfig, screenType?: string): FormConfig {
  const nextForm = form;
  nextForm.sections = form.sections.map((section) => {
    const nextSection = section;
    nextSection.rows = section.rows.map((row) => {
      const nextRow = row;
      nextRow.fields = row.fields
        .map((field) => {
          const { showFor, previewFor } = field;

          if (previewFor !== undefined) {
            if (previewFor.indexOf(screenType ?? '') !== -1) {
              // eslint-disable-next-line no-param-reassign
              field.previewOnly = true;
            }
          }

          if (showFor === undefined) {
            return field;
          }

          if (showFor.indexOf(screenType ?? '') !== -1) {
            return field;
          }

          return null;
        })
        .filter<FormConfigField>((value): value is FormConfigField => value !== null);
      return nextRow;
    });
    return nextSection;
  });

  return nextForm;
}

export default updateFormFieldsVisibility;
