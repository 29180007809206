import _groupBy from 'lodash/groupBy';
import type { ScreenConfig } from '../types/screenConfigTypes';
import type { FormsConnections } from '../types/eventsTypes';

function getFormsConnections(screenConfig: ScreenConfig): FormsConnections {
  const { forms } = screenConfig;
  const groupedByData = _groupBy(
    forms.map((form) => ({ key: form.key, dataFrom: form.dataFrom, pathToValues: form.pathToValues })),
    'dataFrom'
  );
  const result = {} as FormsConnections;

  Object.keys(groupedByData).forEach((groupKey) => {
    groupedByData[groupKey].forEach((groupItem, index, groupArray) => {
      const pathAsStringToSearchFor = groupItem.pathToValues.toString();

      for (let i = index + 1; i < groupArray.length; i += 1) {
        const pathAsString = groupArray[i].pathToValues.toString();
        const sourceIsSame = pathAsString.indexOf(pathAsStringToSearchFor) === 0;
        const sourceIsArray = sourceIsSame && pathAsString[pathAsStringToSearchFor.length + 1] === '{';

        if (sourceIsArray) {
          if (!result[groupItem.key]) {
            result[groupItem.key] = { dispatch: [`form-connection-${groupItem.key}`] };
          } else if (result[groupItem.key] && !result[groupItem.key].dispatch) {
            result[groupItem.key].dispatch = [`form-connection-${groupItem.key}`];
          } else if (result[groupItem.key] && result[groupItem.key].dispatch) {
            if (result[groupItem.key].dispatch?.indexOf(`form-connection-${groupItem.key}`) === -1) {
              result[groupItem.key].dispatch?.push(`form-connection-${groupItem.key}`);
            }
          }

          if (!result[groupArray[i].key]) {
            result[groupArray[i].key] = { listen: [`form-connection-${groupItem.key}`] };
          } else if (result[groupArray[i].key] && !result[groupArray[i].key].listen) {
            result[groupArray[i].key].listen = [`form-connection-${groupItem.key}`];
          } else if (result[groupArray[i].key].listen) {
            if (result[groupArray[i].key].listen?.indexOf(`form-connection-${groupItem.key}`) === -1) {
              result[groupArray[i].key].listen?.push(`form-connection-${groupItem.key}`);
            }
          }
        }
      }
    });
  });

  return result;
}

export default getFormsConnections;
