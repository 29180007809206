import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import removeAtIndex from 'utils/immutability/removeAtIndex';

export type BookingsOverviewFiltersOptionsState = {
  fetching: string[];
  bookingStatus: { value: string; label: string; title: string }[] | null;
  departureAirport: { value: string; label: string }[] | null;
  destinationCode: { value: string; label: string }[] | null;
  flightProvider: { value: string; label: string; title: string }[] | null;
  hotelName: { value: string; label: string; title: string }[] | null;
  hotelProvider: { value: string; label: string; title: string }[] | null;
  productStatus: { value: string; label: string }[] | null;
  promocodeId: { value: number; label: string; title: string }[] | null;
  textSearchType: { value: string; label: string; title: string }[] | null;
  websiteId: { value: number; label: string; title: string }[] | null;
  affiliateId: { value: number; label: string; title: string }[] | null;
  flightCarrier: { value: string; label: string; title: string }[] | null;
  refundPromoCodeId: { value: number; label: string; title: string }[] | null;
};

export type SetBookingsOverviewFiltersOptionsPayload = {
  bookingStatus: BookingsOverviewFiltersOptionsState['bookingStatus'];
  departureAirport: BookingsOverviewFiltersOptionsState['departureAirport'];
  destinationCode: BookingsOverviewFiltersOptionsState['destinationCode'];
  flightProvider: BookingsOverviewFiltersOptionsState['flightProvider'];
  hotelName: BookingsOverviewFiltersOptionsState['hotelName'];
  hotelProvider: BookingsOverviewFiltersOptionsState['hotelProvider'];
  productStatus: BookingsOverviewFiltersOptionsState['productStatus'];
  promocodeId: BookingsOverviewFiltersOptionsState['promocodeId'];
  textSearchType: BookingsOverviewFiltersOptionsState['textSearchType'];
  websiteId: BookingsOverviewFiltersOptionsState['websiteId'];
  affiliateId: BookingsOverviewFiltersOptionsState['affiliateId'];
  flightCarrier: BookingsOverviewFiltersOptionsState['flightCarrier'];
  refundPromoCodeId: BookingsOverviewFiltersOptionsState['refundPromoCodeId'];
};

const initialState: BookingsOverviewFiltersOptionsState = {
  fetching: [],
  bookingStatus: null,
  departureAirport: null,
  destinationCode: null,
  flightProvider: null,
  hotelName: null,
  hotelProvider: null,
  productStatus: null,
  promocodeId: null,
  textSearchType: null,
  websiteId: null,
  affiliateId: null,
  flightCarrier: null,
  refundPromoCodeId: null
};

function pushToFetchingFunction(state: BookingsOverviewFiltersOptionsState, action: PayloadAction<string>) {
  return {
    ...state,
    fetching: [...state.fetching, action.payload],
  };
}

function removeFromFetchingFunction(state: BookingsOverviewFiltersOptionsState, action: PayloadAction<string>) {
  const index = state.fetching.indexOf(action.payload);

  if (index === -1) {
    return state;
  }

  return {
    ...state,
    fetching: removeAtIndex(state.fetching, index),
  };
}

function setBookingStatusFunction(
  state: BookingsOverviewFiltersOptionsState,
  action: PayloadAction<BookingsOverviewFiltersOptionsState['bookingStatus']>
) {
  return {
    ...state,
    bookingStatus: action.payload,
  };
}

function setDepartureAirportFunction(
  state: BookingsOverviewFiltersOptionsState,
  action: PayloadAction<BookingsOverviewFiltersOptionsState['departureAirport']>
) {
  return {
    ...state,
    departureAirport: action.payload,
  };
}

function setDestinationCodeFunction(
  state: BookingsOverviewFiltersOptionsState,
  action: PayloadAction<BookingsOverviewFiltersOptionsState['destinationCode']>
) {
  return {
    ...state,
    destinationCode: action.payload,
  };
}

function setFlightProviderFunction(
  state: BookingsOverviewFiltersOptionsState,
  action: PayloadAction<BookingsOverviewFiltersOptionsState['flightProvider']>
) {
  return {
    ...state,
    flightProvider: action.payload,
  };
}

function setHotelNameFunction(
  state: BookingsOverviewFiltersOptionsState,
  action: PayloadAction<BookingsOverviewFiltersOptionsState['hotelName']>
) {
  return {
    ...state,
    hotelName: action.payload,
  };
}

function setHotelProviderFunction(
  state: BookingsOverviewFiltersOptionsState,
  action: PayloadAction<BookingsOverviewFiltersOptionsState['hotelProvider']>
) {
  return {
    ...state,
    hotelProvider: action.payload,
  };
}

function setProductStatusFunction(
  state: BookingsOverviewFiltersOptionsState,
  action: PayloadAction<BookingsOverviewFiltersOptionsState['productStatus']>
) {
  return {
    ...state,
    productStatus: action.payload,
  };
}

function setPromocodeIdFunction(
  state: BookingsOverviewFiltersOptionsState,
  action: PayloadAction<BookingsOverviewFiltersOptionsState['promocodeId']>
) {
  return {
    ...state,
    promocodeId: action.payload,
  };
}

function setRefundPromoCodeIdFunction(
  state: BookingsOverviewFiltersOptionsState,
  action: PayloadAction<BookingsOverviewFiltersOptionsState['refundPromoCodeId']>
) {
  return {
    ...state,
    refundPromoCodeId: action.payload,
  };
}

function setTextSearchTypeFunction(
  state: BookingsOverviewFiltersOptionsState,
  action: PayloadAction<BookingsOverviewFiltersOptionsState['textSearchType']>
) {
  return {
    ...state,
    textSearchType: action.payload,
  };
}

function setWebsiteIdOptionsFunction(
  state: BookingsOverviewFiltersOptionsState,
  action: PayloadAction<BookingsOverviewFiltersOptionsState['websiteId']>
) {
  return {
    ...state,
    websiteId: action.payload,
  };
}

function setAffiliateIdFunction(
  state: BookingsOverviewFiltersOptionsState,
  action: PayloadAction<BookingsOverviewFiltersOptionsState['affiliateId']>
) {
  return {
    ...state,
    affiliateId: action.payload,
  };
}

function setCarrierFunction(
  state: BookingsOverviewFiltersOptionsState,
  action: PayloadAction<BookingsOverviewFiltersOptionsState['flightCarrier']>
) {
  return {
    ...state,
    flightCarrier: action.payload,
  };
}

function setBookingsOverviewFiltersOptionsFunction(
  state: BookingsOverviewFiltersOptionsState,
  action: PayloadAction<SetBookingsOverviewFiltersOptionsPayload>
) {
  return {
    ...state,
    departureAirport: action.payload.departureAirport,
    destinationCode: action.payload.destinationCode,
    flightProvider: action.payload.flightProvider,
    hotelName: action.payload.hotelName,
    hotelProvider: action.payload.hotelProvider,
    promocodeId: action.payload.promocodeId,
    websiteId: action.payload.websiteId,
    refundPromoCodeId: action.payload.refundPromoCodeId
  };
}

function resetStateFunction() {
  return initialState;
}

const slice = createSlice({
  name: 'bookingsOverviewFilters.options',
  initialState,
  reducers: {
    pushToFetching: pushToFetchingFunction,
    removeFromFetching: removeFromFetchingFunction,
    setBookingStatus: setBookingStatusFunction,
    setDepartureAirport: setDepartureAirportFunction,
    setDestinationCode: setDestinationCodeFunction,
    setFlightProvider: setFlightProviderFunction,
    setHotelName: setHotelNameFunction,
    setHotelProvider: setHotelProviderFunction,
    setProductStatus: setProductStatusFunction,
    setPromocodeId: setPromocodeIdFunction,
    setTextSearchType: setTextSearchTypeFunction,
    setWebsiteIdOptions: setWebsiteIdOptionsFunction,
    setAffiliateId: setAffiliateIdFunction,
    setCarrier: setCarrierFunction,
    setBookingsOverviewFiltersOptions: setBookingsOverviewFiltersOptionsFunction,
    resetState: resetStateFunction,
    setRefundPromoCodeId: setRefundPromoCodeIdFunction
  },
});

export const {
  pushToFetching,
  removeFromFetching,
  setBookingStatus,
  setDepartureAirport,
  setDestinationCode,
  setFlightProvider,
  setHotelName,
  setHotelProvider,
  setProductStatus,
  setPromocodeId,
  setTextSearchType,
  setWebsiteIdOptions,
  setAffiliateId,
  setCarrier,
  setBookingsOverviewFiltersOptions,
  resetState,
  setRefundPromoCodeId
} = slice.actions;
export default slice.reducer;
