import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type FlightCalendarsFiltersState = {
  airportsFrom: string[];
  airportsTo: string[];
  duration: number | null;
  month: string | null;
  activeSearchFiltersKey: string | null;
};

const initialState: FlightCalendarsFiltersState = {
  airportsFrom: [],
  airportsTo: [],
  duration: null,
  month: null,
  activeSearchFiltersKey: null,
};

function setAirportsFromFunction(
  state: FlightCalendarsFiltersState,
  action: PayloadAction<FlightCalendarsFiltersState['airportsFrom']>
) {
  return {
    ...state,
    airportsFrom: action.payload,
  };
}

function setAirportsToFunction(
  state: FlightCalendarsFiltersState,
  action: PayloadAction<FlightCalendarsFiltersState['airportsTo']>
) {
  return {
    ...state,
    airportsTo: action.payload,
  };
}

function setDurationFunction(
  state: FlightCalendarsFiltersState,
  action: PayloadAction<FlightCalendarsFiltersState['duration']>
) {
  return {
    ...state,
    duration: action.payload,
  };
}

function setMonthFunction(
  state: FlightCalendarsFiltersState,
  action: PayloadAction<FlightCalendarsFiltersState['month']>
) {
  return {
    ...state,
    month: action.payload,
  };
}

function setActiveSearchFiltersKeyFunction(
  state: FlightCalendarsFiltersState,
  action: PayloadAction<FlightCalendarsFiltersState['activeSearchFiltersKey']>
) {
  return {
    ...state,
    activeSearchFiltersKey: action.payload,
  };
}

function resetStateFunction() {
  return initialState;
}

const slice = createSlice({
  name: 'flightCalendars.filters.values',
  initialState,
  reducers: {
    setAirportsFrom: setAirportsFromFunction,
    setAirportsTo: setAirportsToFunction,
    setDuration: setDurationFunction,
    setMonth: setMonthFunction,
    setActiveSearchFiltersKey: setActiveSearchFiltersKeyFunction,
    resetState: resetStateFunction,
  },
});

export const {
  setAirportsFrom,
  setAirportsTo,
  setDuration,
  setMonth,
  setActiveSearchFiltersKey,
  resetState,
} = slice.actions;
export default slice.reducer;
