import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Select, Spin, Checkbox, Input } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { RootState } from 'state';
import { useForm } from 'antd/lib/form/Form';

import { FormattedMessage } from 'react-intl';
import fetchOrigins from 'state/reducers/originsDestinations/thunks/fetchOrigins';
import getWebDestinations from 'common/dataService/reducers/destinations/webDestinations/selectors/getWebDestinations';
import fetchWebDestinations from 'common/dataService/reducers/destinations/webDestinations/thunks/fetchWebDestinations';
import { OptionsList } from '../../flightDefinitionGroups/types/OptionsList';
import { getFlightOrigins } from '../../flightDefinitionGroups/reducers';
import { setEditModel, setVisibleModule } from '../reducers/slice';
import { StaticRouteModel, StaticRouteRequest } from 'common/dataService/types/staticRouteTypes';
import upsertStaticRoutes from '../managers/upsertStaticRoutes';
import getStaticRoutesVisibleModel from '../reducers/selectors/getstaticRoutesVisibleModel';
import getStaticRouteUpdating from '../reducers/selectors/getStaticRouteUpdating';
import { FlightProviderType } from '../../../../tools/settings/types';
import FlightProvidersService from '../../../../tools/settings/services/FlightProvidersService';

const { Option } = Select;

function AddStaticRoute(): JSX.Element {
  const loading = useSelector(getStaticRouteUpdating);
  const editModel = useSelector((state: RootState) => state.staticRoutes.editModel);
  const webDestinations = useSelector(getWebDestinations);
  const [webDestinationsSorted, setWebDestinationsSorted] = useState<OptionsList[]>([]);
  const [allFlightProviders, setAllFlightProviders] = useState<FlightProviderType[]>([]);
  const origins = useSelector(getFlightOrigins);
  const visible = useSelector(getStaticRoutesVisibleModel);
  const [form] = useForm<StaticRouteRequest>();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!origins || origins.length === 0) {
      dispatch(fetchOrigins());
    }
    if (!webDestinations || webDestinations.length === 0) {
      dispatch(fetchWebDestinations());
    }
  }, []);

  useEffect(() => {
    if (webDestinations)
      setWebDestinationsSorted(
        webDestinations
          .map((item) => ({ code: item.code, ungrouped: false, airports: [] }))
          .sort((a, b) => (a.code < b.code ? -1 : 1))
      );
  }, [webDestinations]);

  useEffect(() => {
    if (editModel) {
      form.setFieldsValue(editModel);
    }
  }, [editModel]);

  useEffect(() => {
    void FlightProvidersService.getAllFlightProvidersSettings().then((data) => {
      setAllFlightProviders(data);
    });
  }, []);

  const onOk = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    form.submit();
  };

  const onClose = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    dispatch(setEditModel(null));
    dispatch(setVisibleModule(!visible));
    form.resetFields();
  };

  const onFinish = (values: StaticRouteModel) => {
    const newModel = { ...editModel, ...values };
    upsertStaticRoutes(dispatch, newModel);
    dispatch(setEditModel(null));
    form.resetFields();
  };

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      title={<FormattedMessage id={(editModel?.id ? 'Edit' : 'Save') + ' ' + 'Static Route'} />}
      destroyOnClose={true}
      width={720}
      onOk={onOk}
      confirmLoading={loading}
      maskClosable={false}
    >
      <Spin spinning={loading}>
        <Form
          name='addStaticRoute'
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          autoComplete='off'
          form={form}
          onFinish={onFinish}
        >
          <Form.Item
            label={<FormattedMessage id='staticRoutes.origin' />}
            name='origin'
            rules={[{ required: true, message: 'Please choose origin!' }]}
          >
            <SelectContainer showSearch optionFilterProp='label'>
              {origins.map((group) => (
                <>
                  {!group.ungrouped && (
                    <Option value={group.code} key={group.code} label={group.code}>
                      {group.code}
                      {group.airports && group.airports?.map((airport) => <Airport key={airport}>{airport}</Airport>)}
                    </Option>
                  )}
                  {group.ungrouped && (
                    <>
                      <Option value={group.code} key={group.code} label={group.code}>
                        {group.code}
                      </Option>
                      {group.airports &&
                        group.airports?.map((airport, index) => (
                          <Option key={`${airport}-${index}`} value={airport} label={group.code}>
                            <Airport>{airport}</Airport>
                          </Option>
                        ))}
                    </>
                  )}
                </>
              ))}
            </SelectContainer>
          </Form.Item>
          <Form.Item
            label={<FormattedMessage id='staticRoutes.destination' />}
            name='destination'
            rules={[{ required: true, message: 'Please choose destination!' }]}
          >
            <SelectContainer showSearch>
              {webDestinationsSorted?.map((group: any) => (
                <Option value={group.code} key={group.code}>
                  {group.code}
                  {group.airports && group.airports?.map((airport: any) => <Airport key={airport}>{airport}</Airport>)}
                </Option>
              ))}
            </SelectContainer>
          </Form.Item>
          <Form.Item
            label={<FormattedMessage id='staticRoutes.comment' />}
            name='comment'
            rules={[{ required: true, message: 'Please add comment!' }]}
          >
            <Input.TextArea rows={4} maxLength={128} />
          </Form.Item>
          <Form.Item
            label={<FormattedMessage id='staticRoutes.enable' />}
            name='enable'
            valuePropName='checked'
            initialValue={true}
          >
            <Checkbox />
          </Form.Item>
          <Form.Item
            label={<FormattedMessage id='staticRoutes.providers' />}
            name='providers'
          >
            <Select
              mode='multiple'
              allowClear
              options={allFlightProviders.map((provider: FlightProviderType) => ({
                label: provider.name,
                value: provider.name
              }))}
            />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
}

export default AddStaticRoute;

const Root = styled.div``;

const SelectContainer = styled(Select)({
  width: '100%',
});

const Airport = styled('div')({
  fontSize: '12px',
  paddingLeft: '10px',
});
