import type { AppThunk } from 'state';
import FetchingStatus from 'constants/statuses/FetchingStatus';
import fetchLanguages from './fetchLanguages';
import getLanguages from '../selectors/getLanguages';
import { setFetching } from '../slice';

function loadLanguages(): AppThunk<string[]> {
  return async function loadLanguagesThunk(dispatch, getState) {
    try {
      let languages = getLanguages(getState());

      if (languages !== null) {
        return languages;
      }

      await dispatch(fetchLanguages());

      languages = getLanguages(getState());

      if (languages !== null) {
        return languages;
      }
    } catch (error) {
      dispatch(setFetching(FetchingStatus.Failed));
      window.Rollbar.error(error);
    }

    return [];
  };
}

export default loadLanguages;
