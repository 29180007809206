import { FlightCacheDefinitionPeriodsRequest } from 'common/dataService/types/flightDefinintionRulesTypes';
import type { AppDispatch, RootState } from 'state';
import fetchGeneratedDefinitionPeriods from '../../reducers/GeneratedDefinitionPeriods/thunks/fetchGeneratedDefinitionPeriods';

async function executeFetchDefinitionPeriods(dispatch: AppDispatch, getState: () => RootState): Promise<void> {
  const { page, takeCount, sorting, ruleId } = getState().flightDefinitionRules.generatedDefinitionPeriods;
  const request = {} as FlightCacheDefinitionPeriodsRequest;
  request.page = page;
  request.takeCount = takeCount;
  request.ruleId = parseInt(ruleId);
  if (sorting) {
    var sortParam = sorting.split(' ');
    request.sortColumn = sortParam[0];
    request.sortDirection = sortParam[1];
  }
  await dispatch(fetchGeneratedDefinitionPeriods(request));
}

export default executeFetchDefinitionPeriods;
