import { useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import authenticateUserWithJWT from 'state/reducers/auth/thunks/authenticateUserWithJWT';
import { AUTH_TOKEN, EXPIRATION_TIME } from 'localStorage/constants/keys';
import localStorage from 'localStorage';
import moment from 'moment';

function Auth(): null {
  const componentDidMountRef = useRef(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!componentDidMountRef.current) {
      const urlParams = new URLSearchParams(window.location.search);
      const authTokenQueryParam = urlParams.get('authToken');
      if (authTokenQueryParam) {
        localStorage.add(AUTH_TOKEN, authTokenQueryParam);
        localStorage.add(EXPIRATION_TIME, moment().add(8, 'hours').toString());
      }

      dispatch(authenticateUserWithJWT());
      componentDidMountRef.current = true;
    }
  });

  return null;
}

export default Auth;
