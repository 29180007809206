import { configureStore, getDefaultMiddleware, Action } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';
import logger from 'redux-logger';
import reducer from './reducers';

const middleware = getDefaultMiddleware();

if (process.env.REACT_APP_NODE_ENV !== 'production') {
  // Note: logger must be the last middleware in chain, otherwise it will log the thunk and promise.
  middleware.push(logger);
}

const store = configureStore({
  reducer,
  middleware,
});

// TODO: Enable HMR
// if (process.env.REACT_APP_NODE_ENV === 'development' && module.hot) {
//   module.hot.accept('./rootReducer', () => {
//     const newReducer = require('./reducers').default;
//     store.replaceReducer(newReducer);
//   });
// }

export type AppDispatch = typeof store.dispatch;
export type AppThunk<T = void> = ThunkAction<Promise<T>, RootState, unknown, Action<string>>;
export type AppThunkSync<T = void> = ThunkAction<T, RootState, unknown, Action<string>>;
export type RootState = ReturnType<typeof reducer>;
export default store;
