import type { AppThunk } from 'state';
import { PackageCacheFlightRoutesService } from 'common/packageCache';
import FetchingStatus from 'constants/statuses/FetchingStatus';
import handleRequestError from 'managers/handleRequestError';
import { setFetching, setCarriers } from '../slice';

function fetchCarriers(): AppThunk {
  return async function fetchCarriersThunk(dispatch, getState) {
    try {
      const { fetching } = getState().packageCache.flightRoutes.carriers;

      if (fetching === FetchingStatus.InProgress) {
        return;
      }

      dispatch(setFetching(FetchingStatus.InProgress));

      const data = await PackageCacheFlightRoutesService.getFlightRoutesCarriers();

      if (!data) {
        dispatch(setFetching(FetchingStatus.Failed));
        return;
      }

      dispatch(setCarriers(data));
      dispatch(setFetching(FetchingStatus.Succeeded));
    } catch (error) {
      dispatch(handleRequestError(error, 'Error occured on fetch flight routes carriers!', setFetching));
    }
  };
}

export default fetchCarriers;
