import type { ColumnsType } from 'antd/lib/table';
import type { FlightDefinitionGroupsModel } from 'common/dataService';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import renderDeleteCell from './tableCells/renderDeleteCell';
import renderEditCell from './tableCells/renderEditCell';
import renderRegeneratePeriodCell from './tableCells/renderRegeneratePeriodCell';

const columns: ColumnsType<FlightDefinitionGroupsModel> = [
  {
    key: 'name',
    title: <FormattedMessage id='name' />,
    dataIndex: 'name',
    sorter: true,
  },
  {
    key: 'type',
    title: <FormattedMessage id='type' />,
    dataIndex: 'type',
  },
  {
    key: 'updateTime',
    title: <FormattedMessage id='updateTime' />,
    dataIndex: 'updateTime',
    sorter: true,
  },
  {
    key: 'groupData',
    title: <FormattedMessage id='content' />,
    dataIndex: 'groupData',
    render: (e, record) => <span>{record.groupData.join(', ')}</span>,
  },
  {
    key: 'editActions',
    align: 'right' as const,
    fixed: 'right' as const,
    render: renderEditCell,
  },
  {
    key: 'regenerateActions',
    align: 'right' as const,
    fixed: 'right' as const,
    render: renderRegeneratePeriodCell,
  },
  {
    key: 'deleteActions',
    align: 'right' as const,
    fixed: 'right' as const,
    render: renderDeleteCell,
  },
];

export default columns;
