import type { AppThunk } from 'state';
import type { ResponseError } from 'httpClient';
import type { FlightRoute } from 'common/packageCache';
import { notification } from 'antd';
import { PackageCacheFlightRoutesService } from 'common/packageCache';
import FetchingStatus from 'constants/statuses/FetchingStatus';
import { executeFetchFlightRoutes } from 'routes/tourOperator/flightCache/flightRoutes/comp/flightRoutesFilters';
import { setUpdatingKey, setUpdating } from '../slice';
import { AuditData } from 'routes/tourOperator/bookings/types/bookingAudit/bookingAudit';

function updateFlightRoutes(body: FlightRoute, auditData: AuditData): AppThunk {
  return async function updateFlightRoutesThunk(dispatch, getState) {
    try {
      const { updating } = getState().flightRoutes.routes;

      if (updating === FetchingStatus.InProgress) {
        return;
      }

      dispatch(setUpdatingKey(body.key));
      dispatch(setUpdating(FetchingStatus.InProgress));

      await PackageCacheFlightRoutesService.postFlightRoutesUpdate(body, auditData);
      await dispatch(executeFetchFlightRoutes);

      dispatch(setUpdating(FetchingStatus.Succeeded));
      dispatch(setUpdatingKey(null));
    } catch (error) {
      const err = error as ResponseError;
      dispatch(setUpdating(FetchingStatus.Failed));
      dispatch(setUpdatingKey(null));
      notification.error({
        message: 'Error on update flight route!',
        description: err.response?.data?.message,
      });
      window.Rollbar.error(error);
    }
  };
}

export default updateFlightRoutes;
