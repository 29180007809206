import HttpClient from 'httpClient/index';
import type { GetFlightCalendarsRequestParameters, GetFlightCalendarsResponse } from '../types/flightCalendarsTypes';

const BASE_URL = `${process.env.REACT_APP_PACKAGE_CACHE_API || ''}/api/flightCalendars`;

class PackageCacheFlightCalendars extends HttpClient {
  public constructor() {
    super(BASE_URL);
  }

  public getFlightCalendars = (params: GetFlightCalendarsRequestParameters): Promise<GetFlightCalendarsResponse> =>
    this.instance.get('/', { params });
}

export default new PackageCacheFlightCalendars();
