import type { AppThunk } from 'state';
import type { GetAirportGroupingsResponse } from 'common/dataService/types/airportsTypes';
import fetchDestinationAirportGroupings from './fetchDestinationAirportGroupings';
import getDestinationAirportGroupings from '../selectors/getDestinationAirportGroupings';

function loadDestinationAirportsGroupings(): AppThunk<GetAirportGroupingsResponse | null> {
  return async function loadDestinationAirportsGroupingsThunk(dispatch, getState) {
    try {
      let destinationAirportsGroupings = getDestinationAirportGroupings(getState());

      if (destinationAirportsGroupings !== null) {
        return destinationAirportsGroupings;
      }

      await dispatch(fetchDestinationAirportGroupings());

      destinationAirportsGroupings = getDestinationAirportGroupings(getState());

      if (destinationAirportsGroupings !== null) {
        return destinationAirportsGroupings;
      }
    } catch (error) {
      window.Rollbar.error(error);
    }

    return null;
  };
}

export default loadDestinationAirportsGroupings;
