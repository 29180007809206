import type { RootState } from 'state';
import FetchingStatus from 'constants/statuses/FetchingStatus';

function getFlightDefinitionRuleUpdating(state: RootState): boolean {
  const { updating } = state.flightDefinitionRules.definitionRules;

  return updating === FetchingStatus.InProgress;
}

export default getFlightDefinitionRuleUpdating;
