import type { AffiliateInitial } from 'common/dataService/types/affiliatesFullTypes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import FetchingStatus from 'constants/statuses/FetchingStatus';

export type AffiliateInitialState = {
  fetching: FetchingStatus | null;
  value: AffiliateInitial[] | null;
  filteredValue: AffiliateInitial[] | null;
};

const initialState: AffiliateInitialState = {
  fetching: null,
  value: null,
  filteredValue: null,
};

function setFetchingFunction(state: AffiliateInitialState, action: PayloadAction<AffiliateInitialState['fetching']>) {
  return {
    ...state,
    fetching: action.payload,
  };
}
function setAffiliatesInitialFunction(
  state: AffiliateInitialState,
  action: PayloadAction<AffiliateInitialState['value']>
) {
  return {
    ...state,
    value: action.payload,
  };
}

function setFilteredAffiliatesInitialFunction(
  state: AffiliateInitialState,
  action: PayloadAction<AffiliateInitialState['filteredValue']>
) {
  return {
    ...state,
    filteredValue: action.payload,
  };
}
const slice = createSlice({
  name: 'dataService.affiliatesFull.affiliatesInitial',
  initialState,
  reducers: {
    setFetching: setFetchingFunction,
    setAffiliatesInitial: setAffiliatesInitialFunction,
    setFilteredAffiliates: setFilteredAffiliatesInitialFunction,
  },
});

export const { setFetching, setAffiliatesInitial, setFilteredAffiliates } = slice.actions;
export default slice.reducer;
