import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import FetchingStatus from 'constants/statuses/FetchingStatus';
import type { WebsiteHotel } from '../../types';

export type WebsiteHotelsState = {
  fetching: FetchingStatus | null;
  updating: FetchingStatus | null;
  allHotels: WebsiteHotel[] | null;
  allowedHotelIds: number[] | null;
};

const initialState: WebsiteHotelsState = {
  fetching: null,
  updating: null,
  allHotels: null,
  allowedHotelIds: null,
};

function setFetchingFunction(state: WebsiteHotelsState, action: PayloadAction<WebsiteHotelsState['fetching']>) {
  return {
    ...state,
    fetching: action.payload,
  };
}

function setUpdatingFunction(state: WebsiteHotelsState, action: PayloadAction<WebsiteHotelsState['updating']>) {
  return {
    ...state,
    updating: action.payload,
  };
}

function setAllHotelsFunction(state: WebsiteHotelsState, action: PayloadAction<WebsiteHotelsState['allHotels']>) {
  return {
    ...state,
    allHotels: action.payload,
  };
}

function setAllowedHotelIdsFunction(
  state: WebsiteHotelsState,
  action: PayloadAction<WebsiteHotelsState['allowedHotelIds']>
) {
  return {
    ...state,
    allowedHotelIds: action.payload,
  };
}

function resetStateFunction() {
  return initialState;
}

const slice = createSlice({
  name: 'website.websiteHotels',
  initialState,
  reducers: {
    setFetching: setFetchingFunction,
    setUpdating: setUpdatingFunction,
    setAllHotels: setAllHotelsFunction,
    setAllowedHotelIds: setAllowedHotelIdsFunction,
    resetState: resetStateFunction,
  },
});

export const { setFetching, setUpdating, setAllHotels, setAllowedHotelIds, resetState } = slice.actions;
export default slice.reducer;
