import type { TablePaginationConfig } from 'antd/lib/table';
import type { SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface';
import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import { useSelector, useDispatch } from 'react-redux';
import Table, { showTotal } from 'comp/wraps/table';
import columns from './tableConfig/columns';
import { Button, notification } from 'antd';
import { FlightDefinitionGroupsModel } from 'common/dataService';
import executeFetchDefinitionGroups from '../../managers/executeFetchDefinitionGroups';
import { RootState } from 'state';
import {
  getFlightDefinitionGroupFetching,
  getFlightDefinitionGroupsItems,
  getFlightDefinitionGroupsTotalCount,
  getFlightDefinitionGroupVisibleModel,
} from '../../reducers';
import FetchingStatus from 'constants/statuses/FetchingStatus';
import { setVisibleModule } from '../../reducers/slice';
import updateTakeCountAndLoadResults from '../../managers/updateTakeCountAndLoadResults';
import updatePageAndLoadResults from '../../managers/updatePageAndLoadResults';
import updateSortingAndLoadResults from '../../managers/updateSortingAndLoadResults';
import usePrevious from 'comp/hooks/usePrevious';
import { FormattedMessageWithI18n } from 'i18n';
import AddGroup from '../addGroup/AddGroup';

function updateOrderForBackedn(order: string): string {
  if (order === 'ascend') {
    return 'asc';
  }
  return 'desc';
}

function FlightDefinitionGroupTable() {
  const { updating, updatingKey } = useSelector((state: RootState) => state.flightDefinitionGroups);
  const prevUpdating = usePrevious(updating);
  const visibleModule = useSelector(getFlightDefinitionGroupVisibleModel);
  const dataSource = useSelector(getFlightDefinitionGroupsItems);
  const pageSize = useSelector((state: RootState) => state.flightDefinitionGroups.takeCount);
  const current = useSelector((state: RootState) => state.flightDefinitionGroups.page);
  const total = useSelector(getFlightDefinitionGroupsTotalCount);
  const loading = useSelector(getFlightDefinitionGroupFetching);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(executeFetchDefinitionGroups);
  }, []);

  useEffect(() => {
    if (prevUpdating === FetchingStatus.InProgress && updating === FetchingStatus.Succeeded) {
      notification.info({
        message: <FormattedMessageWithI18n id={`flightDefinition.${updatingKey}.succeeded.title`} />,
      });
    }
  }, [updating, prevUpdating]);

  function handleOnChange(
    paggination: TablePaginationConfig,
    filters: Record<string, React.ReactText[] | null>,
    sorter: SorterResult<FlightDefinitionGroupsModel> | SorterResult<FlightDefinitionGroupsModel>[],
    extra: TableCurrentDataSource<FlightDefinitionGroupsModel>
  ) {
    if (extra.action === 'sort') {
      const { columnKey, order } = sorter as SorterResult<FlightDefinitionGroupsModel>;
      const sorting = order === undefined ? null : `${columnKey as string} ${updateOrderForBackedn(order as string)}`;
      dispatch(updateSortingAndLoadResults(sorting));
    }
  }

  function handleOnShowSizeChange(page: number, size: number) {
    dispatch(updateTakeCountAndLoadResults(size));
  }

  function handleOnChangePage(page: number) {
    dispatch(updatePageAndLoadResults(page));
  }

  return (
    <Root>
      <Button onClick={() => dispatch(setVisibleModule(!visibleModule))}>Add Group</Button>
      <AddGroup />
      <br />
      <br />
      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={{
          current,
          pageSize,
          total,
          showSizeChanger: true,
          showTotal,
          onChange: handleOnChangePage,
          onShowSizeChange: handleOnShowSizeChange,
        }}
        loading={loading}
        onChange={handleOnChange}
        configuration={{ tableName: 'flightDefinitionGroupTable' }}
      />
    </Root>
  );
}

export default FlightDefinitionGroupTable;

const Root = styled.div``;
