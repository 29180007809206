import { FlightDefinitionGroupsModel } from 'common/dataService';
import type { AppDispatch } from 'state';
import deleteFlightDefinitionGroups from '../reducers/thunks/deleteFlightDefinitionGroups';
import executeFetchDefinitionGroups from './executeFetchDefinitionGroups';

async function deleteDefinintionGroups(dispatch: AppDispatch, model: FlightDefinitionGroupsModel): Promise<void> {
  if (model?.id) {
    await dispatch(deleteFlightDefinitionGroups(model.id));
    dispatch(executeFetchDefinitionGroups);
  }
}

export default deleteDefinintionGroups;
