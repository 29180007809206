import type { FlightCalendar } from 'common/packageCache';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import FetchingStatus from 'constants/statuses/FetchingStatus';

export type FlightCalendarsDataMap = Record<string, FlightCalendar[]>;

export type FlightCalendarsDataState = {
  fetching: FetchingStatus | null;
  outbound: Record<string, FlightCalendarsDataMap | null> | null;
  inbound: Record<string, FlightCalendarsDataMap | null> | null;
};

const initialState: FlightCalendarsDataState = {
  fetching: null,
  outbound: null,
  inbound: null,
};

function setFetchingFunction(
  state: FlightCalendarsDataState,
  action: PayloadAction<FlightCalendarsDataState['fetching']>
) {
  return {
    ...state,
    fetching: action.payload,
  };
}

function updateFlightCalendarsOutboundDataFunction(
  state: FlightCalendarsDataState,
  action: PayloadAction<{ key: string; value: FlightCalendarsDataMap | null }>
) {
  const { key, value } = action.payload;
  let outbound = state.outbound ?? {};

  return {
    ...state,
    outbound: {
      ...outbound,
      [key]: value,
    },
  };
}

function updateFlightCalendarsInboundDataFunction(
  state: FlightCalendarsDataState,
  action: PayloadAction<{ key: string; value: FlightCalendarsDataMap | null }>
) {
  const { key, value } = action.payload;
  let inbound = state.inbound ?? {};

  return {
    ...state,
    inbound: {
      ...inbound,
      [key]: value,
    },
  };
}

function resetStateFunction() {
  return initialState;
}

const slice = createSlice({
  name: 'flightCalendars.data',
  initialState,
  reducers: {
    setFetching: setFetchingFunction,
    updateFlightCalendarsOutboundData: updateFlightCalendarsOutboundDataFunction,
    updateFlightCalendarsInboundData: updateFlightCalendarsInboundDataFunction,
    resetState: resetStateFunction,
  },
});

export const {
  setFetching,
  updateFlightCalendarsOutboundData,
  updateFlightCalendarsInboundData,
  resetState,
} = slice.actions;
export default slice.reducer;
