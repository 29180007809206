import type { SelectValue } from 'antd/lib/select/index';
import type { RoutesFiltersState } from 'routes/tourOperator/flightCache/flightRoutes/comp/flightRoutesFilters';
import React from 'react';
import styled from 'styled-components/macro';
import { useSelector, useDispatch } from 'react-redux';
import { Select as AntdSelect } from 'antd';
import { FormattedMessage } from 'react-intl';
import { setStops, getStopsValue } from 'routes/tourOperator/flightCache/flightRoutes/comp/flightRoutesFilters';
import LabeledValue from 'comp/dataPresentation/labeledValue/LabeledValue';
import mediaQuery from 'styles/utils/mediaQuery';

const options = [
  { value: 0, label: 0 },
  { value: 1, label: 1 },
  { value: 2, label: 2 },
  { value: 3, label: 3 },
];

function Stops(): JSX.Element {
  const value = useSelector(getStopsValue);
  const dispatch = useDispatch();

  function handleOnChange(option: SelectValue) {
    if (option === undefined) {
      dispatch(setStops(null));
    } else {
      dispatch(setStops(option as RoutesFiltersState['stops']));
    }
  }

  return (
    <LabeledValue label={{ id: 'stops' }}>
      <Select
        allowClear
        showSearch
        listHeight={640}
        mode='multiple'
        placeholder={<FormattedMessage id='stops.placeholder' />}
        options={options}
        value={value || undefined}
        onChange={handleOnChange}
      />
    </LabeledValue>
  );
}

export default Stops;

const Select = styled(AntdSelect)`
  width: 100%;

  ${mediaQuery.fromAndAbove.medium`
    width: 200px;
  `}
`;
