import React, { useState } from 'react';
import { Tooltip, notification, Spin } from 'antd';
import { FormattedMessage } from 'react-intl';
import FontAwesomeIcon from 'comp/wraps/faIcon/FAIcon';
import { DataServiceFlightDefinitionRules, FlightDefinitionRulesModel } from 'common/dataService';
import { ResponseError } from 'httpClient';
import ConfirmationButton from 'comp/wraps/confirmationButton/ConfirmationButton';

function renderRegeneratePeriodCell(text: string, record: FlightDefinitionRulesModel): JSX.Element {
  return <RegeneratePeriodRule ruleId={record?.id ?? 0} />;
}

export default renderRegeneratePeriodCell;

type FlightDefinitionRulesModelProp = {
  ruleId: number;
};

function RegeneratePeriodRule({ ruleId }: FlightDefinitionRulesModelProp): JSX.Element {
  const [loading, setLoading] = useState(false);

  function onClickRegenerate() {
    setLoading(true);

    void DataServiceFlightDefinitionRules.postRegeneratePeriods(ruleId)
      .then((response) => {
        notification.info({
          message: 'Successfully regenerated periods',
          description: `Regenerated ${response} periods`,
        });
        setLoading(false);
      })
      .catch((error) => {
        const err = error as ResponseError;
        notification.error({
          message: 'Error on regenerating periods!',
          description: err.response?.data?.message,
        });
        setLoading(false);
      });
  }

  return (
    <Tooltip title={<FormattedMessage id='regeneratePeriod' />}>
      <Spin spinning={loading}>
        <ConfirmationButton
          buttonProps={{ ghost: true, disabled: false, type: 'primary', icon: <FontAwesomeIcon icon='sync' /> }}
          alertMessage={<FormattedMessage id='rule.regeneratePeriodMessage' />}
          onSubmit={onClickRegenerate}
          visibleForm={false}
        />
      </Spin>
    </Tooltip>
  );
}
