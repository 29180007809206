import { combineReducers } from '@reduxjs/toolkit';
import rgLinkGeneratorOptions from './options/slice';
import rgLinkGeneratorResult from './result/slice';
import rgLinkGeneratorValues from './values/slice';

export default combineReducers({
  rgLinkGeneratorOptions,
  rgLinkGeneratorResult,
  rgLinkGeneratorValues,
});
