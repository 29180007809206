import type { MenuItemShape } from './types';

export const MENU_KEY_TOUR_OPERATOR = 'tour-operator';
export const MENU_KEY_TRANSFERS = 'transfers';
export const MENU_KEY_ACCOMMODATION = 'accommodation';

type IconsMapType = {
  [prop: number]: MenuItemShape['icon'];
};

const iconsMap: IconsMapType = {
  '1': 'hotel',
  '2': 'car-bus',
  '5': 'suitcase',
  '9': 'hotel',
  '10': 'key',
  '11': 'utensils',
  '13': 'calendar-alt',
  '14': 'user-friends',
  '15': 'box-open',
  '16': 'tag',
  '17': 'cars',
  '18': 'map-marker-check',
  '19': 'map-marked-alt',
  '20': 'route',
  '21': 'user-friends',
  '22': 'fire-alt',
  '23': 'user-chart',
  '24': 'ticket-alt',
  '25': 'cog',
  '26': 'box-open',
  '27': 'chart-line',
  '28': 'calendar-times',
  '29': 'shield-check',
  '30': 'swatchbook',
  '31': 'ad',
  '32': 'ticket-alt',
  '33': 'calendar-week',
  '34': 'calendar-times',
  '35': 'euro-sign',
  '36': 'ballot-check',
  '37': 'window-close',
  '38': 'bed-empty',
  '52': 'calendar-edit',
  '53': 'barcode-read',
  '54': 'mail-bulk',
  '69': 'plane-alt',
  '70': 'comments-dollar',
  '71': 'fire-alt',
  '72': 'plane-alt',
  '73': 'parachute-box',
  '74': 'file',
  '77': 'plane-alt',
  '83': 'calendar-edit',
  '86': 'file-export',
  '87': 'tools',
  '88': 'link',
  '90': 'cog',
  '91': 'cog',
  '92': 'plane-alt',
  '94': 'handshake',
  '95': 'code-branch',
  '96': 'globe',
  '99': 'file-export'
};

export default iconsMap;
