import type { RootState } from 'state';
import { SelectListOption } from '../types/SelectListOption';

function getFlightDefinitonDestinationGroups(state: RootState): SelectListOption[] {
  const { value } = state.flightDefinitionGroups;

  if (!value) return [];

  return value.items
    .filter((item) => item.type === 'Destination')
    .map((item) => ({ label: item.name, value: item.id } as SelectListOption));
}

export default getFlightDefinitonDestinationGroups;
