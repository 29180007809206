import React from 'react';
import { useDispatch } from 'react-redux';
import { Tooltip } from 'antd';
import { FormattedMessage } from 'react-intl';
import FontAwesomeIcon from 'comp/wraps/faIcon/FAIcon';
import { FlightDefinitionRulesModel } from 'common/dataService';
import ConfirmationButton from 'comp/wraps/confirmationButton/ConfirmationButton';
import deleteDefinintionRules from 'routes/tourOperator/flightCache/flightDefinitionRules/manager/deleteDefinitionRules';

function renderDeleteCell(record: FlightDefinitionRulesModel): JSX.Element {
  return <DeleteFlightDefinitionRule record={record} />;
}

export default renderDeleteCell;

type FlightDefinitionRulesModelProp = {
  record: FlightDefinitionRulesModel;
};

function DeleteFlightDefinitionRule({ record }: FlightDefinitionRulesModelProp): JSX.Element {
  const dispatch = useDispatch();

  function onSubmit() {
    deleteDefinintionRules(dispatch, record);
  }

  return (
    <Tooltip title={<FormattedMessage id='delete' />}>
      <ConfirmationButton
        buttonProps={{ ghost: true, disabled: false, danger: true, icon: <FontAwesomeIcon icon='times' /> }}
        alertMessage={<FormattedMessage id='flightDefinitionRule.delete' />}
        onSubmit={onSubmit}
        visibleForm={false}
      />
    </Tooltip>
  );
}
