import type { ColumnsType } from 'antd/lib/table';
import type { TableColumnConfig } from '../types/columnConfigTypes';

function getSelectedRowsKeys<T>(columns: ColumnsType<T>, tableColumnConfigs?: TableColumnConfig[] | null): string[] {
  if (!tableColumnConfigs) {
    return columns.map((item) => {
      if (item.key && item.key.toString) {
        return item.key.toString();
      }

      return '';
    });
  }

  return tableColumnConfigs.filter((item) => item.visible).map((item) => item.key);
}

export default getSelectedRowsKeys;
