import HttpClient from 'httpClient/index';
import type { GetPromocodesRequestParameters, Promocode } from '../types/promocodesTypes';

const BASE_URL = `${process.env.REACT_APP_DATA_API || ''}/api/promocodes`;

class DataServicePromocodes extends HttpClient {
  public constructor() {
    super(BASE_URL);
  }

  public getPromocodes = (params?: GetPromocodesRequestParameters): Promise<Promocode[]> =>
    this.instance.get('', { params });

  public getRefundPromoCodes = (): Promise<Promocode[]> =>
    this.instance.get('/refund');
}

export default new DataServicePromocodes();
