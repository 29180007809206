import type { RootState } from 'state';
import type { Enum } from 'routes/tourOperator/bookings/types/configs/enumsTypes';

function getAirportsFromAsConfigEnum(state: RootState): Enum | undefined {
  const { airportsFrom } = state.flightRoutes.filtersOptions;

  if (airportsFrom) {
    const values = Object.keys(airportsFrom)
      .sort((a, b) => a.localeCompare(b))
      .map((item) => ({ key: item, value: item }));

    return {
      name: 'AirportsFrom',
      values,
    };
  }
}

export default getAirportsFromAsConfigEnum;
