import React from 'react';
import LargeDevices from './comp/largeDevices';
import SmallDevices from './comp/smallDevices';
import filters from './comp/filters';

type FiltersListProps = {
  isLargeDevice: boolean;
};

function FiltersList({ isLargeDevice }: FiltersListProps): JSX.Element {
  if (isLargeDevice) {
    return <LargeDevices filters={filters} />;
  }

  return <SmallDevices filters={filters} />;
}

export default FiltersList;
