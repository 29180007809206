import type { AppDispatch } from 'state';
import { resetState as resetFiltersOptions } from '../reducers/options/slice';
import { resetState as resetFiltersValues } from '../reducers/values/slice';

function resetFiltersView(dispatch: AppDispatch): void {
  dispatch(resetFiltersOptions());
  dispatch(resetFiltersValues());
}

export default resetFiltersView;
