import { combineReducers } from '@reduxjs/toolkit';
import affiliates from './affiliates';
import airports from './airports';
import destinations from './destinations';
import productProviders from './productProviders';
import promocodes from './promocodes';
import staticData from './staticData';
import websites from './websites';
import affiliatesFull from './affiliatesFull';
import websitesFull from './websitesFull';
import refundPromoCodes from './refundPromoCodes';

export default combineReducers({
  affiliates,
  affiliatesFull,
  airports,
  destinations,
  productProviders,
  promocodes,
  staticData,
  websites,
  websitesFull,
  refundPromoCodes
});
