import type { FormAction, FormListDeleteCallback } from 'comp/screenBuilder/comp/formBuilder';
import React, { useContext, useEffect } from 'react';
import styled from 'styled-components/macro';
import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import { InnerContext, OuterContext } from 'comp/screenBuilder/context';
import { defaultAction } from 'comp/screenBuilder/comp/formBuilder';
import usePrevious from 'comp/hooks/usePrevious';

type ActionButtonsProps = {
  action: FormAction;
  lastFieldIndex?: number;
  onClickActionButton: (
    arg1: React.MouseEvent<HTMLElement, MouseEvent>,
    arg2?: number,
    arg3?: FormListDeleteCallback
  ) => Promise<void>;
  onClickDismissCallback?: FormListDeleteCallback;
};

function ActionButtons({
  action,
  lastFieldIndex,
  onClickActionButton,
  onClickDismissCallback,
}: ActionButtonsProps): JSX.Element | null {
  const { getInnerRef } = useContext(InnerContext);
  const { callbacks } = useContext(OuterContext);
  const hideActionButtons = action.type === defaultAction.type || !action.buttons;
  const prevHideActionButtons = usePrevious(hideActionButtons);

  useEffect(() => {
    if (hideActionButtons !== prevHideActionButtons) {
      if (typeof callbacks?.onClickActionButton === 'function' && typeof getInnerRef === 'function') {
        callbacks.onClickActionButton(getInnerRef());
      }
    }
  }, [callbacks, getInnerRef, hideActionButtons, prevHideActionButtons]);

  async function onClick(event: React.MouseEvent<HTMLElement, MouseEvent>) {
    event.stopPropagation();
    await onClickActionButton(event, lastFieldIndex, onClickDismissCallback);
  }

  if (hideActionButtons || !action.buttons) {
    return null;
  }

  return (
    <Root>
      {action.buttons.map((button) => {
        const messageId = `formBuilder.action.${action.type}.button.${button.type}`;

        return (
          <Button
            key={messageId}
            size='middle'
            data-action-type={action.type}
            data-button-type={button.type}
            {...button.button}
            onClick={onClick}
          >
            <FormattedMessage id={messageId} />
          </Button>
        );
      })}
    </Root>
  );
}

export default ActionButtons;

const Root = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;

  > *:not(:last-child) {
    margin-right: 8px;
  }
`;
