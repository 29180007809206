/* eslint-disable no-template-curly-in-string */
import type { Method, AxiosRequestConfig } from 'axios';
import { matchPath } from 'react-router';
import updateWithValues from 'utils/string/updateWithValues';

type PathMapType = Record<string, Record<string, Record<string, MapObject>>>;

type MapObject = {
  message: string;
  useHttpMethod?: boolean;
  values?: Record<string, string[]>;
};

const bookingsApi = process.env.REACT_APP_BOOKINGS_API ?? '';

const PathMap: PathMapType = {
  [bookingsApi]: {
    '/manage/booking': {
      '/:bookingCode/transactions': {
        useHttpMethod: true,
        message: '{product} transaction',
        values: {
          product: ['transactionInfo', 'product'],
        },
      },
      '/:bookingCode/transactions/:transactionInfoId/changeIsActive': {
        message: 'Change transaction IsActive',
      },
      '/futurePayments/create': {
        message: 'Create future payment',
      },
      '/futurePayments/deactivate?futurePaymentId=:futurePaymentId': {
        message: 'Deactivate future payment',
      },
      '/travellers/:bookingCode': {
        message: 'Create booking traveller',
      },
      '/travellers?travellerId=:travellerId': {
        message: 'Delete booking traveller',
      },
      '/travellers': {
        message: 'Update booking traveller',
      },
    },
    '/bookings': {
      '/deactivatePaymentRule?paymentRuleId=:paymentRuleId': {
        message: 'Deactivate future payment',
      },
      '/customer/:bookingCode': {
        message: 'Customer info was changed',
      },
    },
    '/rebook': {
      '': {
        message: 'Rebook transaction',
      },
    },
  },
};

const HttpMethodMap: Partial<Record<Method, string>> = {
  get: 'Get',
  post: 'Create',
  put: 'Update',
  delete: 'Delete',
};

function extendAuditMessage(config: AxiosRequestConfig): AxiosRequestConfig {
  try {
    const { baseURL, url, method, headers } = config;

    if (baseURL === undefined || url === undefined) {
      return config;
    }

    const [apiUrl, apiEndpoint] = baseURL.split('/api');

    if (apiUrl === undefined || apiEndpoint === undefined) {
      return config;
    }

    if (PathMap?.[apiUrl]?.[apiEndpoint] === undefined) {
      return config;
    }

    const apiEndpointMethods = Object.keys(PathMap?.[apiUrl]?.[apiEndpoint]);
    const match = matchPath(url, {
      path: apiEndpointMethods,
      exact: true,
      strict: false,
    });

    if (match === null) {
      return config;
    }

    const mapObject = PathMap?.[apiUrl]?.[apiEndpoint]?.[match.path];

    if (mapObject === undefined) {
      return config;
    }

    const { useHttpMethod, values } = mapObject;
    let { message } = mapObject;

    if (values && config.data) {
      message = updateWithValues(message, config.data, values);
    }

    if (useHttpMethod) {
      message = `${HttpMethodMap[method ?? 'get'] ?? ''} ${message}`;
    }

    if (headers) {
      if (headers.auditmessage) {
        headers.auditmessage = `${message}. ${headers.auditmessage}`;
      } else {
        headers.auditmessage = `${message}.`;
      }
    }

    return config;
  } catch (error) {
    console.error(error);
    return config;
  }
}

export default extendAuditMessage;
