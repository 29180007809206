import type { ColumnsType } from 'antd/lib/table';
import type { FlightCalendar } from 'common/packageCache';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import renderFlightInfoCell from './tableCells/renderFlightInfoCell';
import renderPriceCell from './tableCells/renderPriceCell';
import renderLastSeenAtCell from './tableCells/renderLastSeenAtCell';

const columns: ColumnsType<FlightCalendar> = [
  {
    key: 'carrier',
    title: <FormattedMessage id='carrier.short' />,
    dataIndex: 'carrier',
  },
  {
    key: 'source',
    title: <FormattedMessage id='source' />,
    dataIndex: 'source',
  },
  {
    key: 'flightInfo',
    title: <FormattedMessage id='flightInfo' />,
    render: renderFlightInfoCell,
  },
  {
    key: 'price',
    title: <FormattedMessage id='price' />,
    render: renderPriceCell,
  },
  {
    key: 'lastSeen',
    title: <FormattedMessage id='lastSeen' />,
    render: renderLastSeenAtCell,
  },
];

export default columns;
