import React from 'react';
import OuterContext from './context';
import type { OuterContextShape } from './context';

type OuterContextProviderProps = {
  children: JSX.Element;
} & OuterContextShape;

function OuterContextProvider({ children, ...props }: OuterContextProviderProps): JSX.Element {
  return <OuterContext.Provider value={props}>{children}</OuterContext.Provider>;
}

export default OuterContextProvider;
