import type { AppThunk } from 'state';
import FetchingStatus from 'constants/statuses/FetchingStatus';
import handleRequestError from 'managers/handleRequestError';
import { setUpdating, setUpdatingKey } from '../slice';
import { DataServiceFlightDefinitionGroups, FlightDefinitionGroupsModel } from 'common/dataService';

function addFlightDefinitionGroups(requestParameters: FlightDefinitionGroupsModel): AppThunk {
  return async function addFlightDefinitionGroupsThunk(dispatch, getState) {
    try {
      const { updating } = getState().flightDefinitionGroups;

      if (updating === FetchingStatus.InProgress) {
        return;
      }

      dispatch(setUpdatingKey('create'));
      dispatch(setUpdating(FetchingStatus.InProgress));

      await DataServiceFlightDefinitionGroups.postFlightDefinitionGroups(requestParameters);

      dispatch(setUpdating(FetchingStatus.Succeeded));
    } catch (error) {
      dispatch(handleRequestError(error, 'Error occured on saving flight definition route!', setUpdating));
    }
  };
}

export default addFlightDefinitionGroups;
