import type { AppThunk } from 'state';
import FetchingStatus from 'constants/statuses/FetchingStatus';
import { DataServiceStaticRoutes } from 'common/dataService/services';
import { setFetching, setStaticResponseFunction } from '../slice';
import { StaticRouteParams } from 'common/dataService/types/staticRouteTypes';
import handleRequestError from 'managers/handleRequestError';

function fetchStaticRoutes(params: StaticRouteParams): AppThunk {
  return async function fetchStaticRoutes(dispatch, getState) {
    try {
      const { fetching } = getState().flightDefinitionGroups;

      if (fetching === FetchingStatus.InProgress) {
        return;
      }

      dispatch(setFetching(FetchingStatus.InProgress));

      const data = await DataServiceStaticRoutes.getStaticRoutes(params);

      if (!data) {
        dispatch(setFetching(FetchingStatus.Failed));
        return;
      }

      dispatch(setStaticResponseFunction(data));
      dispatch(setFetching(FetchingStatus.Succeeded));
    } catch (error) {
      dispatch(handleRequestError(error, 'Error occured on fetch static routes!', setFetching));
    }
  };
}

export default fetchStaticRoutes;
