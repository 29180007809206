import type { AppThunk } from 'state';
import DataServiceProductProviders from 'common/dataService/services/productProviders';
import FetchingStatus from 'constants/statuses/FetchingStatus';
import { setFetching, setFlightProviders } from '../slice';

function fetchFlightProviders(): AppThunk {
  return async function fetchFlightProvidersThunk(dispatch, getState) {
    try {
      const { fetching } = getState().dataService.productProviders.flightProviders;

      if (fetching === FetchingStatus.InProgress) {
        return;
      }

      dispatch(setFetching(FetchingStatus.InProgress));

      const data = await DataServiceProductProviders.getFlightProviders();

      if (!data) {
        dispatch(setFetching(FetchingStatus.Failed));
        return;
      }

      dispatch(setFlightProviders(data));
      dispatch(setFetching(FetchingStatus.Succeeded));
    } catch (error) {
      dispatch(setFetching(FetchingStatus.Failed));
      window.Rollbar.error(error);
    }
  };
}

export default fetchFlightProviders;
