import type { WebDestination } from 'common/dataService/types/destinationsTypes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import FetchingStatus from 'constants/statuses/FetchingStatus';

export type WebDestinationsState = {
  fetching: FetchingStatus | null;
  value: WebDestination[] | null;
};

const initialState: WebDestinationsState = {
  fetching: null,
  value: null,
};

function setFetchingFunction(state: WebDestinationsState, action: PayloadAction<WebDestinationsState['fetching']>) {
  return {
    ...state,
    fetching: action.payload,
  };
}

function setWebDestinationsFunction(state: WebDestinationsState, action: PayloadAction<WebDestinationsState['value']>) {
  return {
    ...state,
    value: action.payload,
  };
}

const slice = createSlice({
  name: 'dataService.destinations.webDestinations',
  initialState,
  reducers: {
    setFetching: setFetchingFunction,
    setWebDestinations: setWebDestinationsFunction,
  },
});

export const { setFetching, setWebDestinations } = slice.actions;
export default slice.reducer;
