import Router from './Router';
import {
  PATH_DASHBOARD,
  PATH_TOUR_OPERATOR_BOOKINGS,
  PATH_TOUR_OPERATOR_BOOKINGS_OLD,
  PATH_TOUR_OPERATOR_FLIGHT_CALENDARS,
} from './constants/routePaths';
import type { RoutesParams } from './types';
import type { RouteComponentPropsType } from './routes';

export default Router;
export {
  PATH_DASHBOARD,
  PATH_TOUR_OPERATOR_BOOKINGS,
  PATH_TOUR_OPERATOR_BOOKINGS_OLD,
  PATH_TOUR_OPERATOR_FLIGHT_CALENDARS,
};
export type { RoutesParams };
export type { RouteComponentPropsType };
