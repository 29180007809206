import type { FormFieldProps } from 'comp/screenBuilder/comp/formBuilder/types/formPropsTypes';
import React, { useState } from 'react';
import { Form } from 'antd';
import type { AffixesNames, AffixesFieldProps } from './types';

function withAffixesProvider(affixesNames: AffixesNames) {
  return function AffixesProvider(WrappedComponent: React.ComponentType<FormFieldProps & AffixesFieldProps>) {
    return function WithProps(props: FormFieldProps): JSX.Element {
      const [item1Value, setItem1Value] = useState<string | number>();
      const [item2Value, setItem2Value] = useState<string | number>();

      return (
        <Form.Item required={false} name={affixesNames.item1Name} valuePropName='item1'>
          <PrefixValue setItem1Value={setItem1Value}>
            <Form.Item required={false} name={affixesNames.item2Name} valuePropName='item2'>
              <SuffixValue setItem2Value={setItem2Value}>
                <AfixValue
                  item1={item1Value}
                  item2={item2Value}
                  wrappedComponentProps={props}
                  WrappedComponent={WrappedComponent}
                />
              </SuffixValue>
            </Form.Item>
          </PrefixValue>
        </Form.Item>
      );
    };
  };
}

export default withAffixesProvider;

type PrefixValueProps = {
  item1?: string;
  setItem1Value: React.Dispatch<React.SetStateAction<string | number | undefined>>;
  children: JSX.Element;
};

function PrefixValue({ item1, setItem1Value, children }: PrefixValueProps): JSX.Element {
  setItem1Value(item1);
  return children;
}

type SuffixValueProps = {
  item2?: string;
  setItem2Value: React.Dispatch<React.SetStateAction<string | number | undefined>>;
  children: JSX.Element;
};

function SuffixValue({ item2, setItem2Value, children }: SuffixValueProps): JSX.Element {
  setItem2Value(item2);
  return children;
}

type AfixValueProps = {
  item1?: string | number;
  item2?: string | number;
  preview?: boolean;
  wrappedComponentProps: FormFieldProps;
  WrappedComponent: React.ComponentType<FormFieldProps & AffixesFieldProps>;
};

function AfixValue({ item1, item2, wrappedComponentProps, WrappedComponent }: AfixValueProps): JSX.Element {
  return <WrappedComponent item1={item1} item2={item2} {...wrappedComponentProps} />;
}
