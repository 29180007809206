import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { useSelector, useDispatch } from 'react-redux';
import Table from 'comp/wraps/table';

import { Button, notification } from 'antd';
import { RootState } from 'state';
import FetchingStatus from 'constants/statuses/FetchingStatus';

import usePrevious from 'comp/hooks/usePrevious';
import { FormattedMessageWithI18n } from 'i18n';
import getStaticRoutes from '../reducers/selectors/getStaticRoutes';
import columns from './tableConfig/columns';
import AddStaticRoute from './AddStaticRoute';
import { setVisibleModule } from '../reducers/slice';
import getStaticRoutesVisibleModel from '../reducers/selectors/getstaticRoutesVisibleModel';
import executeFetchStaticRoutes from '../managers/execFetchStaticRoutes';
import getStaticRoutesFetching from '../reducers/selectors/getStaticRoutesFetching';
import { TablePaginationConfig } from 'antd/lib/table';
import { StaticRouteModel } from 'common/dataService/types/staticRouteTypes';
import { SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface';
import updateSortingAndLoadResults from '../managers/updateSortingAndLoadResults';
import SearchStaticRoute from './SearchStaticRoute';

function updateOrderForBackend(order: string): string {
  if (order === 'ascend') {
    return 'asc';
  }
  return 'desc';
}

function StaticRoutesTable() {
  const { updating, updatingKey } = useSelector((state: RootState) => state.staticRoutes);
  const prevUpdating = usePrevious(updating);
  const visibleModule = useSelector(getStaticRoutesVisibleModel);
  const dataSource = useSelector(getStaticRoutes);
  const [staticRoutes, setStaticRoutes] = useState(dataSource);
  const loading = useSelector(getStaticRoutesFetching);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(executeFetchStaticRoutes);
  }, []);

  useEffect(() => {
    if (prevUpdating === FetchingStatus.InProgress && updating === FetchingStatus.Succeeded) {
      notification.info({
        message: <FormattedMessageWithI18n id={`staticRoutes.${updatingKey}.succeeded.title`} />,
      });
    }
  }, [updating, prevUpdating]);

  useEffect(() => {
    setStaticRoutes(dataSource);
  }, [dataSource]);

  function handleOnSearch(value: string) {
    if (value) {
      const filteredStaticRoutes = dataSource.filter(
        (x) => x.origin.toLowerCase() === value.toLowerCase() || x.destination.toLowerCase() === value.toLowerCase()
      );
      setStaticRoutes(filteredStaticRoutes);
    } else {
      setStaticRoutes(dataSource);
    }
  }

  function handleOnChange(
    paggination: TablePaginationConfig,
    filters: Record<string, React.ReactText[] | null>,
    sorter: SorterResult<StaticRouteModel> | SorterResult<StaticRouteModel>[],
    extra: TableCurrentDataSource<StaticRouteModel>
  ) {
    if (extra.action === 'sort') {
      const { columnKey, order } = sorter as SorterResult<StaticRouteModel>;
      const sorting = order === undefined ? null : `${columnKey as string} ${updateOrderForBackend(order as string)}`;
      dispatch(updateSortingAndLoadResults(sorting));
    }
  }

  return (
    <Root>
      <SearchStaticRoute handleSearch={(value: string) => handleOnSearch(value)} />
      <Button onClick={() => dispatch(setVisibleModule(!visibleModule))} style={{ marginBottom: 20 }}>
        Add New Route
      </Button>
      <AddStaticRoute />
      <Table
        columns={columns}
        dataSource={staticRoutes}
        pagination={false}
        loading={loading}
        configuration={{ tableName: 'StaticRouteTable' }}
        onChange={handleOnChange}
      />
    </Root>
  );
}

export default StaticRoutesTable;

const Root = styled.div``;
