import type { AppDispatch } from 'state';
import { setPage } from '../reducers/DefinitionRules/slice';
import executeFetchDefinitionRules from './executeFetchDefinitionRules';

function updatePageAndLoadResults(page: number) {
  return async function updatePageAndLoadResultsThunk(dispatch: AppDispatch): Promise<void> {
    dispatch(setPage(page));
    await dispatch(executeFetchDefinitionRules);
  };
}

export default updatePageAndLoadResults;
