import { combineReducers } from '@reduxjs/toolkit';
import websites from './websites/slice';
import loadWebsites from './websites/thunks/loadWebsites';
import isFetchingWebsitesInProgress from './websites/selectors/isFetchingInProgress';

export { loadWebsites };
export { isFetchingWebsitesInProgress };

export default combineReducers({
  websites,
});
