import type { AppDispatch } from 'state';
import { setPage } from '../reducers/slice';
import executeFetchStaticRoutes from './execFetchStaticRoutes';

function updatePageAndLoadResults(page: number) {
  return async function updatePageAndLoadResultsThunk(dispatch: AppDispatch): Promise<void> {
    dispatch(setPage(page));
    await dispatch(executeFetchStaticRoutes);
  };
}

export default updatePageAndLoadResults;
