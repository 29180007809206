import type { RootState } from 'state';
import type { GetAirportGroupingsResponse } from 'common/dataService';

function getAirportGroupings(
  state: RootState
): [GetAirportGroupingsResponse | null, GetAirportGroupingsResponse | null] {
  const { departureGroupings, destinationGroupings } = state.dataService.airports.airportGroupings;
  return [departureGroupings, destinationGroupings];
}

export default getAirportGroupings;
