import HttpClient from 'httpClient/index';
import type { CountriesResponse } from '../types/countriesTypes';

const BASE_URL = `${process.env.REACT_APP_DATA_API || ''}/api/staticData`;

class DataServiceStaticData extends HttpClient {
  public constructor() {
    super(BASE_URL);
  }

  public getLanguages = (): Promise<string[]> => this.instance.get('/languages');

  public getCountries = (): Promise<CountriesResponse[]> => this.instance.get('/countries');
}

export default new DataServiceStaticData();
