import type { AppDispatch } from 'state';
import { setSorting } from 'routes/tourOperator/flightCache/flightRoutes/comp/flightRoutesFilters';
import updatePageAndLoadResults from './updatePageAndLoadResults';

function updateSortingAndLoadResults(sorting: string | null) {
  return async function updateSortingAndLoadResultsThunk(dispatch: AppDispatch): Promise<void> {
    dispatch(setSorting(sorting));
    await dispatch(updatePageAndLoadResults(1));
  };
}

export default updateSortingAndLoadResults;
