import type { RootState } from 'state';
import type { Module } from 'types/user/userTypes';

function getPermittedModulesIds(state: RootState): Module['id'][] {
  if (state.auth.currentUser === null || !state.auth.authenticated) {
    return [];
  }

  return state.auth.currentUser.modules.map(({ id }) => id);
}

export default getPermittedModulesIds;
