import type { SelectValue } from 'antd/lib/select/index';
import type { RoutesFiltersState } from 'routes/tourOperator/flightCache/flightRoutes/comp/flightRoutesFilters';
import React from 'react';
import styled from 'styled-components/macro';
import { useSelector, useDispatch } from 'react-redux';
import { Select as AntdSelect } from 'antd';
import { FormattedMessage } from 'react-intl';
import {
  setCarrier,
  loadAndSetCarriers,
  getCarrierValue,
  getCarrierOptions,
  isFetchingCarrierInProgress,
} from 'routes/tourOperator/flightCache/flightRoutes/comp/flightRoutesFilters';
import LabeledValue from 'comp/dataPresentation/labeledValue/LabeledValue';
import mediaQuery from 'styles/utils/mediaQuery';

function Carrier(): JSX.Element {
  const options = useSelector(getCarrierOptions);
  const value = useSelector(getCarrierValue);
  const loading = useSelector(isFetchingCarrierInProgress);
  const dispatch = useDispatch();

  function handleOnChange(option: SelectValue) {
    if (option === undefined) {
      dispatch(setCarrier(null));
    } else {
      dispatch(setCarrier(option as RoutesFiltersState['carrier']));
    }
  }

  function handleOnFocus() {
    if (!options) {
      dispatch(loadAndSetCarriers());
    }
  }

  return (
    <LabeledValue label={{ id: 'carrier' }}>
      <Select
        allowClear
        showSearch
        listHeight={640}
        loading={loading}
        placeholder={<FormattedMessage id='carrier.placeholder' />}
        options={options}
        value={value || undefined}
        onFocus={handleOnFocus}
        onChange={handleOnChange}
      />
    </LabeledValue>
  );
}

export default Carrier;

const Select = styled(AntdSelect)`
  width: 100%;

  ${mediaQuery.fromAndAbove.medium`
    width: 200px;
  `}
`;
