import type { FormListFieldData } from 'antd/lib/form/FormList';
import type { FormConfigField, FormAction } from 'comp/screenBuilder/comp/formBuilder';
import React, { useContext } from 'react';
import { actionTypes } from 'comp/screenBuilder/comp/formBuilder';
import AutoComplete from './fields/autocomplete/Autocomplete';
import Checkbox from './fields/checkbox/Checkbox';
import CheckboxPreview from './fields/checkbox/CheckboxPreview';
import withAffixesProvider from './fields/affixProvider/withAffixesProvider';
import getAffixesNames from './fields/affixProvider/getAffixesNames';
import Currency from './fields/currency/Currency';
import CurrencyPreview from './fields/currency/CurrencyPreview';
import withCurrencyProvider from './fields/currency/withCurrencyProvider';
import DateTime from './fields/dateTime/DateTime';
import DateTimePreview from './fields/dateTime/DateTimePreview';
import Default from './fields/default/Default';
import DefaultPreview from './fields/default/DefaultPreview';
import HtmlPreview from './fields/html/HtmlPreview';
import ImagePreview from './fields/image/ImagePreview';
import Number from './fields/number/Number';
import Select from './fields/select/Select';
import SelectPreview from './fields/select/SelectPreview';
import SelectWithInput from './fields/selectWithInput/SelectWithInput';
import Time from './fields/time/Time';
import TimePreview from './fields/time/TimePreview';
import UrlPreview from './fields/url/UrlPreview';
import Textarea from './fields/textarea/Textarea';
import FormFieldContext from './context';

type FormFieldProps = {
  field: FormConfigField;
  formListField?: FormListFieldData;
  removeFormItemLabel?: boolean;
  activeAction: FormAction;
};

function FormField({ field, formListField, removeFormItemLabel, activeAction }: FormFieldProps): JSX.Element {
  const formFieldName = formListField ? [formListField.name, ...field.formItem.name] : field.formItem.name;
  const fieldProps = { field, formFieldName, removeFormItemLabel };
  const affixesNames = getAffixesNames(formListField, field.formInput.context);
  const formFieldContext = useContext(FormFieldContext);

  if ((activeAction.type === actionTypes.CREATE || activeAction.type === actionTypes.UPDATE) && !field.previewOnly) {
    switch (field.formInput.type) {
      case 'autocomplete':
        return <AutoComplete {...fieldProps} />;

      case 'checkbox':
        return <Checkbox {...fieldProps} />;

      case 'currency': {
        const currencyName =
          formListField && field.formInput.context
            ? [formListField.name, ...(field.formInput.context?.currency ?? [])]
            : field.formInput.context?.currency;

        if (field.formInput.context?.fieldKey && formFieldContext[field.formInput.context?.fieldKey]?.defaultCurrency) {
          return <Currency {...fieldProps} />;
        }

        if (currencyName) {
          const CurrencyComponent = withCurrencyProvider(currencyName)(Currency);
          return <CurrencyComponent {...fieldProps} />;
        }

        console.error('Missing currency path for currency field!');
        return <Number {...fieldProps} />;
      }

      case 'datetime':
        return <DateTime {...fieldProps} />;

      case 'number':
        return <Number {...fieldProps} />;

      case 'select':
        return <Select {...fieldProps} />;

      case 'selectWithInput':
        return <SelectWithInput {...fieldProps} />;

      case 'time':
        return <Time {...fieldProps} />;

      case 'textarea':
        return <Textarea {...fieldProps} />;

      default:
        return <Default {...fieldProps} />;
    }
  }

  switch (field.formInput.type) {
    case 'checkbox':
      return <CheckboxPreview {...fieldProps} />;

    case 'currency': {
      const currencyName =
        formListField && field.formInput.context
          ? [formListField.name, ...(field.formInput.context?.currency ?? [])]
          : field.formInput.context?.currency;

      if (field.formInput.context?.fieldKey && !currencyName) {
        return <CurrencyPreview {...fieldProps} />;
      }

      if (currencyName) {
        let TempComponent = CurrencyPreview;

        if (affixesNames) {
          TempComponent = withAffixesProvider(affixesNames)(TempComponent);
        }

        const CurrencyPreviewComponent = withCurrencyProvider(currencyName, true)(TempComponent);
        return <CurrencyPreviewComponent {...fieldProps} />;
      }

      console.error('Missing currency path for currency field!');
      return <DefaultPreview {...fieldProps} />;
    }

    case 'datetime':
      return <DateTimePreview {...fieldProps} />;

    case 'html':
      return <HtmlPreview {...fieldProps} />;

    case 'image':
      return <ImagePreview {...fieldProps} />;

    case 'select':
      return <SelectPreview {...fieldProps} />;

    case 'time':
      return <TimePreview {...fieldProps} />;

    case 'url':
      return <UrlPreview {...fieldProps} />;

    default: {
      if (affixesNames) {
        const DefaultPreviewComponent = withAffixesProvider(affixesNames)(DefaultPreview);
        return <DefaultPreviewComponent {...fieldProps} />;
      }

      return <DefaultPreview {...fieldProps} />;
    }
  }
}

export default FormField;
