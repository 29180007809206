import type { FormFieldProps } from 'comp/screenBuilder/comp/formBuilder/types/formPropsTypes';
import React from 'react';
import { Form, Checkbox as AntdCheckbox } from 'antd';
import { FormattedMessage } from 'react-intl';

function Checkbox({ field, formFieldName, removeFormItemLabel }: FormFieldProps): JSX.Element {
  const { formItem } = field;
  const { label, ...formItemProps } = formItem;

  return (
    <Form.Item {...formItemProps} name={formFieldName}>
      <AntdCheckbox>{!removeFormItemLabel && <FormattedMessage {...label} />}</AntdCheckbox>
    </Form.Item>
  );
}

export default Checkbox;
