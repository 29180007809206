import type { SelectValue } from 'antd/lib/select/index';
import type { FormAction, FormListCreateCallback, FormListDeleteCallback } from 'comp/screenBuilder/comp/formBuilder';
import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import { Select as AntdSelect } from 'antd';
import { actionTypes } from 'comp/screenBuilder/comp/formBuilder';
import type { ListDropdownType } from './types';

type ListDropdownProps<T> = {
  listDropdown: ListDropdownType<T>;
  activeAction: FormAction;
  onClickCreateCallback: FormListCreateCallback;
  onClickDeleteCallback: FormListDeleteCallback;
  onClickDispatch: (
    SelectValue: SelectValue | undefined,
    FormListCreateCallback?: FormListCreateCallback,
    FormListDeleteCallback?: FormListDeleteCallback
  ) => void;
};

function ListDropdown<T>({
  listDropdown,
  activeAction,
  onClickCreateCallback,
  onClickDeleteCallback,
  onClickDispatch,
}: ListDropdownProps<T>): JSX.Element | null {
  const filteredOptions = listDropdown.getFilteredOptions();
  const selectedOption = listDropdown.getSelectedOption();
  const optionsAreMissing = !filteredOptions || (filteredOptions && filteredOptions.length === 0);

  useEffect(() => {
    if (optionsAreMissing) {
      listDropdown.setSelectedOption(undefined);
      return;
    }

    if (
      !selectedOption ||
      (selectedOption && filteredOptions.map((item) => item.value).indexOf(selectedOption?.value) === -1)
    ) {
      setTimeout(() => {
        listDropdown.setSelectedOption(filteredOptions[0]);
      }, 0);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionsAreMissing, filteredOptions]);

  function handleOnChange(value: SelectValue) {
    // WHEN the active action is create AND you change the dropdown value
    // THEN we are not changing the selected dropdown option,
    // instead we copy the values and set them to the current option.
    onClickDispatch(value);
    if (activeAction.type === actionTypes.CREATE) {
      const selectedOptionForCopy = filteredOptions.find((option) => option.value === value);

      if (selectedOption && selectedOptionForCopy) {
        const optionKeys = Object.keys(selectedOptionForCopy.option);
        let optionForCopy;

        if (optionKeys.indexOf('travelerIds') !== -1) {
          optionForCopy = {
            ...selectedOptionForCopy.option,
            id: 0,
            travelerIds: [],
          };
        } else if (optionKeys.indexOf('upgrades') !== -1) {
          optionForCopy = {
            ...selectedOptionForCopy.option,
            id: 0,
            upgrades: [],
          };
        } else {
          optionForCopy = {
            ...selectedOptionForCopy.option,
            id: 0,
          };
        }

        onClickDeleteCallback(selectedOption.value);
        onClickCreateCallback(optionForCopy);
        listDropdown.replaceOptionAtIndex(selectedOption.value, optionForCopy);
      }

      return;
    }

    listDropdown.setSelectedOption(filteredOptions.find((option) => option.value === value));
  }

  if (optionsAreMissing) {
    return null;
  }

  return (
    <Select value={selectedOption?.value} size='middle' onChange={handleOnChange}>
      {filteredOptions.map(({ label, value }) => (
        <Select.Option key={value} value={value}>
          {label}
        </Select.Option>
      ))}
    </Select>
  );
}

export default ListDropdown;

const Select = styled(AntdSelect)`
  min-width: 160px;
`;
