import type { AppDispatch } from 'state';
import { setVisibleModule } from '../reducers/slice';
import createStaticRoutes from '../reducers/thunks/createStaticRoute';
import { StaticRouteModel } from 'common/dataService/types/staticRouteTypes';
import editStaticRoute from '../reducers/thunks/editStaticRoute';
import executeFetchStaticRoutes from './execFetchStaticRoutes';

async function upsertStaticRoutes(dispatch: AppDispatch, model: StaticRouteModel): Promise<void> {
  if (model?.id) {
    await dispatch(editStaticRoute(model));
  } else if (model) {
    await dispatch(createStaticRoutes(model));
  }
  dispatch(setVisibleModule(false));
  await dispatch(executeFetchStaticRoutes);
}

export default upsertStaticRoutes;
