import type { AppThunk } from 'state';
import type { GetFlightCalendarsRequestParameters } from 'common/packageCache';
import { PackageCacheFlightCalendarsService } from 'common/packageCache';
import FetchingStatus from 'constants/statuses/FetchingStatus';
import handleRequestError from 'managers/handleRequestError';
import transformFlightCalendarResponseToStateMap from '../transforms/transformFlightCalendarResponseToStateMap';
import { setFetching, updateFlightCalendarsOutboundData, updateFlightCalendarsInboundData } from '../slice';

function fetchFlightCalendars(
  requestsParameters: [GetFlightCalendarsRequestParameters, GetFlightCalendarsRequestParameters],
  updateKeys: [string, string]
): AppThunk {
  return async function fetchFlightCalendarsThunk(dispatch, getState) {
    try {
      const { fetching } = getState().flightCalendars.data;

      if (fetching === FetchingStatus.InProgress) {
        return;
      }

      dispatch(setFetching(FetchingStatus.InProgress));

      const data = await Promise.all([
        PackageCacheFlightCalendarsService.getFlightCalendars(requestsParameters[0]),
        PackageCacheFlightCalendarsService.getFlightCalendars(requestsParameters[1]),
      ]);

      if (!data) {
        dispatch(setFetching(FetchingStatus.Failed));
        return;
      }

      dispatch(
        updateFlightCalendarsOutboundData({
          key: updateKeys[0],
          value: transformFlightCalendarResponseToStateMap(data[0]),
        })
      );
      dispatch(
        updateFlightCalendarsInboundData({
          key: updateKeys[1],
          value: transformFlightCalendarResponseToStateMap(data[1]),
        })
      );
      dispatch(setFetching(FetchingStatus.Succeeded));
    } catch (error) {
      dispatch(handleRequestError(error, 'Error occured on fetch flight calendars!', setFetching));
    }
  };
}

export default fetchFlightCalendars;
