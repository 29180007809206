import type { Affiliate } from 'common/dataService/types/affiliatesTypes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import FetchingStatus from 'constants/statuses/FetchingStatus';

export type AffiliatesState = {
  fetching: FetchingStatus | null;
  value: Affiliate[] | null;
};

const initialState: AffiliatesState = {
  fetching: null,
  value: null,
};

function setFetchingFunction(state: AffiliatesState, action: PayloadAction<AffiliatesState['fetching']>) {
  return {
    ...state,
    fetching: action.payload,
  };
}

function setAffiliatesFunction(state: AffiliatesState, action: PayloadAction<AffiliatesState['value']>) {
  return {
    ...state,
    value: action.payload,
  };
}

const slice = createSlice({
  name: 'dataService.affiliates.affiliates',
  initialState,
  reducers: {
    setFetching: setFetchingFunction,
    setAffiliates: setAffiliatesFunction,
  },
});

export const { setFetching, setAffiliates } = slice.actions;
export default slice.reducer;
