import type { AppThunk } from 'state';
import FetchingStatus from 'constants/statuses/FetchingStatus';
import handleRequestError from 'managers/handleRequestError';
import { setFetching, setFlightDefinitionRulesResponse } from '../slice';
import { DataServiceFlightDefinitionRules, FlightDefinitionRulesRequest } from 'common/dataService';

function fetchFlightDefinitionRules(requestParameters: FlightDefinitionRulesRequest): AppThunk {
  return async function fetchFlightDefinitionRulesThunk(dispatch, getState) {
    try {
      const { fetching } = getState().flightDefinitionRules.definitionRules;

      if (fetching === FetchingStatus.InProgress) {
        return;
      }

      dispatch(setFetching(FetchingStatus.InProgress));

      const data = await DataServiceFlightDefinitionRules.getFlightDefinitionRules(requestParameters);

      if (!data) {
        dispatch(setFetching(FetchingStatus.Failed));
        return;
      }

      dispatch(setFlightDefinitionRulesResponse(data));
      dispatch(setFetching(FetchingStatus.Succeeded));
    } catch (error) {
      dispatch(handleRequestError(error, 'Error occured on fetch flight definition rules!', setFetching));
    }
  };
}

export default fetchFlightDefinitionRules;
