import { combineReducers } from '@reduxjs/toolkit';
import flightProviders from './flight/slice';
import hotelProviders from './hotel/slice';
import loadFlightProviders from './flight/thunks/loadFlightProviders';
import isFetchingFlightProvidersInProgress from './flight/selectors/isFetchingInProgress';
import loadHotelProviders from './hotel/thunks/loadHotelProviders';
import isFetchingHotelProvidersInProgress from './hotel/selectors/isFetchingInProgress';

export { loadFlightProviders };
export { isFetchingFlightProvidersInProgress };
export { loadHotelProviders };
export { isFetchingHotelProvidersInProgress };

export default combineReducers({
  flightProviders,
  hotelProviders,
});
