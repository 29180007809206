import type { DrawerProps as AntdDrawerProps } from 'antd/lib/drawer';
import React from 'react';
import styled from 'styled-components/macro';
import { Drawer as AntdDrawer, Spin } from 'antd';
import useDrawerWidth from './hooks/useDrawerWidth';

type DrawerProps = {
  spinning?: boolean;
  children: React.ReactNode;
} & AntdDrawerProps;

function Drawer({ spinning = false, children, width, ...props }: DrawerProps): JSX.Element {
  const drawerWidth = useDrawerWidth(width);

  function onClickRootElement(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    event.stopPropagation();
  }

  return (
    <ClickIntercept onClick={onClickRootElement}>
      <Root closable placement={'right' as const} width={drawerWidth} {...props}>
        <Spin spinning={spinning}>{children}</Spin>
      </Root>
    </ClickIntercept>
  );
}

export default Drawer;

const ClickIntercept = styled.div``;

const Root = styled(AntdDrawer)`
  .ant-drawer-footer {
    padding: 16px;
  }
`;
