import { css } from 'styled-components/macro';

export default css`
  ::-webkit-scrollbar {
    width: 4px;
    background-color: #c9c9c9;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.palette.secondary.darker[1]};
    border-radius: 6px;
  }
`;
