import type { Promocode } from 'common/dataService/types/promocodesTypes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import FetchingStatus from 'constants/statuses/FetchingStatus';

export type RefundPromoCodesState = {
  fetching: FetchingStatus | null;
  value: Promocode[] | null;
};

const initialState: RefundPromoCodesState = {
  fetching: null,
  value: null,
};

function setFetchingFunction(state: RefundPromoCodesState, action: PayloadAction<RefundPromoCodesState['fetching']>) {
  return {
    ...state,
    fetching: action.payload,
  };
}

function setRefundPromoCodesFunction(state: RefundPromoCodesState, action: PayloadAction<RefundPromoCodesState['value']>) {
  return {
    ...state,
    value: action.payload,
  };
}

const slice = createSlice({
  name: 'dataService.refundPromoCodes.refundPromoCodes',
  initialState,
  reducers: {
    setFetching: setFetchingFunction,
    setRefundPromoCodes: setRefundPromoCodesFunction
  },
});

export const { setFetching, setRefundPromoCodes } = slice.actions;
export default slice.reducer;
