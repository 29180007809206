import type { AppThunk } from 'state';
import { getFlightCalendarsByKey } from '../comp/flightCalendarsData';
import {
  getDurationValue,
  setDurationOptions,
  setDurationValue,
  transformFiltersValuesToKeys,
} from '../comp/flightCalendarsFilters';
import type { FlightCalendarsFiltersState } from '../comp/flightCalendarsFilters';

function updateDurationOptions(filtersValues: FlightCalendarsFiltersState): AppThunk {
  return async function updateDurationOptionsThunk(dispatch, getState): Promise<void> {
    const keys = transformFiltersValuesToKeys(filtersValues);
    const flightCalendars = getFlightCalendarsByKey(keys)(getState());

    if (flightCalendars.length > 0) {
      const [min, max] = flightCalendars.reduce(
        (prev, curr) => {
          const [min, max] = prev;
          const { flightDuration } = curr;

          if (flightDuration !== null && flightDuration < min) {
            return [flightDuration, max];
          }

          if (flightDuration !== null && flightDuration > max) {
            return [min, flightDuration];
          }

          return prev;
        },
        [999, 0]
      );

      dispatch(setDurationOptions({ min, max }));

      const duration = getDurationValue(getState());
      if (duration === null || duration === min) {
        dispatch(setDurationValue(max));
      }
    }
  };
}

export default updateDurationOptions;
