export const MQ_CONFIG_KEY_BELOW = 'below';
export const MQ_CONFIG_KEY_FROM_AND_BELOW = 'fromAndBelow';
export const MQ_CONFIG_KEY_ABOVE = 'above';
export const MQ_CONFIG_KEY_FROM_AND_ABOVE = 'fromAndAbove';

const configuration: MQConfigurationEntry[] = [
  { key: MQ_CONFIG_KEY_BELOW, modifier: -1 },
  { key: MQ_CONFIG_KEY_FROM_AND_BELOW, modifier: 0 },
  { key: MQ_CONFIG_KEY_ABOVE, modifier: 1 },
  { key: MQ_CONFIG_KEY_FROM_AND_ABOVE, modifier: 0 },
];

export default configuration;

export type MQConfigurationEntryKey =
  | typeof MQ_CONFIG_KEY_BELOW
  | typeof MQ_CONFIG_KEY_FROM_AND_BELOW
  | typeof MQ_CONFIG_KEY_ABOVE
  | typeof MQ_CONFIG_KEY_FROM_AND_ABOVE;

export type MQConfigurationEntry = {
  key: MQConfigurationEntryKey;
  modifier: number;
};
