import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import { Button as AntdButton, Drawer } from 'antd';
import { FormattedMessage } from 'react-intl';
import updatePageAndLoadResults from 'routes/tourOperator/flightCache/flightRoutes/comp/flightRoutesFilters/managers/updatePageAndLoadResults';
import type { FilterItem } from '../filters';

type SmallDevicesProps = {
  filters: FilterItem[];
};

function SmallDevices({ filters }: SmallDevicesProps): JSX.Element | null {
  const [visible, setVisible] = useState(false);

  function onClickOpenDrawer() {
    setVisible(true);
  }

  function onClickCloseDrawer() {
    setVisible(false);
  }

  return (
    <>
      <Button onClick={onClickOpenDrawer}>
        <FormattedMessage id='search' />
      </Button>
      <Drawer
        visible={visible}
        placement='right'
        closable
        destroyOnClose
        title={<FormattedMessage id='filters' />}
        onClose={onClickCloseDrawer}
        footer={<Footer onClickCloseDrawer={onClickCloseDrawer} />}
      >
        <Root>
          {filters.map(({ key, component: Component }) => (
            <Component key={key} />
          ))}
        </Root>
      </Drawer>
    </>
  );
}

export default SmallDevices;

const Root = styled.div`
  > *:not(:last-child) {
    margin-bottom: 16px;
  }
`;

const Button = styled(AntdButton)`
  min-width: 144px;
`;

type FooterProps = {
  onClickCloseDrawer: () => void;
};

function Footer({ onClickCloseDrawer }: FooterProps): JSX.Element {
  const dispatch = useDispatch();

  function onClickApplyFilters() {
    dispatch(updatePageAndLoadResults(1));
    onClickCloseDrawer();
  }

  return (
    <RootFooter>
      <Button onClick={onClickApplyFilters} type='primary'>
        <FormattedMessage id='applyFilters' />
      </Button>
    </RootFooter>
  );
}

const RootFooter = styled.div`
  display: flex;
  justify-content: flex-end;
`;
