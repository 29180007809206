import React from 'react';
import { Input } from 'antd';
import styled from 'styled-components';
import { SearchstaticRoutes } from 'common/dataService/types/staticRouteTypes';

function SearchStaticRoutes({ handleSearch }: SearchstaticRoutes): JSX.Element {
  return (
    <SearchWrapper>
      <Input.Search
        placeholder='Search static routes by origin or destination...'
        onSearch={handleSearch}
        enterButton
        style={{ width: '20vw', marginBottom: 20 }}
      />
    </SearchWrapper>
  );
}
export default SearchStaticRoutes;

const SearchWrapper = styled.div``;
