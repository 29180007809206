import HttpClient from 'httpClient/index';
import {
  FlightCacheDefinitionPeriodsPagedResult,
  FlightCacheDefinitionPeriodsRequest,
  FlightDefinitionRulesDates,
  FlightDefinitionRulesModel,
  FlightDefinitionRulesRequest,
  FlightDefinitonRulesPagedResult,
  RuleDatesRequest,
} from '../types/flightDefinintionRulesTypes';

const BASE_URL = `${process.env.REACT_APP_DATA_API || ''}/api/flightDefinitionRules`;

class DataServiceFlightDefinitionRules extends HttpClient {
  public constructor() {
    super(BASE_URL);
  }

  public getFlightDefinitionRules = (params: FlightDefinitionRulesRequest): Promise<FlightDefinitonRulesPagedResult> =>
    this.instance.get('', { params });

  public postFlightDefinitionRules = (body: FlightDefinitionRulesModel): Promise<void> => this.instance.post('/', body);

  public putFlightDefinitionRules = (body: FlightDefinitionRulesModel): Promise<void> => this.instance.put('/', body);

  public deleteFlightDefinitionRules = (ruleId: number): Promise<void> => this.instance.delete(`?ruleId=${ruleId}`);

  public getFlightDefinitionRuleDatesPreview = (params: RuleDatesRequest): Promise<FlightDefinitionRulesDates[]> =>
    this.instance.get('/ruleDates', { params });

  public getFlightDefinitionPeriods = (
    params: FlightCacheDefinitionPeriodsRequest
  ): Promise<FlightCacheDefinitionPeriodsPagedResult> => this.instance.get('/definitionPeriods', { params });

  public postRegeneratePeriods = (ruleId: number): Promise<number> =>
    this.instance.post(`/regeneratePeriods?ruleId=${ruleId}`);

  public postCountPreviewPeriods = (body: FlightDefinitionRulesModel): Promise<number> =>
    this.instance.post(`/countPreviewPeriods`, body);
}

export default new DataServiceFlightDefinitionRules();
