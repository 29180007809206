import React from 'react';
import { useIntl, IntlShape } from 'react-intl';
import { Spin } from 'antd';

type SpinnerProps = {
  useDefaultMessage?: boolean;
  messageId?: string;
};

function getSpinnerTip(
  intl: IntlShape,
  useDefaultMessage: SpinnerProps['useDefaultMessage'],
  messageId: SpinnerProps['messageId']
) {
  if (messageId) {
    return intl.formatMessage({ id: messageId });
  }

  if (useDefaultMessage) {
    return intl.formatMessage({ id: 'loading' });
  }

  return undefined;
}

function Spinner({ useDefaultMessage, messageId }: SpinnerProps): JSX.Element {
  const intl = useIntl();
  const tip = getSpinnerTip(intl, useDefaultMessage, messageId);

  return <Spin tip={tip} />;
}

export default Spinner;
