import type { RootState } from 'state';
import { StaticRouteModel } from 'common/dataService/types/staticRouteTypes';

function getStaticRoutes(state: RootState): StaticRouteModel[] {
  const { value } = state.staticRoutes;

  if (!value) return [];

  return value;
}

export default getStaticRoutes;
