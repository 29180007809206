import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import removeAtIndex from 'utils/immutability/removeAtIndex';
import type { EnumsByProduct, Payload } from './types/enumsFromValuesTypes';

export type EnumsFromValuesState = {
  fetching: string[];
  techPlatforms: EnumsByProduct | null;
  techTypes: EnumsByProduct | null;
};

const initialState: EnumsFromValuesState = {
  fetching: [],
  techPlatforms: null,
  techTypes: null,
};

function pushToFetchingFunction(state: EnumsFromValuesState, action: PayloadAction<string>) {
  return {
    ...state,
    fetching: [...state.fetching, action.payload],
  };
}

function removeFromFetchingFunction(state: EnumsFromValuesState, action: PayloadAction<string>) {
  const index = state.fetching.indexOf(action.payload);

  if (index === -1) {
    return state;
  }

  return {
    ...state,
    fetching: removeAtIndex(state.fetching, index),
  };
}

function setTechPlatformsFunction(state: EnumsFromValuesState, action: PayloadAction<Payload>) {
  const { product, value } = action.payload;

  return {
    ...state,
    techPlatforms: {
      ...state.techPlatforms,
      [product]: value,
    },
  };
}

function setTechTypesFunction(state: EnumsFromValuesState, action: PayloadAction<Payload>) {
  const { product, value } = action.payload;

  return {
    ...state,
    techTypes: {
      ...state.techTypes,
      [product]: value,
    },
  };
}

const slice = createSlice({
  name: 'enumsFromValues',
  initialState,
  reducers: {
    pushToFetching: pushToFetchingFunction,
    removeFromFetching: removeFromFetchingFunction,
    setTechPlatforms: setTechPlatformsFunction,
    setTechTypes: setTechTypesFunction,
  },
});

export const { pushToFetching, removeFromFetching, setTechPlatforms, setTechTypes } = slice.actions;
export default slice.reducer;
