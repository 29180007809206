import type { ColumnsType } from 'antd/lib/table';
import type { FlightDefinitionRulesModel } from 'common/dataService';
import moment from 'moment';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import renderDeleteCell from './tableCells/renderDeleteCell';
import renderEditCell from './tableCells/renderEditCell';
import renderRegeneratePeriodCell from './tableCells/renderRegeneratePeriodCell';
import renderViewCell from './tableCells/renderViewCell';

const columns: ColumnsType<FlightDefinitionRulesModel> = [
  {
    key: 'name',
    title: <FormattedMessage id='name' />,
    dataIndex: 'name',
    sorter: true,
  },
  {
    key: 'creationTime',
    title: <FormattedMessage id='creationTime' />,
    dataIndex: 'creationTime',
  },
  {
    key: 'originGroups',
    title: <FormattedMessage id='addRule.originGroupsColumn' />,
    dataIndex: 'originGroups',
    render: (e, record) => <span>{record.originGroups.map((x) => x.name).join(', ')}</span>,
  },
  {
    key: 'destinationGroups',
    title: <FormattedMessage id='addRule.destinationGroupsColumn' />,
    dataIndex: 'destinationGroups',
    render: (e, record) => <span>{record.destinationGroups.map((x) => x.name).join(', ')}</span>,
  },
  {
    key: 'periodFrom',
    title: <FormattedMessage id='addRule.periodFrom' />,
    dataIndex: 'periodFrom',
    sorter: true,
    render: (e, record) => <span>{record.periodFrom ? moment(record.periodFrom).format('YYYY-MM-DD') : null}</span>,
  },
  {
    key: 'periodTo',
    title: <FormattedMessage id='addRule.periodTo' />,
    dataIndex: 'periodTo',
    sorter: true,
    render: (e, record) => <span>{record.periodTo ? moment(record.periodTo).format('YYYY-MM-DD') : null}</span>,
  },
  {
    key: 'repeatsYearly',
    title: <FormattedMessage id='addRule.repeatsYearly' />,
    dataIndex: 'repeatsYearly',
    render: (e, record) => <input type='checkbox' checked={record.repeatsYearly} readOnly={true} />,
  },
  {
    key: 'fromDaysToDeparture',
    title: <FormattedMessage id='addRule.fromDaysToDeparture' />,
    dataIndex: 'fromDaysToDeparture',
    sorter: true,
  },
  {
    key: 'toDaysToDeparture',
    title: <FormattedMessage id='addRule.toDaysToDeparture' />,
    dataIndex: 'toDaysToDeparture',
    sorter: true,
  },
  {
    key: 'maxFlightsPerRoute',
    title: <FormattedMessage id='addRule.maxFlightsPerRoute' />,
    dataIndex: 'maxFlightsPerRoute',
  },

  {
    key: 'TotalGeneratedPeriods',
    title: <FormattedMessage id='addRule.countGeneratedPeriods' />,
    dataIndex: 'countGeneratedPeriods',
    sorter: true,
  },
  {
    key: 'numberOfStops',
    title: <FormattedMessage id='addRule.numberOfStopsTable' />,
    dataIndex: 'numberOfStops',
  },
  {
    key: 'viewActions',
    align: 'right' as const,
    fixed: 'right' as const,
    render: renderViewCell,
  },
  {
    key: 'editActions',
    align: 'right' as const,
    fixed: 'right' as const,
    render: renderEditCell,
  },
  {
    key: 'regenerateActions',
    align: 'right' as const,
    fixed: 'right' as const,
    render: renderRegeneratePeriodCell,
  },
  {
    key: 'deleteActions',
    align: 'right' as const,
    fixed: 'right' as const,
    render: renderDeleteCell,
  },
];

export default columns;
