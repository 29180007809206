import type { DataAirport } from 'common/dataService/types/airportsTypes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import FetchingStatus from 'constants/statuses/FetchingStatus';

export type DataAirportsState = {
  fetching: FetchingStatus | null;
  value: DataAirport[] | [];
};

const initialState: DataAirportsState = {
  fetching: null,
  value: [],
};

function setFetchingFunction(state: DataAirportsState, action: PayloadAction<DataAirportsState['fetching']>) {
  return {
    ...state,
    fetching: action.payload,
  };
}

function setDataAirportsFunction(
  state: DataAirportsState,
  action: PayloadAction<DataAirportsState['value']>
) {
  return {
    ...state,
    value: action.payload,
  };
}

const slice = createSlice({
  name: 'dataService.airports.dataAirports',
  initialState,
  reducers: {
    setFetching: setFetchingFunction,
    setDataAirports: setDataAirportsFunction,
  },
});

export const { setFetching, setDataAirports } = slice.actions;
export default slice.reducer;
