import type { RootState } from 'state';

function getIsActiveAsNumber(state: RootState): number | undefined {
  const { isActive } = state.flightRoutes.filtersValues;

  if (isActive === null) {
    return undefined;
  }

  return isActive ? 1 : 0;
}

export default getIsActiveAsNumber;
