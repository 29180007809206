import { combineReducers } from '@reduxjs/toolkit';
import webDestinations from './webDestinations/slice';
import loadWebDestinations from './webDestinations/thunks/loadWebDestinations';
import isFetchingWebDestinationsInProgress from './webDestinations/selectors/isFetchingInProgress';

export { loadWebDestinations };
export { isFetchingWebDestinationsInProgress };

export default combineReducers({
  webDestinations,
});
