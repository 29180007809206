import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type ScreensConfigState = {
  currentEdit: string | null;
};

const initialState: ScreensConfigState = {
  currentEdit: null,
};

function setCurrentEditFunction(state: ScreensConfigState, action: PayloadAction<ScreensConfigState['currentEdit']>) {
  return {
    ...state,
    currentEdit: action.payload,
  };
}

function resetStateFunction() {
  return initialState;
}

const slice = createSlice({
  name: 'screensConfig',
  initialState,
  reducers: {
    setCurrentEdit: setCurrentEditFunction,
    resetState: resetStateFunction,
  },
});

export const { setCurrentEdit, resetState } = slice.actions;
export default slice.reducer;
