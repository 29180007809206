import HttpClient from 'httpClient';
import type { UserResponse } from 'types/user/userTypes';
import type { ChangePasswordRequest, LoginRequestParameters } from './requestParameters';

const BASE_URL = process.env.REACT_APP_AUTH_API || '';

class AuthService extends HttpClient {
  public constructor() {
    super(BASE_URL);
  }

  public login = (body: LoginRequestParameters): Promise<UserResponse> =>
    this.instance.post('/users/authenticate', body);

  public loginWithJWT = (): Promise<UserResponse['user']> => this.instance.get('/users/getCurrentUser');

  public changePassword = (body: ChangePasswordRequest): Promise<UserResponse> =>
    this.instance.post('/users/changePassword', body);
}

export default new AuthService();
