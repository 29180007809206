import moment from 'moment';
import { AUTH_TOKEN, EXPIRATION_TIME } from 'localStorage/constants/keys';
import localStorage from 'localStorage';

function getAuthToken(): string {
  const token = localStorage.get<string>(AUTH_TOKEN) as string;
  const tokenExpirationTime = localStorage.get<string>(EXPIRATION_TIME);
  if (token && moment(tokenExpirationTime).isAfter(moment())) {
    return token;
  }

  return '';
}

export default getAuthToken;
