import type { RootState } from 'state';
import FetchingStatus from 'constants/statuses/FetchingStatus';

function getFlightDefinitionGroupUpdating(state: RootState): boolean {
  const { updating } = state.flightDefinitionGroups;

  return updating === FetchingStatus.InProgress;
}

export default getFlightDefinitionGroupUpdating;
