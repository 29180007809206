import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Checkbox, DatePicker, Form, Input, InputNumber, Modal, Select, Spin } from 'antd';
import { FlightDefinitionGroupsModel, FlightDefinitionRulesModel, RuleDatesRequest } from 'common/dataService';
import { RootState } from 'state';
import { useForm } from 'antd/lib/form/Form';
import { FormattedMessage } from 'react-intl';
import executeFetchDefinitionGroups from 'routes/tourOperator/flightCache/flightDefinitionGroups/managers/executeFetchDefinitionGroups';
import upsertDefinintionRules from '../../../manager/upsertDefinintionRules';
import { setEditModel, setVisibleModule } from '../../../reducers/DefinitionRules/slice';
import {
  getFlightDefinitionRuleUpdating,
  getFlightDefinitonDestinationGroups,
  getFlightDefinitonOriginGroups,
} from '../../../reducers/DefinitionRules';
import moment from 'moment';
import mediaQuery from 'styles/utils/mediaQuery';
import { weekDays } from 'routes/tourOperator/flightCache/comp/_helpers/weekDays';
import PreviewDateRange from './PreviewDateRange';
import { ISO_DATE } from 'constants/format/dateTime';
import PreviewGeneratedPeriodsCount from './PreviewGeneratedPeriodsCount';

const numberOfStops = [
  { value: 0, label: 'Direct only' },
  { value: 1, label: '1 stop' },
  { value: 2, label: '2 stops' },
];

const tripLenghtDays = Array.from(Array(14).keys()).map((_, idx) => 1 + idx);

type FormRuleModel = FlightDefinitionRulesModel & {
  originGroupsIds: number[];
  destinationGroupsIds: number[];
};

function AddRule(): JSX.Element {
  const originGroups = useSelector(getFlightDefinitonOriginGroups);
  const destinationGroups = useSelector(getFlightDefinitonDestinationGroups);
  const loading = useSelector(getFlightDefinitionRuleUpdating);
  const { editModel, visibleModel } = useSelector((state: RootState) => state.flightDefinitionRules.definitionRules);
  const { value } = useSelector((state: RootState) => state.flightDefinitionGroups);
  const [showPreviewModel, setShowPreviewModel] = useState(false);
  const [showPreviewGeneratedPeriodsModel, setShowPreviewGeneratedPeriodsModel] = useState(false);
  const [ruleDatesRequest, setRuleDatesRequest] = useState<RuleDatesRequest>(Object);
  const [validatePeriod, setValidatePeriod] = useState(false);
  const [maxPeriodTo, setMaxPeriodTo] = useState<moment.Moment | null>(null);
  const [minPeriodTo, setMinPeriodTo] = useState<moment.Moment | null>(null);
  const [form] = useForm<FormRuleModel>();
  const dispatch = useDispatch();

  const sortedOriginGroups = originGroups.sort((a, b) => a.label.localeCompare(b.label));
  const sortedDestinationGroups = destinationGroups.sort((a, b) => a.label.localeCompare(b.label));

  useEffect(() => {
    if (!value) dispatch(executeFetchDefinitionGroups);
  }, []);

  useEffect(() => {
    form.validateFields(['periodFrom']);
    form.validateFields(['periodTo']);
    form.validateFields(['fromDaysToDeparture']);
    form.validateFields(['toDaysToDeparture']);
  }, [validatePeriod]);

  useEffect(() => {
    if (editModel) {
      const formModel = {
        ...editModel,
        periodFrom: editModel.periodFrom ? moment(editModel.periodFrom) : null,
        periodTo: editModel.periodTo ? moment(editModel.periodTo) : null,
        originGroupsIds: editModel.originGroups.map((item) => item.id),
        destinationGroupsIds: editModel.destinationGroups.map((item) => item.id),
      } as FormRuleModel;
      form.setFieldsValue(formModel);
      onPeriodFromChange(moment(editModel.periodFrom));
    }
  }, [editModel]);

  const onClose = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    dispatch(setVisibleModule(!visibleModel));
    dispatch(setEditModel(null));
    setValidatePeriod(false);
    form.resetFields();
  };

  const onOk = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    var isValidPeriod = isValidSelectedPeriod();
    setValidatePeriod(!isValidPeriod);
    if (isValidPeriod) form.submit();
  };

  const onFinish = (value: FormRuleModel) => {
    const upsertModel = {
      ...editModel,
      ...value,
      periodFrom: value.periodFrom ? moment(value.periodFrom).format(ISO_DATE) : '',
      periodTo: value.periodTo ? moment(value.periodTo).format(ISO_DATE) : '',
      originGroups: value.originGroupsIds.map((item) => ({ id: item } as FlightDefinitionGroupsModel)),
      destinationGroups: value.destinationGroupsIds.map((item) => ({ id: item } as FlightDefinitionGroupsModel)),
    } as FlightDefinitionRulesModel;

    upsertDefinintionRules(dispatch, upsertModel);
    dispatch(setEditModel(null));
    form.resetFields();
  };

  function isValidSelectedPeriod(): boolean {
    var value = form.getFieldsValue() as FormRuleModel;

    if (
      ((value?.fromDaysToDeparture !== null &&
        value?.fromDaysToDeparture !== undefined &&
        value?.toDaysToDeparture !== null &&
        value?.toDaysToDeparture !== undefined) ||
        (value?.periodFrom && value?.periodTo)) &&
      !(
        value?.fromDaysToDeparture !== null &&
        value?.fromDaysToDeparture !== undefined &&
        value?.toDaysToDeparture !== null &&
        value?.toDaysToDeparture !== undefined &&
        value?.periodFrom &&
        value?.periodTo
      )
    )
      return true;
    return false;
  }

  const onClickPreview = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    var value = form.getFieldsValue();
    var isValidPeriod = isValidSelectedPeriod();
    if (isValidPeriod) {
      const request = {
        periodFrom: value.periodFrom ? moment(value.periodFrom).format(ISO_DATE) : '',
        periodTo: value.periodTo ? moment(value.periodTo).format(ISO_DATE) : '',
        repeat: value.repeatsYearly,
        daysFrom: value.fromDaysToDeparture,
        daysTo: value.toDaysToDeparture,
      };
      setRuleDatesRequest(request);
      setShowPreviewModel(true);
    }
    setValidatePeriod(!isValidPeriod);
  };

  const onClickPreviewGeneratedPeriods = async (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setValidatePeriod(false);
    await form.validateFields();
    var isValidPeriod = isValidSelectedPeriod();
    if (isValidPeriod) {
      setShowPreviewGeneratedPeriodsModel(true);
    }
    setValidatePeriod(!isValidPeriod);
  };

  function handleFilterOptions(inputValue: string, option: any) {
    if (!option) {
      return false;
    }

    const optionLabel = option.label as string;
    return optionLabel.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
  }

  function onPeriodFromChange(current: moment.Moment | null) {
    setMaxPeriodTo(moment(current).add(1, 'year').add(-1, 'day'));
    setMinPeriodTo(moment(current));
  }

  function getCountPreviewPeriodsRequest(): FlightDefinitionRulesModel {
    const formValue = form.getFieldsValue() as FormRuleModel;
    const req = {
      ...formValue,
      id: editModel?.id,
      periodFrom: formValue.periodFrom ? moment(formValue.periodFrom).format(ISO_DATE) : '',
      periodTo: formValue.periodTo ? moment(formValue.periodTo).format(ISO_DATE) : '',
      originGroups: formValue.originGroupsIds.map((item) => ({ id: item } as FlightDefinitionGroupsModel)),
      destinationGroups: formValue.destinationGroupsIds.map((item) => ({ id: item } as FlightDefinitionGroupsModel)),
    } as FlightDefinitionRulesModel;
    return req;
  }

  return (
    <>
      {showPreviewModel && <PreviewDateRange onClickOk={setShowPreviewModel} request={ruleDatesRequest} />}
      {showPreviewGeneratedPeriodsModel && (
        <PreviewGeneratedPeriodsCount
          onClickOk={setShowPreviewGeneratedPeriodsModel}
          request={getCountPreviewPeriodsRequest()}
        />
      )}
      <Modal
        visible={visibleModel}
        onCancel={onClose}
        title={<FormattedMessage id={(editModel?.id ? 'edit' : 'save') + 'Rule'} />}
        destroyOnClose={true}
        width={1200}
        onOk={onOk}
        confirmLoading={loading}
        maskClosable={false}
      >
        <Spin spinning={loading}>
          <Form
            name='AddRule'
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            autoComplete='off'
            form={form}
            onFinish={onFinish}
            layout='vertical'
          >
            <Form.Item
              label={<FormattedMessage id='name' />}
              name='name'
              rules={[
                {
                  required: true,
                  message: 'Required field',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={<FormattedMessage id='addRule.originGroups' />}
              name='originGroupsIds'
              rules={[
                {
                  required: true,
                  message: 'Required field',
                },
              ]}
            >
              <Select mode='multiple' options={sortedOriginGroups} filterOption={handleFilterOptions} />
            </Form.Item>

            <Form.Item
              label={<FormattedMessage id='addRule.destinationGroups' />}
              name='destinationGroupsIds'
              rules={[
                {
                  required: true,
                  message: 'Required field',
                },
              ]}
            >
              <Select mode='multiple' options={sortedDestinationGroups} filterOption={handleFilterOptions} />
            </Form.Item>

            <InlineWrap>
              <Form.Item>
                <Form.Item
                  label={<FormattedMessage id='addRule.periodFrom' />}
                  name='periodFrom'
                  rules={[
                    {
                      required: validatePeriod,
                      message: 'Required field',
                    },
                  ]}
                >
                  <DatePicker onChange={onPeriodFromChange} />
                </Form.Item>

                <Form.Item noStyle name='repeatsYearly' valuePropName='checked'>
                  <Checkbox>
                    <FormattedMessage id='addRule.repeatsYearly' />
                  </Checkbox>
                </Form.Item>
              </Form.Item>

              <Form.Item
                label={<FormattedMessage id='addRule.periodTo' />}
                name='periodTo'
                rules={[
                  {
                    required: validatePeriod,
                    message: 'Required field',
                  },
                ]}
              >
                <DatePicker
                  disabledDate={(d) => !d || d.isAfter(maxPeriodTo, 'day') || d.isSameOrBefore(minPeriodTo, 'day')}
                />
              </Form.Item>
              <div>
                <FormattedMessage id='addRule.or' />
                {validatePeriod && (
                  <p style={{ color: 'red' }} id='RequiredMessage'>
                    <FormattedMessage id='addRule.PreviewPeriodMessage' />
                  </p>
                )}
              </div>

              <Form.Item
                label={<FormattedMessage id='addRule.fromDaysToDeparture' />}
                name='fromDaysToDeparture'
                rules={[
                  {
                    required: validatePeriod,
                    message: 'Required field',
                  },
                ]}
              >
                <InputNumber min={0} />
              </Form.Item>

              <Form.Item
                label={<FormattedMessage id='addRule.toDaysToDeparture' />}
                name='toDaysToDeparture'
                rules={[
                  {
                    required: validatePeriod,
                    message: 'Required field',
                  },
                ]}
              >
                <InputNumber min={0} />
              </Form.Item>
            </InlineWrap>

            <h3>
              <FormattedMessage id='addRule.Configuration' />
            </h3>
            <Form.Item label={<FormattedMessage id='addRule.departureDays' />}>
              <Form.Item noStyle name='generateForDepartureDate' valuePropName='checked'>
                <Checkbox>
                  <FormattedMessage id='addRule.generateForDepartureDate' />
                </Checkbox>
              </Form.Item>
              <br />
              <Form.Item
                noStyle
                name='departureDays'
                rules={[
                  {
                    required: true,
                    message: 'Required field',
                  },
                ]}
              >
                <Checkbox.Group>
                  {weekDays.map((item) => (
                    <Checkbox
                      value={item.value.toUpperCase()}
                      key={item.value}
                      style={{
                        lineHeight: '32px',
                      }}
                    >
                      <FormattedMessage id={`weekDays.${item.text}`} />
                    </Checkbox>
                  ))}
                </Checkbox.Group>
              </Form.Item>
            </Form.Item>

            <Form.Item label={<FormattedMessage id='addRule.days' />}>
              <Form.Item noStyle name='generateForEachTripLength' valuePropName='checked'>
                <Checkbox>
                  <FormattedMessage id='addRule.generateForEachTripLength' />
                </Checkbox>
              </Form.Item>

              <Form.Item
                noStyle
                name='days'
                rules={[
                  {
                    required: true,
                    message: 'Required field',
                  },
                ]}
              >
                <Checkbox.Group>
                  {tripLenghtDays.map((item) => (
                    <Checkbox
                      value={item}
                      key={item}
                      style={{
                        lineHeight: '32px',
                      }}
                    >
                      {item}
                    </Checkbox>
                  ))}
                </Checkbox.Group>
              </Form.Item>
            </Form.Item>

            <Form.Item
              label={<FormattedMessage id='addRule.numberOfStops' />}
              name='numberOfStops'
              initialValue={numberOfStops[0].value}
            >
              <Select options={numberOfStops} />
            </Form.Item>

            <Form.Item label={<FormattedMessage id='addRule.maxFlightsPerRoute' />}>
              <Form.Item
                noStyle
                name='maxFlightsPerRoute'
                initialValue={1}
                rules={[
                  {
                    required: true,
                    message: 'Required field',
                  },
                ]}
              >
                <InputNumber min={1} max={50} />
              </Form.Item>
              <MessageSpan>
                <FormattedMessage id='addRule.maxFlightsPerRouteMessage' />
              </MessageSpan>
            </Form.Item>
            <>
              <Button type='primary' onClick={onClickPreview}>
                <FormattedMessage id='addRule.PreviewPeriod' />
              </Button>
            </>
            <MessageSpan>
              <Button type='primary' onClick={onClickPreviewGeneratedPeriods}>
                <FormattedMessage id='addRule.GeneratedPeriods' />
              </Button>
            </MessageSpan>
          </Form>
        </Spin>
      </Modal>
    </>
  );
}

export default AddRule;

const MessageSpan = styled.span`
  margin-left: 10px;
`;

const InlineWrap = styled.div`
  display: flex;
  flex-wrap: wrap;

  > * {
    flex: auto;
    display: inline-block;
  }

  div {
    max-width: inherit;
  }

  ${mediaQuery.fromAndBelow.medium`
    display: inline;
  `}
`;
