import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import FetchingStatus from 'constants/statuses/FetchingStatus';
import type { WebsiteApiKey } from '../types';

export type WebsiteApiKeysState = {
  adding: FetchingStatus | null;
  deleting: FetchingStatus | null;
  fetching: FetchingStatus | null;
  apiKeys: WebsiteApiKey[] | null | undefined;
  affiliateOptions: { value: number; label: string; title: string }[] | null;
};

const initialState: WebsiteApiKeysState = {
  adding: null,
  deleting: null,
  fetching: null,
  apiKeys: null,
  affiliateOptions: null
};

function setAddingFunction(
  state: WebsiteApiKeysState,
  action: PayloadAction<WebsiteApiKeysState['adding']>
) {
  return {
    ...state,
    adding: action.payload,
  };
}

function setDeletingFunction(
  state: WebsiteApiKeysState,
  action: PayloadAction<WebsiteApiKeysState['deleting']>
) {
  return {
    ...state,
    deleting: action.payload,
  };
}

function setFetchingFunction(
  state: WebsiteApiKeysState,
  action: PayloadAction<WebsiteApiKeysState['fetching']>
) {
  return {
    ...state,
    fetching: action.payload,
  };
}

function setAllApiKeysFunction(
  state: WebsiteApiKeysState,
  action: PayloadAction<WebsiteApiKeysState['apiKeys']>
) {
  return {
    ...state,
    apiKeys: action.payload,
  };
}

function setAffiliateOptionsFunction(
  state: WebsiteApiKeysState,
  action: PayloadAction<WebsiteApiKeysState['affiliateOptions']>
) {
  return {
    ...state,
    affiliateOptions: action.payload,
  };
}

function resetStateFunction() {
  return initialState;
}

const slice = createSlice({
  name: 'website.apiKeys',
  initialState,
  reducers: {
    setAdding: setAddingFunction,
    setDeleting: setDeletingFunction,
    setFetching: setFetchingFunction,
    setAllApiKeys: setAllApiKeysFunction,
    setAffiliateOptions: setAffiliateOptionsFunction,
    resetState: resetStateFunction
  },
});

export const {
  setAdding,
  setDeleting,
  setFetching,
  setAllApiKeys,
  setAffiliateOptions,
  resetState,
} = slice.actions;
export default slice.reducer;