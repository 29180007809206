import type { DepartureAirport } from 'common/dataService/types/airportsTypes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import FetchingStatus from 'constants/statuses/FetchingStatus';

export type DepartureAirportsState = {
  fetching: FetchingStatus | null;
  value: DepartureAirport[] | null;
};

const initialState: DepartureAirportsState = {
  fetching: null,
  value: null,
};

function setFetchingFunction(state: DepartureAirportsState, action: PayloadAction<DepartureAirportsState['fetching']>) {
  return {
    ...state,
    fetching: action.payload,
  };
}

function setDepartureAirportsFunction(
  state: DepartureAirportsState,
  action: PayloadAction<DepartureAirportsState['value']>
) {
  return {
    ...state,
    value: action.payload,
  };
}

const slice = createSlice({
  name: 'dataService.airports.departureAirports',
  initialState,
  reducers: {
    setFetching: setFetchingFunction,
    setDepartureAirports: setDepartureAirportsFunction,
  },
});

export const { setFetching, setDepartureAirports } = slice.actions;
export default slice.reducer;
