import type { AppDispatch } from 'state';
import { setPage } from 'routes/tourOperator/flightCache/flightRoutes/comp/flightRoutesFilters';
import executeFetchFlightRoutes from './executeFetchFlightRoutes';

function updatePageAndLoadResults(page: number) {
  return async function updatePageAndLoadResultsThunk(dispatch: AppDispatch): Promise<void> {
    dispatch(setPage(page));
    await dispatch(executeFetchFlightRoutes);
  };
}

export default updatePageAndLoadResults;
