import React from 'react';
import { useDispatch } from 'react-redux';
import { Tooltip } from 'antd';
import { FormattedMessage } from 'react-intl';
import FontAwesomeIcon from 'comp/wraps/faIcon/FAIcon';
import ConfirmationButton from 'comp/wraps/confirmationButton/ConfirmationButton';
import removeStaticRoute from '../../../managers/deleteStaticRoutes';
import { StaticRouteModel } from 'common/dataService/types/staticRouteTypes';

function renderDeleteCell(record: StaticRouteModel): JSX.Element {
  return <DeleteStaticRoute record={record} />;
}

export default renderDeleteCell;

type StaticRouteProps = {
  record: StaticRouteModel;
};

function DeleteStaticRoute({ record }: StaticRouteProps): JSX.Element {
  const dispatch = useDispatch();

  function onSubmit() {
    removeStaticRoute(dispatch, record);
  }

  return (
    <Tooltip title={<FormattedMessage id='delete' />}>
      <ConfirmationButton
        buttonProps={{ ghost: true, disabled: false, danger: true, icon: <FontAwesomeIcon icon='times' /> }}
        alertMessage={<FormattedMessage id='staticRoutes.delete.confirmMessage' />}
        onSubmit={onSubmit}
        visibleForm={false}
      />
    </Tooltip>
  );
}
