import { combineReducers } from '@reduxjs/toolkit';
import languages from './languages/slice';
import loadLanguages from './languages/thunks/loadLanguages';
import isFetchingLanguagesInProgress from './languages/selectors/isFetchingInProgress';
import getLanguagesAsEnum from './languages/selectors/getLanguagesAsEnum';

export { loadLanguages };
export { isFetchingLanguagesInProgress };
export { getLanguagesAsEnum };

export default combineReducers({
  languages,
});
