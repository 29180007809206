import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetStaticRoutesResponse, StaticRouteModel } from 'common/dataService/types/staticRouteTypes';
import FetchingStatus from 'constants/statuses/FetchingStatus';

export type StaticRoutesState = {
  page: number;
  takeCount: number;
  sorting: string | null;
  fetching: FetchingStatus | null;
  value: GetStaticRoutesResponse | null;
  editModel: StaticRouteModel | null;
  visibleModel: boolean;
  updating: FetchingStatus | null;
  updatingKey: string | null;
};

const initialState: StaticRoutesState = {
  page: 1,
  takeCount: 25,
  sorting: null,
  fetching: null,
  value: null,
  editModel: null,
  visibleModel: false,
  updating: null,
  updatingKey: null,
};

function setPageFunction(state: StaticRoutesState, action: PayloadAction<StaticRoutesState['page']>) {
  return {
    ...state,
    page: action.payload,
  };
}

function setTakeCountFunction(state: StaticRoutesState, action: PayloadAction<StaticRoutesState['takeCount']>) {
  return {
    ...state,
    takeCount: action.payload,
  };
}

function setFetchingFunction(state: StaticRoutesState, action: PayloadAction<StaticRoutesState['fetching']>) {
  return {
    ...state,
    fetching: action.payload,
  };
}

function setStaticRoutesResponseFunction(state: StaticRoutesState, action: PayloadAction<StaticRoutesState['value']>) {
  return {
    ...state,
    value: action.payload,
  };
}

function setUpdatingFunction(state: StaticRoutesState, action: PayloadAction<StaticRoutesState['updating']>) {
  return {
    ...state,
    updating: action.payload,
  };
}

function setVisibleModuleFunction(state: StaticRoutesState, action: PayloadAction<StaticRoutesState['visibleModel']>) {
  return {
    ...state,
    visibleModel: action.payload,
  };
}

function setEditModelFunction(state: StaticRoutesState, action: PayloadAction<StaticRoutesState['editModel']>) {
  return {
    ...state,
    editModel: action.payload,
  };
}

function setUpdatingKeyFunction(state: StaticRoutesState, action: PayloadAction<StaticRoutesState['updatingKey']>) {
  return {
    ...state,
    updatingKey: action.payload,
  };
}

function setSortingFunction(state: StaticRoutesState, action: PayloadAction<StaticRoutesState['sorting']>) {
  return {
    ...state,
    sorting: action.payload,
  };
}

const slice = createSlice({
  name: 'staticRoutes',
  initialState,
  reducers: {
    setPage: setPageFunction,
    setTakeCount: setTakeCountFunction,
    setFetching: setFetchingFunction,
    setStaticResponseFunction: setStaticRoutesResponseFunction,
    setUpdating: setUpdatingFunction,
    setVisibleModule: setVisibleModuleFunction,
    setEditModel: setEditModelFunction,
    setUpdatingKey: setUpdatingKeyFunction,
    setSorting: setSortingFunction,
  },
});

export const {
  setPage,
  setTakeCount,
  setFetching,
  setStaticResponseFunction,
  setUpdating,
  setVisibleModule,
  setEditModel,
  setUpdatingKey,
  setSorting,
} = slice.actions;
export default slice.reducer;
