import type { RootState } from 'state';
import type { Enum } from 'routes/tourOperator/bookings/types/configs/enumsTypes';

function getEnums(state: RootState): Enum[] | null {
  const { value } = state.bookings.configsEnums;

  if (!value) {
    return null;
  }

  return value.enums;
}

export default getEnums;
