import type { RouteConfig } from 'router/routes';
import type { Module } from 'types/user/userTypes';

function getPermittedModulesCallback(permittedModulesIds: Module['id'][]): (routeConfig: RouteConfig) => boolean {
  return function getPermittedModules(routeConfig: RouteConfig): boolean {
    const { componentProps } = routeConfig;

    if (componentProps === undefined) {
      return true;
    }

    const { moduleId } = componentProps;

    if (moduleId === undefined) {
      return true;
    }

    return permittedModulesIds.indexOf(moduleId) > -1;
  };
}

export default getPermittedModulesCallback;
