import type { FormInstance } from 'antd/lib/form/hooks/useForm';
import type { ScreenConfig } from 'comp/screenBuilder/types/screenConfigTypes';
import type { OuterContextShape } from 'comp/screenBuilder';
import React from 'react';
import ScreenBuilder from 'comp/screenBuilder';
import { useSelector } from 'react-redux';
import { getFlightRouteByKey } from 'routes/tourOperator/flightCache/flightRoutes/comp/flightRoutesTable/reducer';
import screenConfig from './screen-config.json';
import { getAllConfigEnums } from 'routes/tourOperator/flightCache/flightRoutes/comp/flightRoutesFilters';

type FlightRouteScreenProps = {
  flightRouteKey: string;
  contextValues: OuterContextShape;
  formSubmit: FormInstance;
};

const handlers = {
  'flight-route-info': {},
};

function FlightRouteScreen({ flightRouteKey, contextValues, formSubmit }: FlightRouteScreenProps): JSX.Element {
  const flightRoute = useSelector(getFlightRouteByKey(flightRouteKey));
  const enums = useSelector(getAllConfigEnums);
  const data = {
    flightRoute,
    enums,
  };

  return (
    <ScreenBuilder
      contextValues={contextValues}
      screenConfig={screenConfig as ScreenConfig}
      data={data}
      initialPlaceholdersValues={[]}
      handlers={handlers}
      formSubmit={formSubmit}
    />
  );
}

export default FlightRouteScreen;
