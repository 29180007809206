import type { ColumnsType } from 'antd/lib/table';
import type { FlightRoute } from 'common/packageCache';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import renderActionsCell from './tableCells/renderActionsCell';
import renderCreatedAtCell from './tableCells/renderCreatedAtCell';
import renderIsActiveCell from './tableCells/renderIsActiveCell';
import renderIsOperatingCell from './tableCells/renderIsOperatingCell';
import renderLastSeenAtCell from './tableCells/renderLastSeenAtCell';
import renderOperatingFromCell from './tableCells/renderOperatingFromCell';
import renderOperatingToCell from './tableCells/renderOperatingToCell';

const columns: ColumnsType<FlightRoute> = [
  {
    key: 'airportFrom',
    title: <FormattedMessage id='from' />,
    dataIndex: 'airportFrom',
    sorter: true,
  },
  {
    key: 'airportTo',
    title: <FormattedMessage id='to' />,
    dataIndex: 'airportTo',
    sorter: true,
  },
  {
    key: 'carrier',
    title: <FormattedMessage id='carrier' />,
    dataIndex: 'carrier',
    sorter: true,
  },
  {
    key: 'stops',
    title: <FormattedMessage id='stops' />,
    dataIndex: 'stops',
    sorter: true,
  },
  {
    key: 'isActive',
    title: <FormattedMessage id='active' />,
    align: 'center' as const,
    render: renderIsActiveCell,
  },
  {
    key: 'isOperating',
    title: <FormattedMessage id='operating' />,
    align: 'center' as const,
    render: renderIsOperatingCell,
  },
  {
    key: 'operatingFrom',
    title: <FormattedMessage id='operatingFrom' />,
    render: renderOperatingFromCell,
  },
  {
    key: 'operatingTo',
    title: <FormattedMessage id='operatingTo' />,
    render: renderOperatingToCell,
  },
  {
    key: 'source',
    title: <FormattedMessage id='source' />,
    dataIndex: 'source',
  },
  {
    key: 'createdAt',
    title: <FormattedMessage id='createdAt' />,
    render: renderCreatedAtCell,
  },
  {
    key: 'updateTime',
    title: <FormattedMessage id='lastSeen' />,
    render: renderLastSeenAtCell,
  },
  {
    key: 'actions',
    align: 'right' as const,
    fixed: 'right' as const,
    render: renderActionsCell,
  },
];

export default columns;
