import { createContext } from 'react';
import type { InnerRef, InnerRefProperties, FragmentProperties } from './refTypes';

export type InnerContextShape = {
  getInnerRef?: () => InnerRef | undefined;
  updateInnerRefProperty: (property: InnerRefProperties, value: any) => void;
  updateFragmentProperty: (fragmentKey: string, property: FragmentProperties, value: any) => void;
};

const initialContext: InnerContextShape = {
  updateInnerRefProperty: (property: InnerRefProperties, value: any) => {},
  updateFragmentProperty: (fragmentKey: string, property: FragmentProperties, value: any) => {},
};

const InnerContext = createContext(initialContext);

export default InnerContext;
