import type { BookingDetails } from 'routes/tourOperator/bookings/types/bookingDetails/bookingDetailsTypes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type BookingCarRentalsState = {
  value: BookingDetails['carRentals'] | null;
};

const initialState: BookingCarRentalsState = {
  value: null,
};

function setBookingCarRentalsFunction(
  state: BookingCarRentalsState,
  action: PayloadAction<BookingCarRentalsState['value']>
) {
  return {
    ...state,
    value: action.payload,
  };
}

function resetStateFunction() {
  return initialState;
}

const slice = createSlice({
  name: 'bookingDetails.bookingCarRentals',
  initialState,
  reducers: {
    setBookingCarRentals: setBookingCarRentalsFunction,
    resetState: resetStateFunction,
  },
});

export const { setBookingCarRentals, resetState } = slice.actions;
export default slice.reducer;
