import type { FormFieldProps } from 'comp/screenBuilder/comp/formBuilder/types/formPropsTypes';
import type { EnumValue } from 'routes/tourOperator/bookings/types/configs/enumsTypes';
import React from 'react';
import styled from 'styled-components/macro';
import { Form } from 'antd';
import { FormattedMessage } from 'react-intl';

function SelectPreview({ field, formFieldName, removeFormItemLabel }: FormFieldProps): JSX.Element {
  const { formItem, formInput } = field;
  const { label, ...formItemProps } = formItem;

  return (
    <Root>
      <Form.Item
        required={false}
        label={!removeFormItemLabel && label && <FormattedMessage {...label} />}
        {...formItemProps}
        name={formFieldName}
      >
        <Value options={formInput.options} />
      </Form.Item>
    </Root>
  );
}

export default SelectPreview;

type ValueProps = {
  options: EnumValue[] | undefined;
  value?: string;
};

function Value({ options, value }: ValueProps): JSX.Element | null {
  if (value === undefined || !options) {
    return null;
  }

  const label = options.find(({ key }) => key === value)?.value;

  return <div>{label}</div>;
}

Value.defaultProps = { value: '' };

const Root = styled.div`
  .ant-form-item-no-colon {
    user-select: none;
    color: ${({ theme }) => theme.palette.text.label};
  }

  .ant-form-item-control-input {
    min-height: initial;
  }
`;
