import { combineReducers } from '@reduxjs/toolkit';
import promocodes from './promocodes/slice';
import loadPromocodes from './promocodes/thunks/loadPromocodes';
import isFetchingPromocodesInProgress from './promocodes/selectors/isFetchingInProgress';

export { loadPromocodes };
export { isFetchingPromocodesInProgress };

export default combineReducers({
  promocodes,
});
