import type { FormListFieldData } from 'antd/lib/form/FormList';
import type { FormInputContext } from 'comp/screenBuilder/comp/formBuilder/types/formConfigTypes';
import type { AffixesNames } from './types';

function getAffixesNames(formListField?: FormListFieldData, context?: FormInputContext): AffixesNames | null {
  if (!context) {
    return null;
  }

  const { item1, item2 } = context;
  let item1Name;
  let item2Name;

  if (item1) {
    item1Name = formListField ? [formListField.name, ...item1] : item1;
  }

  if (item2) {
    item2Name = formListField ? [formListField.name, ...item2] : item2;
  }

  if (item1Name || item2Name) {
    return {
      item1Name,
      item2Name,
    };
  }

  return null;
}

export default getAffixesNames;
