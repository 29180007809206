import type { Props as FormattedMessageProps } from 'react-intl/lib/src/components/message';
import React from 'react';
import styled from 'styled-components/macro';
import { FormattedMessage } from 'react-intl';

type LabeledValueProps = {
  label: FormattedMessageProps | string;
  children: React.ReactNode;
};

function LabeledValue({ label, children }: LabeledValueProps): JSX.Element {
  return (
    <Root>
      <Label>{typeof label === 'object' && label.id ? <FormattedMessage {...label} /> : label}</Label>
      <Value>{children}</Value>
    </Root>
  );
}

export default LabeledValue;

const Root = styled.div``;

const Label = styled.label`
  user-select: none;
  font-size: 14px;
  color: ${({ theme }) => theme.palette.text.label};
`;

const Value = styled.div`
  font-size: 14px;
`;
