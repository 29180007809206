import type { FormConfig } from '../comp/formBuilder';
import type { ScreenFormConfig } from '../types/screenConfigTypes';

async function loadFormConfiguration(pathToForm: string): Promise<FormConfig> {
  try {
    const module = (await import(`static/forms/${pathToForm}`)) as { default: FormConfig };
    return module.default;
  } catch (error) {
    window.Rollbar.error(`Error on loadFormConfiguration for ${pathToForm}.`, error);
    throw new Error('Failed to load form!');
  }
}

async function loadFormConfigurations(
  screenKey: string,
  screenFormsConfigs: ScreenFormConfig[]
): Promise<FormConfig[] | undefined> {
  try {
    const promises = screenFormsConfigs.map((form) => form.pathToForm).map(loadFormConfiguration);
    const values = await Promise.all(promises);
    return values;
  } catch (error) {
    window.Rollbar.error(`Error on loading form configurations for screen ${screenKey}`, error);
  }

  return undefined;
}

export default loadFormConfigurations;
