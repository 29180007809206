import type { FormFieldProps } from 'comp/screenBuilder/comp/formBuilder/types/formPropsTypes';
import React, { useContext, useState } from 'react';
import styled from 'styled-components/macro';
import { Form, Select as AntdSelect } from 'antd';
import { FormattedMessage } from 'react-intl';
import FormFieldContext from '../../context';

function AutoComplete({ field, formFieldName, removeFormItemLabel }: FormFieldProps): JSX.Element {
  const { formItem, formInput, componentProps } = field;
  const { label, ...formItemProps } = formItem;
  const { context } = formInput;
  const fieldKey = context?.fieldKey ?? '';
  const formFieldContext = useContext(FormFieldContext);
  const { onChange, onSearch, selectOptions } = formFieldContext[fieldKey] || {};
  const [options, setOptions] = useState<{ value: string | number; label: string }[]>([]);
  const [loading, setLoading] = useState(false);

  async function handleOnChange(option: any) {
    if (typeof onChange === 'function') {
      setLoading(true);
      // eslint-disable-next-line no-void
      await onChange(option as string, formFieldName);
      setLoading(false);
    }
  }

  async function handleOnSearch(searchQuery: string) {
    if (searchQuery && searchQuery.length > 2) {
      if (typeof onSearch === 'function') {
        const results = await onSearch(searchQuery);

        if (results) {
          setOptions(results);
        }
      }
    }
  }

  function handleOnDropdownVisibleChange() {
    if (selectOptions) {
      setOptions(selectOptions);
    }
  }

  function handleFilterOptions(inputValue: string, option: any) {
    if (!option) {
      return false;
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    const optionLabel = option.label as string;
    return optionLabel.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
  }

  return (
    <Form.Item
      label={!removeFormItemLabel && label && <FormattedMessage {...label} />}
      {...formItemProps}
      name={formFieldName}
    >
      <Select
        {...componentProps}
        allowClear
        showSearch
        filterOption={handleFilterOptions}
        autoClearSearchValue={false}
        loading={loading}
        options={options}
        onChange={handleOnChange}
        onSearch={handleOnSearch}
        onDropdownVisibleChange={handleOnDropdownVisibleChange}
      />
    </Form.Item>
  );
}

export default AutoComplete;

const Select = styled(AntdSelect)`
  width: 100%;
`;
