import type { AppThunkSync } from 'state';
import moment from 'moment';
import {
  setAirportsFromValue,
  setAirportsToValue,
  setMonthValue,
} from 'routes/tourOperator/flightCache/flightCalendars/comp/flightCalendarsFilters';
import { executeFetchFlightCalendarsAndLoadMaxFlightDuration } from 'routes/tourOperator/flightCache/flightCalendars/managers';

function openRouteInFlightCalendars(
  airportFrom: string,
  airportTo: string,
  operatingFrom: string,
  operatingTo: string
): AppThunkSync {
  return function openRouteInFlightCalendarsThunk(dispatch, getState) {
    dispatch(setAirportsFromValue([airportFrom]));
    dispatch(setAirportsToValue([airportTo]));

    const currentDate = moment();
    const operatingFromDate = moment(operatingFrom);
    const operatingToDate = moment(operatingTo);

    if (currentDate.isBetween(operatingFromDate, operatingToDate)) {
      dispatch(setMonthValue(currentDate.format('YYYY-MM')));
    } else if (currentDate.isBefore(operatingFromDate)) {
      dispatch(setMonthValue(operatingFromDate.format('YYYY-MM')));
    } else {
      dispatch(setMonthValue(currentDate.format('YYYY-MM')));
    }

    dispatch(executeFetchFlightCalendarsAndLoadMaxFlightDuration());
  };
}

export default openRouteInFlightCalendars;
