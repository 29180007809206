import type { FlightCalendar } from 'common/packageCache';
import moment from 'moment';

function renderFlightInfoCell(text: string, record: FlightCalendar): string {
  const departureMoment = moment(record.departureTime);
  const arriveMoment = moment(record.arriveTime);
  let durationHuman = '';

  if (record.flightDuration) {
    const duration = moment.duration(record.flightDuration, 'minutes');
    durationHuman = ` (${duration.get('hours')}h ${duration.get('minutes')}m)`;
  }

  const duration = moment.duration(arriveMoment.diff(departureMoment));
  return `${departureMoment.format('HH:mm')} - ${arriveMoment.format('HH:mm')}${durationHuman}`;
}

export default renderFlightInfoCell;
