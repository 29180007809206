import type { RouteConfig } from 'router/routes';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import AuthWrapper from 'router/comp/authWrapper/AuthWrapper';
import Layout from 'comp/layout/Layout';
import { PATH_LOGIN } from 'router/constants/routePaths';
import getAuthToken from 'localStorage/methods/getAuthToken';
import localStorage from 'localStorage';
import Breadcrumb from '../breadcrumb/Breadcrumb';

function RouteWrapper({
  secure,
  useLayout,
  routeComponent: RouteComponent,
  componentProps,
  ...routeProps
}: RouteConfig): JSX.Element {
  const token = getAuthToken();

  if (token === '') {
    if (window.location.pathname !== PATH_LOGIN) {
      localStorage.add('previous-path', window.location.pathname);
    }

    return (
      <>
        <Redirect to={{ pathname: PATH_LOGIN, state: { from: routeProps.path } }} />
        <RouteComponent {...componentProps} />
      </>
    );
  }

  if (secure) {
    localStorage.remove('previous-path');

    return (
      <Route {...routeProps}>
        <AuthWrapper {...routeProps}>
          {useLayout ? (
            <Layout>
              <Breadcrumb />
              <RouteComponent {...componentProps} />
            </Layout>
          ) : (
            <RouteComponent {...componentProps} />
          )}
        </AuthWrapper>
      </Route>
    );
  }

  return (
    <Route {...routeProps}>
      {useLayout ? (
        <Layout>
          <Breadcrumb />
          <RouteComponent {...componentProps} />
        </Layout>
      ) : (
        <RouteComponent {...componentProps} />
      )}
    </Route>
  );
}

export default RouteWrapper;
