import type { CancellationReason } from 'routes/tourOperator/bookings/types/bookingCancellation/bookingCancellationTypes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import FetchingStatus from 'constants/statuses/FetchingStatus';

export type CancellationReasonsState = {
  fetching: FetchingStatus | null;
  value: CancellationReason[] | null;
};

const initialState: CancellationReasonsState = {
  fetching: null,
  value: null,
};

function setFetchingFunction(
  state: CancellationReasonsState,
  action: PayloadAction<CancellationReasonsState['fetching']>
) {
  return {
    ...state,
    fetching: action.payload,
  };
}

function setCancellationReasonsFunction(
  state: CancellationReasonsState,
  action: PayloadAction<CancellationReasonsState['value']>
) {
  return {
    ...state,
    value: action.payload,
  };
}

function resetStateFunction() {
  return initialState;
}

const slice = createSlice({
  name: 'bookingCancellationReasons',
  initialState,
  reducers: {
    setFetching: setFetchingFunction,
    setCancellationReasons: setCancellationReasonsFunction,
    resetState: resetStateFunction,
  },
});

export const { setFetching, setCancellationReasons, resetState } = slice.actions;
export default slice.reducer;
