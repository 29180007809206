import type { Validity } from 'types/common/validationTypes';
import type { BookingBalance } from 'routes/tourOperator/bookings/types/bookingBalance/bookingBalance';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import FetchingStatus from 'constants/statuses/FetchingStatus';

export type BookingBalanceState = {
  fetching: FetchingStatus | null;
  validity: Validity | null;
  value: BookingBalance[] | null;
};

const initialState: BookingBalanceState = {
  fetching: null,
  validity: null,
  value: null,
};

function setFetchingFunction(state: BookingBalanceState, action: PayloadAction<BookingBalanceState['fetching']>) {
  return {
    ...state,
    fetching: action.payload,
  };
}

function setValidityFunction(state: BookingBalanceState, action: PayloadAction<BookingBalanceState['validity']>) {
  return {
    ...state,
    validity: action.payload,
  };
}

function setBookingBalanceFunction(state: BookingBalanceState, action: PayloadAction<BookingBalanceState['value']>) {
  return {
    ...state,
    value: action.payload,
  };
}

function resetStateFunction() {
  return initialState;
}

const slice = createSlice({
  name: 'bookingBalance',
  initialState,
  reducers: {
    setFetching: setFetchingFunction,
    setValidity: setValidityFunction,
    setBookingBalance: setBookingBalanceFunction,
    resetState: resetStateFunction,
  },
});

export const { setFetching, setValidity, setBookingBalance, resetState } = slice.actions;
export default slice.reducer;
