import type { Validity } from 'types/common/validationTypes';
import type { BookingValidations } from 'routes/tourOperator/bookings/types/bookingDetails/bookingValidationTypes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import FetchingStatus from 'constants/statuses/FetchingStatus';

export type BookingValidationsState = {
  fetching: FetchingStatus | null;
  validity: Validity | null;
  value: BookingValidations | null;
};

const initialState: BookingValidationsState = {
  fetching: null,
  validity: null,
  value: null,
};

function setFetchingFunction(
  state: BookingValidationsState,
  action: PayloadAction<BookingValidationsState['fetching']>
) {
  return {
    ...state,
    fetching: action.payload,
  };
}

function setValidityFunction(
  state: BookingValidationsState,
  action: PayloadAction<BookingValidationsState['validity']>
) {
  return {
    ...state,
    validity: action.payload,
  };
}

function setBookingValidationsFunction(
  state: BookingValidationsState,
  action: PayloadAction<BookingValidationsState['value']>
) {
  return {
    ...state,
    value: action.payload,
  };
}

function resetStateFunction() {
  return initialState;
}

const slice = createSlice({
  name: 'bookingValidations',
  initialState,
  reducers: {
    setFetching: setFetchingFunction,
    setValidity: setValidityFunction,
    setBookingValidations: setBookingValidationsFunction,
    resetState: resetStateFunction,
  },
});

export const { setFetching, setValidity, setBookingValidations, resetState } = slice.actions;
export default slice.reducer;
