import React, { useContext } from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { Menu as AntdMenu } from 'antd';
import { FormattedMessage } from 'react-intl';
import FontAwesomeIcon from 'comp/wraps/faIcon/FAIcon';
import SiderContext from 'comp/layout/comp/sider/context/index';
import type { MenuItemShape } from '../../types';

const { Item, SubMenu } = AntdMenu;

type MenuItemContentProps = {
  icon: MenuItemShape['icon'];
  label: MenuItemShape['label'];
};

function MenuItemContent({ icon, label }: MenuItemContentProps): JSX.Element {
  return (
    <>
      {icon && <Icon icon={icon} />}
      {label && <Label id={label} />}
    </>
  );
}

const Icon = styled(FontAwesomeIcon)`
  width: 26px !important;
  margin-right: 24px;
  font-size: 20px;
`;

const Label = styled(FormattedMessage)``;

function MenuItem({ key, label, icon, path, items }: MenuItemShape): JSX.Element {
  const { collapsed } = useContext(SiderContext);

  if (items && items.length > 0) {
    const title = !collapsed && <Label id={label} />;

    return (
      <SubMenu key={key} title={title} icon={icon && <Icon icon={icon} />}>
        {items.map(MenuItem)}
      </SubMenu>
    );
  }

  return (
    <Item key={key}>
      {path ? (
        <Link to={path}>
          <MenuItemContent icon={icon} label={label} />
        </Link>
      ) : (
        <MenuItemContent icon={icon} label={label} />
      )}
    </Item>
  );
}

export default MenuItem;
