import type { BookingDetails } from 'routes/tourOperator/bookings/types/bookingDetails/bookingDetailsTypes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type BookingCancellationProtectionsState = {
  value: BookingDetails['cancellationProtections'] | null;
};

const initialState: BookingCancellationProtectionsState = {
  value: null,
};

function setBookingCancellationProtectionsFunction(
  state: BookingCancellationProtectionsState,
  action: PayloadAction<BookingCancellationProtectionsState['value']>
) {
  return {
    ...state,
    value: action.payload,
  };
}

function resetStateFunction() {
  return initialState;
}

const slice = createSlice({
  name: 'bookingDetails.bookingCancellationProtections',
  initialState,
  reducers: {
    setBookingCancellationProtections: setBookingCancellationProtectionsFunction,
    resetState: resetStateFunction,
  },
});

export const { setBookingCancellationProtections, resetState } = slice.actions;
export default slice.reducer;
