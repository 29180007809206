import type { RouteConfig } from 'router/routes';
import React, { Suspense } from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import getPermittedModulesIds from 'state/reducers/auth/selectors/getPermittedModulesIds';
import Layout from 'comp/layout/Layout';
import Spinner from 'comp/feedback/spinner/Spinner';
import ScrollToTop from './comp/scrollToTop/ScrollToTop';
import RouteWrapper from './comp/routeWrapper/RouteWrapper';
import getPermittedModulesCallback from './utils/getPermittedModules';

type PropTypes = {
  routes: RouteConfig[];
};

function Router({ routes }: PropTypes): JSX.Element {
  const permittedModulesIds = useSelector(getPermittedModulesIds);
  const getPermittedModules = getPermittedModulesCallback(permittedModulesIds);
  const permittedRoutes = routes.filter(getPermittedModules);

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Suspense
        fallback={
          <Layout>
            <Spinner />
          </Layout>
        }
      >
        <Switch>
          {permittedRoutes.map((props) => (
            <RouteWrapper key={props.path} {...props} />
          ))}
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
}

export default Router;
