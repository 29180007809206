import type { BookingDetails } from 'routes/tourOperator/bookings/types/bookingDetails/bookingDetailsTypes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type BookingCo2State = {
  value: BookingDetails['co2'] | null;
};

const initialState: BookingCo2State = {
  value: null,
};

function setBookingCo2Function(state: BookingCo2State, action: PayloadAction<BookingCo2State['value']>) {
  return {
    ...state,
    value: action.payload,
  };
}

function resetStateFunction() {
  return initialState;
}

const slice = createSlice({
  name: 'bookingDetails.bookingCo2',
  initialState,
  reducers: {
    setBookingCo2: setBookingCo2Function,
    resetState: resetStateFunction,
  },
});

export const { setBookingCo2, resetState } = slice.actions;
export default slice.reducer;
