import type { CSSObject } from 'styled-components';
import React from 'react';
import styled from 'styled-components/macro';

type CollapsibleCardHeaderProps = {
  styles?: { root?: React.CSSProperties; title?: React.CSSProperties };
  children: React.ReactNode;
};

function CollapsibleCardHeader({ styles = {}, children }: CollapsibleCardHeaderProps): JSX.Element {
  return (
    <Root styles={styles.root as CSSObject}>
      <Wrapper>
        <Title styles={styles.title as CSSObject}>{children}</Title>
      </Wrapper>
    </Root>
  );
}

export default CollapsibleCardHeader;

type RootProps = {
  styles?: CSSObject;
};

const Root = styled.div<RootProps>`
  min-height: 48px;
  margin-bottom: -1px;
  padding: 0 24px;
  color: ${({ theme }) => theme.palette.text.heading};
  font-weight: 500;
  font-size: 16px;
  background: transparent;
  border-radius: 2px 2px 0 0;

  ${({ styles }) => styles};
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  &:before {
    display: table;
    content: '';
  }

  &:after {
    display: table;
    clear: both;
    content: '';
  }
`;

const Title = styled.div<RootProps>`
  display: inline-block;
  flex: 1 1;
  padding: 16px 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  ${({ styles }) => styles};
`;
