import { FlightDefinitionRulesRequest } from 'common/dataService/types/flightDefinintionRulesTypes';
import type { AppDispatch, RootState } from 'state';
import { fetchFlightDefinitionRules } from '../reducers/DefinitionRules';

async function executeFetchDefinitionRules(dispatch: AppDispatch, getState: () => RootState): Promise<void> {
  const { page, takeCount, sorting } = getState().flightDefinitionRules.definitionRules;
  const request = {} as FlightDefinitionRulesRequest;
  request.page = page;
  request.takeCount = takeCount;
  if (sorting) {
    var sortParam = sorting.split(' ');
    request.sortColumn = sortParam[0];
    request.sortDirection = sortParam[1];
  }
  await dispatch(fetchFlightDefinitionRules(request));
}

export default executeFetchDefinitionRules;
