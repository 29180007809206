import type { RootState } from 'state';
import type { FlightRoute } from 'common/packageCache';

function getFlightRoutesItems(state: RootState): FlightRoute[] {
  const { value } = state.flightRoutes.routes;

  if (!value) {
    return [];
  }

  return value.items;
}

export default getFlightRoutesItems;
