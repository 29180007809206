import type { AppThunk } from 'state';
import type { GetMaxFlightDurationRequestParameters } from 'common/dataService';
import fetchMaxFlightDuration from './fetchMaxFlightDuration';
import getDurationForRoute from '../selectors/getDurationForRoute';

function loadMaxFlightDuration(requestParameters: GetMaxFlightDurationRequestParameters): AppThunk<number | null> {
  return async function loadMaxFlightDurationThunk(dispatch, getState) {
    try {
      const { airportFrom, airportTo } = requestParameters;
      const route = `${airportFrom}${airportTo}`;
      let duration = getDurationForRoute(route)(getState());

      if (duration !== null) {
        return duration;
      }

      await dispatch(fetchMaxFlightDuration(requestParameters));

      duration = getDurationForRoute(route)(getState());

      if (duration !== null) {
        return duration;
      }
    } catch (error) {
      window.Rollbar.error(error);
    }

    return null;
  };
}

export default loadMaxFlightDuration;
