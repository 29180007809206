import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import removeAtIndex from 'utils/immutability/removeAtIndex';

export type WebsiteFiltersOptionsState = {
  fetching: string[];
  affiliateId: { value: number; label: string; title: string }[] | null;
  countryCode: { value: string; label: string; title: string }[] | null;
  langCode: { value: string; label: string; title: string }[] | null;
  flightType: { value: string; label: string; title: string }[] | null;
};

export type SetWebsiteFiltersOptionsPayload = {
  affiliateId: WebsiteFiltersOptionsState['affiliateId'];
  countryCode: WebsiteFiltersOptionsState['countryCode'];
  langCode: WebsiteFiltersOptionsState['langCode'];
  flightType: WebsiteFiltersOptionsState['flightType'];
};

const initialState: WebsiteFiltersOptionsState = {
  fetching: [],
  affiliateId: null,
  countryCode: null,
  langCode: null,
  flightType: null,
};
function pushToFetchingFunction(state: WebsiteFiltersOptionsState, action: PayloadAction<string>) {
  return {
    ...state,
    fetching: [...state.fetching, action.payload],
  };
}
function removeFromFetchingFunction(state: WebsiteFiltersOptionsState, action: PayloadAction<string>) {
  const index = state.fetching.indexOf(action.payload);

  if (index === -1) {
    return state;
  }

  return {
    ...state,
    fetching: removeAtIndex(state.fetching, index),
  };
}

function setAffiliateIdFunction(
  state: WebsiteFiltersOptionsState,
  action: PayloadAction<WebsiteFiltersOptionsState['affiliateId']>
) {
  return {
    ...state,
    affiliateId: action.payload,
  };
}

function setCountryCodeFunction(
  state: WebsiteFiltersOptionsState,
  action: PayloadAction<WebsiteFiltersOptionsState['countryCode']>
) {
  return {
    ...state,
    countryCode: action.payload,
  };
}

function setLangCodeFunction(
  state: WebsiteFiltersOptionsState,
  action: PayloadAction<WebsiteFiltersOptionsState['langCode']>
) {
  return {
    ...state,
    langCode: action.payload,
  };
}
function setFlightTypeFunction(
  state: WebsiteFiltersOptionsState,
  action: PayloadAction<WebsiteFiltersOptionsState['flightType']>
) {
  return {
    ...state,
    flightType: action.payload,
  };
}
function resetStateFunction() {
  return initialState;
}

const slice = createSlice({
  name: 'websiteFilters.options',
  initialState,
  reducers: {
    pushToFetching: pushToFetchingFunction,
    removeFromFetching: removeFromFetchingFunction,
    setAffiliateId: setAffiliateIdFunction,
    setCountryCode: setCountryCodeFunction,
    setLangCode: setLangCodeFunction,
    setFlightType: setFlightTypeFunction,
    resetState: resetStateFunction,
  },
});

export const {
  pushToFetching,
  removeFromFetching,
  setAffiliateId,
  setCountryCode,
  setLangCode,
  setFlightType,
  resetState,
} = slice.actions;
export default slice.reducer;
