import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _cloneDeep from 'lodash/cloneDeep';

export type FilterConfig = {
  disabled: boolean;
};

export type BookingsOverviewFiltersConfigState = {
  search: FilterConfig;
  textSearchType: FilterConfig;
  showIncomplete: FilterConfig;
  dateType: FilterConfig;
  dateFrom: FilterConfig;
  dateTo: FilterConfig;
  hotelName: FilterConfig;
  departureAirport: FilterConfig;
  destinationCode: FilterConfig;
  flightProvider: FilterConfig;
  hotelProvider: FilterConfig;
  bookingStatus: FilterConfig;
  productStatus: FilterConfig;
  promocodeId: FilterConfig;
  websiteIds: FilterConfig;
  affiliateId: FilterConfig;
  flightCarrier: FilterConfig;
  showRatedBookings: FilterConfig;
  showRequestedRefund: FilterConfig;
  product: FilterConfig;
  refundPromoCodeId: FilterConfig;
};

type SetPropertyPayload<T> = {
  property: keyof FilterConfig;
  payload: T;
};

const initialState: BookingsOverviewFiltersConfigState = {
  search: { disabled: false },
  textSearchType: { disabled: false },
  showIncomplete: { disabled: false },
  dateType: { disabled: false },
  dateFrom: { disabled: false },
  dateTo: { disabled: false },
  hotelName: { disabled: false },
  departureAirport: { disabled: false },
  destinationCode: { disabled: false },
  flightProvider: { disabled: false },
  hotelProvider: { disabled: false },
  bookingStatus: { disabled: false },
  productStatus: { disabled: false },
  promocodeId: { disabled: false },
  websiteIds: { disabled: false },
  affiliateId: { disabled: false },
  flightCarrier: { disabled: false },
  showRatedBookings: { disabled: false },
  showRequestedRefund: { disabled: false },
  product: { disabled: false },
  refundPromoCodeId: { disabled: false }
};

function setSearchPropertyFunction(
  state: BookingsOverviewFiltersConfigState,
  action: PayloadAction<SetPropertyPayload<boolean>>
) {
  const { property, payload } = action.payload;
  return {
    ...state,
    search: {
      ...state.search,
      [property]: payload,
    },
  };
}

function setTextSearchTypePropertyFunction(
  state: BookingsOverviewFiltersConfigState,
  action: PayloadAction<SetPropertyPayload<boolean>>
) {
  const { property, payload } = action.payload;
  return {
    ...state,
    textSearchType: {
      ...state.textSearchType,
      [property]: payload,
    },
  };
}

function setShowIncompletePropertyFunction(
  state: BookingsOverviewFiltersConfigState,
  action: PayloadAction<SetPropertyPayload<boolean>>
) {
  const { property, payload } = action.payload;
  return {
    ...state,
    showIncomplete: {
      ...state.showIncomplete,
      [property]: payload,
    },
  };
}

function setShowRatedBookingsFunction(
  state: BookingsOverviewFiltersConfigState,
  action: PayloadAction<SetPropertyPayload<boolean>>
) {
  const { property, payload } = action.payload;
  return {
    ...state,
    showRatedBookings: {
      ...state.showRatedBookings,
      [property]: payload,
    },
  };
}

function setShowRequestedRefundFunction(
  state: BookingsOverviewFiltersConfigState,
  action: PayloadAction<SetPropertyPayload<boolean>>
) {
  const { property, payload } = action.payload;
  return {
    ...state,
    showRequestedRefund: {
      ...state.showRequestedRefund,
      [property]: payload,
    },
  };
}

function setDateTypePropertyFunction(
  state: BookingsOverviewFiltersConfigState,
  action: PayloadAction<SetPropertyPayload<boolean>>
) {
  const { property, payload } = action.payload;
  return {
    ...state,
    dateType: {
      ...state.dateType,
      [property]: payload,
    },
  };
}

function setDateFromPropertyFunction(
  state: BookingsOverviewFiltersConfigState,
  action: PayloadAction<SetPropertyPayload<boolean>>
) {
  const { property, payload } = action.payload;
  return {
    ...state,
    dateFrom: {
      ...state.dateFrom,
      [property]: payload,
    },
  };
}

function setDateToPropertyFunction(
  state: BookingsOverviewFiltersConfigState,
  action: PayloadAction<SetPropertyPayload<boolean>>
) {
  const { property, payload } = action.payload;
  return {
    ...state,
    dateTo: {
      ...state.dateTo,
      [property]: payload,
    },
  };
}

function setHotelNamePropertyFunction(
  state: BookingsOverviewFiltersConfigState,
  action: PayloadAction<SetPropertyPayload<boolean>>
) {
  const { property, payload } = action.payload;
  return {
    ...state,
    hotelName: {
      ...state.hotelName,
      [property]: payload,
    },
  };
}

function setDepartureAirportPropertyFunction(
  state: BookingsOverviewFiltersConfigState,
  action: PayloadAction<SetPropertyPayload<boolean>>
) {
  const { property, payload } = action.payload;
  return {
    ...state,
    departureAirport: {
      ...state.departureAirport,
      [property]: payload,
    },
  };
}

function setDestinationCodePropertyFunction(
  state: BookingsOverviewFiltersConfigState,
  action: PayloadAction<SetPropertyPayload<boolean>>
) {
  const { property, payload } = action.payload;
  return {
    ...state,
    destinationCode: {
      ...state.destinationCode,
      [property]: payload,
    },
  };
}

function setFlightProviderPropertyFunction(
  state: BookingsOverviewFiltersConfigState,
  action: PayloadAction<SetPropertyPayload<boolean>>
) {
  const { property, payload } = action.payload;
  return {
    ...state,
    flightProvider: {
      ...state.flightProvider,
      [property]: payload,
    },
  };
}

function setHotelProviderPropertyFunction(
  state: BookingsOverviewFiltersConfigState,
  action: PayloadAction<SetPropertyPayload<boolean>>
) {
  const { property, payload } = action.payload;
  return {
    ...state,
    hotelProvider: {
      ...state.hotelProvider,
      [property]: payload,
    },
  };
}

function setBookingStatusPropertyFunction(
  state: BookingsOverviewFiltersConfigState,
  action: PayloadAction<SetPropertyPayload<boolean>>
) {
  const { property, payload } = action.payload;
  return {
    ...state,
    bookingStatus: {
      ...state.bookingStatus,
      [property]: payload,
    },
  };
}

function setProductFilterPropertyFunction(
  state: BookingsOverviewFiltersConfigState,
  action: PayloadAction<SetPropertyPayload<boolean>>
) {
  const { property, payload } = action.payload;
  return {
    ...state,
    product: {
      ...state.product,
      [property]: payload,
    },
  };
}

function setProductStatusPropertyFunction(
  state: BookingsOverviewFiltersConfigState,
  action: PayloadAction<SetPropertyPayload<boolean>>
) {
  const { property, payload } = action.payload;
  return {
    ...state,
    productStatus: {
      ...state.productStatus,
      [property]: payload,
    },
  };
}

function setPromocodeIdPropertyFunction(
  state: BookingsOverviewFiltersConfigState,
  action: PayloadAction<SetPropertyPayload<boolean>>
) {
  const { property, payload } = action.payload;
  return {
    ...state,
    promocodeId: {
      ...state.promocodeId,
      [property]: payload,
    },
  };
}

function setRefundPromoCodeIdPropertyFunction(
  state: BookingsOverviewFiltersConfigState,
  action: PayloadAction<SetPropertyPayload<boolean>>
) {
  const { property, payload } = action.payload;
  return {
    ...state,
    refundPromoCodeId: {
      ...state.refundPromoCodeId,
      [property]: payload,
    },
  };
}

function setWebsiteIdsPropertyFunction(
  state: BookingsOverviewFiltersConfigState,
  action: PayloadAction<SetPropertyPayload<boolean>>
) {
  const { property, payload } = action.payload;
  return {
    ...state,
    websiteIds: {
      ...state.websiteIds,
      [property]: payload,
    },
  };
}

function setAffiliateIdPropertyFunction(
  state: BookingsOverviewFiltersConfigState,
  action: PayloadAction<SetPropertyPayload<boolean>>
) {
  const { property, payload } = action.payload;
  return {
    ...state,
    affiliateId: {
      ...state.affiliateId,
      [property]: payload,
    },
  };
}

function disableFiltersFunction(
  state: BookingsOverviewFiltersConfigState,
  action: PayloadAction<(keyof BookingsOverviewFiltersConfigState)[]>
) {
  const nextState = _cloneDeep(state);

  action.payload.forEach((filter) => {
    nextState[filter].disabled = true;
  });

  return nextState;
}

function resetStateFunction() {
  return initialState;
}

const slice = createSlice({
  name: 'bookingsOverviewFilters.config',
  initialState,
  reducers: {
    setSearchProperty: setSearchPropertyFunction,
    setTextSearchTypeProperty: setTextSearchTypePropertyFunction,
    setShowIncompleteProperty: setShowIncompletePropertyFunction,
    setShowRatedBookings: setShowRatedBookingsFunction,
    setDateTypeProperty: setDateTypePropertyFunction,
    setDateFromProperty: setDateFromPropertyFunction,
    setDateToProperty: setDateToPropertyFunction,
    setHotelNameProperty: setHotelNamePropertyFunction,
    setDepartureAirportProperty: setDepartureAirportPropertyFunction,
    setDestinationCodeProperty: setDestinationCodePropertyFunction,
    setFlightProviderProperty: setFlightProviderPropertyFunction,
    setHotelProviderProperty: setHotelProviderPropertyFunction,
    setBookingStatusProperty: setBookingStatusPropertyFunction,
    setProductFilterProperty: setProductFilterPropertyFunction,
    setProductStatusProperty: setProductStatusPropertyFunction,
    setPromocodeIdProperty: setPromocodeIdPropertyFunction,
    setWebsiteIdsProperty: setWebsiteIdsPropertyFunction,
    setAffiliateIdProperty: setAffiliateIdPropertyFunction,
    disableFilters: disableFiltersFunction,
    resetState: resetStateFunction,
    setShowRequestedRefund: setShowRequestedRefundFunction,
    setRefundPromoCodeIdProperty: setRefundPromoCodeIdPropertyFunction
  },
});

export const {
  setSearchProperty,
  setTextSearchTypeProperty,
  setShowIncompleteProperty,
  setShowRatedBookings,
  setDateTypeProperty,
  setDateFromProperty,
  setDateToProperty,
  setHotelNameProperty,
  setDepartureAirportProperty,
  setDestinationCodeProperty,
  setFlightProviderProperty,
  setHotelProviderProperty,
  setBookingStatusProperty,
  setProductStatusProperty,
  setPromocodeIdProperty,
  setWebsiteIdsProperty,
  setAffiliateIdProperty,
  disableFilters,
  resetState,
  setShowRequestedRefund,
  setProductFilterProperty
} = slice.actions;
export default slice.reducer;
