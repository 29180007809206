import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip, Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import FontAwesomeIcon from 'comp/wraps/faIcon/FAIcon';
import { StaticRouteModel } from 'common/dataService/types/staticRouteTypes';
import getStaticRoutesVisibleModel from '../../../reducers/selectors/getstaticRoutesVisibleModel';
import { setEditModel, setVisibleModule } from '../../../reducers/slice';

function renderEditCell(text: string, record: StaticRouteModel): JSX.Element {
  return <UpdateStaticRoute record={record} />;
}

export default renderEditCell;

type StaticRouteModelProp = {
  record: StaticRouteModel;
};

function UpdateStaticRoute({ record }: StaticRouteModelProp): JSX.Element {
  const visible = useSelector(getStaticRoutesVisibleModel);

  const dispatch = useDispatch();

  function onClickEdit(event: React.MouseEvent<HTMLAnchorElement>) {
    dispatch(setVisibleModule(!visible));
    dispatch(setEditModel(record));
  }

  return (
    <Tooltip title={<FormattedMessage id='edit' />}>
      <a onClick={onClickEdit}>
        <Button type='primary' icon={<FontAwesomeIcon icon='pen' />} ghost />
      </a>
    </Tooltip>
  );
}
