import type { Website } from 'common/dataService/types/websitesTypes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import FetchingStatus from 'constants/statuses/FetchingStatus';

export type WebsitesState = {
  fetching: FetchingStatus | null;
  value: Website[] | null;
};

const initialState: WebsitesState = {
  fetching: null,
  value: null,
};

function setFetchingFunction(state: WebsitesState, action: PayloadAction<WebsitesState['fetching']>) {
  return {
    ...state,
    fetching: action.payload,
  };
}

function setWebsitesFunction(state: WebsitesState, action: PayloadAction<WebsitesState['value']>) {
  return {
    ...state,
    value: action.payload,
  };
}

const slice = createSlice({
  name: 'dataService.websites.websites',
  initialState,
  reducers: {
    setFetching: setFetchingFunction,
    setWebsites: setWebsitesFunction,
  },
});

export const { setFetching, setWebsites } = slice.actions;
export default slice.reducer;
