import type { RootState } from 'state';

function getFlightDefinitionRulesTotalCount(state: RootState): number {
  const { value } = state.flightDefinitionRules.definitionRules;

  if (!value) {
    return 0;
  }

  return value.totalCount;
}

export default getFlightDefinitionRulesTotalCount;
