import type { AppDispatch, RootState } from 'state';
import type { SelectOption } from 'types/common/SelectOption';
import { getDestinationAirportGroupings } from 'common/dataService';
import { getAirportsFromOptions, getAirportsToOptions, getAirportsFromValue } from '../index';

function getOptionsForAirportsTo(dispatch: AppDispatch, getState: () => RootState): SelectOption<string>[] {
  const destinationGroupings = getDestinationAirportGroupings(getState());
  const selectedAirportsFrom = getAirportsFromValue(getState());

  if (selectedAirportsFrom.length === 0) {
    const airportsToOptionsMap = getAirportsToOptions(getState());

    if (airportsToOptionsMap !== null) {
      return Object.keys(airportsToOptionsMap)
        .sort((a, b) => a.localeCompare(b))
        .map((item) => {
          if (destinationGroupings) {
            const grouping = destinationGroupings.airportGroupings.find(({ code }) => code === item);
            if (grouping) {
              return {
                value: item,
                label: item,
                data: grouping.airports.map(({ code }) => code),
              };
            }
          }

          return { value: item, label: item };
        });
    }

    return [];
  }

  const airportsFromOptionsMap = getAirportsFromOptions(getState());

  if (airportsFromOptionsMap !== null) {
    return selectedAirportsFrom
      .map((item) => airportsFromOptionsMap[item])
      .flat()
      .map((item) => {
        if (destinationGroupings) {
          const grouping = destinationGroupings.airportGroupings.find(({ code }) => code === item);
          if (grouping) {
            return {
              value: item,
              label: item,
              data: grouping.airports.map(({ code }) => code),
            };
          }
        }

        return { value: item, label: item };
      });
  }

  return [];
}

export default getOptionsForAirportsTo;
