import AirportsFrom from './airportsFrom';
import AirportsTo from './airportsTo';
import Carrier from './carrier';
import IsActive from './isActive';
import IsOperating from './isOperating';
import Stops from './stops';

export type FilterItem = {
  key: string;
  component: () => JSX.Element;
};

const filters: FilterItem[] = [
  {
    key: 'airportsFrom',
    component: AirportsFrom,
  },
  {
    key: 'airportsTo',
    component: AirportsTo,
  },
  {
    key: 'carrier',
    component: Carrier,
  },
  {
    key: 'stops',
    component: Stops,
  },
  {
    key: 'isActive',
    component: IsActive,
  },
  {
    key: 'isOperating',
    component: IsOperating,
  },
];

export default filters;
