import type { FormInstance } from 'antd/lib/form/hooks/useForm';
import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components/macro';
import { Form } from 'antd';
import _keyBy from 'lodash/keyBy';
import _pick from 'lodash/pick';
import { InnerContextProvider, OuterContextProvider } from './context';
import FormProvider, { actionTypes } from './comp/formBuilder';
import loadFormConfigurations from './methods/loadFormConfigurations';
import handleOnFormFinish from './methods/handleOnFormFinish';
import mapScreenFormConfigKeyToActiveActionType from './methods/mapScreenFormConfigKeyToActiveActionType';
import getFormsConnections from './utils/getFormsConnections';
import groupScreenFormsConfigsByData from './utils/groupScreenFormsConfigsByData';
import type { OuterContextShape, InnerRef, InnerRefProperties, InnerRefFragment, FragmentProperties } from './context';
import type { ScreenConfig, ScreenHandlers, ScreenFormsConfigsGroupedByData } from './types/screenConfigTypes';
import type { Forms } from './types/formTypes';
import type { FormsConnections } from './types/eventsTypes';
import type { FormConfig, Enum } from './comp/formBuilder/types/formConfigTypes';
import type { FormFieldsValidators } from './comp/formBuilder';

type ScreenBuilderProps = {
  contextValues: OuterContextShape;
  screenConfig: ScreenConfig;
  data: Record<string, Record<string, unknown>[] | Record<string, unknown> | Enum[] | null>;
  initialPlaceholdersValues: number[];
  handlers: ScreenHandlers;
  validators?: FormFieldsValidators;
  formSubmit?: FormInstance;
};

function ScreenBuilder({
  contextValues,
  screenConfig,
  data,
  initialPlaceholdersValues,
  handlers,
  validators,
  formSubmit,
}: ScreenBuilderProps): JSX.Element {
  const [formsConfigurations, setFormsConfigurations] = useState<Record<string, FormConfig>>();
  const [formsConnections, setFormsConnections] = useState<FormsConnections>();
  const [formsGroupedByData, setFormsGroupedByData] = useState<ScreenFormsConfigsGroupedByData[]>();
  const innerRef = useRef<InnerRef>();

  // Side effect for updating values if the screen configuration changes.
  // In theory this should never happen, so this would be executed only once at component mount.
  useEffect(() => {
    // Forms that use the same data and have similar path to values are usually dependent on one another.
    // Here we determine which forms are "connected" and we can use this information to trigger events between them.
    setFormsConnections(getFormsConnections(screenConfig));
    // We are grouping the forms based on the entry data object they use.
    // For each group item we use single Form, this way different forms will return single result when submitting.
    setFormsGroupedByData(groupScreenFormsConfigsByData(screenConfig));
  }, [screenConfig]);

  // In this effect we load the form configuration files and store them in local state (formsConfigurations).
  useEffect(() => {
    async function updateFormsConfigurations() {
      const values = await loadFormConfigurations(screenConfig.key, screenConfig.forms);
      const configToActionMap = mapScreenFormConfigKeyToActiveActionType(screenConfig.forms);

      if (values) {
        const fragmentsNotInRead: string[] = [];
        const fragments = values
          ?.map(({ key }) => key)
          .reduce((prev, curr) => {
            if (configToActionMap[curr] && configToActionMap[curr] !== actionTypes.READ) {
              fragmentsNotInRead.push(curr);
            }

            return {
              ...prev,
              [curr]: { activeActionType: configToActionMap[curr] ?? actionTypes.READ },
            };
          }, {});
        innerRef.current = { fragments, fragmentsNotInRead, formsTouched: false };
      }

      setFormsConfigurations(_keyBy(values, 'key'));
    }
    void updateFormsConfigurations();
  }, [screenConfig]);

  async function onFormFinish(name: string, { forms }: { forms: Forms }) {
    const result = await handleOnFormFinish(forms);

    if (typeof result === 'boolean') {
      formSubmit?.setFieldsValue({ valid: result });
      return;
    }

    formSubmit?.setFieldsValue(result);
  }

  function getInnerRef() {
    return innerRef.current;
  }

  function updateInnerRefProperty(property: InnerRefProperties, value: any) {
    if (innerRef.current) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      innerRef.current[property] = value;
    }
  }

  function updateFragmentProperty(fragmentKey: string, property: FragmentProperties, value: any) {
    if (innerRef.current) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      innerRef.current.fragments[fragmentKey][property] = value;

      if (property === 'activeActionType') {
        const entries = Object.entries(innerRef.current.fragments);
        const fragmentsNotInRead = entries
          .map((entry: [string, InnerRefFragment]) => {
            const [entryKey, entryValue] = entry;
            if (entryValue.activeActionType !== actionTypes.READ) {
              return entryKey;
            }
            return undefined;
          })
          .filter<string>((val): val is string => val !== undefined);
        innerRef.current.fragmentsNotInRead = fragmentsNotInRead;
      }
    }
  }

  return (
    <OuterContextProvider {...contextValues}>
      <InnerContextProvider
        getInnerRef={getInnerRef}
        updateInnerRefProperty={updateInnerRefProperty}
        updateFragmentProperty={updateFragmentProperty}
      >
        <Root>
          <Form.Provider onFormFinish={onFormFinish}>
            {formsConfigurations &&
              formsGroupedByData &&
              formsGroupedByData.map(({ key, forms }) => {
                const screenFormsKeys = forms.map((form) => form.key);
                const formsKeys = forms.map((form) =>
                  form.pathToForm.slice(form.pathToForm.lastIndexOf('/') + 1).replace('.json', '')
                );
                const enumsKeys = forms
                  .map((form) => form.enumsFrom)
                  .filter<string>((value): value is string => value !== undefined);
                const partialFormsConfigurations = _pick(formsConfigurations, formsKeys);
                const partialHandlers = _pick(handlers, screenFormsKeys);
                const partialValidators = validators ? _pick(validators, screenFormsKeys) : undefined;
                const partialEnums = _pick(data, enumsKeys) as Record<string, Enum[]>;
                const partialFormsConnections = formsConnections ? _pick(formsConnections, screenFormsKeys) : undefined;
                const initialValues = data[key] as Record<string, unknown>;

                return (
                  <FormProvider
                    key={key}
                    screenType={screenConfig.type}
                    screenFormsConfigs={forms}
                    formName={key}
                    formsConfigurations={partialFormsConfigurations}
                    initialValues={initialValues}
                    handlers={partialHandlers}
                    validators={partialValidators}
                    enums={partialEnums}
                    formsConnections={partialFormsConnections}
                    initialPlaceholdersValues={initialPlaceholdersValues}
                  />
                );
              })}
            {formSubmit && <Form form={formSubmit} name='footer' />}
          </Form.Provider>
        </Root>
      </InnerContextProvider>
    </OuterContextProvider>
  );
}

export default ScreenBuilder;

const Root = styled.div`
  > *:not(:last-child) {
    margin-bottom: 16px;
  }
`;
