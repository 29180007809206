import type { AppThunk } from 'state';
import FetchingStatus from 'constants/statuses/FetchingStatus';
import { setUpdating, setUpdatingKey } from '../slice';
import { StaticRouteModel } from 'common/dataService/types/staticRouteTypes';
import { DataServiceStaticRoutes } from 'common/dataService/services';
import handleRequestError from 'managers/handleRequestError';

function editStaticRoute(requestParameters: StaticRouteModel): AppThunk {
  return async function editStaticRouteThunk(dispatch, getState) {
    try {
      const { updating } = getState().staticRoutes;

      if (updating === FetchingStatus.InProgress) {
        return;
      }

      dispatch(setUpdatingKey('edit'));
      dispatch(setUpdating(FetchingStatus.InProgress));

      await await DataServiceStaticRoutes.updateStaticRoute(requestParameters);

      dispatch(setUpdating(FetchingStatus.Succeeded));
    } catch (error) {
      dispatch(handleRequestError(error, 'Error occured on updating static route!', setUpdating));
    }
  };
}

export default editStaticRoute;
