import type { AppThunk } from 'state';
import { notification } from 'antd';
import { DataServiceAirports, setDurationForRoute } from 'common/dataService';
import handleRequestError from 'managers/handleRequestError';

function updateMaxFlightDurationForActiveRoute(duration: number): AppThunk {
  return async function updateMaxFlightDurationForActiveRouteThunk(dispatch, getState) {
    try {
      const { activeSearchFiltersKey } = getState().flightCalendars.filtersValues;

      if (!activeSearchFiltersKey) {
        return;
      }

      const [airportFrom, airportTo] = activeSearchFiltersKey?.split('|');
      const requestParameters = {
        airportFrom,
        airportTo,
        duration,
      };

      await DataServiceAirports.upsertMaxFlightDuration(requestParameters);
      dispatch(setDurationForRoute({ key: `${airportFrom}${airportTo}`, value: duration }));
      notification.info({ message: 'Max Flight Duration successfully updated!' });
    } catch (error) {
      dispatch(handleRequestError(error, 'Error occured on upsert max flight duration!'));
    }
  };
}

export default updateMaxFlightDurationForActiveRoute;
